import Accordion from 'react-bootstrap/Accordion';
import Actions from '../commons/actions';
import Basic from '../commons/basic';

function TextInput() {

    let featureProperties = [

        {
            name: 'Text',
            desc: 'Text to be displayed. This property allows you to assign predefined text to the text field.'
        },
        {
            name: 'Auto Store to Key Value',
            desc: 'Using this list, we can select one of the available key-values to sync to the text field. Inputs in the text field will always be synced with the key-values.'
        },
        {
            name: 'Font',
            desc: 'This property allows you to select the font for the text.'
        },
        {
            name: 'Size',
            desc: 'This property allows you to set the font size of the text.'
        },
        {
            name: 'Font Color',
            desc: 'This property sets the font color.'
        },
        {
            name: 'Bold',
            desc: 'Text will be bold when this property is set to true.'
        },
        {
            name: 'Italic',
            desc: 'Text will be italicized when this property is set to true.'
        },
        {
            name: 'Gap Between Lines',
            desc: 'This property specifies the space between the lines. Negative values are not allowed.'
        },
        {
            name: 'Multiline',
            desc: `If this property is set to true, multiline input is enabled.`
        },
        {
            name: 'Horizontal Align',
            desc: 'This property aligns the text in the text box horizontally. We can align left, right and center.'
        },
        // {
        //   name: 'Vertical Align',
        //   desc: `This aligns the text inside the text field vertically. We can align it to Top, Bottom, and Center.`
        // },
        {
            name: 'Show Background',
            desc: `If this is set to true, it enables the background of the text field.`
        },
        {
            name: 'Background Color',
            desc: `Using this property we can select the background color for the text field.`
        },
        {
            name: 'Background Outline Color',
            desc: `This property allows you to choose a different color for the outline of the text field.`
        },
        {
            name: 'Outline Thickness',
            desc: `This property allows you to set the thickness of the outline of the text field.`
        },
        {
            name: 'Background Radius',
            desc: `This property allows you to curve the edges of the text field.`
        },
        // {
        //   name: 'Is Editable',
        //   desc: `This property defines whether the text field is editable or not. If this is selected, the text will be editable. The text field collects user input if this property is selected.`
        // },
        // {
        //   name: 'Is Password',
        //   desc: `If this property is selected, user input will be encoded into password format like -******. This would be helpful while implementing login modules.`
        // },
        // {
        //   name: 'Is Selectable',
        //   desc: `This property defines whether the text field is selectable or not. If this is selected, the text will be selectable.`
        // },
        {
            name: 'Prompt',
            desc: `This sets the prompt for the text field.`
        },
        {
            name: 'Character Restriction',
            desc: `This allows you to restrict illogical/unsupported input formats. For example, a mobile phone number cannot be anything but just numbers. In such cases, you can limit the input in the text field to numbers only. Similarly, there are Alphabet, Integer, Alphanumeric, and Email types.`
        },
        {
            name: 'Maximum Characters',
            desc: `Using this property, we can restrict the maximum number of characters.`
        },
        {
            name: 'Decimal Points',
            desc: `Using this property, we can set number of digits to be shown after decimal point.`
        },


    ]



    let events = [
        {
            event: "Touch",
            trigger: [
                {
                    name: "Tap",
                    desc: "This trigger executes its actions when the user taps on this feature."
                },
                {
                    name: "Double Tap",
                    desc: "This trigger executes its actions when the user double taps on this feature."
                },
                {
                    name: "Long Press",
                    desc: "This trigger executes its actions when the user taps and holds the feature for sometime."
                },
                // {
                //   name: "Pressed",
                //   desc: "This trigger executes its actions when the user has pressed down on this feature."
                // },
                // {
                //   name: "Released",
                //   desc: " This trigger executes its actions when the user has pressed down and released this feature."
                // },
            ]
        },
        {
            event: "Text Input",
            trigger: [
                {
                    name: "Enter Pressed",
                    desc: "This trigger executes its actions when the enter key is pressed."
                },
                {
                    name: "Focus In",
                    desc: "This trigger executes its actions when the text field is in focus."
                },
                {
                    name: "Focus Out",
                    desc: "This trigger executes its actions when the focus is out from the text field."
                },
            ]
        }

    ]

    let actions = [

        // {
        //   actionName: "Feature",
        //   action: [
        //     {
        //       name: "Delete Feature",
        //       desc: "This action deletes the feature from the screen.",
        //     },

        //   ]
        // },
        {
            actionName: "Text",
            action: [
                {
                    name: "Change Text",
                    desc: "This action changes the text in the text field.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Text",
                            paramDesc: "This is the target value."
                        },
                    ]
                },
                {
                    name: "Focus In",
                    desc: "This action sets the focus on the text field.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                    ]
                },
                {
                    name: "Focus Out",
                    desc: "This action takes the focus away from the text field.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                    ]
                },

            ]
        }

    ]


    return (
        <div className="content-main">
            <h2 className="title">Text Input Feature</h2>
            <h3 className="sub-title">Description</h3>
            <p>Text input is a feature that can be used when you want the child to type text.</p>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><h3 className="sub-title">Properties</h3></Accordion.Header>
                    <Accordion.Body>
                        <Basic />
                        <h3 className="sub-title-2">Text Properties</h3>
                        <ul className="property-list">
                            {
                                featureProperties.map((element, index) => {
                                    return (
                                        <li>
                                            <span className="property">{element.name} : </span> {element.desc}
                                        </li>
                                    )
                                })
                            }

                        </ul>

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><h3 className="sub-title">Triggers</h3></Accordion.Header>
                    <Accordion.Body>
                        <ul className="property-list">
                            {
                                events.map((element, index) => {
                                    return (
                                        <li>
                                            <span className="property">{element.event}</span>
                                            <ul>
                                                {
                                                    element.trigger ?
                                                        element.trigger.map(item => (
                                                            <li><span className="property">{item.name} : </span>{item.desc}</li>
                                                        ))
                                                        : ""
                                                }
                                            </ul>
                                        </li>

                                    )
                                })
                            }
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><h3 className="sub-title">Actions</h3></Accordion.Header>
                    <Accordion.Body>

                        <Actions />
                        <ul className="property-list">
                            {
                                actions.map((element, index) => {
                                    return (
                                        <>
                                            <span className="property">{element.actionName} </span>
                                            <ul className="mb-4">
                                                {element.action ?
                                                    element.action.map((action) => (
                                                        <li>
                                                            <span className="property">{action.name} : </span>{action.desc}
                                                            <ul>
                                                                {
                                                                    action.params ?
                                                                        action.params.map(param => (
                                                                            <li><span className="property">{param.paramName} : </span>{param.paramDesc}</li>
                                                                        ))
                                                                        : ""

                                                                }
                                                            </ul>
                                                        </li>
                                                    )) : ""
                                                }
                                            </ul>
                                        </>

                                    )
                                })
                            }
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

        </div>
    );
}
export default TextInput;
