import Modal from 'react-bootstrap/Modal';
import CreatableSelect from 'react-select/creatable';

function CreateCampaignPopup(props) {

    // console.log('data', props.campaignTypeDropdownList)

    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered

        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Create Campaign
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <form onSubmit={props.submitData} className='p-4'>
                    <div className="row mb-4">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="row align-items-end mb-2">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="mb-2">Campaign Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="CampaignName"
                                            // value={individualCampaign.CampaignName}
                                            onChange={(e) => props.createCampaign(e.target.name, e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="row align-items-end mb-2">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="mb-2">Campaign Type</label>
                                        <CreatableSelect
                                            isClearable
                                            name='CampaignType'
                                            options={props.campaignTypeDropdownList}
                                            className="creatable"
                                            onChange={(e) => props.createCampaign('CampaignType', e)}
                                            required
                                        />
                                        {/* <input
                                            type="text"
                                            className="form-control"
                                            name="CampaignType"
                                            // value={individualCampaign.CampaignType}
                                            onChange={(e) => props.createCampaign(e.target.name, e.target.value)}
                                        /> */}

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="row align-items-end mb-2">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="mb-2">URL</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="URL"
                                            // value={individualCampaign.CampaignName}
                                            onChange={(e) => props.createCampaign(e.target.name, e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="row align-items-end mb-2">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="mb-2">Coupon Code</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="CouponCode"
                                            // value={individualCampaign.CampaignType}
                                            onChange={(e) => props.createCampaign(e.target.name, e.target.value)}
                                            required
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="row align-items-end mb-2">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="mb-2">Start Date</label>
                                        <input
                                            type="Date"
                                            className="form-control"
                                            name="StartDate"
                                            // value={individualCampaign.CampaignName}
                                            onChange={(e) => props.createCampaign(e.target.name, e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="row align-items-end mb-2">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="mb-2">End Date</label>
                                        <input
                                            type="Date"
                                            className="form-control"
                                            name="EndDate"
                                            // value={individualCampaign.CampaignType}
                                            onChange={(e) => props.createCampaign(e.target.name, e.target.value)}
                                            required
                                        />

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="row align-items-end mb-2">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="mb-2">Content Category</label>
                                        <CreatableSelect isClearable
                                            name='ContentCategory'
                                            options={props.contentCategoryDropdownList}
                                            className=""
                                            onChange={(e) => props.createCampaign('ContentCategory', e)}
                                            required
                                        />
                                        {/* <input
                                            type="text"
                                            className="form-control"
                                            name="ContentCategory"
                                        // value={individualCampaign.CampaignName}
                                        // onChange={(e) => { handleChange }} 
                                        /> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="row align-items-end mb-2">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="mb-2">Content Type</label>
                                        <CreatableSelect
                                            isClearable
                                            name='ContentType'
                                            options={props.contentTypeDropdownList}
                                            className="creatable"
                                            onChange={(e) => props.createCampaign('ContentType', e)}
                                            required
                                        />
                                        {/* <input
                                            type="text"
                                            className="form-control"
                                            name="ContentType"
                                        // value={individualCampaign.CampaignType}
                                        // onChange={(e) => { handleChange }} 
                                        /> */}

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="row align-items-end mb-2">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="mb-2">Description</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="Description"
                                            // value={individualCampaign.CampaignName}
                                            onChange={(e) => props.createCampaign(e.target.name, e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="row align-items-end mb-2">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="mb-2">Amount Spent</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="AmountSpent"
                                            // value={individualCampaign.CampaignType}
                                            onChange={(e) => props.createCampaign(e.target.name, e.target.value)}
                                            required
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='text-center'>
                        <button className='btn bg-blue text-light px-5'>Submit</button>
                    </div>
                </form>
            </Modal.Body>
            {/* <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer> */}
        </Modal>
    );
}

export default CreateCampaignPopup