import Accordion from 'react-bootstrap/Accordion';
import Actions from '../commons/actions';
import Basic from '../commons/basic';


function RadioGroup() {

    let featureProperties = [

        {
            name: 'Selected Item',
            desc: 'This property is used to set one of the radio buttons to be selected.'
        },
    ]

    let events = [
        {
            name: "Touch",
            params: [
                {
                    paramName: "Tap",
                    paramDesc: "This trigger executes its actions when the user taps on this feature."
                },
                {
                    paramName: "Double Tap",
                    paramDesc: "This trigger executes its actions when the user double taps on this feature."
                },
                {
                    paramName: "Long Press",
                    paramDesc: "This trigger executes its actions when the user taps and holds the feature for sometime."
                },
                // {
                //     paramName: "Pressed",
                //     paramDesc: "This trigger executes its actions when the user has pressed down on this feature."
                // },
                // {
                //     paramName: "Released",
                //     paramDesc: " This trigger executes its actions when the user has pressed down and released this feature."
                // },
            ]
        },
        {
            event: "Swipe",
            name: "Swipe",
            desc: "This trigger executes its actions when the user swipes on the feature in the direction specified.",
            params: [
                {
                    paramName: "Swipe Type",
                    paramDesc: "Using this trigger the user can specify the direction for swipe."
                },
                {
                    paramName: "Key-Values",
                    paramDesc: "This is an optional parameter, we can set the length of the swipe into a key-value."
                },
            ]
        },
        {
            name: "Radio Group",
            params: [
                {
                    paramName: "Selected",
                    paramDesc: "This trigger executes its actions when the feature is selected."
                },
            ]
        },
    ]

    let actions = [

        {
            actionName: "Interaction",
            action: [
                {
                    name: "Change Movement Type",
                    desc: "This action changes the feature’s movement in the scene. It can be set to Free, Vertical, Horizontal or none.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Movement Type",
                            paramDesc: "This is the target movement type."
                        }
                    ]
                },
            ]
        },
        {
            actionName: "Radio Group",
            action: [
                {
                    name: "Change Selection",
                    desc: "This action selects the specified radio button.",
                    params: [
                        {
                            paramName: "Button",
                            paramDesc: "This is the target radio button to be selected."
                        }
                    ]
                },
            ]
        },


    ]


    return (
        <div className="content-main">
            <h2 className="title">Radio Group Feature</h2>
            <h3 className="sub-title">Description</h3>
            <p>The radio group feature works like a container. It groups multiple radio buttons together. Only one radio button in a given group can be selected at a time.</p>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><h3 className="sub-title">Properties</h3></Accordion.Header>
                    <Accordion.Body>
                        <Basic />
                        <h3 className="sub-title-2">Radio Group Properties</h3>
                        <ul className="property-list">
                            {
                                featureProperties.map((element, index) => {
                                    return (
                                        <li>
                                            <span className="property">{element.name} : </span> {element.desc}
                                        </li>
                                    )
                                })
                            }
                        </ul>

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><h3 className="sub-title">Triggers</h3></Accordion.Header>
                    <Accordion.Body>
                        <ul className="property-list">
                            {
                                events.map((element, index) => {
                                    return (
                                        <>
                                            <span className="property">{element.event}</span>
                                            <li className="single-list">
                                                <span className="property">{element.name} : </span>{element.desc}
                                                <ul>
                                                    {
                                                        element.params ?
                                                            element.params.map(param => (
                                                                <li><span className="property">{param.paramName} : </span>{param.paramDesc}</li>
                                                            ))
                                                            : ""
                                                    }
                                                </ul>
                                            </li>
                                        </>
                                    )
                                })
                            }
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><h3 className="sub-title">Actions</h3></Accordion.Header>
                    <Accordion.Body>
                        <Actions />
                        <ul className="property-list">
                            {
                                actions.map((element, index) => {
                                    return (
                                        <>
                                            <span className="action-name property">{element.actionName} </span>
                                            <ul className="mb-4">
                                                {element.action ?
                                                    element.action.map((action) => (
                                                        <li>
                                                            <span className="property">{action.name} : </span>{action.desc}
                                                            <ul>
                                                                {
                                                                    action.params ?
                                                                        action.params.map(param => (
                                                                            <li><span className="property">{param.paramName} : </span>{param.paramDesc}</li>
                                                                        ))
                                                                        : ""
                                                                }
                                                            </ul>
                                                        </li>
                                                    )) : ""
                                                }
                                            </ul>
                                        </>

                                    )
                                })
                            }
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

        </div>
    )
}
export default RadioGroup;