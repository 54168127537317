import { useEffect, useState } from "react";
import Header from "../../header/header";
import Axios from 'axios'
import * as Constants from '../../../assets/helper/constants'
import Swal from 'sweetalert2';
import LoaderComp from "../../loader";
import DataTable from "react-data-table-component";


function TeacherLessonsPerformance(props) {

    const [lessons, setLessons] = useState('')
    const [individualLesson, setIndividualLesson] = useState('')
    const [completed, setCompleted] = useState('')
    const [dropOff, setDropOff] = useState('')
    const [showLesson, setShowLesson] = useState(false)
    const [lessonName, setLessonName] = useState(false)
    const [lessonId, setLessonId] = useState(false)
    const [teacherName, setTeacherName] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')

    useEffect(() => {
        getLessons()
    }, [])


    const getLessons = () => {
        setIsLoading(true)

        Axios.get(Constants.BASE_URL + `api/dashboard/getAllLessonPerformanceForTeacher?userId=${props.userInfo.Id}`).then((result) => {
            setIsLoading(false)
            if (result.data.status) {
                setLessons(result.data.result)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to fetch lessons.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        });
    }

    const getFilterData = (e) => {
        e.preventDefault()

        if (fromDate.trim() === '') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "From-Date cannot be empty.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }
        if (toDate.trim() === '') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "To-Date cannot be empty.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }
        if (fromDate > toDate) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "From-Date cannot be greate than To-Date",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }
        setIsLoading(true)

        Axios.get(Constants.BASE_URL + `api/dashboard/getAllLessonPerformanceForTeacher?userId=${props.userInfo.Id}&fromDate=${fromDate}&toDate=${toDate}`).then((result) => {
            setIsLoading(false)
            if (result.data.status) {
                setLessons(result.data.result)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to fetch lessons.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        });
    }

    const getIndividualLesson = (lessonId, lessonName) => {

        setShowLesson(true)
        setLessonName(lessonName)
        setLessonId(lessonId)
        setIsLoading(true)

        Axios.get(Constants.BASE_URL + `api/dashboard/getLessonPerformanceById/${lessonId}`).then((result) => {
            setIsLoading(false)
            if (result.data.status) {

                let value = ''
                if (result.data.result[0].ListOfViews?.includes('AllViews:')) {
                    value = result.data.result[0].ListOfViews.split('AllViews:')[1].split(',')
                } else {

                    value = result.data.result[0].ListOfViews?.split(',')
                }

                setIndividualLesson(value)

                // let getresult = {
                //     viewsResult: [
                //         {
                //             "Completed": 0,
                //             "DroppedView": "8",
                //             "TimeAgainstViews": "0:40,3:35,8:0,9:0,5:0,10:0,11:0,6:0"
                //         },
                //         {
                //             "Completed": 0,
                //             "DroppedView": "9",
                //             "TimeAgainstViews": "0:3,3:1,8:2,9:2,5:0,10:0,11:0,6:0"
                //         },
                //         {
                //             "Completed": 0,
                //             "DroppedView": "3",
                //             "TimeAgainstViews": "0:40,3:24,8:0,9:0,5:0,10:0,11:0,6:0"
                //         },
                //     ]
                // }

                //Filter invalid data
                let filterInvalid = result.data.viewsResult.filter((obj) => !obj.TimeAgainstViews.includes('AllViews') || !obj.TimeAgainstViews.includes('CurrentView'))

                const convertedArray = filterInvalid.map(obj => {
                    obj.TimeAgainstViews = obj.TimeAgainstViews.split(',')
                        .reduce((acc, pair) => {
                            const [key, value] = pair.split(':');
                            acc[key] = parseInt(value);
                            return acc;
                        }, {});
                    return obj;
                });


                let completed = {}
                let dropOff = {}
                // console.log('conv', convertedArray)
                for (const obj of convertedArray) {
                    for (const key in obj.TimeAgainstViews) {

                        if (obj.Completed == 1) {
                            /**If lessons is completed then increment all scenes by 1
                             * considering all scenes as completed
                            */
                            completed[key] = (completed[key] || 0) + 1;
                        } else if (obj.TimeAgainstViews[key] > 0 && key != obj.DroppedView) {
                            /**else if TimeAgainstViews of a scene is > 0 and is not dropped
                             *  then the scene is considered as completed*/
                            completed[key] = (completed[key] || 0) + 1;
                        }

                        /**if scene is dropped and lesson is not completed
                         * then the scene is considerd as dropped
                        */
                        if (key == obj.DroppedView && obj.Completed != 1) {
                            dropOff[key] = (dropOff[key] || 0) + 1;
                        }
                    }
                }
                // console.log('value', completed, dropOff)
                setCompleted(completed)
                setDropOff(dropOff)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to fetch lessons.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        });

    }
    const getFilteredIndividualLesson = (e) => {
        e.preventDefault()

        if (fromDate.trim() === '') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "From-Date cannot be empty.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }
        if (toDate.trim() === '') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "To-Date cannot be empty.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }
        if (fromDate > toDate) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "From-Date cannot be greate than To-Date",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }

        setIsLoading(true)

        Axios.get(Constants.BASE_URL + `api/dashboard/getLessonPerformanceById/${lessonId}?fromDate=${fromDate}&toDate=${toDate}`).then((result) => {
            setIsLoading(false)
            if (result.data.status) {

                let value = ''
                if (result.data.result[0].ListOfViews.includes('AllViews:')) {
                    value = result.data.result[0].ListOfViews.split('AllViews:')[1].split(',')
                } else {

                    value = result.data.result[0].ListOfViews.split(',')
                }

                setIndividualLesson(value)

                // let getresult = {
                //     viewsResult: [
                //         {
                //             "Completed": 0,
                //             "DroppedView": "8",
                //             "TimeAgainstViews": "0:40,3:35,8:0,9:0,5:0,10:0,11:0,6:0"
                //         },
                //         {
                //             "Completed": 0,
                //             "DroppedView": "9",
                //             "TimeAgainstViews": "0:3,3:1,8:2,9:2,5:0,10:0,11:0,6:0"
                //         },
                //         {
                //             "Completed": 0,
                //             "DroppedView": "3",
                //             "TimeAgainstViews": "0:40,3:24,8:0,9:0,5:0,10:0,11:0,6:0"
                //         },
                //     ]
                // }

                //Filter invalid data
                let filterInvalid = result.data.viewsResult.filter((obj) => !obj.TimeAgainstViews.includes('AllViews') || !obj.TimeAgainstViews.includes('CurrentView'))

                const convertedArray = filterInvalid.map(obj => {
                    obj.TimeAgainstViews = obj.TimeAgainstViews.split(',')
                        .reduce((acc, pair) => {
                            const [key, value] = pair.split(':');
                            acc[key] = parseInt(value);
                            return acc;
                        }, {});
                    return obj;
                });


                let completed = {}
                let dropOff = {}
                // console.log('conv', convertedArray)
                for (const obj of convertedArray) {
                    for (const key in obj.TimeAgainstViews) {

                        if (obj.Completed == 1) {
                            /**If lessons is completed then increment all scenes by 1
                             * considering all scenes as completed
                            */
                            completed[key] = (completed[key] || 0) + 1;
                        } else if (obj.TimeAgainstViews[key] > 0 && key != obj.DroppedView) {
                            /**else if TimeAgainstViews of a scene is > 0 and is not dropped
                             *  then the scene is considered as completed*/
                            completed[key] = (completed[key] || 0) + 1;
                        }

                        /**if scene is dropped and lesson is not completed
                         * then the scene is considerd as dropped
                        */
                        if (key == obj.DroppedView && obj.Completed != 1) {
                            dropOff[key] = (dropOff[key] || 0) + 1;
                        }
                    }
                }
                // console.log('value', completed, dropOff)
                setCompleted(completed)
                setDropOff(dropOff)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to fetch lessons.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        });

    }

    const customStyles = {
        headRow: {
            style: {
                // backgroundColor: '#fff',
            },
        },
        headCells: {
            style: {
                // color: '#777',
                fontSize: '16px',
                // fontWeight: '700',
                // paddingBottom: '20px',
                // paddingTop: '50px'
            },
        },
        rows: {
            style: {
                padding: "5px",
                // backgroundColor: '#fff',
                fontSize: '14px',
                // color: '#777',
            },
        },
        pagination: {
            style: {
                // border: 'none',
                // backgroundColor: '#fff',
                // fontSize: '14px'
            },
        },
    };

    const columns = [
        {
            name: 'Sl No.',
            grow: 0,
            // center: true,
            selector: (row, index) => {
                return (
                    <div className="">
                        {index + 1}
                    </div>
                )
            }
        },
        {
            name: 'Lesson Name',
            grow: 2,
            wrap: true,
            center: false,
            sortable: true,
            selector: (row) => row.Name
        },
        // {
        //     name: 'Teacher Name',
        //     maxWidth: '400px',
        //     // center: true,
        //     grow: 2,
        //     wrap: true,
        //     sortable: true,
        //     selector: (row) => row.TeacherName
        // },
        {
            name: "Views",
            wrap: true,
            // center: true,
            sortable: true,
            grow: 1,
            selector: (row) => row.TotalViews
        },
        {
            name: 'Completed',
            // center: true,
            // maxWidth: '100px',
            sortable: true,
            grow: 1.5,
            selector: (row) => row.TotalCompletedViews
        },
        {
            name: "Drop offs",
            // center: true,
            sortable: true,
            grow: 1,
            selector: (row) => row.TotalViews - row.TotalCompletedViews
        },
        {
            name: "Completed%",
            // center: true,
            grow: 1.5,
            sortable: true,
            selector: row => (isNaN(((row.TotalCompletedViews / row.TotalViews) * 100).toFixed(2)) ? 0 : ((row.TotalCompletedViews / row.TotalViews) * 100).toFixed(2))
        },
        {
            name: "Drop off%",
            // center: true,
            grow: 1,
            sortable: true,
            selector: row => {
                return (isNaN((((row.TotalViews - row.TotalCompletedViews) / row.TotalViews) * 100).toFixed(2)) ? 0 : (((row.TotalViews - row.TotalCompletedViews) / row.TotalViews) * 100).toFixed(2))
            }
        },
        {
            name: "View Lesson",
            // center: true,
            grow: 1,
            selector: row => {
                return (
                    <div className="d-flex">
                        <button className="btn bg-blue btn-sm text-light"
                            onClick={() => getIndividualLesson(row.Id, row.Name)}>View <i className="fa fa-chevron-right"></i>
                        </button>
                    </div>
                )
            }
        },
    ];

    const sortViews = (row1, row2) => {


        var comp1 = completed[row1.split(':')[0]]
        if (comp1 === undefined || comp1 === null) {
            comp1 = 0
        }

        var drop1 = dropOff[row1.split(':')[0]]
        if (drop1 === undefined || drop1 === null) {
            drop1 = 0
        }

        var comp2 = +completed[row2.split(':')[0]]
        if (comp2 === undefined || comp2 === null) {
            comp2 = 0
        }

        var drop2 = dropOff[row2.split(':')[0]]
        if (drop2 === undefined || drop2 === null) {
            drop2 = 0
        }

        if ((comp1 + drop1) > (comp2 + drop2)) {
            return 1
        }
        if ((comp2 + drop2) > (comp1 + drop1)) {
            return -1
        }
        return 0;
    }

    const sortCompleted = (row1, row2) => {


        var comp1 = completed[row1.split(':')[0]]
        if (comp1 === undefined || comp1 === null) {
            comp1 = 0
        }

        var drop1 = dropOff[row1.split(':')[0]]
        if (drop1 === undefined || drop1 === null) {
            drop1 = 0
        }

        var comp2 = completed[row2.split(':')[0]]
        if (comp2 === undefined || comp2 === null) {
            comp2 = 0
        }

        var drop2 = dropOff[row2.split(':')[0]]
        if (drop2 === undefined || drop2 === null) {
            drop2 = 0
        }

        let complete1 = ((comp1 / (comp1 + drop1)) * 100).toFixed(2) > 0 ? ((comp1 / (comp1 + drop1)) * 100).toFixed(2) : 0
        let complete2 = ((comp2 / (comp2 + drop2)) * 100).toFixed(2) > 0 ? ((comp2 / (comp2 + drop2)) * 100).toFixed(2) : 0


        // console.log('drop', typeof complete1)
        // console.log('drop', +dropped1 > +dropped2)


        if (+complete1 > +complete2) {
            return 1
        }
        if (+complete2 > +complete1) {
            return -1
        }
        return 0;
    }

    const sortDropOff = (row1, row2) => {

        var comp1 = completed[row1.toString().split(':')[0]]
        if (comp1 === undefined || comp1 === null) {
            comp1 = 0
        }

        var drop1 = dropOff[row1.toString().split(':')[0]]
        if (drop1 === undefined || drop1 === null) {
            drop1 = 0
        }


        var comp2 = completed[row2.toString().split(':')[0]]
        if (comp2 === undefined || comp2 === null) {
            comp2 = 0
        }

        var drop2 = dropOff[row2.toString().split(':')[0]]
        if (drop2 === undefined || drop2 === null) {
            drop2 = 0
        }


        let dropped1 = ((drop1 / (comp1 + drop1)) * 100).toFixed(2) > 0 ? ((drop1 / (comp1 + drop1)) * 100).toFixed(2) : 0
        let dropped2 = ((drop2 / (comp2 + drop2)) * 100).toFixed(2) > 0 ? ((drop2 / (comp2 + drop2)) * 100).toFixed(2) : 0


        if (+dropped1 > +dropped2) {
            return 1
        }
        if (+dropped2 > +dropped1) {
            return -1
        }
        return 0;

    }

    const sceneColumn = [
        {
            name: 'Sl No.',
            grow: 0,
            // center: true,
            selector: (row, index) => {
                return (
                    <div className="d-flex flex-column justify-content-between">
                        {index + 1}
                    </div>
                )
            }
        },
        {
            name: 'Scene Name',
            grow: 2,
            wrap: true,
            center: false,
            sortable: true,
            selector: (row) => row.toString().split(':')[1]

        },

        {
            name: "Views",
            wrap: true,
            sortable: true,
            // center: true,
            selector: (row) => {

                var comp = completed[row.toString().split(':')[0]]
                if (comp === undefined || comp === null) {
                    comp = 0
                }

                var drop = dropOff[row.toString().split(':')[0]]
                if (drop === undefined || drop === null) {
                    drop = 0
                }
                return (

                    <div className="">
                        {comp + drop}
                    </div>
                )

            },
            sortFunction: sortViews,
        },
        {
            name: 'Completed',
            sortable: true,
            // center: true,
            // maxWidth: '100px',
            selector: (row) => (completed[row.toString().split(':')[0]] > 0 ? completed[row.toString().split(':')[0]] : 0),
        },
        {
            name: "Drop offs",
            sortable: true,
            // center: true,
            selector: (row) => (dropOff[row.toString().split(':')[0]] > 0 ? dropOff[row.toString().split(':')[0]] : 0)
        },
        {
            name: "Completed %",
            // center: true,
            sortable: true,
            grow: 1,
            selector: row => {
                var comp = completed[row.toString().split(':')[0]]
                if (comp === undefined || comp === null) {
                    comp = 0
                }

                var drop = dropOff[row.toString().split(':')[0]]
                if (drop === undefined || drop === null) {
                    drop = 0
                }
                return (

                    <div className="d-flex flex-column justify-content-center">
                        {((comp / (comp + drop)) * 100).toFixed(2) > 0 ? ((comp / (comp + drop)) * 100).toFixed(2) : 0}%
                    </div>
                )
            },
            sortFunction: sortCompleted
        },
        {
            name: "Drop off %",
            // center: true,
            grow: 1,
            sortable: true,
            selector: row => {
                var comp = completed[row.toString().split(':')[0]]
                if (comp === undefined || comp === null) {
                    comp = 0
                }

                var drop = dropOff[row.toString().split(':')[0]]
                if (drop === undefined || drop === null) {
                    drop = 0
                }
                return (

                    <div className="d-flex flex-column justify-content-center">
                        {((drop / (comp + drop)) * 100).toFixed(2) > 0 ? ((drop / (comp + drop)) * 100).toFixed(2) : 0}%
                    </div>
                )
            },
            sortFunction: sortDropOff
        },

    ];


    return (
        <div className="row right-panel-container">
            {isLoading ? <LoaderComp isLoading={isLoading} /> : null}

            <div className="col-12">
                <div className="row data-container-wrapper">
                    {
                        !showLesson ?
                            <div className="card border-0">
                                <div className="card-header bg-white">
                                    <form onSubmit={getFilterData}>
                                        <div className="row">
                                            <div className="col-2">
                                                <label>From</label>
                                                <input onChange={(e) => setFromDate(e.target.value)} type="date" className="form-control" required />
                                            </div>
                                            <div className="col-2">
                                                <label>To</label>
                                                <input onChange={(e) => setToDate(e.target.value)} type="date" className="form-control" required />
                                            </div>
                                            <div className="col-2 d-flex align-items-end">
                                                <button type="submit" className="btn bg-blue text-light">Submit</button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                                <div className="card-body">
                                    <DataTable
                                        columns={columns}
                                        data={lessons}
                                        pagination
                                        customStyles={customStyles}
                                        persistTableHead
                                        fixedHeader
                                        fixedHeaderScrollHeight="650px"
                                        highlightOnHover
                                    />
                                </div>
                            </div>
                            :

                            <div className="card border-0">
                                <div className="card-header bg-white">
                                    <div className="d-flex align-items-end justify-content-between">
                                        <div className="row">
                                            <div className="col-12 d-flex align-items-baseline">
                                                <button className="btn bg-blue btn-sm text-light" onClick={() => setShowLesson(false)}><i className="fa fa-chevron-left"></i>Back</button>
                                                <p className="fs-6 ms-4"><span className="fw-bold">Lesson Name</span>: {lessonName}</p>
                                            </div>
                                        </div>

                                        <form onSubmit={(e) => getFilteredIndividualLesson(e)}>
                                            <div className="row">
                                                <div className="col-5">
                                                    <label>From</label>
                                                    <input onChange={(e) => setFromDate(e.target.value)} type="date" className="form-control" required />
                                                </div>
                                                <div className="col-5">
                                                    <label>To</label>
                                                    <input onChange={(e) => setToDate(e.target.value)} type="date" className="form-control" required />
                                                </div>
                                                <div className="col-2 d-flex align-items-end">
                                                    <button type="submin" className="btn bg-blue text-light">Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <DataTable
                                        columns={sceneColumn}
                                        data={individualLesson}
                                        pagination
                                        customStyles={customStyles}
                                        persistTableHead
                                        fixedHeader
                                        fixedHeaderScrollHeight="650px"
                                    />
                                </div>
                            </div>
                    }
                </div>
            </div>

        </div>
    )
}

export default TeacherLessonsPerformance;