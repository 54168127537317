import Accordion from 'react-bootstrap/Accordion';

function KeyValues() {

    let actions = [
        {
            actionName: "Arithmetic",
            action: [
                {
                    name: "Increment",
                    desc: "This action increments the key-value value by 1.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                    ]
                },
                {
                    name: "Decrement",
                    desc: "This action decrements the key-value by 1.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                    ]
                },
                {
                    name: "Increase By",
                    desc: "This action increases the key-value by the given value.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Amount",
                            paramDesc: "This is the amount by which to increase the key-value. "
                        },
                    ]
                },
                {
                    name: "Decrease By",
                    desc: "This action decreases the key-value by the given value.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Amount",
                            paramDesc: "This is the amount by which to decrease the key-value."
                        },
                    ]
                },
                {
                    name: "Multiply By",
                    desc: "This action multiplies the key-value by the given value.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Amount",
                            paramDesc: "This is the amount by which to multiply the key-value."
                        },
                    ]
                },
                {
                    name: "Divide By",
                    desc: "This action divides the key-value by the given value.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Amount",
                            paramDesc: "This is the amount by which to divide the key-value."
                        },
                    ]
                },
                {
                    name: "Raise To The Power",
                    desc: "This action calculates the power of key-value by the given value.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Power",
                            paramDesc: "This is the amount by which to calculate power of the key-value."
                        },
                    ]
                },
                {
                    name: "Calculate",
                    desc: "This action calculates if the target value is in formula format. For example, if the value is 2+3-1, the value is calculated and the resulting 4 is stored in the key-value.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Value",
                            paramDesc: "This is the target value."
                        },
                    ]
                },

            ]
        },
        {
            actionName: "General",
            action: [
                {
                    name: "Set To",
                    desc: " This action sets the target value to the key-value.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Value",
                            paramDesc: "This is the target value."
                        },
                    ]
                },
                {
                    name: "Restore To Original Value",
                    desc: "This action restores the key-value to its initial value.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                    ]
                },
                // {
                //     name: "Restore All Key values ",
                //     desc: "This action restores all the key-value to its initial value.",
                //     params: [
                //         {
                //             paramName: "Delay",
                //             paramDesc: "The length of time to wait in seconds before performing the action."
                //         },
                //     ]
                // },
                {
                    name: "Format For Currency",
                    desc: "This changes the number to currency format.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                    ]
                },
                {
                    name: "Get Random Number Between",
                    desc: "This action generates a random number between two numbers.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Minimum Value",
                            paramDesc: "This is the minimum value."
                        },
                        {
                            paramName: "Maximum Value",
                            paramDesc: "This is the maximum value."
                        },
                    ]
                },
            ]
        },
        {
            actionName: "Text",
            action: [
                {
                    name: "Append",
                    desc: "This action adds the given text to the end of the key-value.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Value",
                            paramDesc: "This is the target value."
                        },
                    ]
                },
                {
                    name: "Backspace",
                    desc: "This action deletes the last character from the text of the key-value.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                    ]
                },
                {
                    name: "To Lower Case",
                    desc: "This action converts upper case text to lower case in key-value.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                    ]
                },
                {
                    name: "To Upper Case",
                    desc: "This action converts upper case text to lower case in key-value.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                    ]
                },
                {
                    name: "Find Pattern In Text",
                    desc: "This action returns the position of the character in the given text.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Key Value To Search",
                            paramDesc: "Key-value which contains the text value."
                        },
                        {
                            paramName: "Pattern",
                            paramDesc: "The character to look for in the text."
                        },
                    ]
                },
                {
                    name: "Get Length Of Text",
                    desc: "This action gives the length of the target value.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Value",
                            paramDesc: "This is the target value."
                        },
                    ]
                },
                {
                    name: "Create Substring",
                    desc: "A substring is a subset of the existing text. You can reduce it by the start and end positions.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Start Position",
                            paramDesc: "This parameter contains the start position."
                        },
                        {
                            paramName: "End Position",
                            paramDesc: "This parameter contains the end position."
                        },
                    ]
                },
            ]
        },
        {
            actionName: "Time",
            action: [
                // {
                //     name: "Get Time In Epoch",
                //     desc: "This action gives the current time in epoch format.",
                //     params: [
                //         {
                //             paramName: "Delay",
                //             paramDesc: "The length of time to wait in seconds before performing the action."
                //         },
                //     ]
                // },
                // {
                //     name: "Get Time In Epoch Milliseconds",
                //     desc: "This action returns the current time in epoch milliseconds format.",
                //     params: [
                //         {
                //             paramName: "Delay",
                //             paramDesc: "The length of time to wait in seconds before performing the action."
                //         },
                //     ]
                // },
                {
                    name: "Get Date in Date",
                    desc: "This action gives the current date.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                    ]
                },
                {
                    name: "Get Date in Month",
                    desc: "This action gives the month of the current date.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                    ]
                },
                {
                    name: "Get Date in Year",
                    desc: "This action gives the year of the current date.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                    ]
                },
                {
                    name: "Get Time Hours",
                    desc: "This action gives the current hour value.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                    ]
                },
                {
                    name: "Get Time Minutes:",
                    desc: "This action gives the current minute value.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                    ]
                },
                {
                    name: "Get Time Seconds",
                    desc: "This action gives the second value in time.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                    ]
                },
            ]
        }
    ]


    return (
        <div className="content-main">
            <h2 className="title">Key Values</h2>
            <h3 className="sub-title">Description</h3>
            <p>Key-Values can be used to store the data in the Lesson. We can also perform different actions on them like mathematical operations.</p>


            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header> <h3 className="sub-title">Actions</h3></Accordion.Header>
                    <Accordion.Body>
                        <ul className="property-list">
                            {
                                actions.map((element, index) => {
                                    return (
                                        <>
                                            <span className="property">{element.actionName} </span>
                                            <ul className="mb-4">
                                                {element.action ?
                                                    element.action.map((action) => (
                                                        <li>
                                                            <span className="property">{action.name} : </span>{action.desc}
                                                            <ul>
                                                                {
                                                                    action.params ?
                                                                        action.params.map(param => (
                                                                            <li><span className="property">{param.paramName} : </span>{param.paramDesc}</li>
                                                                        ))
                                                                        : ""

                                                                }
                                                            </ul>
                                                        </li>
                                                    )) : ""
                                                }
                                            </ul>
                                        </>

                                    )
                                })
                            }
                        </ul>

                    </Accordion.Body>
                </Accordion.Item>
                {/* <Accordion.Item eventKey="1">
                    <Accordion.Header><h3 className="sub-title">Triggers</h3></Accordion.Header>
                    <Accordion.Body>

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><h3 className="sub-title">Actions</h3></Accordion.Header>
                    <Accordion.Body>


                    </Accordion.Body>
                </Accordion.Item> */}
            </Accordion>

        </div>
    )
}
export default KeyValues;