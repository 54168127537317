import React from 'react';
import Axios from 'axios'
import * as Constants from '../assets/helper/constants'
import LoaderComp from './loader'

class VerifyEmail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            uniqueId: '',
            isVerified: '',
            isLoading: false,
        }
    }



    verifyEmail = () => {
        let data = {
            uniqueId: this.state.uniqueId,
        }

        this.setState({ isLoading: true })
        Axios.post(Constants.BASE_URL + "api/aggregator/emailVerification", data, Constants.header).then((result) => {
            this.setState({ isLoading: false })
            if (result.data.status) {
                this.setState({ isVerified: result.data.result })
            } else {
                this.setState({ isVerified: result.data.result })
            }
        });
    }

    componentDidMount() {
        var url = window.location.href.split("/");
        let id = url[url.length - 1]

        this.setState({ uniqueId: id }, () => {
            this.verifyEmail()
        })
    }


    render() {
        return (
            <div className="mainBG">
                {this.state.isLoading ? (<LoaderComp isLoading={this.state.isLoading} />) : null}
                <div className="form">
                    {this.state.isVerified === "" ? <h2 className='verify-text'>Please wait..</h2> : <h2 className='verify-text'>{this.state.isVerified}</h2>}
                </div>
            </div>
        )
    }

}
export default VerifyEmail;