import { useEffect, useState } from "react";
import * as Constants from '../../../assets/helper/constants'

function TeacherProfile(props) {

    const [userInfo, setUserInfo] = useState([])

    useEffect(() => {
        setUserInfo(props.userInfo)
    }, [])
    console.log('img', Constants.BASE_URL + props.userInfo.Photo)
    return (
        <div className="right-panel-container">
            <div className="container-fluid">
                <div className="row mb-4">
                    <div className="col-12">
                        <h2 className="section-name">Profile</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card box-shadows mb-4 text-left p-4 rounded">
                            <div className="row">
                                <div className="col-md-6">
                                    <p className="fw-500">Photos</p>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-12">
                                            <label className="fw-400">Profile Picture</label>
                                            <div className="image-wrap mb-2">

                                                {props.userInfo.Photo ? <img src={Constants.BASE_URL + props.userInfo.Photo} alt="Profile pic" />
                                                    : <div className="rounded-circle bg-blue w-100 h-100 d-flex align-items-center justify-content-center text-light"><span>{props.userInfo.FirstName.slice(0, 1) + props.userInfo.LastName.slice(0, 1)}</span></div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <label className="fw-400 disabled">Banner Image</label>
                                            <div className="image-wrap">
                                                {props.userInfo.BannerImage ? <img src={Constants.BASE_URL + props.userInfo.BannerImage} alt="Banner image" />
                                                    : <div className="rounded-circle bg-blue w-100 h-100 d-flex align-items-center justify-content-center text-light"><span>{props.userInfo.FirstName.slice(0, 1) + props.userInfo.LastName.slice(0, 1)}</span></div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card box-shadows mb-4 text-left p-4 rounded">
                            <div className="row">
                                <div className="col-md-6">
                                    <p className="fw-500">Basic Details</p>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control input-style" value={userInfo?.FirstName + ' ' + userInfo?.LastName} id="floatingInput" placeholder="Name" readOnly />
                                        <label htmlFor="floatingInput">Name</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="number" className="form-control input-style" value={userInfo?.Phone} id="floatingInput" placeholder="Phone number" readOnly />
                                        <label htmlFor="floatingInput">Phone Number</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="email" className="form-control input-style" value={userInfo?.Email} id="floatingInput" placeholder="Email" readOnly />
                                        <label htmlFor="floatingInput">Email address</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card box-shadows mb-4 text-left p-4 rounded">
                            <div className="row">
                                <div className="col-md-6">
                                    <p className="fw-500">Professional Details</p>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control input-style" value={userInfo?.Experience} id="floatingInput" placeholder="Experience in years" readOnly />
                                        <label htmlFor="floatingInput">Experience in years</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control input-style" value={userInfo?.Subjects} id="floatingInput" placeholder="Subjects" readOnly />
                                        <label htmlFor="floatingInput">Subjects</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default TeacherProfile;