import axios from "axios";
import Swal from "sweetalert2";
import * as Constants from '../assets/helper/constants'

export const sendFeedback = (data) => {
    var options = {
        method: 'POST',
        url: Constants.BASE_URL + 'api/AggregatorLessons/feedback',
        headers: {
            'dashboard': true
        },
        data: data
    };

    try {
        axios.request(options).then((response) => {
            if (response.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    html: '<h4>Feedback submitted successfully.</h4>',
                    showConfirmButton: false,
                    timer: 2000
                })
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    html: '<h4>Something went wrong, try again.</h4>',
                    showConfirmButton: false,
                    timer: 2000
                })
            }

        }).catch((error) => {
            console.log('Error: ', error);
        })
    } catch (err) {
        console.log('Error: catch block', err)
        Swal.fire({
            position: 'top-end',
            icon: 'error',
            html: '<h4>Something went wrong, try again.</h4>',
            showConfirmButton: false,
            timer: 2000
        })
    }
}

export const validateEmailOTP = (data) => {
    var options = {
        method: 'POST',
        url: Constants.BASE_URL + 'api/aggregator/generateEmailOTP',
        headers: {
            'dashboard': true,
            'uuid': '123456789'
        },
        data: data
    };

    try {
        axios.request(options).then((response) => {
            if (response.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    html: '<h4>OTP Sent Successfully.</h4>',
                    showConfirmButton: false,
                    timer: 2000
                })
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    html: '<h4>Something went wrong, try again.</h4>',
                    showConfirmButton: false,
                    timer: 2000
                })
            }

        }).catch((error) => {
            console.log('Error: ', error);
        })
    } catch (err) {
        console.log('Error: catch block', err)
        Swal.fire({
            position: 'top-end',
            icon: 'error',
            html: '<h4>Something went wrong, try again.</h4>',
            showConfirmButton: false,
            timer: 2000
        })
    }
}