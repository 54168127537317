import Accordion from 'react-bootstrap/Accordion';
import Actions from '../commons/actions';
import Basic from '../commons/basic';
import Interaction from '../commons/interaction';
import Triggers from '../commons/triggers';

function Video() {


    let featureProperties = [

        {
            name: 'Video',
            desc: 'This property specifies which video from the media library will be played here.'
        },
        {
            name: 'Background Transparency',
            desc: 'This is a value that specifies the transparency of the video’s background. 0 is fully transparent and 1 is fully opaque.'
        },
        {
            name: 'Background Color',
            desc: 'This sets the background color for the unused portion of the feature.'
        },
        {
            name: 'Repeat',
            desc: 'If this is set to true, the video will play in a loop.'
        },
        {
            name: 'Auto Start',
            desc: 'If this is set to true, the video will start playing automatically as soon as the scene starts.'
        },
        {
            name: 'Volume (0-10)',
            desc: 'This sets the volume of the video player. 0 is the minimum and 10 is the maximum value.'
        },
        {
            name: 'Show Controls',
            desc: 'If this is set to true, feature will show the video controls.'
        },
    ]


    let events = [

        {
            event: "Video",
            triggers: [
                {
                    name: "Ends",
                    desc: "This trigger executes its actions when the video duration ends.",
                },
                {
                    name: "Reaches Time",
                    desc: "This trigger executes when the video reaches the time defined by the following parameters.",

                    params: [
                        {
                            paramName: "Minutes",
                            paramDesc: "This value represents minutes."
                        },
                        {
                            paramName: "Seconds",
                            paramDesc: "This value represents seconds."
                        },
                    ]
                }
            ]

        },
    ]

    let actions = [

        // {
        //   actionName: "Feature",
        //   action: [
        //     {
        //       name: "Delete Feature",
        //       desc: "This action deletes the feature from the screen.",
        //     },

        //   ]
        // },
        {
            actionName: "Interaction",
            action: [
                {
                    name: "Change Movement Type",
                    desc: "This action changes the feature’s movement in the scene. It can be set to Free, Vertical, Horizontal or none.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Movement Type",
                            paramDesc: "This is the target movement type."
                        },
                    ]
                },

            ]
        },

        {
            actionName: "Video",
            action: [
                {
                    name: "GO TO",
                    desc: "This action changes the video playback position to the parameter value.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Minutes",
                            paramDesc: "Minute number to seek the video."
                        },
                        {
                            paramName: "Seconds",
                            paramDesc: "Additional seconds number to seek the video."
                        },
                    ]
                },
                {
                    name: "Play",
                    desc: "This action plays the video.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                    ]
                },
                {
                    name: "Pause",
                    desc: "This action pauses the video.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                    ]
                },
                {
                    name: "Load Video",
                    desc: "This action loads a new video into the player.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Video Path",
                            paramDesc: "This is the destination path of the video file."
                        },
                    ]
                },
                {
                    name: "Mute Audio",
                    desc: "This action mutes the video.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                    ]
                },
                {
                    name: "Unmute Audio",
                    desc: "This action unmutes the video.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                    ]
                },
                {
                    name: "Set Volume",
                    desc: "This action sets the volume of the video.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Volume",
                            paramDesc: "This sets the volume of the video player. 0 is the minimum and 10 is the maximum value."
                        },
                    ]
                },

            ]
        }
    ]


    return (
        <div className="content-main">
            <h2 className="title">Video Feature</h2>
            <h3 className="sub-title">Description</h3>
            <p>This is a feature that plays a video on the scene. Video feature comes with the optional controls, it can be enabled or disabled.</p>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><h3 className="sub-title">Properties</h3></Accordion.Header>
                    <Accordion.Body>
                        <Basic />

                        <Interaction />

                        <h3 className="sub-title-2">Video Properties</h3>
                        <ul className="property-list">
                            {
                                featureProperties.map((element, index) => {
                                    return (
                                        <li>
                                            <span className="property">{element.name} : </span> {element.desc}
                                        </li>
                                    )
                                })
                            }
                        </ul>

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><h3 className="sub-title">Triggers</h3></Accordion.Header>
                    <Accordion.Body>
                        <Triggers />
                        <ul className="property-list">
                            {
                                events.map((element, index) => {
                                    return (
                                        <>
                                            <span className="property">{element.event} </span>
                                            <ul className="mb-4">
                                                {element.triggers ?
                                                    element.triggers.map((trigger) => (
                                                        <li>
                                                            <span className="property">{trigger.name} : </span>{trigger.desc}
                                                            <ul>
                                                                {
                                                                    trigger.params ?
                                                                        trigger.params.map(param => (
                                                                            <li><span className="property">{param.paramName} : </span>{param.paramDesc}</li>
                                                                        ))
                                                                        : ""
                                                                }
                                                            </ul>
                                                        </li>
                                                    )) : ""
                                                }
                                            </ul>
                                        </>

                                    )
                                })
                            }
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><h3 className="sub-title">Actions</h3></Accordion.Header>
                    <Accordion.Body>

                        <Actions />
                        <ul className="property-list">
                            {
                                actions.map((element, index) => {
                                    return (
                                        <>
                                            <span className="property">{element.actionName} </span>
                                            <ul className="mb-4">
                                                {element.action ?
                                                    element.action.map((action) => (
                                                        <li>
                                                            <span className="property">{action.name} : </span>{action.desc}
                                                            <ul>
                                                                {
                                                                    action.params ?
                                                                        action.params.map(param => (
                                                                            <li><span className="property">{param.paramName} : </span>{param.paramDesc}</li>
                                                                        ))
                                                                        : ""

                                                                }
                                                            </ul>
                                                        </li>
                                                    )) : ""
                                                }
                                            </ul>
                                        </>

                                    )
                                })
                            }
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

        </div>
    );
}
export default Video;
