import React, { useEffect, useState } from 'react';
import * as Constants from '../../../../assets/helper/constants'
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import Axios from 'axios';
import LoaderComp from '../../../loader';
import ThumbnailPopup from './thumbnailPopup';
import RejectLessonPopup from './rejectLessonPopup';

function ValidateLesson(props) {

    const [lesson, setLesson] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [showThumbnailPopup, setShowThumbnailPopup] = useState(false)
    const [thumbImage, setThumbImage] = useState('')
    const [showLesson, setShowLesson] = useState(false)
    const [isExtracting, setIsExtracting] = useState(false)
    const [lessonURL, setLessonURL] = useState('')
    const [showRejectLessonPopup, setShowRejectLessonPopup] = useState(false)

    const params = useParams();
    const history = useHistory()

    const goBack = (e) => {
        history.goBack()
    }

    useEffect(() => {
        getLessonDetails()
    }, [])

    const getLessonDetails = () => {

        let projectId = params.projectId;

        Axios.get(Constants.BASE_URL + `api/AggregatorLessons/getLessonDetailsForAdmin?id=${projectId}`).then((result) => {

            if (result.data.status) {
                setLesson(result.data.result[0])
            } else {
                setLesson('')
            }
        })
    }



    const rejectLesson = (inputList) => {

        let projectId = params.projectId;

        let data = {
            lessonId: lesson.Id,
            from: props.userInfo.FirstName + ' ' + props.userInfo.LastName,
            message: inputList,
            projectId: projectId,
            senderId: props.userInfo.Id
        }
        setIsLoading(true)
        Axios.put(Constants.BASE_URL + `api/AggregatorLessons/rejectedLessonDetails`, data, Constants.header).then((result) => {
            setIsLoading(false)
            setShowRejectLessonPopup(false)
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "Lesson rejected.",
                    showConfirmButton: false,
                    timer: 2000
                })
                getLessonDetails()
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to reject lesson.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    const approveLesson = (e) => {

        let data = {
            lessonId: lesson.Id,
            lessonName: lesson.Name
        }

        Swal.fire({
            title: "Approve Lesson?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(true)
                Axios.put(Constants.BASE_URL + `api/AggregatorLessons/approveLessonDetails`, data, Constants.header).then((result) => {
                    setIsLoading(false)
                    if (result.data.status) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: "Lesson Approved.",
                            showConfirmButton: false,
                            timer: 2000
                        })
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: "Unable to approve lesson.",
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }
                })

            }
        })
    }

    const toggleThumbnailPopup = (imgeLink) => {

        setShowThumbnailPopup(!showThumbnailPopup)
        setThumbImage(imgeLink)
    }

    const toggleRejectLessonPopup = () => {
        setShowRejectLessonPopup(!showRejectLessonPopup)

    }

    //extract the lesson if not, else playe the lesson.
    const previewLesson = () => {

        props.hideChats(false)

        setShowLesson(true)

        let fileName = lesson.Path.split("/").pop()

        let data = {
            teacherID: lesson.Created_By,
            lessonID: lesson.Id,
            fileName: fileName
        }
        console.log('isextracted', data)
        if (lesson.IsExtracted === 0) {

            setIsExtracting(true)

            Axios.post(Constants.BASE_URL_A + `api/AggregatorS3Router/extractLesson/`, data, Constants.header).then((result) => {
                setIsExtracting(false)
                if (result.data.status) {

                    setLessonURL(`https://d13ao8zeo4gmiq.cloudfront.net/playerweb/index.html?type=Aggregator&path=${lesson.Id}&width=1280&height=720`)

                } else {

                    if (result.data.info === "File Not Found") {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: "File not found.",
                            showConfirmButton: false,
                            timer: 2000
                        })
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: "Unable to play lesson. Please try again.",
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }

                }
            }).catch((exception) => {

                setIsExtracting(false)
                setLessonURL("")
                if (exception.response.data.info === "File Not Found") {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: "File not found.",
                        showConfirmButton: false,
                        timer: 2000
                    })
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: "Something went wrong, Please try again.",
                        showConfirmButton: false,
                        timer: 2000
                    })
                }

                console.log('catch block', exception);
            });
        } else {
            setIsExtracting(false)
            setLessonURL(`https://d13ao8zeo4gmiq.cloudfront.net/playerweb/index.html?type=Aggregator&path=${lesson.Id}&width=1280&height=720`)
        }

    }

    const closeLesson = (e) => {
        setShowLesson(false)
        props.hideChats(true)
    }


    return (

        <div className='right-panel-container text-left pb-4'>
            {isLoading ? <LoaderComp isLoading={isLoading} /> : null}
            <ThumbnailPopup
                thumbnail={thumbImage}
                show={showThumbnailPopup}
                onHide={toggleThumbnailPopup}
            />

            <RejectLessonPopup
                show={showRejectLessonPopup}
                onHide={toggleRejectLessonPopup}
                rejectLessonFunc={rejectLesson}
            />
            <div className='container-fluid'>
                <div className="row mb-4">
                    <div className="col-12">
                        <h2 className="section-name">Validate Lesson</h2>
                    </div>
                </div>

                {!showLesson ?
                    <>

                        <div className="row mb-4">
                            <div className="col-12 d-flex justify-content-start align-items-baseline flex-wrap">
                                <button onClick={goBack} className="btn"><i className="fa fa-chevron-left me-2"></i>Back</button>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-6 col-md-12 ">
                                <div className="card">
                                    <h5 className="card-header py-2">Lesson Details</h5>
                                    <div className="row card-body g-3">
                                        <div className="row border-bottom py-1 my-2">
                                            <div className="col-4">
                                                <p className="fw-500">Lesson</p>
                                            </div>
                                            <div className="col">
                                                <button onClick={(e) => previewLesson()} className="btn btn-blue btn-sm text-light">Preview Lesson</button>
                                            </div>
                                        </div>

                                        <div className="row border-bottom py-1 my-2">
                                            <div className="col-4">
                                                <p className="fw-500">Thumbnail 1:</p>
                                            </div>
                                            <div className="col thumbnails">
                                                <img
                                                    alt="Thumbnail 1"
                                                    className=""
                                                    src={Constants.BASE_URL_A + lesson.Thumb1}
                                                    onClick={() => toggleThumbnailPopup(Constants.BASE_URL_A + lesson.Thumb1)}
                                                />
                                            </div>
                                        </div>

                                        <div className="row border-bottom py-1 my-2">
                                            <div className="col-4">
                                                <p className="fw-500">Thumbnail 2:</p>
                                            </div>
                                            <div className="col thumbnails">
                                                <img
                                                    alt="Thumbnail 2"
                                                    className=""
                                                    src={Constants.BASE_URL_A + lesson.Thumb2}
                                                    onClick={() => toggleThumbnailPopup(Constants.BASE_URL_A + lesson.Thumb2)}
                                                />
                                            </div>
                                        </div>

                                        <div className="row border-bottom py-1 my-2">
                                            <div className="col-4">
                                                <p className="fw-500">Thumbnail 3:</p>
                                            </div>
                                            <div className="col thumbnails">
                                                <img
                                                    alt="Thumbnail 3"
                                                    className=""
                                                    src={Constants.BASE_URL_A + lesson.Thumb3}
                                                    onClick={() => toggleThumbnailPopup(Constants.BASE_URL_A + lesson.Thumb3)}
                                                />
                                            </div>
                                        </div>

                                        <div className="row border-bottom py-1 my-2">
                                            <div className="col-4">
                                                <p className="fw-500">Subject:</p>
                                            </div>
                                            <div className="col">
                                                <p className="">{lesson.Subject}</p>
                                            </div>
                                        </div>

                                        <div className="row border-bottom py-1 my-2">
                                            <div className="col-4">
                                                <p className="fw-500">Lesson Name:</p>
                                            </div>
                                            <div className="col">
                                                <p className="">{lesson.Name}</p>
                                            </div>
                                        </div>

                                        <div className="row border-bottom py-1 my-2">
                                            <div className="col-4">
                                                <p className="fw-500">Tags:</p>
                                            </div>
                                            <div className="col">
                                                <p className="">{lesson.Tags}</p>
                                            </div>
                                        </div>

                                        <div className="row border-bottom py-1 my-2">
                                            <div className="col-4">
                                                <p className="fw-500">Age:</p>
                                            </div>
                                            <div className="col">
                                                <p className="">{lesson.Age}</p>
                                            </div>
                                        </div>

                                        <div className="row border-bottom py-1 my-2">
                                            <div className="col-4">
                                                <p className="fw-500">Region:</p>
                                            </div>
                                            <div className="col">
                                                <p className="">{lesson.Region}</p>
                                            </div>
                                        </div>

                                        <div className="row border-bottom py-1 my-2">
                                            <div className="col-4">
                                                <p className="fw-500">Description:</p>
                                            </div>
                                            <div className="col">
                                                <p className="">{lesson.Description}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex justify-content-end">
                                        <button className="btn btn-sm btn-danger text-light me-2" onClick={toggleRejectLessonPopup}>Reject</button>
                                        <button className="btn btn-sm btn-success text-light" onClick={approveLesson}>Approve</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    : <>
                        <div className='row'>
                            <div className='col-4'>
                                <button className='btn' onClick={(e) => closeLesson(e)}><i className='fa fa-chevron-left me-2'></i>Back</button>
                            </div>
                        </div>

                        <div className='row justify-content-center'>
                            <div className='col-lg-10'>
                                <div className='player-wrap text-center'>
                                    {
                                        isExtracting ?
                                            <div className="row d-flex justify-content-center align-items-center ">
                                                <div className="spinner-border text-primary mb-3" role="status">
                                                    <span className="sr-only"></span>
                                                </div>
                                                <p className="loading-text">Extracting Lesson, Please wait...</p>
                                            </div>
                                            :
                                            <iframe src={lessonURL} width="1280" height="720" className="player-iframe" title="Player" />
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div >
        </div >
    )
}

export default ValidateLesson