import { Link, } from "react-router-dom";
import * as Constants from "../../../assets/helper/constants";
import ImageInactive from "../../../assets/images/help/Image.png";
import LabelInactive from "../../../assets/images/help/Label.png";
import ButtonInactive from "../../../assets/images/help/Button.png";
import ToggleButtonInactive from "../../../assets/images/help/ToggleButton.png";
import VideoInactive from "../../../assets/images/help/Video.png";
import GifInactive from "../../../assets/images/help/Gif.png";
import TextInputInactive from "../../../assets/images/help/TextInput.png";
import CheckboxInactive from "../../../assets/images/help/Checkbox.png";
import RadioGroupInactive from "../../../assets/images/help/RadioGroup.png";
import RadioInactive from "../../../assets/images/help/Radio.png";

import TextInputActive from "../../../assets/images/help/TextInputActive.png";
import ToggleButtonActive from "../../../assets/images/help/ToggleButtonActive.png";
import VideoActive from "../../../assets/images/help/VideoActive.png";
import LabelActive from "../../../assets/images/help/LabelActive.png";
import GifActive from "../../../assets/images/help/GifActive.png";
import ButtonActive from "../../../assets/images/help/ButtonActive.png";
import ImageActive from "../../../assets/images/help/ImageActive.png";
import CheckboxActive from "../../../assets/images/help/CheckboxActive.png";
import RadioGroupActive from "../../../assets/images/help/RadioGroupActive.png";
import RadioActive from "../../../assets/images/help/RadioActive.png";
import { useState } from "react";

function LeftPanel(props) {
    const [clicked, setClicked] = useState(false)
    let featureData = [
        {
            id: 1,
            name: "Image",
            activeLink: "ImageFeature",
            link: Constants.ImageFeature,
            activeImg: ImageActive,
            inActiveImage: ImageInactive,
        },
        {
            id: 2,
            name: "Text Input",
            activeLink: "TextInputFeature",
            link: Constants.TextInputFeature,
            activeImg: TextInputActive,
            inActiveImage: TextInputInactive,
        },
        {
            id: 3,
            name: "Label",
            activeLink: "LabelFeature",
            link: Constants.LabelFeature,
            activeImg: LabelActive,
            inActiveImage: LabelInactive,
        },
        {
            id: 4,
            name: "Button",
            activeLink: "ButtonFeature",
            link: Constants.ButtonFeature,
            activeImg: ButtonActive,
            inActiveImage: ButtonInactive,
        },
        {
            id: 5,
            name: "Toggle Button",
            activeLink: "ToggleButtonFeature",
            link: Constants.ToggleButtonFeature,
            activeImg: ToggleButtonActive,
            inActiveImage: ToggleButtonInactive,
        },
        {
            id: 6,
            name: "Video",
            activeLink: "VideoFeature",
            link: Constants.VideoFeature,
            activeImg: VideoActive,
            inActiveImage: VideoInactive,
        },
        {
            id: 7,
            name: "Gif",
            activeLink: "GifFeature",
            link: Constants.GifFeature,
            activeImg: GifActive,
            inActiveImage: GifInactive,
        },
        {
            id: 8,
            name: "Checkbox",
            activeLink: "CheckboxFeature",
            link: Constants.CheckboxFeature,
            activeImg: CheckboxActive,
            inActiveImage: CheckboxInactive,
        },
        {
            id: 9,
            name: "RadioGroup",
            activeLink: "RadioGroupFeature",
            link: Constants.RadioGroupFeature,
            activeImg: RadioGroupActive,
            inActiveImage: RadioGroupInactive,
        },
        {
            id: 10,
            name: "RadioButton",
            activeLink: "RadioButtonFeature",
            link: Constants.RadioButtonFeature,
            activeImg: RadioActive,
            inActiveImage: RadioInactive,
        },
    ];

    let appData = [
        {
            id: 1,
            name: "Lesson",
            activeLink: "Lesson",
            link: Constants.Lesson,
        },
        {
            id: 2,
            name: "Scene",
            activeLink: "Scene",
            link: Constants.Scene,
        },
    ];

    let data = [
        {
            id: 1,
            name: "Key-Values",
            activeLink: "KeyValues",
            link: Constants.KeyValues,
        },
    ]

    const setActive = (id) => {
        props.checkPath(id)
    }

    const toggleMenu = () => {
        setClicked(!clicked);
    }

    return (
        <>

            <div className="menu-icon" onClick={toggleMenu}>
                <i className={clicked ? "fa fa-times fa-2x" : "fa fa-bars fa-2x"}></i>
            </div>
            <div className={clicked ? "backdrop" : "backdrop-inactive"} onClick={toggleMenu}></div>
            <div className={clicked ? "left-panel nav-menu mobile-menu" : "left-panel nav-menu"}>
                {/* <Link to={Constants.UploadLesson} className="docs-back-btn">
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>Back
                </Link> */}

                <h2>APPLICATION</h2>
                <ul className="nav flex-column gap-2 left-panel-menu mb-4">
                    {appData.map((element) => {
                        return (
                            <li key={element.id} className={props.activeLink === element.activeLink ? "docs-nav-item selected" : "docs-nav-item de-selected"} onClick={(e) => { setActive(element.activeLink) }}>
                                <Link to={element.link} className={props.activeLink === element.activeLink ? "docs-disable-link nav-link" : "nav-link"} onClick={toggleMenu}>

                                    <span className={props.activeLink === element.activeLink ? 'text-white' : 'text-gray'}>{element.name}</span>
                                </Link>

                            </li>
                        )
                    })}
                </ul>

                <h2>FEATURES</h2>
                <ul className="nav flex-column gap-2 left-panel-menu mb-4">
                    {featureData.map((element) => {
                        return (
                            <li key={element.id} className={props.activeLink === element.activeLink ? "docs-nav-item selected" : "docs-nav-item de-selected"} onClick={(e) => { setActive(element.activeLink) }}>
                                <Link to={element.link} className={props.activeLink === element.activeLink ? "docs-disable-link nav-link" : "nav-link"} onClick={toggleMenu}>
                                    {props.activeLink === element.activeLink ? <img src={element.activeImg} alt='Icons' /> : <img src={element.inActiveImage} alt='Icons' />}
                                    <span className={props.activeLink === element.activeLink ? 'text-white' : 'text-gray'}>{element.name}</span>
                                </Link>

                            </li>
                        )
                    })}
                </ul>

                <h2>DATA</h2>
                <ul className="nav flex-column gap-2 left-panel-menu mb-5">
                    {data.map((element) => {
                        return (
                            <li key={element.id} className={props.activeLink === element.activeLink ? "docs-nav-item selected" : "docs-nav-item de-selected"} onClick={(e) => { setActive(element.activeLink) }}>
                                <Link to={element.link} className={props.activeLink === element.activeLink ? "docs-disable-link nav-link" : "nav-link"} onClick={toggleMenu}>

                                    <span className={props.activeLink === element.activeLink ? 'text-white' : 'text-gray'}>{element.name}</span>
                                </Link>

                            </li>
                        )
                    })}
                </ul>
            </div>
        </>
    );
}
export default LeftPanel;
