import { useEffect, useState } from "react";
import Axios from 'axios'
import * as Constants from '../../../assets/helper/constants'
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";

function Unsubscribe(props) {

    const [promotionalChecked, setPromotionalChecked] = useState(true)
    const [progressChecked, setProgressChecked] = useState(true)
    const [userId, setUserId] = useState("")

    const param = useParams()
    const history = useHistory()

    useEffect(() => {
        let userString = localStorage['userDetails']
        if (userString != null || userString != undefined) {
            let user = JSON.parse(userString)
            setUserId(user.Id)
            getEmailNotificationPreference(user.Id)
        }
        else if (userString == null || userString == undefined) {
            history.push("/Login?type=unsubscribe")
        }
    }, [])


    const getEmailNotificationPreference = (userId) => {

        Axios.get(Constants.BASE_URL + `api/aggregator/getEmailNotificationPreference/${userId}`).then((response) => {
            if (response.data.status) {

                if (response.data.result[0].DisabledEmailNotification == null || response.data.result[0].DisabledEmailNotification == "") {

                } else {
                    if (response.data.result[0].DisabledEmailNotification.includes('promotional')) {
                        setPromotionalChecked(false)
                    }
                    if (response.data.result[0].DisabledEmailNotification.includes('progress')) {
                        setProgressChecked(false)
                    }
                }
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to get email communication preference.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        }).catch(() => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Unable to get email communication preference.",
                showConfirmButton: false,
                timer: 2000
            })
        })
    }

    const handleCheckbox = (e, data) => {
        if (data == 'promotional') {
            setPromotionalChecked(!promotionalChecked)
        }
        if (data == 'progress') {
            setProgressChecked(!progressChecked)
        }
    }

    const updatePreference = (e) => {
        e.preventDefault()

        let data = {
            userId: userId,
            promotional: promotionalChecked,
            progress: progressChecked
        }

        Axios.post(Constants.BASE_URL + `api/aggregator/updateEmailNotificationPreference`, data, Constants.header).then((response) => {
            if (response.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "Updated email preference successfully.",
                    showConfirmButton: false,
                    timer: 2000
                })
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to update preference. Please try again.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    const logoutHandler = () => {

        localStorage.removeItem('userDetails')
        //localStorage.removeItem('rememberme')
        history.push('/Login?type=unsubscribe')
    }

    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-lg-8 col-md-12">
                    <div className="unsubscribe-wrap">
                        <h4>Unsubscribe from email communication.</h4>
                        <p>Uncheck the type of emails that you do not wish to receive.</p>
                        <p>This setting will not affect other mandatory communications.</p>
                        <div className="email-subscription-list">
                            <form onSubmit={updatePreference}>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="checkbox"
                                        checked={promotionalChecked}
                                        value='promotional'
                                        onChange={(e) => handleCheckbox(e, 'promotional')}
                                        id="defaultCheck1" />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                        Promotional emails
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value='progress'
                                        checked={progressChecked}
                                        onChange={(e) => handleCheckbox(e, 'progress')}
                                        id="defaultCheck2" />
                                    <label className="form-check-label" htmlFor="defaultCheck2">
                                        Lesson progress emails
                                    </label>
                                </div>
                                <button type="submit" className="btn btn-blue btn-sm text-light mt-2 me-2">Submit</button>
                                <button type="button" onClick={logoutHandler} className="btn  btn-sm text-success mt-2">Logout</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Unsubscribe;