import React, { useEffect, useState } from 'react';
import Axios from 'axios'
import * as Constants from '../../../../assets/helper/constants'
import Swal from 'sweetalert2';
import DataTable from "react-data-table-component";
import LoaderComp from '../../../loader';


function NotificationsList(props) {

    const [userInfo, setUserInfo] = useState({})
    const [notifications, setNotifications] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    // const [selectedRows, setSelectedRows] = useState([])
    // const [enableButtons, setEnableButtons] = useState(false)



    useEffect(() => {
        setUserInfo(props.userInfo)
        getAllNotifications()
    }, [])


    const getAllNotifications = () => {

        Axios.get(Constants.BASE_URL + "api/notification/getAllNotifications").then((response) => {

            if (response.data.status) {
                setNotifications(response.data.result)
            } else {
                setNotifications([])
            }
        })
    }

    const customStyles = {
        headRow: {
            style: {
                fontWeight: "600"
            },
        },

        headCells: {
            style: {
                color: '#202124',
                fontSize: '16px',
            },
        },
        rows: {
            highlightOnHoverStyle: {
                backgroundColor: 'rgb(230, 244, 244)',
                borderBottomColor: '#FFFFFF',
                borderRadius: '0',
                outline: '1px solid #FFFFFF',
            },
        },
        pagination: {
            style: {
                border: 'none',
            },
        },

    }

    const columns = [
        {
            name: 'Sl No.',
            grow: 1,
            selector: (row, index) => {
                return (
                    <div className="">
                        {index + 1}
                    </div>
                )
            }
        },
        {
            name: 'Name',
            grow: 3,
            sortable: true,
            selector: row => row.Name,
        },
        {
            name: 'Channel',
            grow: 2,
            selector: row => row.Channel,
            sortable: true,
        },
        {
            name: 'Trigger Type',
            grow: 2,
            sortable: true,
            selector: row => row.TriggerType === "TimeBased" ? "Time Based" : "Event Based",
        },
        {
            name: 'Start Date',
            grow: 2,
            sortable: true,
            selector: row => row.FromDate,
        },
        {
            name: 'End Date',
            grow: 2,
            sortable: true,
            selector: row => row.ToDate,
        },
        {
            name: 'Trigger Time',
            grow: 2,
            sortable: true,
            selector: row => row.TriggerTime,
        },
        {
            name: 'Created Date',
            grow: 2,
            sortable: true,
            selector: row => row.CreatedOn.slice(0, 10),
        },
        {
            name: 'Status',
            grow: 2,
            selector: row => (
                row.Active === 1 ? <span className="approved-status">Active</span> : <span className="rejected-status">Inactive</span>
            )
            ,
        },
        {
            name: 'Action',
            grow: 2,
            selector: row => (
                row.Active === 1 ? <button className='btn btn-sm bg-blue text-light' onClick={(e) => deActivateNotification(row.Id)}>Deactivate</button>
                    : <button className='btn btn-sm bg-blue text-light' onClick={(e) => activateNotification(row.Id)}>Activate</button>
            )
            ,
        },
        {
            name: 'Delete',
            grow: 2,
            center: true,
            selector: row => (
                <button className='btn btn-sm text-light p-0' onClick={(e) => deleteNotification(row.Id)}><i className="fa text-dark fa-trash-o fa-2x" aria-hidden="true"></i></button>
            )
            ,
        },
    ];


    const deActivateNotification = (id) => {
        setIsLoading(true)
        Axios.get(Constants.BASE_URL + `api/notification/deactivateNotification?id=${id}`, Constants.header).then((response) => {
            setIsLoading(false)
            if (response.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    html: "<h2>Deactivated Successfully</h2>",
                    showConfirmButton: false,
                    timer: 2000
                })
                getAllNotifications()
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    html: "<h2>Unable to Deactivate. Please try again.</h2>",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }
    const deleteNotification = (id) => {

        Swal.fire({
            title: "Delete notification? Once deleted cannot be undone.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(true)
                Axios.delete(Constants.BASE_URL + `api/notification/deleteNotification?id=${id}`, Constants.header).then((response) => {
                    setIsLoading(false)
                    if (response.data.status) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            html: "<h3>Deleted Successfully</h3>",
                            showConfirmButton: false,
                            timer: 2000
                        })
                        getAllNotifications()
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            html: "<h4>Unable to Delete. Please try again.</h4>",
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }
                })
            }
        })
    }
    const activateNotification = (id) => {
        setIsLoading(true)
        Axios.get(Constants.BASE_URL + `api/notification/activateNotification?id=${id}`, Constants.header).then((response) => {
            setIsLoading(false)
            if (response.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    html: "<h2>Activated Successfully</h2>",
                    showConfirmButton: false,
                    timer: 2000
                })
                getAllNotifications()
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    html: "<h2>Unable to Activate. Please try again.</h2>",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    // const handleSelectedRowsChange = (details) => {
    //     if (details.selectedCount > 0) {

    //         var array = []
    //         details.selectedRows.forEach(element => {
    //             array.push(element.Id);
    //         })
    //         setSelectedRows(array)
    //     } else {
    //         setSelectedRows([])
    //     }
    // }


    return (

        <div className='right-panel-container'>
            <div className='container-fluid'>
                {isLoading ? <LoaderComp isLoading={isLoading} /> : null}

                <div className='row mb-4'>
                    <div className='col-12 d-flex align-items-baseline justify-content-between'>
                        <h2 className="section-name">Notifications</h2>
                    </div>
                </div>
                {/* <div className='row mb-4'>
                    <div className='col-12 d-flex justify-content-end gap-3'>
                        <button className='btn bg-blue text-light'>Deactivate</button>
                        <button className='btn bg-danger text-light' onClick={e => deleteNotification(selectedRows)}>Delete</button>
                    </div>
                </div> */}
                <div className='row'>
                    <div className='col-12'>
                        <div className='card border-0 data-container-wrapper box-shadows rounded p-0'>
                            <div className='card-body'>
                                <DataTable
                                    columns={columns}
                                    data={notifications}
                                    customStyles={customStyles}
                                    pagination
                                    highlightOnHover
                                // selectableRows
                                // onSelectedRowsChange={handleSelectedRowsChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default NotificationsList