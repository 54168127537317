import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PlayStore from '../../../assets/images/play-store.svg'
import AppStore from '../../../assets/images/app-store.svg'
import BlueBg from '../../../assets/images/blue-bg.png'

function PricingModal(props) {

    const style = {
        border: 'none',
        paddingBottom: '5px'
    }
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            contentClassName="pricing-modal-bg"
        >
            <Modal.Header closeButton style={style} closeVariant="white">
                {/* <Modal.Title id="contained-modal-title-vcenter">
                    <h4>Please buy premium Membership on our app</h4>
                </Modal.Title> */}
            </Modal.Header>
            <Modal.Body>

                <div className='container pricing-modal-container'>
                    <div className='pricing-modal-title'>
                        <h4>Please buy the premium membership on our app</h4>
                    </div>

                    <div className='pricing-download-app'>
                        <h6>To download Baetho visit:</h6>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-4'>
                            <a href='https://play.google.com/store/apps/details?id=com.baetho.aggregator' target='_blank' rel="noreferrer">
                                <img className="download-app-img" src={PlayStore} alt="Playstore" />
                            </a>
                        </div>
                        <div className='col-4'>
                            <a href='https://apps.apple.com/in/app/baetho-the-activity-store/id6447284412' target='_blank' rel="noreferrer">
                                <img className="download-app-img" src={AppStore} alt="Playstore" />
                            </a>
                        </div>
                    </div>
                </div>


                {/* <p>
                    Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                    dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
                    consectetur ac, vestibulum at eros.
                </p> */}
            </Modal.Body>
            {/* <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer> */}
        </Modal>
    )
}

export default PricingModal