import Accordion from 'react-bootstrap/Accordion';
import Actions from '../commons/actions';
import Basic from '../commons/basic';
function Checkbox() {


    let featureProperties = [

        {
            name: 'Dark Default Icons',
            desc: 'If this property is selected, color of the checkbox is set to dark mode.'
        },
        {
            name: 'Unchecked Icon',
            desc: 'This property provides an option to select an unchecked icon used for the checkbox.'
        },
        {
            name: 'Checked Icon',
            desc: 'This property provides an option to select the checked icon used for the checkbox.'
        },
        {
            name: 'Is Selected',
            desc: 'If this property is enabled, the check box starts in a checked state.'
        },
        {
            name: 'Label',
            desc: 'This is the text displayed for the checkbox.'
        },
        {
            name: 'Font',
            desc: 'Using this property we can select the font for the text.'
        },
        {
            name: 'Size',
            desc: 'Using this property we can set the font size of the text.'
        },
        {
            name: 'Font Color',
            desc: 'This property sets the font color.'
        },
        {
            name: 'Bold',
            desc: 'Text will be bold when this property is enabled.'
        },
        {
            name: 'Italic',
            desc: 'Text will be italicized when this property is enabled.'
        },
        {
            name: 'Label Offset X',
            desc: 'Use this property to shift the label along the X-axis.'
        },
        {
            name: 'Label Offset Y',
            desc: 'Use this property to shift the label along the Y-axis.'
        },
        {
            name: 'Icon Width',
            desc: 'This property sets width of the icon.'
        },
        {
            name: 'Icon Height',
            desc: 'This property sets height of the icon.'
        },

    ]

    let events = [
        {
            name: "Touch",
            params: [
                {
                    paramName: "Tap",
                    paramDesc: "This trigger executes its actions when the user taps on this feature."
                },
                {
                    paramName: "Double Tap",
                    paramDesc: "This trigger executes its actions when the user double taps on this feature."
                },
                {
                    paramName: "Long Press",
                    paramDesc: "This trigger executes its actions when the user taps and holds the feature for sometime."
                },
                // {
                //     paramName: "Pressed",
                //     paramDesc: "This trigger executes its actions when the user has pressed down on this feature."
                // },
                // {
                //     paramName: "Released",
                //     paramDesc: " This trigger executes its actions when the user has pressed down and released this feature."
                // },
            ]
        },
        {
            event: "Swipe",
            name: "Swipe",
            desc: "This trigger executes its actions when the user swipes on the feature in the direction specified.",
            params: [
                {
                    paramName: "Swipe Type",
                    paramDesc: "Using this trigger the user can specify the direction for swipe."
                },
                {
                    paramName: "Key-Values",
                    paramDesc: "This is an optional parameter, we can set the length of the swipe into a key-value."
                },
            ]
        },
        {
            name: "Checkbox",
            params: [
                {
                    paramName: "Selected",
                    paramDesc: "This trigger executes its actions when the feature is selected."
                },
                {
                    paramName: "Deselected",
                    paramDesc: "This trigger executes its actions when the feature is deselected."
                },
            ]
        },
    ]

    let actions = [
        {
            actionName: "Checkbox",
            action: [

                {
                    name: "Change State",
                    desc: "This action changes the state of checkbox to the value specified.",
                    params: [
                        {
                            paramName: "Button State",
                            paramDesc: "This is the target value."
                        },
                        {
                            paramName: "Perform Action",
                            paramDesc: "If this is true, the specified action will be performed."
                        },
                    ]
                },
                {
                    name: "Change Label",
                    desc: "This action changes the label of this feature to the value specified.",
                    params: [
                        {
                            paramName: "Label",
                            paramDesc: "This is the target value."
                        },
                    ]
                },

            ]
        }

    ]




    return (
        <div className="content-main">
            <h2 className="title">Checkbox Feature</h2>
            <h3 className="sub-title">Description</h3>
            <p>Checkbox is like a switch which can be either selected or unselected.</p>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><h3 className="sub-title">Properties</h3></Accordion.Header>
                    <Accordion.Body>
                        <Basic />
                        <h3 className="sub-title-2">Checkbox Properties</h3>
                        <ul className="property-list">
                            {
                                featureProperties.map((element, index) => {
                                    return (
                                        <li>
                                            <span className="property">{element.name} : </span> {element.desc}
                                        </li>
                                    )
                                })
                            }
                        </ul>

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><h3 className="sub-title">Triggers</h3></Accordion.Header>
                    <Accordion.Body>
                        <ul className="property-list">
                            {
                                events.map((element, index) => {
                                    return (
                                        <>
                                            <span className="property">{element.event}</span>
                                            <li className="single-list">
                                                <span className="property">{element.name} : </span>{element.desc}
                                                <ul>
                                                    {
                                                        element.params ?
                                                            element.params.map(param => (
                                                                <li><span className="property">{param.paramName} : </span>{param.paramDesc}</li>
                                                            ))
                                                            : ""
                                                    }
                                                </ul>
                                            </li>
                                        </>
                                    )
                                })
                            }
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><h3 className="sub-title">Actions</h3></Accordion.Header>
                    <Accordion.Body>
                        <Actions />
                        <ul className="property-list">
                            {
                                actions.map((element, index) => {
                                    return (
                                        <>
                                            <span className="action-name property">{element.actionName} </span>
                                            <ul className="mb-4">
                                                {element.action ?
                                                    element.action.map((action) => (
                                                        <li>
                                                            <span className="property">{action.name} : </span>{action.desc}
                                                            <ul>
                                                                {
                                                                    action.params ?
                                                                        action.params.map(param => (
                                                                            <li><span className="property">{param.paramName} : </span>{param.paramDesc}</li>
                                                                        ))
                                                                        : ""
                                                                }
                                                            </ul>
                                                        </li>
                                                    )) : ""
                                                }
                                            </ul>
                                        </>

                                    )
                                })
                            }
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

        </div>
    )
}
export default Checkbox;