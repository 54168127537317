import Accordion from 'react-bootstrap/Accordion';

function Lesson() {



    let events = [
        {
            event: "Key Press",
            name: "Key Pressed",
            desc: "This trigger is called every time a key is pressed. It follows the key that is specified in the key type.",

            params: [
                {
                    paramName: "Key Type",
                    paramDesc: "Select the type of the key from the dropdown. You can also define custom key."
                },
                {
                    paramName: "Key",
                    paramDesc: "Character that it’s listening for."
                },
                {
                    paramName: "Case Sensitive",
                    paramDesc: "If enabled, it only listens for the specified character. Letter 'a' and letter 'A' are considered as different characters."
                }
            ]
        },
        {
            event: "Timer",
            name: "Timer",
            desc: "This starts a timer and executes the actions when the time is up. You can have it run multiple times by specifying the number of runs, 0 is to keep it running indefinitely.",
            params: [{
                paramName: "Duration",
                paramDesc: "Duration of time before executing actions."
            },
            {
                paramName: "Number Of Runs",
                paramDesc: "Specifies the number of runs, 0 is to keep it running indefinitely."
            }]
        }
    ]

    let actions = [
        {
            actionName: "Scene Navigation",
            action: [
                {
                    name: "Go To Scene",
                    desc: "This action will go to a specified scene.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Target",
                            paramDesc: "This is the scene you wish to go to."
                        },
                        {
                            paramName: "Transition",
                            paramDesc: "These are the effects that can be added to transition from one scene to another."
                        },
                        {
                            paramName: "Effect",
                            paramDesc: "This option allows you to change the way a transition effect behaves."
                        },
                        {
                            paramName: "Duration",
                            paramDesc: "This is used to set transition speed."
                        },

                    ]
                },


            ]
        },

        {
            actionName: "Sound",
            action: [
                {
                    name: "Play Sound",
                    desc: "This action will play a specified audio.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Sound Asset",
                            paramDesc: "This is the sound file that will be played by this action."
                        },
                        // {
                        //   paramName: "Start Time",
                        //   paramDesc: "The audio will start from the specified time."
                        // },
                        {
                            paramName: "Loops",
                            paramDesc: "This specifies that the audio will start over again, every time it ends."
                        },
                        {
                            paramName: "Stop All Other Sounds",
                            paramDesc: "If enabled, it stops all the other sounds."
                        }
                    ]
                },
                {
                    name: "Text To Speech",
                    desc: "This action will convert given text into speech.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Text",
                            paramDesc: "This is the text that will be converted to speech."
                        },
                        {
                            paramName: "Accent",
                            paramDesc: "This specifies the accent of the speech. It can be Region based, American, British, or Indian."
                        },
                    ]
                },
                {
                    name: "Stop Sound",
                    desc: "This action will stop a specified audio.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Sound Asset",
                            paramDesc: "This is the sound file that will be stopped by this action."
                        },
                    ]
                },
                {
                    name: "Stop All Sounds",
                    desc: "This action will stop all the sounds.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                    ]
                }
            ]
        },


    ]

    return (
        <div className="content-main">
            <h2 className="title">Lesson</h2>
            <h3 className="sub-title">Description</h3>
            <p>The Lesson includes three main parts: Media Library, Scenes, and Key Values. Components such as Images, Videos, GIFs, Labels, etc can be added from the list of features to the lesson.</p>

            <Accordion defaultActiveKey="1">
                {/* <Accordion.Item eventKey="0">
          <Accordion.Header><h3 className="sub-title">Properties</h3></Accordion.Header>
          <Accordion.Body>
            <h3 className="sub-title-2">Basic</h3>
            <h3 className="sub-title-2">Feature Properties</h3>

          </Accordion.Body>
        </Accordion.Item> */}
                <Accordion.Item eventKey="1">
                    <Accordion.Header><h3 className="sub-title">Triggers</h3></Accordion.Header>
                    <Accordion.Body>
                        <ul className="property-list">
                            {
                                events.map((element, index) => {
                                    return (
                                        <>
                                            <span className="property">{element.event}</span>
                                            <li className="single-list">
                                                <span className="property">{element.name}: </span>{element.desc}
                                                <ul>
                                                    {
                                                        element.params ?
                                                            element.params.map(param => (
                                                                <li><span className="property">{param.paramName}: </span>{param.paramDesc}</li>
                                                            ))
                                                            : ""
                                                    }
                                                </ul>
                                            </li>
                                        </>
                                    )
                                })
                            }
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><h3 className="sub-title">Actions</h3></Accordion.Header>
                    <Accordion.Body>
                        <ul className="property-list">
                            {
                                actions.map((element, index) => {
                                    return (
                                        <>
                                            <span className="property">{element.actionName} </span>
                                            <ul>
                                                {element.action ?
                                                    element.action.map((action) => (
                                                        <li>
                                                            <span className="property">{action.name}: </span>{action.desc}
                                                            <ul>
                                                                {
                                                                    action.params ?
                                                                        action.params.map(param => (
                                                                            <li><span className="property">{param.paramName}: </span>{param.paramDesc}</li>
                                                                        ))
                                                                        : ""

                                                                }
                                                            </ul>
                                                        </li>
                                                    )) : ""
                                                }
                                            </ul>
                                        </>

                                    )
                                })
                            }
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>


        </div >


    );
}
export default Lesson;
