import Accordion from 'react-bootstrap/Accordion';
import Actions from '../commons/actions';
import Basic from '../commons/basic';
import Interaction from '../commons/interaction';
import Triggers from '../commons/triggers';
function Gif() {


    let featureProperties = [

        {
            name: 'Gif',
            desc: 'This property allows to select Gif file from media library.'
        },
        {
            name: 'Repeat',
            desc: 'If this is set to true, Gif will play in a loop.'
        },
        {
            name: 'Auto Start',
            desc: 'If this is set to true, Gif starts playing automatically as soon as the scene starts.'
        },

    ]



    let actions = [

        {
            actionName: "Interaction",
            action: [
                {
                    name: "Change Movement Type",
                    desc: "This action changes the feature’s movement in the scene. It can be set to Free, Vertical, Horizontal or none.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Movement Type",
                            paramDesc: "This is the target movement type."
                        },
                    ]
                },

            ]
        },
        {
            actionName: "Gif",
            action: [
                {
                    name: "Play",
                    desc: "This action plays the Gif.",
                },
                {
                    name: "Stop",
                    desc: "This action stops the Gif.",
                },
                {
                    name: "Load Animation",
                    desc: "This action loads a new animation into the player.",
                    params: [
                        {
                            paramName: "Animation Path",
                            paramDesc: "This is the destination path of the animation file."
                        },
                    ]
                },

            ]
        }

    ]


    return (
        <div className="content-main">
            <h2 className="title">GIF Feature</h2>
            <h3 className="sub-title">Description</h3>
            <p>A GIF is a feature that can be used to display GIF content.</p>

            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><h3 className="sub-title">Properties</h3></Accordion.Header>
                    <Accordion.Body>
                        <Basic />
                        <Interaction />

                        <h3 className="sub-title-2">GIF Properties</h3>
                        <ul className="property-list">
                            {
                                featureProperties.map((element, index) => {
                                    return (
                                        <li>
                                            <span className="property">{element.name} : </span> {element.desc}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><h3 className="sub-title">Triggers</h3></Accordion.Header>
                    <Accordion.Body>
                        <Triggers />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><h3 className="sub-title">Actions</h3></Accordion.Header>
                    <Accordion.Body>
                        <Actions />
                        <ul className="property-list">
                            {
                                actions.map((element, index) => {
                                    return (
                                        <>
                                            <span className="property">{element.actionName} </span>
                                            <ul className="mb-4">
                                                {element.action ?
                                                    element.action.map((action) => (
                                                        <li>
                                                            <span className="property">{action.name} : </span>{action.desc}
                                                            <ul>
                                                                {
                                                                    action.params ?
                                                                        action.params.map(param => (
                                                                            <li><span className="property">{param.paramName} : </span>{param.paramDesc}</li>
                                                                        ))
                                                                        : ""

                                                                }
                                                            </ul>
                                                        </li>
                                                    )) : ""
                                                }
                                            </ul>
                                        </>

                                    )
                                })
                            }
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

        </div>
    );
}
export default Gif;
