import { Link } from 'react-router-dom';
import BaethoLogo from '../../../assets/images/baetho-kids-logo.png'
import { useState } from 'react';
import Swal from 'sweetalert2';
import Axios from 'axios'
import * as Constants from '../../../assets/helper/constants'
import LoaderComp from '../../loader';
import Navbar from '../navbar/navbar';

const AboutUs = () => {

    const [requestType, setRequestType] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)


    const formSubmit = (e) => {
        e.preventDefault()

        if (requestType.trim() === "" || name.trim() === "" || email.trim() === "" || message.trim() === "") {

            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "All fields are mandatory.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }

        let data = {
            requestType: requestType,
            name: name,
            email: email,
            message: message
        }
        setIsLoading(true)
        Axios.post(Constants.BASE_URL + "api/aggregator/supportRequest", data, Constants.header).then((result) => {
            setIsLoading(false)
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "Request sent successfully.",
                    showConfirmButton: false,
                    timer: 2000
                })
                setName('')
                setEmail('')
                setMessage('')
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to send. Please try again.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })

    }

    return (
        <div className='main-bg'>
            {isLoading ? <LoaderComp isLoading={isLoading} /> : null}

            <Navbar />

            <div className='container  px-4 pb-5 pt-4'>
                <div className="row my-4">
                    <div className="col-12">
                        <div className="home-title">
                            <h1 className="text-center">About Us</h1>
                        </div>
                    </div>
                </div>
                <div className='row mb-5'>
                    <div className='col-12'>
                        {/* <h1 className='mt-5 text-center pricing-title'>About Us</h1> */}
                        <p className='text-left'>Baetho has a library of fun educational activities built solely by passionate teachers.
                            These activities are completely interactive which ensures that your child is engaged
                            and having fun while learning about various topics such as English, Math, and Science.
                            We take the utmost care when it comes to reviewing our content to ensure it is child
                            appropriate so you can be rest assured that your child is safe with Baetho.</p>
                    </div>
                </div>
            </div>
            <div className='container  px-4 pb-5'>
                <h2 className='text-center mb-4'>Contact Us</h2>
                <div className='row '>
                    <div className='col-md-6 col-sm-12'>
                        <p>Address:</p>
                        <p> BAETHO TECHNOLOGIES PRIVATE LIMITED<br></br>
                            no.198, Vinayaka Complex,<br></br>
                            2nd main, Old Airport Road, Domlur,<br></br>
                            Bangalore North, Bangalore 560071<br></br>
                        </p>
                        <p> Email: support@baetho.app</p>
                    </div>
                    <div className='col-md-6 col-sm-12'>


                        <form className='' onSubmit={formSubmit}>
                            <div className='row'>
                                <div className='col-md-12 col-12 my-3'>
                                    <select className="form-select" aria-label="Default select example" onChange={e => setRequestType(e.target.value)}>
                                        <option value="">Request Type</option>
                                        <option value="General Inquiry">General Inquiry</option>
                                        <option value="Application Support">Application Support</option>
                                    </select>
                                </div>
                            </div>

                            <div className='row '>
                                <div className='col-md-12 col-12'>
                                    <div className="form-group my-3">
                                        <input type="text"
                                            value={name}
                                            className="form-control"
                                            id="username"
                                            name="firstname"
                                            placeholder="Name"
                                            required
                                            onChange={e => setName(e.target.value)} />
                                    </div>
                                </div>
                            </div>

                            <div className='row '>
                                <div className='col-md-12 col-12'>
                                    <div className="form-group  my-3 ">
                                        <input type="email"
                                            value={email}
                                            className="form-control"
                                            id="email"
                                            name='email'
                                            placeholder="Email"
                                            required
                                            onChange={e => setEmail(e.target.value)} />
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-md-12 form-group mt-3 ">
                                    <textarea
                                        className="form-control"
                                        name="message"
                                        id="message"
                                        cols="30" rows="4"
                                        placeholder="Write your message"
                                        required
                                        value={message}
                                        onChange={e => setMessage(e.target.value)} ></textarea>
                                </div>
                            </div>
                            {/* 
                            <div className="form-group col-md-6 my-3">
                                <textarea rows="4" cols="25" className="form-control desc-area" />
                            </div> */}
                            <div className=''>
                                <button type="submit" className="btn bg-blue text-light">Submit</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
            <div className="footer-copyright pb-2">
                <p>© COPYRIGHT @ {JSON.stringify(new Date().getFullYear())} BAETHO TECHNOLOGIES PRIVATE LIMITED. ALL RIGHTS RESERVED.</p>
                <Link to="/privacy-policy" className="nav-link text-blue d-inline-block">Privacy Policy</Link> | <Link to="/terms-of-use" className="nav-link text-blue d-inline-block">Terms of use</Link>
            </div>
        </div>
    )
}

export default AboutUs;