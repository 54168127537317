import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Swal from 'sweetalert2';
import Axios from 'axios';
import * as Constants from '../../../../assets/helper/constants'

function AdminChats(props) {

    const [messageText, setMessageText] = useState('');
    const [messageList, setMessageList] = useState([]);

    const [messageUpdate, setMessageUpdate] = useState([]);
    const chatBoxRef = useRef(null);
    const params = useParams()

    useEffect(() => {
        getMessage()
        keepScrollAtBottom();
    }, [JSON.stringify(messageList)])

    // Function to keep the chat box scroll at the bottom
    const keepScrollAtBottom = () => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    };

    let chat = [
        { from: 'Reviewer', date: '12-05-23', message: ['test message'] },
        { from: 'Teacher', date: '12-05-23', message: ['test message'] },
        { from: 'System', date: '12-05-23', message: ['lesson plan uploaded'] },
        { from: 'Reviewer', date: '12-05-23', message: ['point 1', 'point 2', 'point 3'] },
    ]

    const getMessage = () => {
        let projectId = params[0].split('/').pop();


        Axios.get(Constants.BASE_URL + `api/teacher/getMessages/${projectId}`).then((result) => {

            if (result.data.status) {
                if (result.data.result.length > 0) {
                    let msg = JSON.parse(result.data.result[0].Message)
                    setMessageList(msg)
                    setMessageUpdate(...msg)//used to scroll to bottom when this updates

                } else {
                    setMessageList([])
                    setMessageUpdate([])
                }

            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Could not fetch messages.',
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    const sendMessage = (e) => {

        let projectId = params[0].split('/').pop();

        let data = {
            senderId: props.userInfo.Id,
            from: props.userInfo.FirstName + ' ' + props.userInfo.LastName,
            message: messageText,
            projectId: projectId
        }

        Axios.post(Constants.BASE_URL + `api/teacher/sendMessage`, data, Constants.header).then((result) => {

            if (result.data.status) {
                //fetch chats
                getMessage()
                setMessageText('')
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Could not send message. Please Try again.',
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })

    }

    return (
        <div className="row">
            <div className="col-6">
                <div className="card text-center chat-wrap">
                    <div className="card-header text-left">
                        <h6 className="fw-500">Messages</h6>
                    </div>
                    <div className="card-body chat-body" ref={chatBoxRef}>
                        {/* <h5 className="card-title">Special title treatment</h5>
                        <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                        <a href="#" className="btn btn-primary">Go somewhere</a> */}
                        {
                            messageList.map((message) => {
                                return (
                                    <>
                                        <div className={props.userInfo.Id == message.senderId ? "d-flex flex-column align-items-end" : "d-flex flex-column align-items-start"}>
                                            <div>
                                                {/* <i className="fa fa-user-circle fa-2x" aria-hidden="true"></i> */}
                                                <p className="mb-0 fw-500 user-name">{message.from}<span className="fw-normal ms-2">{message.date.slice(0, 10)}</span></p>
                                            </div>
                                            <div className={props.userInfo.Id == message.senderId ? "card mb-4 message-wrap my-msg border-0" : "card mb-4 message-wrap other-msg"}>
                                                <div className="message-body">
                                                    <p className="mb-0">
                                                        {message.type === 'rejectedLesson' || message.type === 'rejectedPlan' || message.type === 'answer' ?
                                                            <ul className="mb-0">
                                                                {
                                                                    message.message.map((msg) => (
                                                                        <>
                                                                            <li className="">{msg}</li>
                                                                        </>
                                                                    ))
                                                                }
                                                            </ul>
                                                            :
                                                            message.message.map((msg) => (
                                                                <>
                                                                    <span>{msg}</span>
                                                                    <br></br>
                                                                </>
                                                            ))
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div >
                                    </>
                                )
                            })
                        }

                    </div>
                    <div className="card-footer ">
                        <textarea
                            value={messageText}
                            onChange={(e) => setMessageText(e.target.value)}
                            className="chat-textarea"
                            placeholder="Type your message..."
                            rows="3"
                        ></textarea>
                        <div className="chat-send-btn">
                            <button className="btn btn-blue text-light" onClick={(e) => sendMessage(e)}>Send<i className="fa fa-paper-plane ms-2" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
                {/* <div className="chats-window chat-box-wrap d-flex flex-column justify-content-between">

                    <div className="chat-header ">
                        <h4>Messages</h4>
                        <hr></hr>
                    </div>
                    <div className='msg-wrap d-flex flex-column justify-content-end flex-grow-1 mb-3 text-left '>
                        {
                            messageList.map((message) => {
                                return (
                                    <div className="card mb-4 w-50">
                                        <div className="card-body">
                                            <p className="mb-0 fw-bold">{message.from}<span className="fw-normal ms-2">{message.date.slice(0, 10)}</span></p>

                                            <p className="mb-0">{message.message}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div>
                        <div className="mb-1">
                            <textarea
                                onChange={(e) => setMessageText(e.target.value)}
                                value={messageText}
                                className="form-control input-style"
                                placeholder="Message"
                                id="exampleFormControlTextarea1"
                                rows="3"></textarea>
                        </div>
                        <button
                            className="btn btn-blue text-light "
                            onClick={(e) => sendMessage(e)}
                        >
                            Send
                        </button>
                    </div>

                </div> */}
            </div>
        </div >
    )
}

export default AdminChats