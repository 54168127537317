import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';

function DropOffPopup(props) {


    const data = Object.entries(props.dropOff).map((e) => ({ ['id']: e[0], ['count']: e[1] }));

    return (
        <Modal {...props} centered className="transparent-bg">
            <Modal.Header closeButton >
                <Modal.Title id="contained-modal-title-vcenter">
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center align-items-center" >
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="id"> <Label value="View Id" offset={-5} position="insideBottom" /></XAxis>
                    <YAxis label={{ value: 'Drop Count', angle: -90, position: 'insideLeft', textAnchor: 'middle' }} />
                    <Tooltip />
                    <Bar dataKey="count" fill="#8884d8" />
                </BarChart>
            </Modal.Body>
        </Modal>
    )
}
export default DropOffPopup;