import React, { useEffect, useState } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom'
import UploadLesson from '../teacher/uploadLesson/uploadLesson';
import * as Constants from '../../assets/helper/constants'
import AdminLeftPanel from '../admin/leftPanel/adminLeftPanel';
import ProfileUpdate from '../admin/approveProfile/profileUpdate';
import ValidateLesson from '../admin/lessons/validateLesson/ValidateLesson';
import Swal from 'sweetalert2';
import LessonStatus from '../teacher/lessonStatus/lessonStatus';
import TeacherLeftPanel from '../teacher/teacherLeftPanel/teacherLeftPanel';
import Analytics from '../admin/analytics/analytics';
import '../../assets/css/style.css'
import ParentLeftPanel from '../parent/parentLeftPanel/parentLeftPanel';
import Profile from '../parent/profile/profile';
import Timeline from '../teacher/lessons/timeline/Timeline';
import Projects from '../teacher/lessons/projects/Projects';
import Composer from '../teacher/composer/Composer';
import Header from '../header/header';
import LessonPlan from '../teacher/lessons/lessonPlan/LessonPlan';
import TeacherProfile from '../teacher/profile/teacherProfile';
import TeacherChats from '../teacher/lessons/chats/teacherChats';
import MainLesson from '../teacher/lessons/uploadLesson/MainLesson';
import UpdateLesson from '../teacher/lessons/updateLesson/updateLesson';
import AdminProject from '../admin/lessons/adminProject/AdminProject';
import AdminTimeline from '../admin/lessons/adminTimeline/AdminTimeline';
import AdminLessonPlan from '../admin/lessons/adminLessonPlan/AdminLessonPlan';
import AdminChats from '../admin/lessons/chat/adminChats';
import LessonsPerformance from '../admin/lessonPerformance/lessonsPerformance';
import Campaign from '../marketing/campaign/Campaign';
import MarketingLeftPanel from '../marketing/marketingLeftPanel/marketingLeftPanel';
import MarketingAnalytics from '../marketing/marketingAnalytics/marketingAnalytics';
import Retention from '../marketing/marketingAnalytics/retention';
import AdminLessonUpdates from '../admin/lessons/adminLessonUpdate/AdminLessonUpdates';
import TeacherLessonsPerformance from '../teacher/lessonPerformance/lessonPerformance';
import IndividualLessonsPerformance from '../admin/lessonPerformance/IndividualLessonPerformance';
import CreateNotification from '../marketing/notification/createNotification/createNotification';
import NotificationsList from '../marketing/notification/notificationsList/notificationsList';
// import WhatsappChatList from '../marketing/whatsappChat/whatsappChatList';

function Contents(props) {
    const [userInfo, setUserInfo] = useState('');
    const [activeLink, setActiveLink] = useState('');
    const [teacherId, setTeacherId] = useState('');
    const [showChats, setShowChats] = useState(true);
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        if (props.pageType !== "Login" && props.pageType !== "ForgotPassword" && props.pageType !== "VerifyEmail") {
            if (localStorage.getItem("userDetails") === null) {
                history.push('/login')
            } else {
                let userDetails = JSON.parse(localStorage.getItem("userDetails"))


                if (location.pathname.includes("/contents") || location.pathname.includes("/contents/")) {
                    setUserInfo(userDetails)
                }

                // if (userInfo.UserType === "Admin") {
                //     history.push(Constants.ProfileUpdate)
                // } else {
                //     console.log('pathnae', location.pathname)
                //     history.push(Constants.UploadLesson)
                // }
            }
            checkPath(location.pathname.split('/').pop())
        }
    }, [])


    const logoutHandler = () => {
        Swal.fire({
            title: "Are you sure you want to logout?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('userDetails')
                //localStorage.removeItem('rememberme')
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Logged Out Successfully',
                    showConfirmButton: false,
                    timer: 3000
                })
                history.push('/Login')
            }
        })
    }

    const hideChats = (value) => {
        setShowChats(value)
    };

    const checkPath = (path) => {
        setActiveLink(path);
    };

    // <div className='container-fluid p-0 m-0 w-100 h-100 dashboard-bg overflow-auto'>
    return (
        <div className='container-fluid  dashboard-bg'>
            {userInfo.UserType === "Teacher" ? (
                <>
                    <div className="row header-wrap">
                        <div className="col-12 p-0">
                            <Header logout={logoutHandler} userName={userInfo.FirstName + ' ' + userInfo.LastName} />
                        </div>
                    </div>
                    <TeacherLeftPanel activeLink={activeLink} checkPath={checkPath} />

                    <Route exact path={`${Constants.UploadLesson}/:projectId`} component={() => <MainLesson userInfo={userInfo} logout={logoutHandler} />} />
                    <Route exact path={`${Constants.UpdateLesson}/:projectId`} component={() => <UpdateLesson userInfo={userInfo} logout={logoutHandler} />} />
                    <Route exact path={Constants.LessonStatus} component={() => <LessonStatus userInfo={userInfo} logout={logoutHandler} />} />
                    <Route exact path={Constants.Projects} component={() => <Projects userInfo={userInfo} logout={logoutHandler} />} />
                    <Route exact path={`${Constants.StatusTimeline}/:projectId`} component={() => <Timeline userInfo={userInfo} logout={logoutHandler} />} />
                    <Route exact path={`${Constants.LessonPlan}/:projectId`} component={() => <LessonPlan userInfo={userInfo} logout={logoutHandler} />} />
                    <Route exact path={`${Constants.Chats}/*`} component={() => <TeacherChats userInfo={userInfo} logout={logoutHandler} />} />
                    <Route exact path={Constants.Composer} component={() => <Composer userInfo={userInfo} logout={logoutHandler} />} />
                    <Route exact path={Constants.TeacherProfile} component={() => <TeacherProfile userInfo={userInfo} logout={logoutHandler} />} />
                    <Route exact path={Constants.LessonPerformance} component={() => <TeacherLessonsPerformance userInfo={userInfo} logout={logoutHandler} teacher={setTeacherId} />} />
                </>
                // <div className='row p-0 m-0 w-100 h-100'>
                //     <div className='col-2 px-0 mr-5 pos-rel'>
                //         <TeacherLeftPanel activeLink={activeLink} checkPath={checkPath} />
                //     </div>
                //     <div className='col-sm-12 col-md-12 col-lg-12 col-xl-10 p-0 m-0'>
                //         <Route path={Constants.UploadLesson} component={() => <UploadLesson userInfo={userInfo} logout={logoutHandler} />} />
                //         <Route path={Constants.LessonStatus} component={() => <LessonStatus userInfo={userInfo} logout={logoutHandler} />} />

                //     </div>
                // </div>

            ) : userInfo.UserType === "Admin" ? (
                <>
                    <div className="row header-wrap">
                        <div className="col-12 p-0">
                            <Header logout={logoutHandler} userName={userInfo.FirstName + ' ' + userInfo.LastName} />
                        </div>
                    </div>
                    <AdminLeftPanel activeLink={activeLink} checkPath={checkPath} />
                    <Route exact path={Constants.ProfileUpdate} component={() => <ProfileUpdate logout={logoutHandler} />} />
                    {/* <Route exact path={Constants.ValidateLesson} component={() => <ValidateLesson logout={logoutHandler} />} /> */}
                    <Route exact path={Constants.Analytics} component={() => <Analytics logout={logoutHandler} teacher={setTeacherId} />} />
                    <Route exact path={Constants.Performance} component={() => <LessonsPerformance logout={logoutHandler} teacher={setTeacherId} />} />
                    <Route exact path={Constants.IndividualLessonPerformance + "/:lessonId"} component={() => <IndividualLessonsPerformance userInfo={userInfo} logout={logoutHandler} teacher={setTeacherId} />} />


                    <Route exact path={Constants.AdminProject} component={() => <AdminProject userInfo={userInfo} logout={logoutHandler} teacher={setTeacherId} />} />
                    <Route exact path={`${Constants.AdminTimeline}/:projectId`} component={() => <AdminTimeline userInfo={userInfo} logout={logoutHandler} teacher={setTeacherId} />} />
                    <Route exact path={`${Constants.AdminLessonPlan}/:projectId`} component={() => <AdminLessonPlan userInfo={userInfo} logout={logoutHandler} teacher={setTeacherId} />} />
                    <Route exact path={`${Constants.ValidateLesson}/:projectId`} component={() => <ValidateLesson userInfo={userInfo} logout={logoutHandler} hideChats={hideChats} teacher={setTeacherId} />} />
                    <Route exact path={`${Constants.AdminLessonUpdate}/:projectId`} component={() => <AdminLessonUpdates userInfo={userInfo} logout={logoutHandler} teacher={setTeacherId} />} />
                    {showChats && <Route exact path={`${Constants.AdminChats}/*`} component={() => <AdminChats userInfo={userInfo} logout={logoutHandler} teacher={setTeacherId} />} />}
                </>
                // <div className='row p-0 m-0 bg-gray'>
                //     <div className='col-2 px-0 mr-5 pos-rel'>
                //         <AdminLeftPanel activeLink={activeLink} checkPath={checkPath} />
                //     </div>
                //     <div className='col-sm-12 col-md-12 col-lg-12 col-xl-10 ml-5'>
                //         <Route exact path={Constants.ProfileUpdate} component={() => <ProfileUpdate logout={logoutHandler} />} />
                //         <Route exact path={Constants.ValidateLesson} component={() => <ValidateLesson logout={logoutHandler} />} />
                //         <Route exact path={Constants.Analytics} component={() => <Analytics logout={logoutHandler} teacher={setTeacherId} />} />

                //     </div>
                // </div>
            ) : userInfo.UserType === "Parent" ? (
                <>
                    {/* <div className="row header-wrap">
                        <div className="col-12 p-0">
                            <Header logout={logoutHandler} userName={userInfo.FirstName} />
                        </div>
                    </div> */}
                    {/* <ParentLeftPanel activeLink={activeLink} checkPath={checkPath} /> */}
                    <Route exact path={Constants.UserProfile} component={() => <Profile userInfo={userInfo} logout={logoutHandler} />} />

                </>
            ) : userInfo.UserType === "MarketingAdmin" ? (
                <>
                    <div className="row header-wrap">
                        <div className="col-12 p-0">
                            <Header logout={logoutHandler} userName={userInfo.FirstName} />
                        </div>
                    </div>
                    <MarketingLeftPanel activeLink={activeLink} checkPath={checkPath} />
                    <Route exact path={Constants.Campaign} component={() => <Campaign userInfo={userInfo} logout={logoutHandler} />} />
                    <Route exact path={Constants.MarketingAnalytics} component={() => <MarketingAnalytics userInfo={userInfo} logout={logoutHandler} />} />
                    <Route exact path={Constants.Retention} component={() => <Retention userInfo={userInfo} logout={logoutHandler} />} />
                    <Route exact path={Constants.CreateNotification} component={() => <CreateNotification userInfo={userInfo} logout={logoutHandler} />} />
                    <Route exact path={Constants.NotificationList} component={() => <NotificationsList userInfo={userInfo} logout={logoutHandler} />} />
                    {/* <Route exact path={Constants.WhatsappChatList} component={() => <WhatsappChatList userInfo={userInfo} logout={logoutHandler} />} /> */}

                    {/* <div className='row p-0 m-0 bg-gray'>
                        <div className='col-2 px-0 mr-5 pos-rel'>

                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-10 ml-5'>
                            <Route exact path={Constants.Campaign} component={() => <Campaign userInfo={userInfo} logout={logoutHandler} />} />
                            <Route exact path={Constants.MarketingAnalytics} component={() => <MarketingAnalytics userInfo={userInfo} logout={logoutHandler} />} />
                            <Route exact path={Constants.Retention} component={() => <Retention userInfo={userInfo} logout={logoutHandler} />} />
                        </div>
                    </div> */}
                </>

            ) :

                (
                    <div>
                        <h4>Invalid User</h4>
                    </div>
                )
            }
        </div >

    )

}
export default Contents;