
import LessonCard from "../lessonCard/lessonCard";
import LessonModal from "../modal/lessonModal";
import { useEffect, useState } from "react";
import Axios from 'axios'
import { Link } from "react-router-dom";
import BaethoLogo from '../../../assets/images/baetho-kids-logo.png'
import PlayStore from '../../../assets/images/play-store.svg'
import AppStore from '../../../assets/images/app-store.svg'
import * as Constants from '../../../assets/helper/constants'
import Navbar from "../navbar/navbar";


// const BASE_URL = 'http://localhost:8090/' // Local Server
// const BASE_URL = 'http://122.248.229.117:8090/' //Test server
const BASE_URL = 'https://baethoedu.com/' //Production server

function Home(props) {

    const [showModal, setShowModal] = useState(false)
    const [id, setLessonId] = useState(false)
    const [lessons, setLessons] = useState([])


    const toggleModal = (id) => {
        // console.log('modal', url)
        setLessonId(id)
        setShowModal(!showModal)
    }

    const getLessons = () => {

        Axios.get(Constants.BASE_URL + `api/AggregatorLessons/getDemoLessons`).then((result) => {
            if (result.data.status) {
                setLessons(result.data.result)
            } else {
                window.alert("Something went wrong.")
            }
        })
    }

    useEffect(() => {
        getLessons()
    }, [])



    return (
        <div className="container-fluid main-bg">
            {showModal && <LessonModal show={showModal} setShow={setShowModal} onHide={toggleModal} id={id} />}

            <Navbar />

            <div className="container pt-4">

                <div className="row my-4">
                    <div className="col-12">
                        <div className="home-title">
                            <h1 className="text-center">Try Lessons</h1>
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    {

                        lessons.map((element) => (
                            <div key={element.Id} className="col-12 col-sm-6 col-md-6 col-lg-4" onClick={() => toggleModal(element.Id)}>
                                <LessonCard
                                    key={element.Id}
                                    id={element.Id}
                                    thumb1={BASE_URL + element.Thumb1}
                                    name={element.Name}
                                    desc={element.Description}
                                />
                            </div>
                        ))
                    }
                </div>

                <div className="jumbotron jumbotron-fluid bg-blue rounded p-4 mb-5 shadow">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-4 text-white">
                                <h2 className="mb-1">DOWNLOAD OUR APP</h2>
                            </div>
                            <div className="col-6 col-sm-6 col-md-3 col-lg-2 ms-auto">
                                <a href='https://play.google.com/store/apps/details?id=com.baetho.aggregator' target='_blank' rel="noreferrer">
                                    <img className="download-app-img" src={PlayStore} alt="Playstore" />
                                </a>
                            </div>
                            <div className="col-6 col-sm-6 col-md-3 col-lg-2">
                                <a href='https://apps.apple.com/in/app/baetho-the-activity-store/id6447284412' target='_blank' rel="noreferrer">
                                    <img className="download-app-img" src={AppStore} alt="Playstore" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright pb-2">
                    <p>© COPYRIGHT @ {JSON.stringify(new Date().getFullYear())} BAETHO TECHNOLOGIES PRIVATE LIMITED. ALL RIGHTS RESERVED.</p>
                    <Link to="/privacy-policy" className="nav-link text-blue d-inline-block">Privacy Policy</Link> | <Link to="/terms-of-use" className="nav-link text-blue d-inline-block">Terms of use</Link>
                </div>
            </div>
        </div>
    )
}
export default Home;