import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select';
import Axios from 'axios'
import * as Constants from '../../../../assets/helper/constants'
import Swal from 'sweetalert2';
// import JoditEditor from 'jodit-react';


const CreateNotification = (props) => {

    const [countries, setCountries] = useState([])
    const [allTestUsers, setAllTestUsers] = useState([])
    const [selectedTestUsers, setSelectedTestUsers] = useState([])
    const [premiumCourses, setPremiumCourses] = useState([])
    const [lessons, setLessons] = useState([])
    const [premiumLessons, setPremiumLessons] = useState([])
    const [nonPremiumLessons, setNonPremiumLessons] = useState([])
    const [courses, setCourses] = useState([])

    const [formFields, setFormFields] = useState([
        {
            id: Date.now(),
            category: '',
            age: []
        },
    ]);
    const [feedBackFields, setFeedBackFields] = useState([
        {
            id: Date.now(),
            inputType: "",
            value: ""
        }
    ])
    const [isValid, setIsValid] = useState('')
    const [notificationName, setNotificationName] = useState('')
    const [channel, setChannel] = useState('App')
    const [triggerType, setTriggerType] = useState('')
    const [eventTrigger, setEventTrigger] = useState({ trigger: "" })
    const [timeBasedTrigger, setTimeBasedTrigger] = useState({ startDate: '', endDate: "", repeat: "", repeatOn: "", time: "" })
    const [content, setContent] = useState({ title: "", message: "", type: "" })
    const [image, setImage] = useState(null)
    const [video, setVideo] = useState(null)
    const [actionLinkFields, setActionLinkFields] = useState("")
    const [mediaType, setMediaType] = useState("")
    const [appVersions, setAppVersions] = useState([])

    const whatsappVariables = [
        { label: "LessonName", value: "LessonName" },
        { label: "CourseName", value: "CourseName" },
        { label: "ParentName", value: "ParentName" },
        { label: "KidName", value: "KidName" }
    ]
    // const editor = useRef(null);
    // const [emailContent, setEmailContent] = useState('');

    useEffect(() => {
        getCountryList()
        getPremiumCourses()
        getLessons()
        getTestUsers()
        getAppVersion()
    }, [])

    const getAppVersion = () => {
        Axios.get(Constants.BASE_URL + "api/notification/getAppVersions").then((response) => {
            if (response.data.status) {

                let arr = response.data.result.map((element) => {
                    return { label: element.AppVersion, value: element.AppVersion }
                })
                setAppVersions(arr)
            } else {
                setAppVersions([])
            }
        })
    }
    const getCountryList = async () => {
        Axios.get(Constants.BASE_URL + "api/notification/getCountriesList").then((response) => {
            if (response.data.status) {
                let options = response.data.result.map((item) => {
                    return { label: item.Country, value: item.Country }
                })
                setCountries(options)

            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to fetch countries list.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    const getTestUsers = () => {
        Axios.get(Constants.BASE_URL + "api/notification/getTestUsers").then((response) => {
            if (response.data.status) {
                let options = response.data.result.map((item) => {
                    return { label: item.FirstName, value: item.Id }
                })
                setAllTestUsers(options)

            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to fetch test users.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    const getPremiumCourses = async () => {
        Axios.get(Constants.BASE_URL + "api/notification/getPremiumCourses").then((response) => {
            if (response.data.status) {
                let options = response.data.result.map((item) => {
                    return { label: item.CourseName, value: item.Id }
                })
                setPremiumCourses(options)
                setCourses(response.data.result)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to fetch courses.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    const getLessons = () => {
        Axios.get(Constants.BASE_URL + "api/notification/getLessons").then((response) => {
            if (response.data.status) {
                setLessons(response.data.result)
                let premiumLessonsArr = []
                let nonPremiumLessonsArr = []
                response.data.result.forEach(lesson => {
                    if (lesson.Premium == 1) {
                        premiumLessonsArr.push({ label: lesson.Name, value: lesson.Id })
                    } else if (lesson.Premium == 0) {
                        nonPremiumLessonsArr.push({ label: lesson.Name, value: lesson.Id })
                    }
                })
                setPremiumLessons(premiumLessonsArr)
                setNonPremiumLessons(nonPremiumLessonsArr)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to fetch lessons.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    const channelChangeHandler = (value) => {
        setChannel(value)
        setImage(null)
        setVideo(null)
        //reset data on channel change
        setContent({ title: "", message: "", type: "" })
    }
    //main action fields
    const handleMainActionLinkDropdown = (value) => {
        if (value !== null && value !== undefined && value !== "") {
            let obj = {
                page: value
            }
            setActionLinkFields(obj)
        } else {
            setActionLinkFields("")
        }
    }

    //sub actions field options
    const handleSubActionLinkDropdown = (key, value) => {
        if (key === 'courseId') {
            let obj = courses.filter((course) => {
                return course.Id == value
            })

            setActionLinkFields((prevState) => {
                return { ...prevState, [key]: value, ['subjectId']: obj[0].Subject }
            })
        } else {
            setActionLinkFields((prevState) => {
                return { ...prevState, [key]: value }
            })
        }

    }

    const handleEventTrigger = (field, value) => {

        //reset eventTrigger to default if event is changed.
        if (field == "trigger" && eventTrigger.trigger != value) {
            setEventTrigger({ trigger: "" })
        }
        //reset value if 
        if (field == "completedLessonType") {
            setEventTrigger((prevState) => {
                return { ...prevState, ['value']: "" }
            })
        }
        if (field == "condition") {
            setEventTrigger((prevState) => {
                return { ...prevState, ['value']: "" }
            })
            if (value == 'UsingTheApp') {
                delete eventTrigger?.startDate
                delete eventTrigger?.endDate
                delete eventTrigger?.time
            }
        }

        setEventTrigger((prevState) => {
            return { ...prevState, [field]: value }
        })
    }

    const onTimeBasedTriggerChange = (field, value) => {
        if (field === "repeatOnWeekly") {
            let daysArray = value.map((ele) => {
                return ele.value
            })
            setTimeBasedTrigger((prevState) => {
                return { ...prevState, ['repeatOn']: daysArray }
            })
        } else {
            setTimeBasedTrigger((prevState) => {
                return { ...prevState, [field]: value }
            })
        }
    }

    const subjects = [
        { label: 'Science', value: 'Science' },
        { label: 'Mathemetics', value: 'Mathemetics' },
        { label: 'English', value: 'English' },
    ]

    const days = [
        { label: 'Monday', value: 'Monday' },
        { label: 'Tuesday', value: 'Tuesday' },
        { label: 'Wednesday', value: 'Wednesday' },
        { label: 'Thursday', value: 'Thursday' },
        { label: 'Friday', value: 'Friday' },
        { label: 'Saturday', value: 'Saturday' },
        { label: 'Sunday', value: 'Sunday' },
    ]

    const setWhatsappMedia = (type, file) => {
        if (type === 'image') {
            setVideo(null)
            setImage(file)
        } else {
            setImage(null)
            setVideo(file)
        }
    }

    const handleContentChange = (field, value) => {
        if (field === 'variables') {
            let variableArr = value.map((ele) => {
                return ele.value
            })
            setContent((prevState) => {
                return { ...prevState, [field]: variableArr }
            })
        } else if (field === 'tags') {
            let tagsArr = value.split(",").map(tag => tag.trim())
            let parsedArr = tagsArr.filter(value => value.trim() !== '');
            setContent((prevState) => {
                return { ...prevState, [field]: parsedArr }
            })
        }
        else {
            setContent((prevState) => {
                return { ...prevState, [field]: value }
            })
        }
    }
    const addContentVarable = (field, value) => {
        if (field === 'title') {
            setContent((prevState) => {
                return { ...prevState, [field]: content.title + "" + value }
            })
        } else {
            setContent((prevState) => {
                return { ...prevState, [field]: content.message + "" + value }
            })
        }
    }
    const handleDropdownChange = (field, value, index) => {
        const updatedFormFields = [...formFields];
        updatedFormFields[index] = {
            ...updatedFormFields[index],
            [field]: value,
        };
        setFormFields(updatedFormFields);

    };

    const handleTestUsersChange = (field, value) => {

        let testUsersArr = value.map((ele) => {
            return ele.value
        })
        setSelectedTestUsers(testUsersArr)
    }
    const handleInterestChange = (arr, value, index) => {
        const updatedFormFields = [...formFields];

        let interestArr = value.map((ele) => {
            return ele.value
        })
        updatedFormFields[index] = {
            ...updatedFormFields[index],
            valueArr: interestArr,
        };
        setFormFields(updatedFormFields);
    };

    const handlePremiumCourseChange = (arr, value, index) => {
        const updatedFormFields = [...formFields];

        let interestArr = value.map((ele) => {
            return ele.value
        })
        updatedFormFields[index] = {
            ...updatedFormFields[index],
            valueArr: interestArr,
        };
        setFormFields(updatedFormFields);
    };

    const handleCheckboxChange = (age, index) => {

        const updatedFormFields = [...formFields];

        const updatedAge = updatedFormFields[index]?.age?.includes(age)
            ? updatedFormFields[index].age.filter((a) => a !== age)
            : [...updatedFormFields[index].age, age];
        updatedFormFields[index] = {
            ...updatedFormFields[index],
            age: updatedAge,
        };
        setFormFields(updatedFormFields);

    };

    const handleInputChange = (field, value, index) => {
        const updatedFormFields = [...formFields];
        updatedFormFields[index] = {
            ...updatedFormFields[index],
            [field]: value,
        };
        setFormFields(updatedFormFields);
    };

    const addFormField = () => {
        setFormFields([
            ...formFields,
            {
                id: Date.now(),
                category: '',
                age: []
            },
        ]);
    };

    const removeFormField = (index) => {
        const updatedFormFields = [...formFields];
        updatedFormFields.splice(index, 1);
        setFormFields(updatedFormFields);
    };

    const addFeedBackForm = () => {
        setFeedBackFields([
            ...feedBackFields,
            {
                id: Date.now(),
                inputType: '',
                value: ""
            },
        ]);
    };
    const removeFeedBackForm = (index) => {
        const updatedFeedbackFields = [...feedBackFields];
        updatedFeedbackFields.splice(index, 1);
        setFeedBackFields(updatedFeedbackFields);
    };
    const handleFeedbackFormChange = (field, value, index) => {
        const updatedFeedBackFields = [...feedBackFields];
        updatedFeedBackFields[index] = {
            ...updatedFeedBackFields[index],
            [field]: value,
        };
        setFeedBackFields(updatedFeedBackFields);

    };


    const createNotification = (notificationType) => {

        var valid = true;

        formFields.map((obj) => {
            if (obj.category === 'Users') {
                if (obj.value === "" || obj.value === undefined || obj.value === null) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("Users")
                    valid = false
                }
            } else if (obj.category === 'OS') {
                if (obj.value === "" || obj.value === undefined || obj.value === null) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("OS")
                    valid = false
                }
            }
            else if (obj.category === 'Age') {
                // console.log('age', obj.age)
                if (obj.age.length === 0) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("Age")
                    valid = false
                }
            }
            else if (obj.category === 'LastLogin') {
                if (obj.condition === "" || obj.condition === undefined || obj.condition === null) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("LastLoginCondition")
                    valid = false
                }
                else if (obj.range === "" || obj.range === undefined || obj.range === null) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("LastLoginRange")
                    valid = false
                }
                else if (obj.range === "Custom" && (obj.value === "" || obj.value === undefined || obj.value === null)) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("LastLoginValue")
                    valid = false
                }
            }
            else if (obj.category === 'NotLoggedIn') {
                if (obj.condition === "" || obj.condition === undefined || obj.condition === null) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("NotLoggedInCondition")
                    valid = false
                }
                else if (obj.range === "" || obj.range === undefined || obj.range === null) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("NotLoggedInRange")
                    valid = false
                }
                else if (obj.range === "Custom" && (obj.value === "" || obj.value === undefined || obj.value === null)) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("NotLoggedInValue")
                    valid = false
                }
            }
            else if (obj.category === 'Experience') {
                if (obj.condition === "" || obj.condition === undefined || obj.condition === null) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("ExperienceCondition")
                    valid = false
                }
                else if (obj.value === "" || obj.value === undefined || obj.value === null) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("ExperienceValue")
                    valid = false
                }
            }
            else if (obj.category === 'AgeOfRegistration') {
                if (obj.condition === "" || obj.condition === undefined || obj.condition === null) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("AgeOfRegistrationCondition")
                    valid = false
                }
                else if (obj.range === "" || obj.range === undefined || obj.range === null) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("AgeOfRegistrationRange")
                    valid = false
                }
                else if (obj.range === "Custom" && (obj.value === "" || obj.value === undefined || obj.value === null)) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("AgeOfRegistrationValue")
                    valid = false
                }
            }
            else if (obj.category === 'AverageSessionTime') {
                if (obj.condition === "" || obj.condition === undefined || obj.condition === null) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("AverageSessionTimeCondition")
                    valid = false
                }
                else if (obj.value === "" || obj.value === undefined || obj.value === null) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("AverageSessionTimeValue")
                    valid = false
                }
                else if (obj.duration === "" || obj.duration === undefined || obj.duration === null) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("AverageSessionTimeDuration")
                    valid = false
                }
                else if (obj.range === "" || obj.range === undefined || obj.range === null) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("AverageSessionTimeRange")
                    valid = false
                }
                else if (obj.range === "Custom" && (obj.subValue === "" || obj.subValue === undefined || obj.subValue === null)) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("AverageSessionTimeSubValue")
                    valid = false
                }
            }
            else if (obj.category === 'Interests') {
                if (obj.valueArr.length === 0) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("Interests")
                    valid = false
                }
            }
            else if (obj.category === 'Countries') {
                if (obj.condition === "" || obj.condition === undefined || obj.condition === null) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("CountriesCondition")
                    valid = false
                }
                else if (obj.valueArr === undefined || obj.valueArr === null || obj?.valueArr?.length === 0) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("CountriesOption")
                    valid = false
                }
            }
            else if (obj.category === 'Subjects') {

                if (obj.valueArr === undefined || obj.valueArr === null || obj?.valueArr?.length === 0) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("Subjects")
                    valid = false
                }
            }
            else if (obj.category === 'DaysToExpire') {
                if (obj.condition === "" || obj.condition === undefined || obj.condition === null) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("DaysToExpireCondition")
                    valid = false
                }
                else if (obj.value === "" || obj.value === undefined || obj.value === null) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("DaysToExpireValue")
                    valid = false
                }
            }
            else if (obj.category === 'AppVersion') {
                if (obj.condition === "" || obj.condition === undefined || obj.condition === null) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("AppVersionCondition")
                    valid = false
                }
                else if (obj.valueArr === undefined || obj.valueArr === null || obj?.valueArr?.length === 0) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("AppVersionValue")
                    valid = false
                }
            }

        })

        if (notificationName === "" || notificationName.trim() === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                html: "<h2>Invalid data.</h2>",
                showConfirmButton: false,
                timer: 2000
            })
            setIsValid("NotificationName")
            valid = false
        }
        if (channel === "" || channel.trim() === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                html: "<h2>Invalid data.</h2>",
                showConfirmButton: false,
                timer: 2000
            })
            setIsValid("Channel")
            valid = false
        }
        if (triggerType === "" || triggerType.trim() === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                html: "<h2>Invalid data.</h2>",
                showConfirmButton: false,
                timer: 2000
            })
            setIsValid("TriggerType")
            valid = false
        }
        if (triggerType === 'TimeBased') {
            if (timeBasedTrigger.startDate == "") {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    html: "<h2>Invalid data.</h2>",
                    showConfirmButton: false,
                    timer: 2000
                })
                setIsValid("StartDate")
                valid = false
            }
            if (timeBasedTrigger.endDate === "") {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    html: "<h2>Invalid data.</h2>",
                    showConfirmButton: false,
                    timer: 2000
                })
                setIsValid("EndDate")
                valid = false
            }
            if (timeBasedTrigger.time === "") {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    html: "<h2>Invalid data.</h2>",
                    showConfirmButton: false,
                    timer: 2000
                })
                setIsValid("Time")
                valid = false
            }
            if (timeBasedTrigger.repeat === "") {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    html: "<h2>Invalid data.</h2>",
                    showConfirmButton: false,
                    timer: 2000
                })
                setIsValid("Repeat")
                valid = false
            }
            if (timeBasedTrigger.repeat === "Weekly") {
                if (timeBasedTrigger.repeatOn === "" || timeBasedTrigger.repeatOn?.length === 0) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("RepeatOn")
                    valid = false
                }
            }
            if (timeBasedTrigger.repeat === "Monthly") {
                if (timeBasedTrigger.repeatOn?.length === 0 || timeBasedTrigger.repeatOn === "") {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("RepeatOn")
                    valid = false
                }
            }
        } else {

            if (eventTrigger.trigger === "") {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    html: "<h2>Invalid data.</h2>",
                    showConfirmButton: false,
                    timer: 2000
                })
                setIsValid("EventTrigger")
                valid = false
            }
            if (eventTrigger.trigger === "NumberOfCompletedLessons") {
                if (eventTrigger?.lessonType === undefined || eventTrigger?.lessonType === "") {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("NumberOfCompletedLessonsType")
                    valid = false
                }
                if (eventTrigger?.condition === undefined || eventTrigger?.condition === "") {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("NumberOfCompletedLessonsCondition")
                    valid = false
                }
                if (eventTrigger?.value === undefined || eventTrigger?.value === "") {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("NumberOfCompletedLessonsValue")
                    valid = false
                }
            }
            if (eventTrigger.trigger === "CompletedLesson") {
                if (eventTrigger?.value.length === 0 || eventTrigger?.value === "") {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("CompletedLessonValue")
                    valid = false
                }


                if (eventTrigger?.completedLessonType === undefined || eventTrigger?.completedLessonType === "") {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("CompletedLessonType")
                    valid = false
                }

            }
            if (eventTrigger.trigger === "NewLesson") {
                if (eventTrigger?.value === undefined || eventTrigger?.value === "") {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("NewLessonValue")
                    valid = false
                }
            }
            if (eventTrigger.trigger === "CourseCompleted") {
                if (eventTrigger?.value === undefined || eventTrigger?.value === "") {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("CourseCompletedValue")
                    valid = false
                }
            }
            if (eventTrigger.trigger === "UnlockRewards") {
                if (eventTrigger?.value === undefined || eventTrigger?.value === "") {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("RewardsValue")
                    valid = false
                }
            }
            if (eventTrigger.trigger === "RewardNotUsed") {
                if (eventTrigger?.condition === undefined || eventTrigger?.condition === "") {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("RewardNotUsedCondition")
                    valid = false
                }
                if (eventTrigger?.condition === "UsingTheApp" && (eventTrigger?.value === undefined || eventTrigger?.value === "")) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("RewardNotUsedValue1")
                    valid = false
                }
                if (eventTrigger?.condition === "NotUsingTheApp" && (eventTrigger?.value === undefined || eventTrigger?.value === "")) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("RewardNotUsedValue2")
                    valid = false
                }
                // console.log(eventTrigger?.startDate === undefined);
                if (eventTrigger?.condition === "NotUsingTheApp" && (eventTrigger?.startDate === undefined || eventTrigger?.startDate === "" ||
                    eventTrigger?.endDate === undefined || eventTrigger?.endDate === "" || eventTrigger?.time === undefined || eventTrigger?.time === "")) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("RewardNotUsedTriggerDate")
                    valid = false
                }
            }


        }

        if (content.type === "" || content.type.trim() === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                html: "<h2>Invalid data.</h2>",
                showConfirmButton: false,
                timer: 2000
            })
            setIsValid("notificationType")
            valid = false
        }

        if (channel === 'WhatsApp') {

            if (content.title === "" || content.title.trim() === "") {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    html: "<h2>Invalid data.</h2>",
                    showConfirmButton: false,
                    timer: 2000
                })
                setIsValid("WhatsappCampaignName")
                valid = false
            }
            if (image !== null && image !== undefined) {
                if (image.name.split('.').pop() !== 'jpg' && image.name.split('.').pop() !== 'JPG'
                    && image.name.split('.').pop() !== 'png' && image.name.split('.').pop() !== 'PNG'
                    && image.name.split('.').pop() !== 'jpeg' && image.name.split('.').pop() !== 'JPEG') {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid image format.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("InvalidWhatsAppImageFormat")
                    valid = false
                    return
                }

                if (image.size > 5000000) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Image size should not exceed 5MB.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("InvalidWhatsAppImageSize")
                    valid = false
                    return
                }
            }
            if (video !== null && video !== undefined) {
                if (video.name.split('.').pop() !== 'mp4' && video.name.split('.').pop() !== 'MP4') {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid video format.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("InvalidWhatsAppVideoFormat")
                    valid = false
                    return
                }

                if (video.size > 16000000) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Video size should not exceed 16MB.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("InvalidWhatsAppVideoSize")
                    valid = false
                    return
                }
            }


        } else {
            if (content.title === "" || content.title.trim() === "") {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    html: "<h2>Invalid data.</h2>",
                    showConfirmButton: false,
                    timer: 2000
                })
                setIsValid("ContentTitle")
                valid = false
            }
            if (content.message === "" || content.message.trim() === "") {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    html: "<h2>Invalid data.</h2>",
                    showConfirmButton: false,
                    timer: 2000
                })
                setIsValid("ContentMessage")
                valid = false
            }
            if (channel === "App") {
                if (actionLinkFields.page === "lessonPage" && (actionLinkFields.lessonId === undefined ||
                    actionLinkFields.lessonId === null || actionLinkFields.lessonId === "")) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("InvalidLesson")
                    valid = false
                    return
                }

                if (actionLinkFields.page === "coursePage" && (actionLinkFields.courseId === undefined ||
                    actionLinkFields.courseId === null || actionLinkFields.courseId === "")) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("InvalidCourseName")
                    valid = false
                    return
                }
                if (actionLinkFields.page === "coupon" && (actionLinkFields.couponCode === undefined ||
                    actionLinkFields.couponCode === null || actionLinkFields.couponCode === "")) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("CouponCode")
                    valid = false
                    return
                }
                if (actionLinkFields.page === "url" && (actionLinkFields.url === undefined ||
                    actionLinkFields.url === null || actionLinkFields.url === "")) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("URL")
                    valid = false
                    return
                }
                if (actionLinkFields.page === "feedback") {

                    feedBackFields.map((fields) => {
                        if (fields.inputType === "" || fields.inputType === undefined ||
                            (fields.inputType === "option" && (fields.value === "" || fields.value === undefined))) {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'error',
                                html: "<h2>Invalid data.</h2>",
                                showConfirmButton: false,
                                timer: 2000
                            })
                            setIsValid("FeedBackValue")
                            valid = false
                            return
                        }
                    })
                    if (actionLinkFields.feedBackTitle === "" || actionLinkFields.feedBackTitle === undefined) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            html: "<h2>Invalid data.</h2>",
                            showConfirmButton: false,
                            timer: 2000
                        })
                        setIsValid("FeedBackTitle")
                        valid = false
                        return
                    }
                }
                if (content.expiresAt === "" || content.expiresAt === undefined) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: "<h2>Invalid data.</h2>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setIsValid("ExpiresAt")
                    valid = false
                }
                if (image !== null && image !== undefined) {
                    if (image.name.split('.').pop() !== 'jpg' && image.name.split('.').pop() !== 'JPG'
                        && image.name.split('.').pop() !== 'png' && image.name.split('.').pop() !== 'PNG'
                        && image.name.split('.').pop() !== 'jpeg' && image.name.split('.').pop() !== 'JPEG') {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            html: "<h2>Invalid image format.</h2>",
                            showConfirmButton: false,
                            timer: 2000
                        })
                        setIsValid("InvalidImageFormat")
                        valid = false
                        return
                    }

                    if (image.size > 1000000) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            html: "<h2>Image size should not exceed 1MB.</h2>",
                            showConfirmButton: false,
                            timer: 2000
                        })
                        setIsValid("InvalidImageSize")
                        valid = false
                        return
                    }
                }
                if (video !== null && video !== undefined) {
                    if (video.name.split('.').pop() !== 'mp4' && video.name.split('.').pop() !== 'MP4') {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            html: "<h2>Invalid video format.</h2>",
                            showConfirmButton: false,
                            timer: 2000
                        })
                        setIsValid("InvalidvideoFormat")
                        valid = false
                        return
                    }

                    if (video.size > 10000000) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            html: "<h2>Video size should not exceed 10MB.</h2>",
                            showConfirmButton: false,
                            timer: 2000
                        })
                        setIsValid("InvalidVideoSize")
                        valid = false
                        return
                    }
                }
            }
        }

        if (notificationType != "TEST") {
            if (selectedTestUsers.length > 0) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    html: "<h2>Invalid data.</h2>",
                    showConfirmButton: false,
                    timer: 2000
                })
                setIsValid("InvalidTestUsers")
                valid = false
                return
            }
        }

        let actionParam = ""
        let feedbackOptions = ""
        if (actionLinkFields !== null && actionLinkFields !== undefined && actionLinkFields !== "") {
            if (actionLinkFields.page === 'feedback') {
                //deleting unwanted property id
                feedBackFields.map(obj => {
                    delete obj.id
                })
                actionParam = actionLinkFields
                actionParam['feedbackFields'] = feedBackFields
            } else {
                actionParam = actionLinkFields
            }
        }

        if (!valid) {
            return
        }
        setIsValid("")


        console.log('valid', valid)
        var trigger = triggerType === "TimeBased" ? timeBasedTrigger : eventTrigger

        const formData = new FormData();
        formData.append("name", notificationName);
        formData.append("filterData", JSON.stringify(formFields));
        formData.append("channel", channel);
        formData.append("triggerType", triggerType);
        formData.append("trigger", JSON.stringify(trigger));
        formData.append("content", JSON.stringify(content));
        formData.append("image", image);
        formData.append("video", video);
        formData.append('actionParam', actionParam === "" ? null : JSON.stringify(actionParam))
        formData.append('feedbackFields', feedbackOptions === "" ? null : JSON.stringify(feedbackOptions))

        Swal.fire({
            title: "Create Notification?",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {

                if (notificationType != "TEST") {

                    Axios.post(Constants.BASE_URL + "api/notification/createNotification", formData, Constants.header).then((response) => {
                        if (response.data.status) {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: "Notification created successfully.",
                                showConfirmButton: false,
                                timer: 2000
                            })

                        } else {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'error',
                                title: "Unable to create notification.",
                                showConfirmButton: false,
                                timer: 2000
                            })
                        }
                    })
                } else {

                    if (selectedTestUsers.length == 0) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            html: "<h2>Invalid data.</h2>",
                            showConfirmButton: false,
                            timer: 2000
                        })
                        setIsValid("TestUsers")
                        valid = false
                        return
                    }
                    formData.append("testUsers", selectedTestUsers);
                    formData.append("notificationType", "TestNotification");

                    // const formData = new FormData();
                    // formData.append("name", notificationName);
                    // formData.append("filterData", JSON.stringify(formFields));
                    // formData.append("channel", channel);
                    // formData.append("triggerType", triggerType);
                    // formData.append("trigger", JSON.stringify(trigger));
                    // formData.append("content", JSON.stringify(content));
                    // formData.append("image", image);
                    // formData.append("video", video);
                    // formData.append("testUsers", selectedTestUsers);
                    // formData.append("notificationType", "TestNotification");
                    // formData.append('actionParam', actionParam === "" ? null : JSON.stringify(actionParam))


                    Axios.post(Constants.BASE_URL + "api/notification/createNotification", formData, Constants.header).then((response) => {
                        if (response.data.status) {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: "Notification created successfully.",
                                showConfirmButton: false,
                                timer: 2000
                            })

                        } else {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'error',
                                title: "Unable to create notification.",
                                showConfirmButton: false,
                                timer: 2000
                            })
                        }
                    })
                }

            }
        })
    }

    return (
        <div className='right-panel-container'>
            <div className='container-fluid'>
                <div className="row mb-4">
                    <div className="col-12">
                        <h2 className="section-name">Create Notifications</h2>
                    </div>
                </div>
                <div className='card box-shadows mb-4'>
                    <div className='card-body px-5'>
                        <div className="form-group row mb-4">
                            <label htmlFor="notificationName" className="col-sm-2 col-form-label">Name<sup className="text-danger">*</sup></label>
                            <div className="col-sm-3">
                                <input
                                    value={notificationName}
                                    type="text"
                                    className="form-control"
                                    id="notificationName"
                                    placeholder='Notification name'
                                    onChange={(e) => setNotificationName(e.target.value)}
                                />
                                {isValid === "NotificationName" ? <small className='text-danger'> Please provide vailid name.</small> : null}
                            </div>
                        </div>
                        <hr></hr>
                        <div className="form-group row mb-4">
                            <label htmlFor="notificationName" className="col-sm-2 col-form-label">Channel<sup className="text-danger">*</sup></label>
                            <div className="col-sm-6" >
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input
                                        type="radio"
                                        checked={channel === 'App'}
                                        value="App"
                                        id="channelApp"
                                        name="channel"
                                        className="custom-control-input me-2"
                                        onChange={e => channelChangeHandler(e.target.value)}
                                    />
                                    <label className="custom-control-label" htmlFor="channelApp">App</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input
                                        type="radio"
                                        checked={channel === 'Email'}
                                        value="Email"
                                        id="channelEmail"
                                        name="channel"
                                        className="custom-control-input me-2"
                                        onChange={e => channelChangeHandler(e.target.value)}
                                    />
                                    <label className="custom-control-label" htmlFor="channelEmail">Email</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input
                                        type="radio"
                                        checked={channel === 'WhatsApp'}
                                        value="WhatsApp"
                                        id="channelWhatsApp"
                                        name="channel"
                                        className="custom-control-input me-2"
                                        onChange={e => channelChangeHandler(e.target.value)}
                                    />
                                    <label className="custom-control-label" htmlFor="channelWhatsApp">WhatsApp</label>
                                </div>
                                {/* <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadioInline2" name="customRadioInline1" className="custom-control-input" />
                                    <label className="custom-control-label" htmlFor="customRadioInline2">Whatsapp</label>
                                </div> */}
                                {isValid === "Channel" ? <small className='text-danger'> Please provide vailid channel.</small> : null}

                            </div>
                        </div>
                        <hr></hr>
                        <div className="form-group row mb-4" onChange={e => setTriggerType(e.target.value)}>
                            <label className="col-sm-2 col-form-label">Trigger Type<sup className="text-danger">*</sup></label>
                            <div className="col-sm-6">
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" value='TimeBased' id="triggerTime" name="triggerType" className="custom-control-input me-2" />
                                    <label className="custom-control-label" htmlFor="triggerTime">Time based</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" value="EventBased" id="triggerEvent" name="triggerType" className="custom-control-input me-2" />
                                    <label className="custom-control-label" htmlFor="triggerEvent">Event Based</label>
                                </div>
                                {isValid === "TriggerType" ? <small className='text-danger'> Please provide vailid trigger type.</small> : null}
                            </div>
                        </div>
                        {
                            triggerType == 'TimeBased' ?
                                (
                                    <div>
                                        <div className="form-group row mb-4">
                                            <label className="col-sm-2 col-form-label">Start Date<sup className="text-danger">*</sup></label>
                                            <div className="col-2">
                                                <input
                                                    type="date"
                                                    value={timeBasedTrigger.startDate}
                                                    id="startDate"
                                                    name="triggerType"
                                                    className="form-control me-2"
                                                    onChange={(e) => onTimeBasedTriggerChange('startDate', e.target.value)}
                                                />
                                                {isValid === "StartDate" ? <small className='text-danger'> Please provide vailid start date.</small> : null}
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4">
                                            <label className="col-sm-2 col-form-label">End Date<sup className="text-danger">*</sup></label>
                                            <div className="col-2">
                                                <input
                                                    type="date"
                                                    value={timeBasedTrigger.endDate}
                                                    id="endDate"
                                                    name="triggerType"
                                                    className="form-control me-2"
                                                    onChange={(e) => onTimeBasedTriggerChange('endDate', e.target.value)}
                                                />
                                                {isValid === "EndDate" ? <small className='text-danger'> Please provide vailid end date.</small> : null}
                                            </div>
                                            <div className='col-2'>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input
                                                        type="radio"
                                                        checked={timeBasedTrigger.endDate === "Indefinite"}
                                                        value=""
                                                        id="indefinite"
                                                        name="indefinite"
                                                        className="custom-control-input me-2"
                                                        onChange={(e) => onTimeBasedTriggerChange('endDate', 'Indefinite')}
                                                    />
                                                    <label className="custom-control-label" htmlFor="indefinite">Indefinite</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4">
                                            <label className="col-sm-2 col-form-label">Repeat<sup className="text-danger">*</sup></label>
                                            <div className="col-2">
                                                <select
                                                    className='form-select me-2'
                                                    value={timeBasedTrigger.repeat}
                                                    onChange={(e) => onTimeBasedTriggerChange('repeat', e.target.value)}

                                                >
                                                    <option value="">Select an option</option>
                                                    <option value="Daily">Daily</option>
                                                    <option value="Weekly">Weekly</option>
                                                    <option value="Monthly">Monthly</option>
                                                </select>
                                                {isValid === "Repeat" ? <small className='text-danger'> Please provide vailid option.</small> : null}

                                            </div>
                                            <div className='col-8'>
                                                {timeBasedTrigger.repeat === 'Weekly' ?
                                                    (
                                                        <>
                                                            <CreatableSelect
                                                                isMulti
                                                                options={days}
                                                                className="w-25"
                                                                onChange={(e) => onTimeBasedTriggerChange('repeatOnWeekly', e)}

                                                            />
                                                            {isValid === "RepeatOn" ? <small className='text-danger'> Please provide vailid option.</small> : null}
                                                        </>
                                                    )
                                                    : timeBasedTrigger.repeat === 'Monthly' ?
                                                        (
                                                            <>
                                                                <input
                                                                    type="number"
                                                                    value={timeBasedTrigger.repeatOn}
                                                                    min={1}
                                                                    max={31}
                                                                    maxLength="2"
                                                                    id="dateOfMonth"
                                                                    name="dateOfMonth"
                                                                    className="form-control w-25"
                                                                    placeholder='Enter date of the month'
                                                                    onChange={(e) => onTimeBasedTriggerChange('repeatOn', e.target.value)}

                                                                />
                                                                {isValid === "RepeatOn" ? <small className='text-danger'> Please provide vailid value.</small> : null}
                                                            </>
                                                        )
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4">
                                            <label className="col-sm-2 col-form-label">Time<sup className="text-danger">*</sup></label>
                                            <div className="col-2">
                                                <input
                                                    type="time"
                                                    value={timeBasedTrigger.time}
                                                    id="triggerTime"
                                                    name="triggerTime"
                                                    className="form-control"
                                                    onChange={(e) => onTimeBasedTriggerChange('time', e.target.value)}
                                                />
                                                {isValid === "Time" ? <small className='text-danger'> Please provide vailid option.</small> : null}
                                            </div>
                                        </div>

                                        {/* <pre>{JSON.stringify(timeBasedTrigger)}</pre> */}
                                    </div>
                                )
                                : triggerType == 'EventBased' ?
                                    (
                                        <div className="form-group row mb-4" >

                                            <label className="col-sm-2 ">Events<sup className="text-danger">*</sup></label>
                                            <div className="col-sm-10">
                                                <div className='row custom-control custom-radio custom-control-inline'>
                                                    <div className='col-3'>
                                                        <input
                                                            // checked={eventTrigger.trigger === "LessonCompleted"}
                                                            type="radio"
                                                            value="NumberOfCompletedLessons"
                                                            id='numberOfCompletedLessons'
                                                            name='triggerValue'
                                                            onChange={(e) => handleEventTrigger('trigger', e.target.value)}
                                                        />
                                                        <label htmlFor="numberOfCompletedLessons" className='col-form-label ms-2'>No. of Completed Lessons</label>
                                                    </div>

                                                    {
                                                        eventTrigger.trigger === 'NumberOfCompletedLessons' ?
                                                            <>
                                                                <div className='col-2 d-flex flex-column align-items-baseline'>
                                                                    <select
                                                                        className='form-select me-2'
                                                                        value={eventTrigger.lessonType}
                                                                        onChange={(e) => handleEventTrigger('lessonType', e.target.value)}
                                                                    >
                                                                        <option value="">Select an option</option>
                                                                        <option value="NonPremium">Non-Premium</option>
                                                                        <option value="Premium">Premium</option>
                                                                        <option value="Premium_NonPremium">Premium & Non-Premium</option>
                                                                    </select>
                                                                    {isValid === "NumberOfCompletedLessonsType" ? <small className='text-danger'> Please provide vailid option.</small> : null}
                                                                </div>
                                                                <div className='col-6 d-flex jutify-content-between flex-wrap align-items-baseline'>
                                                                    <div className='me-2 mb-1'>
                                                                        <select
                                                                            className='form-select me-2'
                                                                            value={eventTrigger.condition}
                                                                            onChange={(e) => handleEventTrigger('condition', e.target.value)}
                                                                        >
                                                                            <option value="">Condition</option>
                                                                            <option value="EqualTo">Equal To</option>
                                                                            {/* <option value="LessThan">Less Than </option>
                                                                        <option value="GreaterThan">Greater Than</option> */}

                                                                        </select>
                                                                        {isValid === "NumberOfCompletedLessonsCondition" ? <small className='text-danger'> Please provide vailid option.</small> : null}
                                                                    </div>
                                                                    <div className='me-2 mb-1 w-25'>
                                                                        <input
                                                                            className='form-control'
                                                                            type='number'
                                                                            value={eventTrigger.value}
                                                                            onChange={(e) => handleEventTrigger('value', e.target.value)}
                                                                        />
                                                                        {isValid === "NumberOfCompletedLessonsValue" ? <small className='text-danger'> Please provide vailid value.</small> : null}
                                                                    </div>
                                                                </div>
                                                            </>

                                                            : ""
                                                    }
                                                </div>
                                                <div className='row custom-control custom-radio custom-control-inline'>
                                                    <div className='col-3'>
                                                        <input
                                                            // checked={eventTrigger.trigger === "LessonCompleted"}
                                                            type="radio"
                                                            value="CompletedLesson"
                                                            id='completedLesson'
                                                            name='triggerValue'
                                                            onChange={(e) => handleEventTrigger('trigger', e.target.value)}
                                                        />
                                                        <label htmlFor="completedLesson" className='col-form-label ms-2'>Completed Lesson</label>
                                                    </div>

                                                    {
                                                        eventTrigger.trigger === 'CompletedLesson' ?
                                                            <>
                                                                <div className='col-2 d-flex flex-column align-items-baseline'>
                                                                    <select
                                                                        className='form-select me-2'
                                                                        value={eventTrigger.completedLessonType}
                                                                        onChange={(e) => handleEventTrigger('completedLessonType', e.target.value)}
                                                                    >
                                                                        <option value="">Select an option</option>
                                                                        <option value="NonPremium">Non-Premium</option>
                                                                        <option value="Premium">Premium</option>
                                                                    </select>
                                                                    {isValid === "CompletedLessonType" ? <small className='text-danger'> Please provide vailid option.</small> : null}
                                                                </div>
                                                                <div className='col-4 d-flex jutify-content-between align-items-baseline'>
                                                                    <div className='me-2'>
                                                                        {
                                                                            eventTrigger.completedLessonType === "NonPremium" ?
                                                                                <div>
                                                                                    <CreatableSelect
                                                                                        isMulti
                                                                                        options={nonPremiumLessons}
                                                                                        value={nonPremiumLessons.value}
                                                                                        className="width-200-px"
                                                                                        onChange={(e) => handleEventTrigger('value', e)}
                                                                                        required
                                                                                    />
                                                                                    {/* <select
                                                                                        className='form-select me-2'
                                                                                        value={eventTrigger.value}
                                                                                        onChange={(e) => handleEventTrigger('value', e.target.value)}
                                                                                    >
                                                                                        <option value="">Select Lesson</option>
                                                                                        {nonPremiumLessons.map(item => (
                                                                                            <option key={item.Id} value={item.Id}>{item.Name}</option>
                                                                                        ))}
                                                                                    </select> */}
                                                                                    {isValid === "CompletedLessonValue" ? <small className='text-danger'> Please provide vailid option.</small> : null}
                                                                                </div>
                                                                                : eventTrigger.completedLessonType === "Premium" ?
                                                                                    <div className='col-12'>
                                                                                        <CreatableSelect
                                                                                            isMulti
                                                                                            options={premiumLessons}
                                                                                            value={premiumLessons.value}
                                                                                            className="width-200-px"
                                                                                            onChange={(e) => handleEventTrigger('value', e)}
                                                                                            required
                                                                                        />
                                                                                        {/* <select
                                                                                            className='form-select me-2'
                                                                                            value={eventTrigger.condition}
                                                                                            onChange={(e) => handleEventTrigger('value', e.target.value)}
                                                                                        >
                                                                                            <option value="">Select Lesson</option>
                                                                                            {premiumLessons.map(item => (
                                                                                                <option key={item.Id} value={item.Id}>{item.Name}</option>
                                                                                            ))}
                                                                                        </select> */}
                                                                                        {isValid === "CompletedLessonValue" ? <small className='text-danger'> Please provide vailid option.</small> : null}
                                                                                    </div>
                                                                                    : ""
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </>

                                                            : ""
                                                    }
                                                </div>
                                                <div className='row custom-control custom-radio custom-control-inline'>
                                                    <div className='col-3'>
                                                        <input
                                                            // checked={eventTrigger.trigger === "NewLesson"}
                                                            type="radio"
                                                            value="NewLesson"
                                                            id='newLesson'
                                                            name='triggerValue'
                                                            onChange={(e) => handleEventTrigger('trigger', e.target.value)}
                                                        />
                                                        <label htmlFor="newLesson" className='col-form-label ms-2'>New Lesson</label>
                                                    </div>
                                                    {
                                                        eventTrigger.trigger === 'NewLesson' ?
                                                            <>
                                                                <div className='col-3 d-flex flex-column align-items-baseline'>
                                                                    <select
                                                                        className='form-select me-2'
                                                                        value={eventTrigger.value}
                                                                        onChange={(e) => handleEventTrigger('value', e.target.value)}
                                                                    >
                                                                        <option value="">Select Options</option>
                                                                        <option value="NonPremium">Non-Premium</option>
                                                                        <option value="Premium">Premium</option>
                                                                    </select>
                                                                    {isValid === "NewLessonValue" ? <small className='text-danger'> Please provide vailid option.</small> : null}
                                                                </div>
                                                            </> : ""
                                                    }
                                                </div>
                                                <div className='row custom-control custom-radio custom-control-inline'>
                                                    <div className='col-3'>
                                                        <input
                                                            // checked={eventTrigger.trigger === "CourseCompleted"}
                                                            type="radio"
                                                            value="CourseCompleted"
                                                            id='courseCompleted'
                                                            name='triggerValue'
                                                            onChange={(e) => handleEventTrigger('trigger', e.target.value)}
                                                        />
                                                        <label htmlFor="courseCompleted" className='col-form-label ms-2'>Course Completed</label>
                                                    </div>

                                                    {
                                                        eventTrigger.trigger === 'CourseCompleted' ?
                                                            <div className='col-4 '>
                                                                <div>
                                                                    <CreatableSelect
                                                                        options={premiumCourses}
                                                                        className=""
                                                                        onChange={(e) => handleEventTrigger('value', e.value)}
                                                                        required
                                                                    />
                                                                    {isValid === "CourseCompletedValue" ? <small className='text-danger'> Please provide vailid option.</small> : null}
                                                                </div>
                                                            </div>
                                                            : ""
                                                    }
                                                </div>
                                                <div className='row custom-control custom-radio custom-control-inline'>
                                                    <div className='col-3'>
                                                        <input
                                                            // checked={eventTrigger.trigger === "NewLesson"}
                                                            type="radio"
                                                            value="UnlockRewards"
                                                            id='rewards'
                                                            name='triggerValue'
                                                            onChange={(e) => handleEventTrigger('trigger', e.target.value)}
                                                        />
                                                        <label htmlFor="rewards" className='col-form-label ms-2'>Unlocked Reward</label>
                                                    </div>
                                                    {
                                                        eventTrigger.trigger === 'UnlockRewards' ?
                                                            <>
                                                                <div className='col-3 d-flex flex-column align-items-baseline'>
                                                                    <select
                                                                        className='form-select me-2'
                                                                        value={eventTrigger.value}
                                                                        onChange={(e) => handleEventTrigger('value', e.target.value)}
                                                                    >
                                                                        <option value="">Select Options</option>
                                                                        <option value="Artifacts">Artifacts</option>
                                                                        <option value="Characters">Characters</option>
                                                                    </select>
                                                                    {isValid === "RewardsValue" ? <small className='text-danger'> Please provide vailid option.</small> : null}
                                                                </div>
                                                            </> : ""
                                                    }
                                                </div>
                                                <div className='row custom-control custom-radio custom-control-inline'>
                                                    <div className='col-3'>
                                                        <input
                                                            // checked={eventTrigger.trigger === "NewLesson"}
                                                            type="radio"
                                                            value="RewardNotUsed"
                                                            id='rewardNotUserd'
                                                            name='triggerValue'
                                                            onChange={(e) => handleEventTrigger('trigger', e.target.value)}
                                                        />
                                                        <label htmlFor="rewardNotUserd" className='col-form-label ms-2'>Reward not used</label>
                                                    </div>
                                                    {
                                                        eventTrigger.trigger === 'RewardNotUsed' ?
                                                            <>
                                                                <div className='col-3 d-flex flex-column align-items-baseline'>
                                                                    <select
                                                                        className='form-select me-2'
                                                                        value={eventTrigger.condition}
                                                                        onChange={(e) => handleEventTrigger('condition', e.target.value)}
                                                                    >
                                                                        <option value="">Condition</option>
                                                                        <option value="UsingTheApp">When Using App</option>
                                                                        <option value="NotUsingTheApp">When Not Using App</option>
                                                                    </select>
                                                                    {isValid === "RewardNotUsedCondition" ? <small className='text-danger'> Please provide vailid option.</small> : null}
                                                                </div>
                                                            </> : ""
                                                    }
                                                    {
                                                        eventTrigger.trigger === 'RewardNotUsed' && eventTrigger.condition === 'UsingTheApp' ?
                                                            <>
                                                                <div className='col-3 d-flex flex-column align-items-baseline'>
                                                                    <div className='d-flex align-items-baseline'>
                                                                        <input
                                                                            type="number"
                                                                            placeholder='Number of days'
                                                                            className='form-select me-2'
                                                                            value={eventTrigger.value}
                                                                            onChange={(e) => handleEventTrigger('value', e.target.value)}
                                                                        />
                                                                        <p className='fw-500'>Days</p>
                                                                    </div>
                                                                    {isValid === "RewardNotUsedValue1" ? <small className='text-danger'> Please provide vailid option.</small> : null}

                                                                </div>
                                                            </> : ""
                                                    }
                                                    {
                                                        eventTrigger.trigger === 'RewardNotUsed' && eventTrigger.condition === 'NotUsingTheApp' ?
                                                            <>
                                                                <div className='col-3 mb-4'>
                                                                    <div className='d-flex align-items-baseline'>
                                                                        <input
                                                                            type='number'
                                                                            value={eventTrigger.value}
                                                                            className='form-control me-2'
                                                                            placeholder='Number of days'
                                                                            onChange={(e) => handleEventTrigger('value', e.target.value)}
                                                                        />
                                                                        <span className='fw-500'>Days</span>
                                                                    </div>
                                                                    {isValid === "RewardNotUsedValue2" ? <small className='text-danger'> Please provide vailid option.</small> : null}
                                                                </div>
                                                                <div className='col-6 offset-3'>
                                                                    <div className='d-flex flex-gap-1 align-items-baseline'>
                                                                        <div className='me-2'>
                                                                            <p className='mb-0'>Start date</p>
                                                                            <input
                                                                                type='date'
                                                                                className='form-control'
                                                                                value={eventTrigger.startDate}
                                                                                onChange={(e) => handleEventTrigger('startDate', e.target.value)}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <p className='mb-0'>End date</p>
                                                                            <input
                                                                                type='date'
                                                                                className='form-control'
                                                                                value={eventTrigger.endDate}
                                                                                onChange={(e) => handleEventTrigger('endDate', e.target.value)}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <p className='mb-0'>Trigger time</p>
                                                                            <input
                                                                                type='time'
                                                                                className='form-control'
                                                                                value={eventTrigger.time}
                                                                                onChange={(e) => handleEventTrigger('time', e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {isValid === "RewardNotUsedTriggerDate" ? <small className='text-danger'> Please provide vailid date and time.</small> : null}
                                                                </div>

                                                            </> : ""
                                                    }
                                                </div>

                                                {isValid === "EventTrigger" ? <small className='text-danger'> Please provide vailid option.</small> : null}
                                                {/* <pre>{JSON.stringify(eventTrigger, null, 2)}</pre> */}

                                            </div>
                                        </div>
                                    )
                                    : ""
                        }
                        <hr></hr>

                        <div className="form-group row mb-4">
                            <label className="col-sm-2 col-form-label">Notification Type<sup className="text-danger">*</sup></label>
                            <div className="col-2">
                                <select
                                    className='form-select me-2'
                                    value={content.type}
                                    onChange={(e) => handleContentChange("type", e.target.value)}
                                >
                                    <option value="">Select type</option>
                                    <option value="promotional">Discount</option>
                                    <option value="progress">Progress</option>
                                    <option value="informative">Informative</option>
                                    <option value="mandatory">Mandatory</option>
                                </select>
                                {isValid === "notificationType" ? <small className='text-danger'>Please provide vailid option.</small> : null}
                            </div>
                        </div>
                        <hr></hr>
                        <h3 className='mb-4'>Filters</h3>
                        <small></small>
                        {formFields.map((field, index) => (
                            <div key={field.id}>
                                <div className='row'>
                                    <div className='col-2 mb-4'>
                                        <select
                                            className='form-select'
                                            value={field.category}
                                            onChange={(e) => handleDropdownChange('category', e.target.value, index)}
                                        >
                                            <option value="">Select Category</option>
                                            <option value="Users">Users</option>
                                            <option value="OS">OS</option>
                                            <option value="Age">Age</option>
                                            <option value="LastLogin">Last Login</option>
                                            {/* <option value="NotLoggedIn">Not Loggedin</option> */}
                                            {/* <option value="Experience">Experience</option> */}
                                            <option value="Interests">Interests</option>
                                            <option value="Countries">Countries</option>
                                            {/* <option value="Subjects">Subjects</option> */}
                                            <option value="AgeOfRegistration">Age of Registration</option>
                                            <option value="DaysToExpire">Days To Expire</option>
                                            <option value="AverageSessionTime">Average Session Time</option>
                                            <option value="AppVersion">App Version</option>
                                        </select>
                                    </div>


                                    {field.category === 'Users' && (
                                        <div className='col-2 '>
                                            <select
                                                className='form-select'
                                                value={field.value}
                                                onChange={(e) => handleDropdownChange('value', e.target.value, index)}
                                            >
                                                <option value="">Select User</option>
                                                <option value="All">All</option>
                                                <option value="Premium">Premium User</option>
                                                <option value="Nonpremium">Non-Premium User</option>
                                            </select>
                                            {isValid === "Users" ? <small className='text-danger'>Please provide vailid user.</small> : null}
                                        </div>
                                    )}
                                    {field.category === 'OS' && (
                                        <div className='col-2'>
                                            <select
                                                className='form-select'
                                                value={field.value}
                                                onChange={(e) => handleDropdownChange('value', e.target.value, index)}
                                            >
                                                <option value="">Select OS</option>
                                                <option value="Android">Android</option>
                                                <option value="IOS">iOS</option>
                                                <option value="Android,IOS">Both</option>
                                            </select>
                                            {isValid === "OS" ? <small className='text-danger'> Please provide vailid OS.</small> : null}

                                        </div>
                                    )}

                                    {field.category === 'Age' && (
                                        <>
                                            <div className='col-3 checkbox-group'>
                                                {/* <input
                                                type="checkbox"
                                                id='allAge'
                                                className='age-checkbox'
                                                checked={field.age.includes("2", "3", "4", "5", "6", "7", "8", "9", "10")}
                                                onChange={() => handleCheckboxChange('all', index)}
                                            />
                                            <label className='' htmlFor='allAge'>All</label> */}

                                                <input
                                                    type="checkbox"
                                                    id='ageTwo'
                                                    className='age-checkbox'
                                                    checked={field?.age?.includes('2')}
                                                    onChange={() => handleCheckboxChange('2', index)}
                                                />
                                                <label className='' htmlFor='ageTwo'>2</label>

                                                <input
                                                    type="checkbox"
                                                    id='ageThree'
                                                    className='age-checkbox'
                                                    checked={field?.age?.includes('3')}
                                                    onChange={() => handleCheckboxChange('3', index)}
                                                />
                                                <label className='' htmlFor='ageThree'>3</label>

                                                <input
                                                    type="checkbox"
                                                    id='ageFour'
                                                    className='age-checkbox'
                                                    checked={field?.age?.includes('4')}
                                                    onChange={() => handleCheckboxChange('4', index)}
                                                />
                                                <label className='' htmlFor='ageFour'>4</label>

                                                <input
                                                    id="ageFive"
                                                    type="checkbox"
                                                    checked={field?.age?.includes('5')}
                                                    onChange={() => handleCheckboxChange('5', index)}
                                                />
                                                <label htmlFor='ageFive'>5</label>

                                                <input
                                                    id="ageSix"
                                                    type="checkbox"
                                                    checked={field?.age?.includes('6')}
                                                    onChange={() => handleCheckboxChange('6', index)}
                                                />
                                                <label htmlFor='ageSix'>6</label>
                                                <input
                                                    id="ageSeven"
                                                    type="checkbox"
                                                    checked={field?.age?.includes('7')}
                                                    onChange={() => handleCheckboxChange('7', index)}
                                                />
                                                <label htmlFor='ageSeven'>7</label>

                                                <input
                                                    id="ageEight"
                                                    type="checkbox"
                                                    checked={field?.age?.includes('8')}
                                                    onChange={() => handleCheckboxChange('8', index)}
                                                />
                                                <label htmlFor='ageEight'>8</label>

                                                <input
                                                    id="ageNine"
                                                    type="checkbox"
                                                    checked={field?.age?.includes('9')}
                                                    onChange={() => handleCheckboxChange('9', index)}
                                                />
                                                <label htmlFor='ageNine'>9</label>

                                                <input
                                                    id="ageTen"
                                                    type="checkbox"
                                                    checked={field?.age?.includes('10')}
                                                    onChange={() => handleCheckboxChange('10', index)}
                                                />
                                                <label htmlFor='ageTen'>10</label>
                                            </div>
                                            {isValid === "Age" ? <small className='text-danger'> Please provide vailid Age.</small> : null}

                                        </>
                                    )}

                                    {field.category === 'LastLogin' && (
                                        <>
                                            <div className='col-2'>
                                                <select
                                                    className='form-select'
                                                    value={field.condition}
                                                    onChange={(e) =>
                                                        handleDropdownChange("condition", e.target.value, index)
                                                    }
                                                >
                                                    <option value="">Condition</option>
                                                    <option value="GreaterThan">Greater Than</option>
                                                    <option value="LessThan">Less Than</option>
                                                    <option value="EqualTo">Equal To</option>
                                                </select>
                                                {isValid === "LastLoginCondition" ? <small className='text-danger'> Please provide vailid condtion.</small> : null}

                                            </div>
                                            <div className='col-2'>
                                                <select
                                                    className='form-select'
                                                    value={field.range}
                                                    onChange={(e) =>
                                                        handleDropdownChange("range", e.target.value, index)
                                                    }
                                                >
                                                    <option value="">Select an option</option>
                                                    <option value="7">1 Week</option>
                                                    <option value="30">1 Month</option>
                                                    <option value="Custom">Custom</option>
                                                </select>
                                                {isValid === "LastLoginRange" ? <small className='text-danger'> Please provide vailid option.</small> : null}
                                            </div>

                                            {field.range === "Custom" && (
                                                <div className='col-2 '>
                                                    <div className='d-flex align-items-baseline'>
                                                        <input
                                                            className='form-control me-1'
                                                            type="number"
                                                            min={0}
                                                            value={field.value}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    "value",
                                                                    e.target.value,
                                                                    index
                                                                )
                                                            }
                                                        />
                                                        <span className='fw-500'>Days</span>
                                                    </div>
                                                    {isValid === "LastLoginValue" ? <small className='text-danger'> Please provide vailid days.</small> : null}
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {/* {field.category === 'NotLoggedIn' && (
                                        <>
                                            <div className='col-2'>

                                                <select
                                                    className='form-select'
                                                    value={field.condition}
                                                    onChange={(e) =>
                                                        handleDropdownChange("condition", e.target.value, index)
                                                    }
                                                >
                                                    <option value="">Condition</option>
                                                    <option value="GreaterThan">Greater Than</option>
                                                    <option value="LessThan">Less Than</option>
                                                </select>
                                                {isValid === "NotLoggedInCondition" ? <small className='text-danger'> Please provide vailid condtion.</small> : null}

                                            </div>
                                            <div className='col-2'>

                                                <select
                                                    className='form-select'
                                                    value={field.range}
                                                    onChange={(e) =>
                                                        handleDropdownChange("range", e.target.value, index)
                                                    }
                                                >
                                                    <option value="">Select an option</option>
                                                    <option value="7">1 Week</option>
                                                    <option value="30">1 Month</option>
                                                    <option value="Custom">Custom</option>
                                                </select>
                                                {isValid === "NotLoggedInRange" ? <small className='text-danger'> Please provide vailid option.</small> : null}
                                            </div>


                                            {field.range === "Custom" && (
                                                <div className='col-2 '>
                                                    <div className='d-flex align-items-baseline'>
                                                        <input
                                                            className='form-control me-1'
                                                            type="number"
                                                            min={0}
                                                            value={field.value}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    "value",
                                                                    e.target.value,
                                                                    index
                                                                )
                                                            }
                                                        />
                                                        <span className='fw-500'>Days</span>
                                                    </div>
                                                    {isValid === "NotLoggedInValue" ? <small className='text-danger'> Please provide vailid days.</small> : null}

                                                </div>

                                            )}

                                        </>
                                    )} */}
                                    {/* {field.category === 'Experience' && (
                                        <>
                                            <div className='col-2'>
                                                <select
                                                    className='form-select'
                                                    value={field.condition}
                                                    onChange={(e) => handleDropdownChange('condition', e.target.value, index)}
                                                >
                                                    <option value="">Condition</option>
                                                    <option value="EqualTo">Equal to</option>
                                                    <option value="LessThan">Less than</option>
                                                    <option value="GreaterThan">Greater than</option>
                                                </select>
                                                {isValid === "ExperienceCondition" ? <small className='text-danger'> Please provide vailid condition.</small> : null}

                                            </div>

                                            {field.condition !== '' && (
                                                <div className='col-2'>
                                                    <div className='d-flex align-items-baseline'>
                                                        <input
                                                            className='form-control me-1'
                                                            type="number"
                                                            min={0}
                                                            value={field.value}
                                                            required
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    "value",
                                                                    e.target.value,
                                                                    index
                                                                )
                                                            }
                                                        />
                                                        <span className='fw-500'>Years</span>
                                                    </div>
                                                    {isValid === "ExperienceValue" ? <small className='text-danger'> Please provide vailid value.</small> : null}
                                                </div>

                                            )}
                                        </>
                                    )} */}
                                    {field.category === 'AgeOfRegistration' && (
                                        <>
                                            <div className='col-2'>
                                                <select
                                                    className='form-select'
                                                    value={field.condition}
                                                    onChange={(e) =>
                                                        handleDropdownChange("condition", e.target.value, index)
                                                    }
                                                >
                                                    <option value="">Condition</option>
                                                    <option value="GreaterThan">Greater Than</option>
                                                    <option value="LessThan">Less Than</option>
                                                    <option value="EqualTo">Equal To</option>
                                                </select>
                                                {isValid === "AgeOfRegistrationCondition" ? <small className='text-danger'> Please provide vailid condition.</small> : null}
                                            </div>

                                            <div className='col-2'>
                                                <select
                                                    className='form-select'
                                                    value={field.range}
                                                    onChange={(e) =>
                                                        handleDropdownChange("range", e.target.value, index)
                                                    }
                                                >
                                                    <option value="">Select an option</option>
                                                    <option value="7">1 Week</option>
                                                    <option value="30">1 Month</option>
                                                    <option value="Custom">Custom</option>
                                                </select>
                                                {isValid === "AgeOfRegistrationRange" ? <small className='text-danger'> Please provide vailid option.</small> : null}

                                            </div>

                                            {field.range === "Custom" && (
                                                <div className='col-2'>
                                                    <div className='d-flex align-items-baseline'>
                                                        <input
                                                            className='form-control me-1'
                                                            type="number"
                                                            min={0}
                                                            value={field.value}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    "value",
                                                                    e.target.value,
                                                                    index
                                                                )
                                                            }
                                                        />
                                                        <span className='fw-500'>Days</span>
                                                    </div>
                                                    {isValid === "AgeOfRegistrationValue" ? <small className='text-danger'> Please provide vailid value.</small> : null}
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {
                                        field.category === 'AverageSessionTime' && (
                                            <>
                                                <div className='col-2'>
                                                    <select
                                                        className='form-select'
                                                        value={field.condition}
                                                        onChange={(e) =>
                                                            handleDropdownChange("condition", e.target.value, index)
                                                        }
                                                    >
                                                        <option value="">Condition</option>
                                                        <option value="EqualTo">Equal To</option>
                                                        <option value="GreaterThan">Greater Than</option>
                                                        <option value="LessThan">Less Than</option>
                                                    </select>
                                                    {isValid === "AverageSessionTimeCondition" ? <small className='text-danger'> Please provide vailid condition.</small> : null}
                                                </div>
                                                <div className='col-1'>
                                                    <input
                                                        className='form-control'
                                                        value={field.value}
                                                        min={0}
                                                        type='number'
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                "value",
                                                                e.target.value,
                                                                index
                                                            )
                                                        }
                                                    />
                                                    {isValid === "AverageSessionTimeValue" ? <small className='text-danger'> Please provide vailid value.</small> : null}
                                                </div>
                                                <div className='col-2'>
                                                    <div className='d-flex align-items-baseline'>
                                                        <select
                                                            className='form-select me-2'
                                                            value={field.duration}
                                                            onChange={(e) =>
                                                                handleDropdownChange("duration", e.target.value, index)
                                                            }
                                                        >
                                                            <option value="">Select Duration</option>
                                                            <option value="minutes">Minutes</option>
                                                            <option value="hours">Hours</option>
                                                        </select>
                                                        <span className='fw-500'>Per</span>
                                                    </div>

                                                    {isValid === "AverageSessionTimeDuration" ? <small className='text-danger'> Please provide vailid duration.</small> : null}

                                                </div>

                                                <div className='col-2'>
                                                    <select
                                                        className='form-select'
                                                        value={field.range}
                                                        onChange={(e) =>
                                                            handleDropdownChange("range", e.target.value, index)
                                                        }
                                                    >
                                                        <option value="">Select Range</option>
                                                        <option value="Day">Day</option>
                                                        <option value="Week">1 Week</option>
                                                        <option value="Month">1 Month</option>
                                                        <option value="Custom">Custom</option>
                                                    </select>
                                                    {isValid === "AverageSessionTimeRange" ? <small className='text-danger'> Please provide vailid option.</small> : null}
                                                </div>
                                                {field.range === "Custom" && (
                                                    <div className='col-2'>
                                                        <div className='d-flex align-items-baseline'>
                                                            <input
                                                                className='form-control me-1'
                                                                type="number"
                                                                min={0}
                                                                value={field.subValue}
                                                                onChange={(e) =>
                                                                    handleInputChange(
                                                                        "subValue",
                                                                        e.target.value,
                                                                        index
                                                                    )
                                                                }
                                                            />
                                                            <span className='fw-500'>Days</span>
                                                        </div>
                                                        {isValid === "AverageSessionTimeSubValue" ? <small className='text-danger'> Please provide vailid days.</small> : null}
                                                    </div>
                                                )}
                                            </>
                                        )
                                    }
                                    {
                                        field.category === 'DaysToExpire' && (
                                            <>
                                                <div className='col-2'>
                                                    <select
                                                        className='form-select'
                                                        value={field.condition}
                                                        onChange={(e) =>
                                                            handleDropdownChange("condition", e.target.value, index)
                                                        }
                                                    >
                                                        <option value="">Condition</option>
                                                        <option value="EqualTo">Equal To</option>
                                                        <option value="GreaterThan">Greater Than</option>
                                                        <option value="LessThan">Less Than</option>
                                                    </select>
                                                    {isValid === "DaysToExpireCondition" ? <small className='text-danger'> Please provide vailid condition.</small> : null}

                                                </div>
                                                <div className='col-2 d-flex align-items-baseline'>
                                                    <div className=' d-flex align-items-baseline'>
                                                        <input
                                                            className='form-control me-1'
                                                            type="number"
                                                            min={0}
                                                            value={field.value}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    "value",
                                                                    e.target.value,
                                                                    index
                                                                )
                                                            }
                                                        />
                                                        <span className='fw-500'>Days</span>
                                                    </div>
                                                    {isValid === "DaysToExpireValue" ? <small className='text-danger'> Please provide vailid days.</small> : null}

                                                </div>
                                            </>
                                        )
                                    }
                                    {field.category === 'Countries' && (
                                        <>
                                            <div className='col-2'>
                                                <select
                                                    className='form-select'
                                                    value={field.condition}
                                                    onChange={(e) =>
                                                        handleDropdownChange("condition", e.target.value, index)
                                                    }
                                                >
                                                    <option value="">Condition</option>
                                                    <option value="EqualTo">Equal To</option>
                                                    <option value="NotEqualTo">Not Equal To</option>
                                                </select>
                                                {isValid === "CountriesCondition" ? <small className='text-danger'> Please provide vailid condition.</small> : null}
                                            </div>
                                            <div className='col-2'>
                                                <CreatableSelect
                                                    isMulti
                                                    options={countries}
                                                    className=""
                                                    onChange={(e) => handleInterestChange('countries', e, index)}
                                                    required
                                                />
                                                {isValid === "CountriesOption" ? <small className='text-danger'> Please provide vailid option.</small> : null}
                                            </div>

                                        </>
                                    )}
                                    {field.category === 'AppVersion' && (
                                        <>
                                            <div className='col-2'>

                                                <select
                                                    className='form-select'
                                                    value={field.condition}
                                                    onChange={(e) =>
                                                        handleDropdownChange("condition", e.target.value, index)
                                                    }
                                                >
                                                    <option value="">Condition</option>
                                                    <option value="EqualTo">Equal To</option>
                                                    <option value="NotEqualTo">Not Equal To</option>
                                                </select>
                                                {isValid === "AppVersionCondition" ? <small className='text-danger'> Please provide vailid condition.</small> : null}
                                            </div>
                                            <div className='col-2'>
                                                <CreatableSelect
                                                    isMulti
                                                    options={appVersions}
                                                    className=""
                                                    onChange={(e) => handleInterestChange('appVersion', e, index)}
                                                    required
                                                />
                                                {isValid === "AppVersionValue" ? <small className='text-danger'>Invalid data.</small> : null}
                                            </div>
                                        </>
                                    )}

                                    {field.category === 'Interests' && (
                                        <div className='col-2'>
                                            <CreatableSelect
                                                isMulti
                                                options={subjects}
                                                className=""
                                                onChange={(e) => handleInterestChange('interests', e, index)}
                                                required
                                            />
                                            {isValid === "Interests" ? <small className='text-danger'> Please provide vailid options.</small> : null}
                                        </div>
                                    )}
                                    {/* {field.category === 'Subjects' && (
                                        <div className='col-2'>
                                            <CreatableSelect
                                                isMulti
                                                options={subjects}
                                                className=""
                                                onChange={(e) => handleInterestChange('subjects', e, index)}
                                                required
                                            />
                                            {isValid === "Subjects" ? <small className='text-danger'> Please provide vailid options.</small> : null}
                                        </div>
                                    )} */}
                                    <div className='col-2'>
                                        <button className='btn btn-sm btn-danger' onClick={() => removeFormField(index)}>- Remove</button>
                                    </div>
                                </div>
                            </div>

                        ))}
                        <div className='col-3 mb-5'>
                            <button className='w-50 btn btn-sm btn-success' onClick={addFormField}>+ Add</button>
                        </div>

                        {/* <pre>{JSON.stringify(formFields, null, 2)}</pre> */}
                        <hr></hr>
                        <h3 className='mb-4'>Content</h3>
                        {
                            channel === 'App' ? (
                                <div className='card mb-5 p-4 '>
                                    <h5 className='mb-4'>App notification content</h5>
                                    <div className='row mb-4'>
                                        <div className='col-2'>
                                            <label htmlFor='notificationTitle'>Title<sup className="text-danger">*</sup></label>
                                        </div>
                                        <div className='col-6'>
                                            <input
                                                type='text'
                                                id='notificationTitle'
                                                className='form-control'
                                                value={content.title}
                                                placeholder='Notification title'
                                                onChange={(e) => handleContentChange('title', e.target.value)}
                                            />
                                            <small>Recommended length 50-70 characters.</small>
                                            {isValid === "ContentTitle" ? <small className='text-danger'> Please provide title.</small> : null}
                                        </div>
                                        <div className='col-2'>

                                            <div className="dropdown">
                                                <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Select Variable
                                                </button>
                                                <div className="dropdown-menu box-shadows" aria-labelledby="dropdownMenuButton">
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={(e) => addContentVarable('title', '${UserName}')}>
                                                        Parent Name
                                                    </button>
                                                </div>
                                            </div>


                                            {/* <select
                                                className='form-select'
                                                onChange={(e) => addContentVarable('title', e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                <option value="${UserName}">UserName</option>
                                            </select> */}
                                        </div>

                                    </div>
                                    <div className='row mb-4'>
                                        <div className='col-2'>
                                            <label htmlFor='notificationMessage'>Message<sup className="text-danger">*</sup></label>
                                        </div>
                                        <div className='col-6'>
                                            <textarea
                                                type='text'
                                                id='notificationMessage'
                                                className='form-control'
                                                value={content.message}
                                                placeholder='Notification message'
                                                rows={4}
                                                onChange={(e) => handleContentChange('message', e.target.value)}
                                            />
                                            <small>Recommended length 100-120 characters.</small>
                                            {isValid === "ContentMessage" ? <small className='text-danger'> Please provide message.</small> : null}
                                        </div>
                                        <div className='col-2'>
                                            <div className="dropdown">
                                                <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Select Variable
                                                </button>
                                                <div className="dropdown-menu box-shadows" aria-labelledby="dropdownMenuButton">
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={(e) => addContentVarable('message', '${UserName}')}>
                                                        Parent Name
                                                    </button>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={(e) => addContentVarable('message', "${KidName}")}>
                                                        Kid Name
                                                    </button>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={(e) => addContentVarable('message', "${LessonName}")}>
                                                        Lesson Name
                                                    </button>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={(e) => addContentVarable('message', "${CourseName}")}>
                                                        Course Name
                                                    </button>
                                                </div>
                                            </div>
                                            {/* <select
                                                className='form-select'
                                                onChange={(e) => addContentVarable('message', e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                <option value="${UserName}">UserName</option>
                                                <option value="${UserName}">LessonName</option>
                                            </select> */}
                                        </div>
                                    </div>
                                    <div className='row mb-4'>
                                        <div className='col-2'>
                                            <label htmlFor=''>Expires At<sup className="text-danger">*</sup></label>
                                        </div>
                                        <div className='col-3'>
                                            <input type='datetime-local' value={content.expiresAt} className='form-control' onChange={(e) => handleContentChange('expiresAt', e.target.value)} />
                                            {isValid === 'ExpiresAt'
                                                ? <small className='text-danger'>Please provide expiration date.<br></br></small>
                                                : ""}
                                        </div>
                                        <div className='col-3'>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input
                                                    type="radio"
                                                    checked={content.expiresAt === "Indefinite"}
                                                    value=""
                                                    id="indefiniteExpiry"
                                                    name="indefiniteExpiry"
                                                    className="custom-control-input me-2"
                                                    onChange={(e) => handleContentChange('expiresAt', 'Indefinite')}
                                                />
                                                <label className="custom-control-label" htmlFor="indefiniteExpiry">Indefinite</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-4'>
                                        <div className='col-2'>
                                            <label htmlFor='actionLink'>Action Link<span><small>(optional)</small></span></label>
                                        </div>
                                        <div className='col-3'>
                                            <select
                                                id='actionLink'
                                                className='form-select'
                                                onChange={(e) => handleMainActionLinkDropdown(e.target.value)}
                                            >
                                                <option value="">Select an option</option>
                                                <option value="lessonPage">Lesson</option>
                                                <option value="coursePage">Course</option>
                                                <option value="comparisonPage">Comparison Page</option>
                                                <option value="paymentPage">Payment Page</option>
                                                <option value="packagesPage">Packages Page</option>
                                                <option value="coupon">Coupon</option>
                                                <option value="url">URL</option>
                                                <option value="feedback">Feedback</option>
                                            </select>
                                        </div>
                                        {
                                            actionLinkFields.page === 'coursePage' && (
                                                <div className='col-3'>
                                                    <select
                                                        id='actionLink'
                                                        className='form-select'
                                                        onChange={(e) => handleSubActionLinkDropdown('courseId', e.target.value)}
                                                    >
                                                        <option value="">Select a course</option>
                                                        {courses.map((course) => (
                                                            <option value={course.Id} key={course.Id}>{course.CourseName}</option>
                                                        ))}
                                                    </select>
                                                    {isValid === 'InvalidCourseName' ? <small className='text-danger'>Please provide valid course</small> : null}
                                                </div>
                                            )
                                        }
                                        {
                                            actionLinkFields.page === 'lessonPage' && (
                                                <div className='col-3'>
                                                    <select
                                                        id='lessonActionLink'
                                                        className='form-select'
                                                        onChange={(e) => handleSubActionLinkDropdown('lessonId', e.target.value)}
                                                    >
                                                        <option value="">Select a lesson</option>
                                                        {lessons.map((lesson) => (
                                                            <option value={lesson.Id} key={lesson.Id}>{lesson.Name}</option>
                                                        ))}
                                                    </select>
                                                    {isValid === 'InvalidLesson' ? <small className='text-danger'>Please provide valid lesson</small> : null}
                                                </div>
                                            )
                                        }
                                        {
                                            actionLinkFields.page === 'coupon' && (
                                                <div className='col-3'>
                                                    <input
                                                        id='couponActionLink'
                                                        className='form-control'
                                                        onChange={(e) => handleSubActionLinkDropdown('couponCode', e.target.value)}
                                                        placeholder='Enter coupon code'
                                                    />
                                                    {isValid === 'CouponCode' ? <small className='text-danger'>Please provide Coupon Code</small> : null}
                                                </div>
                                            )
                                        }
                                        {
                                            actionLinkFields.page === 'url' && (
                                                <div className='col-3'>
                                                    <input
                                                        id='urlActionLink'
                                                        className='form-control'
                                                        onChange={(e) => handleSubActionLinkDropdown('url', e.target.value)}
                                                        placeholder='Enter URL'
                                                    />
                                                    {isValid === 'URL' ? <small className='text-danger'>Please provide URL</small> : null}
                                                </div>
                                            )
                                        }
                                        {
                                            actionLinkFields.page === 'feedback' && (
                                                <>
                                                    <div className='col-6'>
                                                        <div className='mb-4'>
                                                            <input
                                                                id='feedBackTitle'
                                                                className='form-control'
                                                                onChange={(e) => handleSubActionLinkDropdown('feedBackTitle', e.target.value)}
                                                                placeholder='Feedback title'
                                                            />
                                                            {isValid === 'FeedBackTitle' ? <small className='text-danger'>Invalid input</small> : null}
                                                        </div>

                                                        <div className='row'>

                                                            {
                                                                feedBackFields.map((field, index) => (
                                                                    <div className='row mb-2' key={index}>
                                                                        <div className='col-3'>
                                                                            <select
                                                                                id='feedbackType'
                                                                                value={field.inputType}
                                                                                className='form-select'
                                                                                onChange={(e) => handleFeedbackFormChange('inputType', e.target.value, index)}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                <option value="option">Option</option>
                                                                                <option value="input">Input</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className='col-7'>
                                                                            <input
                                                                                value={field.value}
                                                                                className='form-control'
                                                                                onChange={(e) => handleFeedbackFormChange('value', e.target.value, index)}
                                                                                placeholder=''
                                                                            />
                                                                        </div>
                                                                        <div className='col-2'>
                                                                            <button className='bg-danger btn-sm btn text-light' onClick={(e) => removeFeedBackForm(index)}>Remove</button>
                                                                        </div>
                                                                    </div>

                                                                ))
                                                            }
                                                            {isValid === 'FeedBackValue' ? <small className='text-danger'>Invalid input</small> : null}
                                                            <div className=''>
                                                                <button className='bg-blue btn-sm btn text-light' onClick={addFeedBackForm}>Add</button>
                                                            </div>
                                                        </div>

                                                        {/* <pre>{JSON.stringify(feedBackFields, null, 2)}</pre> */}
                                                    </div>
                                                </>
                                            )
                                        }

                                        {/* <pre>{JSON.stringify(actionLinkFields)}</pre> */}
                                    </div>

                                    <div className='row mb-4'>
                                        <div className='col-2'>
                                            <label htmlFor=''>Image<span><small>(optional)</small></span></label>
                                        </div>
                                        <div className='col-3'>
                                            <input id='' type='file' className='form-control' onChange={(e) => setImage(e.target.files[0])} />
                                            {isValid === 'InvalidImageSize'
                                                ? <small className='text-danger'>Image size should not exceed 1MB<br></br></small>
                                                : isValid === 'InvalidImageFormat' ? <small className='text-danger'>Invalid image format.<br></br></small> : null}
                                            <small>*Image size should not exceed 1MB. <br></br></small>
                                            <small>*Upload only .JPG, .JPEG, .PNG images.</small>
                                        </div>
                                    </div>
                                    <div className='row mb-4'>
                                        <div className='col-2'>
                                            <label htmlFor=''>Video<span><small>(optional)</small></span></label>
                                        </div>
                                        <div className='col-3'>
                                            <input id='' type='file' className='form-control' onChange={(e) => setVideo(e.target.files[0])} />
                                            {isValid === 'InvalidVideoFormat' ? <small className='text-danger'>Invalid video format.<br></br></small>
                                                : isValid === 'InvalidVideoSize' ? <small className='text-danger'>Invalid video size.<br></br></small> : null}
                                            <small>*Video size should not exceed 10MB. <br></br></small>
                                            <small>*Upload only .MP4 video format.</small>
                                        </div>
                                    </div>
                                </div>

                            )
                                : channel === 'Email' ? (
                                    <div className='card mb-5 p-4 '>
                                        <h5 className='mb-4'>Email notification content</h5>

                                        <div className='row mb-4'>
                                            <div className='col-2'>
                                                <label htmlFor='emailSubject'>Subject<sup className="text-danger">*</sup></label>
                                            </div>
                                            <div className='col-6'>
                                                <input
                                                    type='text'
                                                    id='emailSubject'
                                                    className='form-control'
                                                    value={content.title}
                                                    placeholder='Email subject'
                                                    onChange={(e) => handleContentChange('title', e.target.value)}
                                                />
                                                {isValid === "ContentMessage" ? <small className='text-danger'> Please provide Subject.</small> : null}
                                            </div>
                                            <div className='col-2'>

                                                <div className="dropdown">
                                                    <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        Select Variable
                                                    </button>
                                                    <div className="dropdown-menu box-shadows" aria-labelledby="dropdownMenuButton">
                                                        <button
                                                            className="dropdown-item"
                                                            type="button"
                                                            onClick={(e) => addContentVarable('title', '${UserName}')}>
                                                            Parent Name
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-2'>
                                                <label htmlFor='emailBody'>Body<sup className="text-danger">*</sup></label>
                                            </div>
                                            <div className='col-6'>
                                                <textarea
                                                    type='text'
                                                    id='emailBody'
                                                    className='form-control'
                                                    value={content.message}
                                                    placeholder='Email body'
                                                    rows={4}
                                                    onChange={(e) => handleContentChange('message', e.target.value)}
                                                />
                                                {/* <JoditEditor
                                                    ref={editor}
                                                    value={emailContent}
                                                    // config={config}
                                                    // tabIndex={1} // tabIndex of textarea
                                                    // onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                                    onChange={newContent => { handleContentChange('message', newContent) }}
                                                /> */}
                                                {isValid === "ContentMessage" ? <small className='text-danger'> Please provide message.</small> : null}

                                            </div>
                                            <div className='col-2'>
                                                <div className="dropdown">
                                                    <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        Select Variable
                                                    </button>
                                                    <div className="dropdown-menu box-shadows" aria-labelledby="dropdownMenuButton">
                                                        <button
                                                            className="dropdown-item"
                                                            type="button"
                                                            onClick={(e) => addContentVarable('message', '${UserName}')}>
                                                            Parent Name
                                                        </button>
                                                        <button
                                                            className="dropdown-item"
                                                            type="button"
                                                            onClick={(e) => addContentVarable('message', '${KidName}')}>
                                                            Kid Name
                                                        </button>
                                                        <button
                                                            className="dropdown-item"
                                                            type="button"
                                                            onClick={(e) => addContentVarable('message', '${LessonName}')}>
                                                            Lesson Name
                                                        </button>
                                                        <button
                                                            className="dropdown-item"
                                                            type="button"
                                                            onClick={(e) => addContentVarable('message', '${CourseName}')}>
                                                            Course Name
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    < div className='card mb-5 p-4 '>
                                        <h5 className='mb-4'>WhatsApp Content</h5>
                                        <div className='row mb-4'>
                                            <div className='col-2'>
                                                <label htmlFor='emailSubject'>Campaign Name<sup className="text-danger">*</sup></label>
                                            </div>
                                            <div className='col-6'>
                                                <input
                                                    type='text'
                                                    id='whatsappTitle'
                                                    className='form-control'
                                                    value={content.title}
                                                    placeholder='Whatsapp Campaign Name'
                                                    onChange={(e) => handleContentChange('title', e.target.value)}
                                                />
                                                {isValid === "WhatsappCampaignName" ? <small className='text-danger'> Please provide Campaign name.</small> : null}
                                            </div>
                                            {/* <div className='col-2'>

                                        <div className="dropdown">
                                            <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Select Variable
                                            </button>
                                            <div className="dropdown-menu box-shadows" aria-labelledby="dropdownMenuButton">
                                                <button
                                                    className="dropdown-item"
                                                    type="button"
                                                    onClick={(e) => addContentVarable('title', '${UserName}')}>
                                                    User Name
                                                </button>
                                            </div>
                                        </div>
                                    </div> */}
                                        </div>
                                        {/* <div className='row mb-4'>
                                            <div className='col-2'>
                                                <label htmlFor='whatsappTag'>Tags(optional)</label>
                                            </div>
                                            <div className='col-6'>
                                                <input
                                                    type='text'
                                                    id='whatsappTag'
                                                    className='form-control'
                                                    // value={content.tags}
                                                    placeholder='Tags'
                                                    onChange={(e) => handleContentChange('tags', e.target.value)}
                                                />
                                                <small>Multiple tags must be separated by comma.</small>
                                                {isValid === "WhatsappTemplateLanguage" ? <small className='text-danger'> Please provide vailid option.</small> : null}
                                            </div>
                                        </div> */}
                                        <div className='row mb-4'>
                                            <div className='col-2'>
                                                <label htmlFor='whatsappTag'>Variables(optional)</label>
                                            </div>
                                            <div className='col-6'>
                                                <CreatableSelect
                                                    isMulti
                                                    options={whatsappVariables}
                                                    className=""
                                                    placeholder='Variables'
                                                    onChange={(e) => handleContentChange('variables', e)}
                                                />
                                                {isValid === "WhatsappVariables" ? <small className='text-danger'> Please provide vailid option.</small> : null}
                                            </div>
                                        </div>
                                        <div className='row mb-4'>
                                            <div className='col-2'>
                                                <label htmlFor=''>Media<span><small>(optional)</small></span></label>
                                            </div>
                                            <div className='col-3'>
                                                <select
                                                    className='form-select me-2'
                                                    value={mediaType}
                                                    onChange={(e) => setMediaType(e.target.value)}
                                                >
                                                    <option value="">Select media</option>
                                                    <option value="Image">Image</option>
                                                    <option value="Video">Video</option>
                                                </select>
                                            </div>
                                            {
                                                mediaType == "Image" ?
                                                    (
                                                        <div className='col-3'>
                                                            <input id='whatsappImage' type='file' className='form-control' onChange={(e) => setWhatsappMedia("image", e.target.files[0])} />
                                                            {isValid === 'InvalidWhatsAppImageSize'
                                                                ? <small className='text-danger'>Image size should not exceed 5MB<br></br></small>
                                                                : isValid === 'InvalidWhatsAppImageFormat' ? <small className='text-danger'>Invalid image format.<br></br></small> : null}
                                                            <small>*Image size should not exceed 5MB. <br></br></small>
                                                            <small>*Upload only .JPG, .JPEG, .PNG images.</small>
                                                        </div>
                                                    )
                                                    : mediaType == "Video" ?
                                                        (
                                                            <div className='col-3'>
                                                                <input id='whatsappVideo' type='file' className='form-control' onChange={(e) => setWhatsappMedia("video", e.target.files[0])} />
                                                                {isValid === 'InvalidWhatsAppVideoFormat' ? <small className='text-danger'>Invalid video format.<br></br></small>
                                                                    : isValid === 'InvalidWhatsAppVideoSize' ? <small className='text-danger'>Invalid video size.<br></br></small> : null}
                                                                <small>*Video size should not exceed 16MB. <br></br></small>
                                                                <small>*Upload only .MP4 video format.</small>
                                                            </div>
                                                        )
                                                        : ""
                                            }

                                        </div>
                                    </div>
                        }
                        <div className='row mb-5'>
                            <div className='col-2'>
                                <label htmlFor=''>Send test notification</label>
                            </div>
                            <div className='col-3'>
                                <CreatableSelect
                                    isMulti
                                    options={allTestUsers}
                                    className=""
                                    onChange={(e) => handleTestUsersChange('testUsers', e)}
                                    required
                                />
                                {isValid === 'TestUsers'
                                    ? <small className='text-danger'>Please select users.<br></br></small>
                                    : isValid === 'InvalidTestUsers' ? <small className='text-danger'>Please clear test users.<br></br></small>
                                        : null}

                            </div>
                            <div className='col-6'>
                                <button className='btn btn-blue text-light w-25' onClick={(e) => createNotification('TEST')}>Send Test</button>
                            </div>
                        </div>
                        <div className='col-12 text-center'>
                            <button className='btn btn-blue text-light w-25' onClick={(e) => createNotification('PRODUCTION')}>Submit</button>
                        </div>
                        {/* <pre>{JSON.stringify(content, null, 2)}</pre> */}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default CreateNotification;