import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import * as Constants from '../../../../assets/helper/constants'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import Swal from "sweetalert2"
import Axios from 'axios';
import LoaderComp from "../../../loader";
import ThumbnailPopup from "./thumbnailPopup"
import RejectUpdatePopup from "./rejectUpdatePopup"

function AdminLessonUpdates(props) {

    const [updatedDetails, setUpdatedDetails] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [showThumbnailPopup, setShowThumbnailPopup] = useState(false)
    const [thumbImage, setThumbImage] = useState('')
    const [showRejectUpdatePopup, setShowRejectUpdatePopup] = useState('')
    const [rejected, setRejected] = useState('')
    const [value, setValue] = useState('')
    const history = useHistory()
    const params = useParams()

    const goBack = (e) => {
        history.goBack()
    }

    useEffect(() => {
        getUpdatedLessonDetails()
    }, [])

    const getUpdatedLessonDetails = () => {
        let projectId = params.projectId

        setIsLoading(true)
        Axios.get(Constants.BASE_URL + `api/AggregatorLessons/getUpdatedLessonDetails?id=${projectId}`).then((result) => {
            setIsLoading(false)
            if (result.data.status) {
                setUpdatedDetails(result.data.result[0])
            } else {
                setUpdatedDetails('')
            }

        })

    }

    const toggleThumbnailPopup = (imgeLink) => {

        setShowThumbnailPopup(!showThumbnailPopup)
        setThumbImage(imgeLink)
    }

    const approveUpdates = (approved, value) => {

        let endpoint = ''

        switch (value) {
            case 'thumb1':
                endpoint = "api/AggregatorLessons/approveThumb1";
                break;
            case 'thumb2':
                endpoint = "api/AggregatorLessons/approveThumb2";
                break;
            case 'thumb3':
                endpoint = "api/AggregatorLessons/approveThumb3";
                break;
            case 'subject':
                endpoint = "api/AggregatorLessons/approveSubject";
                break;
            case 'lessonName':
                endpoint = "api/AggregatorLessons/approveName";
                break;
            case 'tags':
                endpoint = "api/AggregatorLessons/approveTags";
                break;
            case 'age':
                endpoint = "api/AggregatorLessons/approveAge";
                break;
            case 'region':
                endpoint = "api/AggregatorLessons/approveRegion";
                break;
            case 'description':
                endpoint = "api/AggregatorLessons/approveDescription";
                break;
        }

        console.log('valu', endpoint)
        let data = {
            lessonID: updatedDetails.Id,
            approve: approved
        }

        Swal.fire({
            title: "Approve Lesson?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(true)
                Axios.post(Constants.BASE_URL + endpoint, data, Constants.header).then((result) => {
                    setIsLoading(false)
                    if (result.data.status) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: "Approved successfully.",
                            showConfirmButton: false,
                            timer: 2000
                        })
                        getUpdatedLessonDetails()
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: "Unable to approve.",
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }
                })

            }
        })

    }

    const rejectUpdates = (inputList) => {
        let projectId = params.projectId

        let endpoint = ''
        console.log('age', value)
        switch (value) {
            case 'thumb1':
                endpoint = "api/AggregatorLessons/approveThumb1";
                break;
            case 'thumb2':
                endpoint = "api/AggregatorLessons/approveThumb2";
                break;
            case 'thumb3':
                endpoint = "api/AggregatorLessons/approveThumb3";
                break;
            case 'subject':
                endpoint = "api/AggregatorLessons/approveSubject";
                break;
            case 'lessonName':
                endpoint = "api/AggregatorLessons/approveName";
                break;
            case 'tags':
                endpoint = "api/AggregatorLessons/approveTags";
                break;
            case 'age':
                endpoint = "api/AggregatorLessons/approveAge";
                break;
            case 'region':
                endpoint = "api/AggregatorLessons/approveRegion";
                break;
            case 'description':
                endpoint = "api/AggregatorLessons/approveDescription";
                break;
        }

        let data = {
            lessonID: updatedDetails.Id,
            approve: rejected,
            message: inputList,
            projectId: projectId,
            senderId: props.userInfo.Id,
            from: props.userInfo.FirstName + ' ' + props.userInfo.LastName,
        }

        setIsLoading(true)
        Axios.post(Constants.BASE_URL + endpoint, data, Constants.header).then((result) => {
            setIsLoading(false)
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "Updated successfully.",
                    showConfirmButton: false,
                    timer: 2000
                })
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to reject.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })

    }
    const toggleRejectUpdatePopup = (rejected, value) => {
        setShowRejectUpdatePopup(!showRejectUpdatePopup)
        setRejected(rejected)
        setValue(value)
    }

    return (
        <div className="right-panel-container">
            {isLoading ? <LoaderComp isLoading={isLoading} /> : null}
            <ThumbnailPopup thumbnail={thumbImage} show={showThumbnailPopup} onHide={toggleThumbnailPopup} />
            <RejectUpdatePopup
                show={showRejectUpdatePopup}
                onHide={toggleRejectUpdatePopup}
                rejectLessonFunc={rejectUpdates}
            />
            <div className="container-fluid">

                <div className="row mb-4">
                    <div className="col-12">
                        <h2 className="section-name">Lesson Updates</h2>
                    </div>
                </div>

                <div className='row mb-4'>
                    <div className='col-12 text-left'>
                        <button onClick={goBack} className="btn me-auto "><i className="fa fa-chevron-left me-2"></i>Back</button>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-lg-6 col-md-6">
                        <div className="card">
                            <h5 className="card-header">Validate Lesson Updates</h5>
                            <div className="card-body">
                                {/* <div className="row ">
                                        <div className="col-3">
                                            <p className="fw-500">Lesson</p>
                                        </div>
                                        <div className="col-6">
                                            <button className="btn btn-blue text-light">Preview Lesson</button>
                                        </div>
                                        <div className="col-3">
                                            <button className="btn btn-sm btn-danger text-light me-3">Reject</button>
                                            <button className="btn btn-sm btn-success text-light">Approve</button>
                                        </div>
                                    </div> */}
                                <hr></hr>
                                {updatedDetails.Thumb1Approval == 1 ? <div className="row border-bottom py-1 my-2">
                                    <div className="col-3">
                                        <p className="fw-500">Thumbnail 1</p>
                                    </div>
                                    <div className="col-6 thumbnails">
                                        <img
                                            className=" img"
                                            src={Constants.BASE_URL + updatedDetails.TempThumb1}
                                            alt="Thumbnail 1"
                                            onClick={() => toggleThumbnailPopup(Constants.BASE_URL + updatedDetails.TempThumb1)}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <button className="btn btn-sm btn-danger text-light me-3 " onClick={(e) => toggleRejectUpdatePopup(2, 'thumb1')}>Reject</button>
                                        <button
                                            className="btn btn-sm btn-success text-light"
                                            onClick={(e) => approveUpdates(1, 'thumb1')}
                                        >
                                            Approve
                                        </button>
                                    </div>
                                </div> : ""}

                                {updatedDetails.Thumb2Approval == 1 ? <div className="row border-bottom py-1 my-2">
                                    <div className="col-3">
                                        <p className="fw-500">Thumbnail 2</p>
                                    </div>
                                    <div className="col-6 thumbnails">
                                        <img
                                            src={Constants.BASE_URL + updatedDetails.TempThumb2}
                                            alt="Thumbnail 2"
                                            onClick={() => toggleThumbnailPopup(Constants.BASE_URL + updatedDetails.TempThumb2)}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <button className="btn btn-sm btn-danger text-light me-3" onClick={(e) => toggleRejectUpdatePopup(2, 'thumb2')}>Reject</button>
                                        <button className="btn btn-sm btn-success text-light" onClick={(e) => approveUpdates(1, 'thumb2')}>Approve</button>
                                    </div>
                                </div> : ""}

                                {updatedDetails.Thumb3Approval == 1 ? <div className="row border-bottom py-1 my-2">
                                    <div className="col-3">
                                        <p className="fw-500">Thumbnail 3</p>
                                    </div>
                                    <div className="col-6 thumbnails">
                                        <img
                                            src={Constants.BASE_URL + updatedDetails.TempThumb3}
                                            alt="Thumbnail 3"
                                            onClick={() => toggleThumbnailPopup(Constants.BASE_URL + updatedDetails.TempThumb3)}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <button className="btn btn-sm btn-danger text-light me-3" onClick={(e) => toggleRejectUpdatePopup(2, 'thumb3')}>Reject</button>
                                        <button className="btn btn-sm btn-success text-light" onClick={(e) => approveUpdates(1, 'thumb3')}>Approve</button>
                                    </div>
                                </div> : ""}

                                {updatedDetails.SubjectApproval == 1 ? <div className="row border-bottom py-1 my-2">
                                    <div className="col-3">
                                        <p className="fw-500">Subject</p>
                                    </div>
                                    <div className="col-6">
                                        <p className="">{updatedDetails.TempSubject}</p>
                                    </div>
                                    <div className="col-3">
                                        <button className="btn btn-sm btn-danger text-light me-3" onClick={(e) => toggleRejectUpdatePopup(2, 'subject')}>Reject</button>
                                        <button className="btn btn-sm btn-success text-light" onClick={(e) => approveUpdates(1, 'subject')}>Approve</button>
                                    </div>
                                </div> : null}

                                {updatedDetails.NameApproval == 1 ? <div className="row border-bottom py-1 my-2">
                                    <div className="col-3">
                                        <p className="fw-500">Lesson Name</p>
                                    </div>
                                    <div className="col-6">
                                        <p className="">{updatedDetails.TempName}</p>
                                    </div>
                                    <div className="col-3">
                                        <button className="btn btn-sm btn-danger text-light me-3" onClick={(e) => toggleRejectUpdatePopup(2, 'lessonName')}>Reject</button>
                                        <button className="btn btn-sm btn-success text-light" onClick={(e) => approveUpdates(1, 'lessonName')}>Approve</button>
                                    </div>
                                </div> : null}

                                {updatedDetails.TagsApproval == 1 ? <div className="row border-bottom py-1 my-2">
                                    <div className="col-3">
                                        <p className="fw-500">Tag</p>
                                    </div>
                                    <div className="col-6">
                                        <p className="">{updatedDetails.TempTags}</p>
                                    </div>
                                    <div className="col-3">
                                        <button className="btn btn-sm btn-danger text-light me-3" onClick={(e) => toggleRejectUpdatePopup(2, 'tags')}>Reject</button>
                                        <button className="btn btn-sm btn-success text-light" onClick={(e) => approveUpdates(1, 'tags')}>Approve</button>
                                    </div>
                                </div> : null}

                                {updatedDetails.AgeApproval == 1 ? <div className="row border-bottom py-1 my-2">
                                    <div className="col-3">
                                        <p className="fw-500">Age</p>
                                    </div>
                                    <div className="col-6">
                                        <p className="">{updatedDetails.TempAge}</p>
                                    </div>
                                    <div className="col-3">
                                        <button className="btn btn-sm btn-danger text-light me-3" onClick={(e) => toggleRejectUpdatePopup(2, 'age')}>Reject</button>
                                        <button className="btn btn-sm btn-success text-light" onClick={(e) => approveUpdates(1, 'age')}>Approve</button>
                                    </div>
                                </div> : null}

                                {updatedDetails.RegionApproval == 1 ? <div className="row border-bottom py-1 my-2">
                                    <div className="col-3">
                                        <p className="fw-500">Region</p>
                                    </div>
                                    <div className="col-6">
                                        <p className="">{updatedDetails.TempRegion}</p>
                                    </div>
                                    <div className="col-3">
                                        <button className="btn btn-sm btn-danger text-light me-3" onClick={(e) => toggleRejectUpdatePopup(2, 'region')}>Reject</button>
                                        <button className="btn btn-sm btn-success text-light" onClick={(e) => approveUpdates(1, 'region')}>Approve</button>
                                    </div>
                                </div> : null}

                                {updatedDetails.DescriptionApproval == 1 ? <div className="row border-bottom py-1 my-2">
                                    <div className="col-3">
                                        <p className="fw-500">Description</p>
                                    </div>
                                    <div className="col-6">
                                        <p className="">{updatedDetails.TempDescription}</p>
                                    </div>
                                    <div className="col-3">
                                        <button className="btn btn-sm btn-danger text-light me-3" onClick={(e) => toggleRejectUpdatePopup(2, 'description')}>Reject</button>
                                        <button className="btn btn-sm btn-success text-light" onClick={(e) => approveUpdates(1, 'description')}>Approve</button>
                                    </div>
                                </div> : null}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminLessonUpdates