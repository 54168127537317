function Triggers() {


    let triggers = [
        {
            event: "Touch",
            triggers: [
                {
                    name: "Tap",
                    desc: "This trigger executes its actions when the user taps on this feature."
                },
                {
                    name: "Double Tap",
                    desc: "This trigger executes its actions when the user double taps on this feature."
                },
                {
                    name: "Long Press",
                    desc: "This trigger executes its actions when the user taps and holds the feature for sometime."
                },
                // {
                //   name: "Pressed",
                //   desc: "This trigger executes its actions when the user has pressed down on this feature."
                // },
                // {
                //   name: "Released",
                //   desc: " This trigger executes its actions when the user has pressed down and released this feature."
                // },
            ]
        },
        {
            event: "Swipe",
            triggers: [
                {
                    name: "Swipe",
                    desc: "This trigger executes its actions when the user swipes on the feature in the direction specified.",
                    params: [
                        {
                            paramName: "Swipe Type",
                            paramDesc: "Using this trigger the user can specify the direction for swipe."
                        },
                        {
                            paramName: "Key Values",
                            paramDesc: "This is an optional parameter, we can set the length of the swipe into a key value."
                        },
                    ]
                }
            ]
        },
        {
            event: "Drag and Drop",
            triggers: [
                {
                    name: "Dragged Over",
                    desc: "This trigger executes its actions when this feature is dragged over another feature.",

                    params: [
                        {
                            paramName: "Target",
                            paramDesc: "This specifies the other feature to which this feature should be dragged."
                        },
                    ]
                },
                {
                    name: "Dropped On",
                    desc: "This trigger executes its actions when this feature is dragged and dropped onto another feature.",

                    params: [
                        {
                            paramName: "Target",
                            paramDesc: "This specifies the other feature to which this feature should be dropped on."
                        },
                        {
                            paramName: "Return On Fail",
                            paramDesc: "If enabled, it returns to its original position on failure."
                        },
                        {
                            paramName: "Position On Success",
                            paramDesc: "If enabled, this feature will be placed at the specified position on the target."
                        },
                    ]
                },
                {
                    name: "Dropped On Failed",
                    desc: "This trigger executes its actions when dropped onto another feature fails."
                }
            ]

        },

    ]

    return (
        <ul className="property-list">
            {
                triggers.map((element, index) => {
                    return (
                        <>
                            <span className="property">{element.event} </span>
                            <ul className="mb-4">
                                {element.triggers ?
                                    element.triggers.map((trigger) => (
                                        <li>
                                            <span className="property">{trigger.name} : </span>{trigger.desc}
                                            <ul>
                                                {
                                                    trigger.params ?
                                                        trigger.params.map(param => (
                                                            <li><span className="property">{param.paramName} : </span>{param.paramDesc}</li>
                                                        ))
                                                        : ""
                                                }
                                            </ul>
                                        </li>
                                    )) : ""
                                }
                            </ul>
                        </>

                    )
                })
            }
        </ul>
    )
}

export default Triggers;