// export const BASE_URL = 'http://localhost:8090/' // Local Server
// export const BASE_URL_A = 'http://localhost:8090/' // Local Server
// export const BASE_URL = 'http://122.248.229.117:8090/'       //Test server
// export const BASE_URL = 'https://baethoedu.com/'       //Production server
// export const BASE_URL_A = 'https://baethoapis.in/'       //Production A
// export const BASE_URL = 'https://baetho.in/'       //Production B
export const BASE_URL_A = 'https://baethoedu.com/'       //Production 
export const BASE_URL = 'https://baethoedu.com/'       //Production 




export const header = {
    headers: {
        dashboard: true
    }
};

//Home
export const InitialPath = '/'
export const PrivacyPolicy = '/privacy-policy'
export const TermsOfUse = '/terms-of-use'
export const Login = '/login'
export const ResetPassword = '/reset-passowrd'
export const AboutUs = '/about-us'
export const Pricing = '/pricing'

export const Contents = '/contents'
export const UnsubscribeLogin = '/unsubscribe'
export const UnsubscribeEmails = '/unsubscribe-emails'

// Admin dashboard
export const ProfileUpdate = '/contents/profile-update'
// export const ValidateLesson = '/contents/validate-lesson'
export const Analytics = '/contents/analytics'
export const Lessons = '/contents/analytics/lessons'
export const Performance = '/contents/performance'
export const IndividualLessonPerformance = '/contents/performance'

export const AdminProject = '/contents/admin-lessons'
export const AdminTimeline = '/contents/admin-lessons/timeline'
export const AdminLessonPlan = '/contents/admin-lessons/lesson-plan'
export const ValidateLesson = '/contents/admin-lessons/validate-lesson'
export const AdminLessonUpdate = '/contents/admin-lessons/update-lesson'
export const AdminChats = '/contents/admin-lessons'

//Teacher dashboard
export const LessonStatus = '/contents/lesson-status'
export const Documentation = '/documentation'
export const Projects = '/contents/lessons'
export const StatusTimeline = '/contents/lessons/status-timeline'
export const LessonPlan = '/contents/lessons/lesson-plan'
export const UploadLesson = '/contents/lessons/upload-lesson'
export const Chats = '/contents/lessons'
export const Composer = '/contents/composer'
export const TeacherProfile = '/contents/profile'
export const UpdateLesson = '/contents/lessons/update-lesson'
export const LessonPerformance = '/contents/lesson-performance'


//Marketing dashboard
export const Campaign = '/contents/campaign'
export const MarketingAnalytics = '/contents/marketing-analytics'
export const Retention = '/contents/retention-analysis'
export const CreateNotification = '/contents/create-notification'
export const NotificationList = '/contents/notification-list'
export const WhatsappChatList = '/contents/whatsapp-chat-list'


//Parent dashboard
export const UserProfile = '/contents/user-profile'

//App download 
export const AppDownloads = '/store'

//----------------------Documentation section---------------------//
//Experience
export const Lesson = "/documentation/Lesson";

//View
export const Scene = "/documentation/Scene";

// Features
export const ImageFeature = "/documentation/ImageFeature";
export const TextInputFeature = "/documentation/TextInputFeature";
export const LabelFeature = "/documentation/LabelFeature";
export const ButtonFeature = "/documentation/ButtonFeature";
export const ToggleButtonFeature = "/documentation/ToggleButtonFeature";
export const VideoFeature = "/documentation/VideoFeature";
export const GifFeature = "/documentation/GifFeature";
export const CheckboxFeature = "/documentation/CheckboxFeature";
export const RadioGroupFeature = "/documentation/RadioGroupFeature";
export const RadioButtonFeature = "/documentation/RadioButtonFeature";
export const KeyValues = "/documentation/KeyValues"
