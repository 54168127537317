import Navbar from "../home/navbar/navbar";
import * as Constants from '../../assets/helper/constants'
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { useHistory } from "react-router-dom";
function ResetPassword(props) {

    const [phoneLogin, setPhoneLogin] = useState(false)
    const [email, setEmail] = useState("")
    const [countryCode, setCountryCode] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [OTP, setOTP] = useState("")
    const [showVerify, setShowVerify] = useState(false)
    const [IPAddress, setIPAddress] = useState(null)
    const [password, setPassword] = useState("")
    const [reEnteredPassword, setReEnteredPassword] = useState("")
    const history = useHistory()



    useEffect(() => {

        getIPAddress()

    }, [])

    const getIPAddress = () => {
        axios.get(Constants.BASE_URL + "api/test/getIPAddress", Constants.header).then((response) => {
            if (response.data.status) {
                setIPAddress(response.data.ipAddress)
            }
        })
    }

    const sendOTP = async (e) => {
        e.preventDefault()

        if (!phoneLogin) {
            if (email.trim() === "" || email === "" || email === undefined) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    html: '<h4>Invalid email id.</h4>',
                    showConfirmButton: false,
                    timer: 2000
                })
            }
            let data = {
                email: email,
                type: "Login",
            }

            const header = {
                headers: {
                    dashboard: true,
                    'uuid': IPAddress
                }
            };


            axios.post(Constants.BASE_URL + 'api/aggregator/generateEmailOTP', data, header).then((response) => {

                if (response.data.status) {
                    setShowVerify(true)
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        html: '<h4>Please check your email id for OTP.</h4>',
                        showConfirmButton: false,
                        timer: 2000
                    })
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: '<h4>Something went wrong, please try again.</h4>',
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })


        } else {
            // console.log('phone');
            if (countryCode !== "91" && countryCode !== "+91") {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    html: '<h4>Only Country code "+91" is allowed, Please use email instead.</h4>',
                    showConfirmButton: false,
                    timer: 2000
                })
                return
            }
            if (phoneNumber.trim() === "" || phoneNumber === "" || phoneNumber === undefined ||
                countryCode.trim() === "" || countryCode === "" || countryCode === undefined) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    html: '<h4>Invalid phone number.</h4>',
                    showConfirmButton: false,
                    timer: 2000
                })
                return
            }
            let data = {
                phone: phoneNumber,
                type: "Login",
                uuid: IPAddress,
                ip: IPAddress,
                countryCode: "+" + countryCode
            }

            axios.post(Constants.BASE_URL + 'api/aggregator/generateOTP', data, Constants.header).then((response) => {
                setShowVerify(true)
                if (response.status) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        html: '<h4>OTP Sent</h4>',
                        showConfirmButton: false,
                        timer: 2000
                    })
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        html: '<h4>Something went wrong, please try again.</h4>',
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })
        }

    }

    const resetPassword = (e) => {
        e.preventDefault()


        if (OTP === "" || OTP.trim() === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                html: '<h4>All fields are mandatory.</h4>',
                showConfirmButton: false,
                timer: 2000
            })
            return
        }

        if (password !== reEnteredPassword) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                html: "<h4>Passwords didn't match.</h4> ",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }

        if (password.length <= 3) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                html: '<h4>Password is too small.</h4>',
                showConfirmButton: false,
                timer: 2000
            })
            return
        }

        let data = ""
        let path = ""
        if (!phoneLogin) {
            path = Constants.BASE_URL + 'api/aggregator/setPasswordByEmailOTP'

            data = {
                email: email,
                otp: OTP,
                password: password
            }
        } else {
            path = Constants.BASE_URL + "api/aggregator/setPasswordByOTP"

            data = {
                phone: phoneNumber,
                countryCode: countryCode,
                otp: OTP,
                password: password
            }
        }



        axios.post(path, data, Constants.header).then((response) => {

            if (response.data.result) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    html: "<h4>Password reset successful.</h4>",
                    showConfirmButton: false,
                    timer: 2000
                })
                history.push(Constants.Login)
            } else {
                let message = ""

                response.data.data.includes("not matched") ?
                    message = "<h4>OTP didn't match.</h4>"
                    : response.data.data.includes("Exceeds") ?
                        message = "<h4>Exceeds wrong attempts limit.</h4>"
                        : response.data.data.includes("No details") ?
                            message = "<h4>No details found.</h4>"
                            : response.data.data.includes("expires") ?
                                message = "<h4>Time expired, please try again.</h4>"
                                : message = "<h4>Something went wrong, Please try again.</h4>"


                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    html: message,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    return (
        <>
            <div className="mainBG">
                <Navbar />

                <div className="login-form-container">
                    <div className="login-form">

                        {
                            !showVerify ?
                                <>
                                    <Link to={Constants.Login} className="d-flex align-items-baseline gap-2 text-light login-type-info ">
                                        <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                        <p>Login</p>
                                    </Link>
                                    <form onSubmit={(e) => sendOTP(e)}>
                                        <h2 className="title">Reset Password</h2>
                                        {
                                            phoneLogin ?
                                                <div className="d-flex flex-column">
                                                    <label className="login-label input-phone">Phone</label>
                                                    <div className="d-flex gap-1">
                                                        <input
                                                            type="number"
                                                            className='input-field w-25'
                                                            required onChange={(e) => { setCountryCode(e.target.value) }}
                                                            placeholder="+91"
                                                        />
                                                        <input
                                                            type="number"
                                                            className='input-field'
                                                            required onChange={(e) => { setPhoneNumber(e.target.value) }}
                                                            placeholder="Phone number"
                                                        />
                                                    </div>
                                                    <p className="login-type-info ms-auto" onClick={() => setPhoneLogin(false)}> Try email instead  </p>
                                                </div>
                                                :
                                                <div className="d-flex flex-column">
                                                    <label className="login-label">Email</label>
                                                    <input
                                                        type="email"
                                                        className='input-field'
                                                        required onChange={(e) => { setEmail(e.target.value) }}
                                                        placeholder="Enter your email"
                                                    />
                                                    <p className="login-type-info align-self-end" onClick={() => setPhoneLogin(true)}> Try phone instead  </p>
                                                </div>
                                        }

                                        <button className="submit-btn mt-2 mb-4" type="submit">Send OTP</button>
                                    </form>
                                </>

                                :
                                <>
                                    <span className="d-flex align-items-baseline gap-2 text-light login-type-info" onClick={() => setShowVerify(false)}>
                                        <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                        <p>Back</p>
                                    </span>
                                    <form onSubmit={(e) => resetPassword(e)}>
                                        <div className="d-flex flex-column">
                                            <label className="login-label">OTP</label>
                                            <input
                                                type="number"
                                                className='input-field'
                                                required
                                                onChange={(e) => { setOTP(e.target.value) }}
                                                placeholder="Enter OTP"
                                            />
                                        </div>
                                        <div className="d-flex flex-column">
                                            <label className="login-label">Enter new password</label>
                                            <input
                                                type="password"
                                                className='input-field'
                                                required
                                                onChange={(e) => { setPassword(e.target.value) }}
                                                placeholder="Enter Password"
                                            />
                                        </div>
                                        <div className="d-flex flex-column">
                                            <label className="login-label">Re-Enter new password</label>
                                            <input
                                                type="password"
                                                className='input-field'
                                                required
                                                onChange={(e) => { setReEnteredPassword(e.target.value) }}
                                                placeholder="Re-Enter Password"
                                            />
                                        </div>
                                        {/* <button type='submit' className='submit-btn mt-2' >Verify OTP</button> */}
                                        <button className="submit-btn" type="submit" >Submit </button>
                                    </form>
                                </>
                        }

                    </div>
                </div>

            </div >
        </>
    )
}
export default ResetPassword;