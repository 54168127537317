import Accordion from 'react-bootstrap/Accordion';
import Actions from '../commons/actions';
import Basic from '../commons/basic';
import Interaction from '../commons/interaction';
import Triggers from '../commons/triggers';

function LabelFeature() {


    let featureProperties = [
        {
            name: 'Text',
            desc: 'Text to be displayed. This property allows you to assign predefined text to the label field.'
        },

        {
            name: 'Font',
            desc: 'This property allows you to select the font for the label.'
        },
        {
            name: 'Size',
            desc: 'This property allows you to set the font size of the label.'
        },
        {
            name: 'Font Color',
            desc: 'This property sets the font color.'
        },
        {
            name: 'Bold',
            desc: 'Text will be bold when this property is set to true.'
        },
        {
            name: 'Italic',
            desc: 'Text will be italicized when this property is set to true.'
        },
        {
            name: 'Horizontal Align',
            desc: 'This property aligns the text in the label field horizontally. We can align left, right and center.'
        },
        {
            name: 'Gap between lines',
            desc: 'This property specifies the space between the lines. Negative values are not allowed.'
        },
        {
            name: 'Show Background',
            desc: `If this is set to true, it enables the background of the label field.`
        },
        {
            name: 'Background Color',
            desc: `Using this property we can select the background color for the label field.`
        },
        {
            name: 'Background Outline Color',
            desc: `This property allows you to choose a different color for the outline of the label field.`
        },
        {
            name: 'Outline Thickness',
            desc: `This property allows you to set the thickness of the outline of the label field.`
        },
        {
            name: 'Background Radius',
            desc: `This property allows you to curve the edges of the label field.`
        },
    ]





    let actions = [
        {
            actionName: "Interaction",
            action: [
                {
                    name: "Change Movement Type",
                    desc: "This action changes the feature’s movement in the scene. It can be set to Free, Vertical, Horizontal or none.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Movement Type",
                            paramDesc: "This is the target movement type."
                        }
                    ]
                },
            ]
        },
        // {
        //   actionName: "Feature",
        //   action: [
        //     {
        //       name: "Delete Feature",
        //       desc: "This action deletes the feature from the screen.",
        //     },

        //   ]
        // },
        {
            actionName: "Text",
            action: [
                {
                    name: "Change Text",
                    desc: "This action changes the text in the label field.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Text",
                            paramDesc: "This is the target value."
                        },
                    ]
                },

            ]
        }
    ]


    return (
        <div className="content-main">
            <h2 className="title">Label Feature</h2>
            <h3 className="sub-title">Description</h3>
            <p>The label feature is used to display text.</p>


            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header> <h3 className="sub-title">Properties</h3></Accordion.Header>
                    <Accordion.Body>

                        <Basic />

                        <Interaction />

                        <h3 className="sub-title-2">Label Properties</h3>
                        <ul className="property-list">
                            {
                                featureProperties.map((element, index) => {
                                    return (
                                        <li>
                                            <span className="property">{element.name} : </span> {element.desc}
                                        </li>
                                    )
                                })
                            }
                        </ul>

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><h3 className="sub-title">Triggers</h3></Accordion.Header>
                    <Accordion.Body>
                        <Triggers />

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><h3 className="sub-title">Actions</h3></Accordion.Header>
                    <Accordion.Body>

                        <Actions />
                        <ul className="property-list">
                            {
                                actions.map((element, index) => {
                                    return (
                                        <>
                                            <span className="property">{element.actionName} </span>
                                            <ul className="mb-3">
                                                {element.action ?
                                                    element.action.map((action) => (
                                                        <li>
                                                            <span className="property">{action.name} : </span>{action.desc}
                                                            <ul>
                                                                {
                                                                    action.params ?
                                                                        action.params.map(param => (
                                                                            <li><span className="property">{param.paramName} : </span>{param.paramDesc}</li>
                                                                        ))
                                                                        : ""

                                                                }
                                                            </ul>
                                                        </li>
                                                    )) : ""
                                                }
                                            </ul>
                                        </>

                                    )
                                })
                            }
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>


        </div>
    );
}
export default LabelFeature;
