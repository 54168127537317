import { useHistory } from "react-router-dom"
import CreatableSelect from 'react-select/creatable';
import { useEffect, useState } from "react";
import Swal from 'sweetalert2';
import Axios from 'axios';
import LoaderComp from "../../../loader";
import * as Constants from '../../../../assets/helper/constants'
import { useParams } from "react-router-dom"
import ThumbnailPopup from "./thumbnailPopup";
import AnswerForRejectionPopup from "./answerForRejectionPopup";

function UpdateLesson(props) {

    const [isLoading, setIsLoading] = useState(false)
    const [userInfo, setUserInfo] = useState('')
    const [lessonDetails, setLessonDetails] = useState([])
    // const [lessonName, setLessonName] = useState('')
    // const [lessonTags, setLessonTags] = useState('')
    // const [lessonDesc, setLessonDesc] = useState('')
    // const [age, setAge] = useState('')
    // const [region, setRegion] = useState('')
    const [subject, setSubject] = useState('')
    // const [thumbOne, setThumbOne] = useState({})
    // const [thumbTwo, setThumbTwo] = useState({})
    // const [thumbThree, setThumbThree] = useState({})
    const [subjectDropdown, setSubjectDropdown] = useState('')
    const [showThumbnailPopup, setShowThumbnailPopup] = useState(false)
    const [thumbImage, setThumbImage] = useState('')
    const [showRejectLessonPopup, setShowRejectLessonPopup] = useState(false)
    const [isChanged, setIsEditable] = useState({
        lesson: false,
        thumb1: false,
        thumb2: false,
        thumb3: false,
        subject: false,
        lessonName: false,
        tags: false,
        age: false,
        region: false,
        desc: false,
    })
    const params = useParams();
    const history = useHistory()
    const [lessonOptions, setLessonOptions] = useState(
        [{ value: 'English', label: 'English' },
        { value: 'Mathematics', label: 'Mathematics' },
        { value: 'Science', label: 'Science' }])


    //Lesson update states
    // const [newLessonFile, setNewLessonFile] = useState(null)
    // const [newThumbOne, setNewThumbOne] = useState(null)
    // const [newThumbTwo, setNewThumbTwo] = useState(null)
    // const [newThumbThree, setNewThumbThree] = useState(null)

    //changed input data
    const [inputChanged, setInputChanged] = useState(false)
    const [changedData, setChangedData] = useState({})

    const [messages, setMessages] = useState([])

    //invalid thumb alert
    const [invalidThumb1, setInvalidThumb1] = useState(false)
    const [invalidThumb2, setInvalidThumb2] = useState(false)
    const [invalidThumb3, setInvalidThumb3] = useState(false)


    useEffect(() => {
        setUserInfo(props.userInfo)
        getLessonDetailsForTeacher()
        getMessage()
    }, [])


    //on input change
    const onInputChange = (value, changed) => {
        // setIsEditable(prevState => {
        //     return { ...prevState, [changed]: true };
        // });
        // setter(e.target.value)

        if (changed === 'subject') {
            if (value === '' || value === null || value === undefined) {
                setChangedData((prevState) => {
                    return { ...prevState, [changed]: '' }
                })
            } else {
                setInputChanged(true)
                setChangedData((prevState) => {
                    return { ...prevState, [changed]: value }
                })
            }
        } else {
            setInputChanged(true)
            setChangedData((prevState) => {
                return { ...prevState, [changed]: value }
            })
        }


    }

    //on thumbnails change
    const onInputFileChange = (e, setter, changed) => {
        setIsEditable(prevState => {
            return { ...prevState, [changed]: true };
        });
        setter(e.target.files[0])
    }

    const handleSubject = (e) => {
        if (e) {
            setSubject(e.value)
            setIsEditable(prevState => {
                return { ...prevState, ["subject"]: true };
            });
        } else {
            setSubject("")
        }
    }
    const getMessage = () => {
        let projectId = params.projectId;


        Axios.get(Constants.BASE_URL + `api/teacher/getMessages/${projectId}`).then((result) => {

            if (result.data.status) {
                if (result.data.result.length > 0) {
                    let msg = JSON.parse(result.data.result[0].Message)

                    let rejected = msg.filter(message => {

                        return message.type === 'rejectedLesson' && message.answered === false
                    })

                    /** 
                     * merge rejection reasons object into one array
                     * */
                    const resultArray = rejected.reduce((acc, curr) => {
                        acc[0].message = acc[0].message.concat(curr.message);
                        return acc;
                    }, [{ message: [] }]);

                    setMessages(resultArray)
                } else {
                    setMessages([])
                }
            } else {
                setMessages([])
            }
        })
    }

    const getLessonDetailsForTeacher = () => {
        //get lesson details to display
        Axios.get(Constants.BASE_URL + `api/teacher/getLessonDetailsForTeacher?projectId=${params.projectId}`).then((result) => {
            if (result.data.status) {
                setLessonDetails(result.data.result[0])
                let sub = result.data.result[0]?.Subject
                let selectedSuject = [{ value: sub, label: sub }]
                let index = lessonOptions.findIndex((element) => { return element.label == result.data.result[0]?.Subject })

                setSubjectDropdown(index)

            } else {
                setLessonDetails([])
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Unable to fetch lesson details.',
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        }).catch((error) => {
            console.log('err', error)
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'An Error occured while fetching details.',
                showConfirmButton: false,
                timer: 2000
            })
        })
    }

    const onUpdateLessonDetails = (answersList) => {


        for (const key in changedData) {
            if (key.includes('thumb') && changedData[key] != undefined && changedData[key] != null) {
                //check if thumbs are valid, if not show alert message.
                //console.log('thumb')
                if (changedData[key].name.split('.').pop() !== 'jpg' && changedData[key].name.split('.').pop() !== 'JPG'
                    && changedData[key].name.split('.').pop() !== 'png' && changedData[key].name.split('.').pop() !== 'PNG'
                    && changedData[key].name.split('.').pop() !== 'jpeg' && changedData[key].name.split('.').pop() !== 'JPEG') {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: "Invalid thumbnail image format.",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    return
                }
            }
            else if (changedData[key] != undefined && changedData[key] != null) {
                //check if any of the property is not empty. If empty show alert message.
                // console.log('not thumb')
                if (changedData[key].trim() === '') {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: "Cannot submit empty field.",
                        html: "<h6>Please check fields are not empty.</h6>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    return
                }
            }
        }

        var promise1
        var promise2
        var promise3

        if (changedData['thumbOne']) {
            promise1 = new Promise((resolve, reject) => {
                let img1 = new Image();
                img1.src = URL.createObjectURL(changedData['thumbOne']);
                img1.onload = function () {
                    if (this.width === 640 && this.height === 360) {
                        URL.revokeObjectURL(img1);
                        resolve(true)
                        setInvalidThumb1(false)
                    } else {
                        URL.revokeObjectURL(img1);
                        reject(false)
                        setInvalidThumb1(true)
                    }
                };
            })
        }
        if (changedData['thumbTwo']) {
            promise2 = new Promise((resolve, reject) => {
                let img1 = new Image();
                img1.src = URL.createObjectURL(changedData['thumbTwo']);
                img1.onload = function () {
                    if (this.width === 640 && this.height === 360) {
                        URL.revokeObjectURL(img1);
                        resolve(true)
                        setInvalidThumb2(false)
                    } else {
                        URL.revokeObjectURL(img1);
                        reject(false)
                        setInvalidThumb2(true)
                    }
                };
            })
        }
        if (changedData['thumbThree']) {
            promise3 = new Promise((resolve, reject) => {
                let img1 = new Image();
                img1.src = URL.createObjectURL(changedData['thumbThree']);
                img1.onload = function () {
                    if (this.width === 640 && this.height === 360) {
                        URL.revokeObjectURL(img1);
                        resolve(true)
                        setInvalidThumb3(false)
                    } else {
                        URL.revokeObjectURL(img1);
                        reject(false)
                        setInvalidThumb3(true)
                    }
                };
            })
        }

        Promise.all([promise1, promise2, promise3]).then((result) => {
            const formData = new FormData();

            formData.append("projectId", params.projectId);
            formData.append("lessonID", lessonDetails.Id);
            formData.append("userId", userInfo.Id);
            formData.append("answersList", JSON.stringify(answersList));
            formData.append("from", props.userInfo.FirstName + ' ' + props.userInfo.LastName);


            for (const key in changedData) {
                if (changedData[key] !== undefined || changedData !== null || changedData !== '') {

                    formData.append([key], changedData[key]);
                }
            }


            Axios.post(Constants.BASE_URL + "api/AggregatorLessons/updateLessonDetails", formData, Constants.header).then((result) => {
                if (result.data.result) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: "Submitted for approval.",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    Object.keys(isChanged).forEach((i) => isChanged[i] = false);
                    getLessonDetailsForTeacher()
                    setInputChanged(false)
                    setChangedData({})
                } else if (result.data.data == "Lesson under review") {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'info',
                        title: 'Cannot update.',
                        html: '<h4>Lesson is under review.</h4>',
                        showConfirmButton: false,
                        timer: 2000
                    })
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Unable to update. Please try again.',
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            }).catch((error) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'An Error occured while updating.',
                    showConfirmButton: false,
                    timer: 2000
                })
            })

        }).catch((e) => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Invalid thumbnail.',
                html: '<h6>Upload thumbnails of 640x360 dimensions.</h6>',
                showConfirmButton: false,
                timer: 2000
            })
        })


    }

    // const submitLessonForReview = (e) => {

    //     let data = {
    //         lessonId: lessonDetails[0].Id
    //     }
    //     Axios.put(Constants.BASE_URL + `api/AggregatorLessons/submitLessonForReview`, data, Constants.header).then((result) => {
    //         getLessonDetailsForTeacher()
    //         if (result.data.status) {
    //             Swal.fire({
    //                 position: 'top-end',
    //                 icon: 'success',
    //                 title: "Lesson submitted for review.",
    //                 showConfirmButton: false,
    //                 timer: 2000
    //             })
    //         } else {
    //             Swal.fire({
    //                 position: 'top-end',
    //                 icon: 'error',
    //                 title: "Unable to submit lesson.",
    //                 showConfirmButton: false,
    //                 timer: 2000
    //             })
    //         }
    //     });
    // }

    /** 
     * This function validates all the updated details. and
     * sends lesson file, thumnails along with any changed data like lesson name, age, description etc.
     * If data other than lesson & thumbnail is not changed then
     * send the current details(lesson name, tags, age, region, desc) of the lesson.
    */
    const updateLesson = (answersList) => {

        // console.log('input', inputList)
        if (changedData['thumbOne'] === undefined || changedData['thumbTwo'] === undefined || changedData['thumbThree'] === undefined) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please select Thumbnails.",
                html: 'Thumbnails are mandatory to update lesson.',
                showConfirmButton: false,
                timer: 2000
            })
            return
        }

        for (const key in changedData) {
            if (key.includes('lessonFile')) {
                if (changedData[key] === undefined || changedData[key] === null || changedData[key] === '') {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: "Please select lesson file.",
                        html: 'Lesson file is not selected.',
                        showConfirmButton: false,
                        timer: 2000
                    })
                    return
                }
                else if (changedData[key].name.split('.').pop() !== 'exp' && changedData[key].name.split('.').pop() !== 'prj') {


                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: "Invalid lesson file.",
                        html: 'Please upload .exp or .prj files only.',
                        showConfirmButton: false,
                        timer: 2000
                    })

                    return
                }
            }

            if (key.includes('thumb')) {
                if (changedData[key] === undefined || changedData[key] === null || changedData[key] === '') {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: "Please select Thumbnails.",
                        html: 'Thumbnails are mandatory to update lesson.',
                        showConfirmButton: false,
                        timer: 2000
                    })
                    return
                }
                else if (changedData[key].name.split('.').pop() !== 'jpg' && changedData[key].name.split('.').pop() !== 'JPG'
                    && changedData[key].name.split('.').pop() !== 'png' && changedData[key].name.split('.').pop() !== 'PNG'
                    && changedData[key].name.split('.').pop() !== 'jpeg' && changedData[key].name.split('.').pop() !== 'JPEG') {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: "Invalid thumbnail image format.",
                        html: 'Please upload JPEG, JPG, or PNG images only.',
                        showConfirmButton: false,
                        timer: 2000
                    })
                    return
                }
            }

            if (!key.includes('thumb') && !key.includes('lessonFile')) {
                //check if any of the property is not empty. If empty show alert message.
                if (changedData[key].trim() === '') {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: "Cannot submit empty field.",
                        html: "<h6>Please check fields are not empty.</h6>",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    return
                }
            }

        }

        var promise1
        var promise2
        var promise3

        if (changedData['thumbOne']) {
            promise1 = new Promise((resolve, reject) => {
                let img1 = new Image();
                img1.src = URL.createObjectURL(changedData['thumbOne']);
                img1.onload = function () {
                    if (this.width === 640 && this.height === 360) {
                        URL.revokeObjectURL(img1);
                        resolve(true)
                        setInvalidThumb1(false)
                    } else {
                        URL.revokeObjectURL(img1);
                        reject(false)
                        setInvalidThumb1(true)
                    }
                };
            })
        }
        if (changedData['thumbTwo']) {
            promise2 = new Promise((resolve, reject) => {
                let img1 = new Image();
                img1.src = URL.createObjectURL(changedData['thumbTwo']);
                img1.onload = function () {
                    if (this.width === 640 && this.height === 360) {
                        URL.revokeObjectURL(img1);
                        resolve(true)
                        setInvalidThumb2(false)
                    } else {
                        URL.revokeObjectURL(img1);
                        reject(false)
                        setInvalidThumb2(true)
                    }
                };
            })
        }
        if (changedData['thumbThree']) {
            promise3 = new Promise((resolve, reject) => {
                let img1 = new Image();
                img1.src = URL.createObjectURL(changedData['thumbThree']);
                img1.onload = function () {
                    if (this.width === 640 && this.height === 360) {
                        URL.revokeObjectURL(img1);
                        resolve(true)
                        setInvalidThumb3(false)
                    } else {
                        URL.revokeObjectURL(img1);
                        reject(false)
                        setInvalidThumb3(true)
                    }
                };
            })
        }
        Promise.all([promise1, promise2, promise3]).then((result) => {

            const formData = new FormData();
            formData.append("parentID", lessonDetails.ParentID);
            formData.append("oldLessonId", lessonDetails.Id);
            formData.append("userId", userInfo.Id);
            formData.append("projectId", params.projectId);

            formData.append("answersList", JSON.stringify(answersList));
            formData.append("from", props.userInfo.FirstName + ' ' + props.userInfo.LastName);

            formData.append("lessonFile", changedData['lessonFile']);
            formData.append("thumbOne", changedData['thumbOne']);
            formData.append("thumbTwo", changedData['thumbTwo']);
            formData.append("thumbThree", changedData['thumbThree']);
            formData.append("subject", changedData['subject'] ? changedData['subject'] : lessonDetails.Subject);
            formData.append("lessonName", changedData['lessonName'] ? changedData['lessonName'] : lessonDetails.Name);
            formData.append("lessonTags", changedData['lessonTags'] ? changedData['lessonTags'] : lessonDetails.Tags);
            formData.append("age", changedData['age'] ? changedData['age'] : lessonDetails.Age);
            formData.append("region", changedData['region'] ? changedData['region'] : lessonDetails.Region);
            formData.append("lessonDesc", changedData['lessonDesc'] ? changedData['lessonDesc'] : lessonDetails.Description);

            setIsLoading(true)
            Axios.post(Constants.BASE_URL_A + "api/AggregatorLessons/updateLesson", formData, Constants.header).then((result) => {
                setIsLoading(false)
                if (result.data.result) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: "Submitted for approval.",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    getLessonDetailsForTeacher()
                    setInputChanged(false)
                    setChangedData({})
                } else if (result.data.data == "Lesson under review") {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'info',
                        title: 'Cannot update. Lesson is under review.',
                        showConfirmButton: false,
                        timer: 2000
                    })
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Unable to update. Please try again.',
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })

        }).catch((e) => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Invalid thumbnail.',
                html: '<h6>Upload thumbnails of 640x360 dimensions.</h6>',
                showConfirmButton: false,
                timer: 2000
            })
        })



    }

    const submitLesson = (answersList) => {
        // console.log('answer', changedData)
        if (changedData.lessonFile) {
            updateLesson(answersList)
        } else {
            onUpdateLessonDetails(answersList)
        }
    }

    const goBack = (e) => {
        history.goBack()
    }

    const toggleThumbnailPopup = (imgeLink) => {

        setShowThumbnailPopup(!showThumbnailPopup)
        setThumbImage(imgeLink)
    }
    const toggleRejectLessonPopup = () => {
        setShowRejectLessonPopup(!showRejectLessonPopup)
    }
    return (
        <div className='right-panel-container text-left pb-4'>
            {isLoading ? <LoaderComp isLoading={isLoading} /> : null}
            <div className='container-fluid'>
                <ThumbnailPopup thumbnail={thumbImage} show={showThumbnailPopup} onHide={toggleThumbnailPopup} />
                <AnswerForRejectionPopup
                    show={showRejectLessonPopup}
                    onHide={toggleRejectLessonPopup}
                    submitLesson={submitLesson}
                    messages={messages}
                />
                <div className="row mb-4">
                    <div className="col-12">
                        <h2 className="section-name">Update Lesson</h2>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12 d-flex justify-content-start align-items-baseline flex-wrap">
                        <button onClick={goBack} className="btn"><i className="fa fa-chevron-left me-2"></i>Back</button>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-lg-6 col-md-12 ">

                        {/* ------------------------------------ */}
                        <div className="card border-0 box-shadows">
                            <h6 className="card-header card-title py-3">Update Lesson Details</h6>
                            <div className="card-body">
                                <ul>
                                    <li> <small>Updating thumbnails is mandatory while updating lesson.</small></li>
                                    <li> <small>Please upload thumbnails of 640 x 360 dimensions only.</small></li>
                                </ul>

                                <div className="row">
                                    <div className="row mb-4 align-items-end mt-4">
                                        <div className="col">
                                            <label htmlFor="Lessonfile" className="form-label">Lesson file</label>
                                            <input
                                                type="file"
                                                id="Lessonfile"
                                                className="form-control"
                                                aria-label="Lesson file"
                                                onChange={(e) => onInputChange(e.target.files[0], 'lessonFile')}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-4 align-items-end">
                                        <div className="col">
                                            <label htmlFor="newThumb1" className="form-label">Thumbnail 1</label>
                                            <input
                                                type="file"
                                                id="newThumb1"
                                                className="form-control"
                                                aria-label="new Thumb1"
                                                onChange={(e) => onInputChange(e.target.files[0], 'thumbOne')}
                                            />
                                            {invalidThumb1 ? <small className="text-danger">Please upload thumbnail of 640 x 360 dimension only.</small> : ''}
                                        </div>
                                    </div>
                                    <div className="row mb-4 align-items-end">
                                        <div className="col">
                                            <label htmlFor="newThumb2" className="form-label">Thumbnail 2</label>
                                            <input
                                                type="file"
                                                id="newThumb2"
                                                className="form-control"
                                                aria-label="new Thumb1"
                                                onChange={(e) => onInputChange(e.target.files[0], 'thumbTwo')}
                                            />
                                            {invalidThumb2 ? <small className="text-danger">Please upload thumbnail of 640 x 360 dimension only.</small> : ''}
                                        </div>
                                    </div>
                                    <div className="row mb-4 align-items-end">
                                        <div className="col">
                                            <label htmlFor="newThumb3" className="form-label">Thumbnail 3</label>
                                            <input
                                                type="file"
                                                id="newThumb3"
                                                className="form-control"
                                                aria-label="new Thumb1"
                                                onChange={(e) => onInputChange(e.target.files[0], 'thumbThree')}
                                            />
                                            {invalidThumb3 ? <small className="text-danger">Please upload thumbnail of 640 x 360 dimension only.</small> : ''}
                                        </div>
                                    </div>
                                    <div className="row mb-4 align-items-end">
                                        <div className="col">
                                            <label htmlFor="subject" className="form-label">Subject</label>
                                            <CreatableSelect
                                                isClearable
                                                id="subject"
                                                defaultValue={lessonOptions[subjectDropdown]}
                                                options={lessonOptions}
                                                className="creatable"
                                                onChange={(e) => onInputChange(e?.value, 'subject')}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-4 align-items-end">
                                        <div className="col">
                                            <label htmlFor="lessonName" className="form-label">Lesson Name</label>
                                            <input
                                                defaultValue={lessonDetails?.Name}
                                                type="text"
                                                id="lessonName"
                                                className="form-control"
                                                aria-label="lessonName"
                                                onChange={(e) => onInputChange(e.target.value, 'lessonName')}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-4 align-items-end">
                                        <div className="col">
                                            <label htmlFor="tags" className="form-label">Tags</label>
                                            <input
                                                defaultValue={lessonDetails?.Tags}
                                                type="text"
                                                id="tags"
                                                className="form-control"
                                                aria-label="tags"
                                                onChange={(e) => onInputChange(e.target.value, 'lessonTags')}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-4 align-items-end">
                                        <div className="col">
                                            <label htmlFor="age" className="form-label">Age</label>
                                            <select name="age" id="age" defaultValue={lessonDetails?.Age} onChange={(e) => onInputChange(e.target.value, 'age')} className="form-control input-style">
                                                <option value="">Select age</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mb-4 align-items-end">
                                        <div className="col">
                                            <label htmlFor="region" className="form-label">Region</label>
                                            <select name="Region" id="region" defaultValue={lessonDetails?.Region} onChange={(e) => onInputChange(e.target.value, 'region')} className="form-control input-style">
                                                <option value="">Select Region</option>
                                                <option value="India">India</option>
                                                <option value="International">International</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mb-4 align-items-end">
                                        <div className="col">
                                            <label htmlFor="description" className="form-label">Description</label>
                                            <textarea
                                                defaultValue={lessonDetails?.Description}
                                                type="text"
                                                id="description"
                                                className="form-control"
                                                aria-label="description"
                                                onChange={(e) => onInputChange(e.target.value, 'lessonDesc')}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                            type="submit"
                                            className={inputChanged ? "btn bg-blue text-light w-100 mb-4" : "btn w-100 disabled mb-4"}
                                            onClick={toggleRejectLessonPopup}>
                                            Update
                                        </button>

                                        {/* <button
                                            onClick={toggleRejectLessonPopup}
                                            type="button"
                                            className={lessonDetails?.Approved == 0 ? 'btn btn-blue text-light w-100' : 'btn text-dark w-100 disabled'}>
                                            Submit for review
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 my-3">
                            {/* <Chats /> */}
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}
export default UpdateLesson