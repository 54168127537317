import { useEffect, useState } from "react"
import Swal from 'sweetalert2';
import Axios from 'axios'
import * as Constants from '../../../assets/helper/constants'
import { useHistory } from "react-router-dom";
import Header from "../../header/header";
import LoaderComp from "../../loader";
import CreatableSelect from 'react-select/creatable';

function UploadLesson(props) {

    const [subject, setSubject] = useState('')
    const [lessonName, setLessonName] = useState('')
    const [tags, setTags] = useState('')
    const [age, setAge] = useState('')
    const [region, setRegion] = useState('')
    const [desc, setDesc] = useState('')
    const [lessonFile, setLessonFile] = useState(null)
    const [thumbOne, setThumbOne] = useState(null)
    const [thumbTwo, setThumbTwo] = useState(null)
    const [thumbThree, setThumbThree] = useState(null)
    const [userInfo, setUserInfo] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [uploaded, setUploaded] = useState(null)
    const history = useHistory()
    const lessonOptions = [
        { value: 'English', label: 'English' },
        { value: 'Mathematics', label: 'Mathematics' },
        { value: 'Science', label: 'Science' }
    ]

    const msg = [
        {
            id: 1,
            user: "Teacher",
            message: "Test message",
            date: '17-05-23'
        },
        {
            id: 2,
            user: "Admin",
            message: "Test message",
            date: '17-05-23'
        },

    ]

    useEffect(() => {
        // let userDetails = JSON.parse(localStorage.getItem("userDetails"))
        setUserInfo(props.userInfo)

    }, [])

    const handleSubject = (e) => {

        if (e) {
            setSubject(e.value)
        } else {
            setSubject("")
        }
    }

    const uploadLesson = async (e) => {
        e.preventDefault()

        if (subject.trim() === "" || lessonName.trim() === "" || tags.trim() === "" || age.trim() === "" || region.trim() === "" || desc.trim() === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "All fields are mandatory.",
                showConfirmButton: false,
                timer: 1000
            })

            return
        }
        if (lessonFile.name.split('.').pop() !== 'exp' && lessonFile.name.split('.').pop() !== 'prj') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Only lesson files of .exp or .prj are allowed.",
                showConfirmButton: false,
                timer: 2000
            })

            return
        }
        if (thumbOne.name.split('.').pop() !== 'jpg' && thumbOne.name.split('.').pop() !== 'JPG'
            && thumbOne.name.split('.').pop() !== 'png' && thumbOne.name.split('.').pop() !== 'PNG'
            && thumbOne.name.split('.').pop() !== 'jpeg' && thumbOne.name.split('.').pop() !== 'JPEG') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Thumbnail image format is not supported.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }
        if (thumbTwo.name.split('.').pop() !== 'jpg' && thumbTwo.name.split('.').pop() !== 'JPG'
            && thumbTwo.name.split('.').pop() !== 'png' && thumbTwo.name.split('.').pop() !== 'PNG'
            && thumbTwo.name.split('.').pop() !== 'jpeg' && thumbTwo.name.split('.').pop() !== 'JPEG') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Thumbnail image format is not supported.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }
        if (thumbThree.name.split('.').pop() !== 'jpg' && thumbThree.name.split('.').pop() !== 'JPG'
            && thumbThree.name.split('.').pop() !== 'png' && thumbThree.name.split('.').pop() !== 'PNG'
            && thumbThree.name.split('.').pop() !== 'jpeg' && thumbThree.name.split('.').pop() !== 'JPEG') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Thumbnail image format is not supported.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }


        const fromData = new FormData();
        fromData.append("lessonFile", lessonFile);
        fromData.append("thumbOne", thumbOne);
        fromData.append("thumbTwo", thumbTwo);
        fromData.append("thumbThree", thumbThree);
        fromData.append("subject", subject);
        fromData.append("lessonName", lessonName);
        fromData.append("lessonTags", tags);
        fromData.append("age", age);
        fromData.append("region", region);
        fromData.append("lessonDesc", desc);
        fromData.append("userId", userInfo.Id);

        const { value: accept } = await Swal.fire({
            title: 'Terms and conditions',
            html:
                'Please read our, ' +
                '<a href="https://baethokids.com/#/terms-and-conditions" target="_blank">Terms & Condtions</a> ',
            input: 'checkbox',
            inputValue: 0,
            inputPlaceholder:
                'I agree with the terms and conditions',
            confirmButtonText:
                'Continue <i class="fa fa-arrow-right"></i>',
            inputValidator: (result) => {
                return !result && 'You need to agree with T&C'
            }
        })

        if (accept) {
            setIsLoading(true)
            Axios.post(Constants.BASE_URL + "api/AggregatorLessons/uploadLesson/", fromData, Constants.header, {
                onUploadProgress: (data) => {
                    setUploaded(Math.round((data.loaded / data.total) * 100))
                }
            }).then((result) => {
                setIsLoading(false)

                if (result.data.status) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: "Lesson Uploaded succesfully",
                        showConfirmButton: false,
                        timer: 2000
                    })

                    setSubject("")
                    setLessonName("")
                    setTags("")
                    setDesc("")
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: "Unable to Upload Lesson.",
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            });
        }
    }

    const logout = () => {
        Swal.fire({
            title: "Are you sure you want to logout?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('userDetails')
                //localStorage.removeItem('rememberme')
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Logged Out Successfully',
                    showConfirmButton: false,
                    timer: 3000
                })
                history.push('/Login')
            }
        })
    }
    const goBack = (e) => {
        history.goBack()
    }
    return (
        <>
            <div className="right-panel-container">
                {isLoading ? <LoaderComp isLoading={isLoading} /> : null}
                {/* <div className="row p-0 header-wrap">
                    <div className="col-12 p-0">
                        <Header section="Upload Lesson" logout={props.logout} userName={userInfo.FirstName + ' ' + userInfo.LastName} />
                    </div>
                </div> */}
                <div className="container-fluid">
                    <div className="row mb-4">
                        <div className="col-12">
                            <h2 className="section-name ">Upload Lesson</h2>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12 d-flex">
                            <button onClick={goBack} className="btn text-left"><i className="fa fa-chevron-left me-2"></i>Back</button>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="data-container-wrapper shadows rounded ">
                                {uploaded && <div className="progress p-0">
                                    <div className="progress-bar"
                                        role="progressbar"
                                        aria-valuenow={uploaded}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{ width: `${uploaded}%`, backgroundColor: "#837DFF" }} >
                                        <span>{uploaded}%</span>
                                    </div>
                                </div>}
                                <div className="form-container">
                                    <form className="upload-lesson-form" onSubmit={uploadLesson}>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12 col-lg-6">
                                                <div className="input-wrap">
                                                    <label className="upload-lesson-label">Select Lesson</label>
                                                    <input type="file" className="form-control file-input input-style" required onChange={(e) => setLessonFile(e.target.files[0])} />
                                                </div>
                                                <div className="input-wrap">
                                                    <label className="upload-lesson-label">Thumbnail 1</label>
                                                    <input type="file" className="form-control file-input input-style" required onChange={(e) => setThumbOne(e.target.files[0])} />
                                                </div>
                                                <div className="input-wrap">
                                                    <label className="upload-lesson-label">Thumbnail 2</label>
                                                    <input type="file" className="form-control file-input input-style" required onChange={(e) => setThumbTwo(e.target.files[0])} />
                                                </div>
                                                <div className="input-wrap">
                                                    <label className="upload-lesson-label">Thumbnail 3</label>
                                                    <input type="file" className="form-control file-input input-style" required onChange={(e) => setThumbThree(e.target.files[0])} />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12 col-lg-6">
                                                <div className="input-wrap">
                                                    <label className="upload-lesson-label">Subject</label>
                                                    <CreatableSelect isClearable options={lessonOptions} className="creatable input-style" onChange={handleSubject} />
                                                    {/* <input type="text" className="form-control form-input" value={subject} required onChange={(e) => setSubject(e.target.value)} /> */}
                                                </div>


                                                <div className="input-wrap">
                                                    <label className="upload-lesson-label">Lesson Title</label>
                                                    <input type="text" className="form-control form-input input-style" value={lessonName} required onChange={(e) => setLessonName(e.target.value)} />
                                                </div>
                                                <div className="input-wrap">
                                                    <label className="upload-lesson-label">Tags<span className="ms-2">Comma-separated</span></label>
                                                    <input type="text" className="form-control form-input input-style" value={tags} placeholder="Tag1, Tag2" required onChange={(e) => setTags(e.target.value)} />
                                                </div>
                                                <div className="input-wrap">
                                                    <label className="upload-lesson-label">Age</label>
                                                    <select name="age" id="age" className="form-control form-input input-style" required onChange={(e) => setAge(e.target.value)}>
                                                        <option value="">Select age</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                    </select>
                                                </div>
                                                <div className="input-wrap">
                                                    <label className="upload-lesson-label">Description</label>
                                                    <textarea rows="4" cols="25" className="form-control desc-area input-style" value={desc} required onChange={(e) => setDesc(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row my-4 text-end">
                                            <div className="col-12">
                                                <button type="submit" className="btn btn-blue text-light px-5 me-3">Upload Lesson</button>
                                                <button type="submit" className="btn btn-blue text-light px-5">Submit For Review</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* <div className="row mb-3">
                        <div className="col-12">
                            <div className="chat-box-wrap d-flex flex-column justify-content-between shadows">
                                <div className="chat-message">
                                    <div className="chat-header text-left">
                                        <h3>Messages</h3>
                                    </div>
                                    <hr></hr>
                                    {
                                        msg.map((message) => {
                                            return (
                                                <div className='msg-wrap mb-3 text-left'>
                                                    <p className="mb-0 fw-bold">{message.user}<span className="fw-normal ms-2">{message.date}</span></p>

                                                    <p> {message.message}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div class="form-floating">
                                    <textarea class="form-control chat-text-area input-style " placeholder="Message" id="floatingTextarea"></textarea>
                                    <label for="floatingTextarea">Message</label>
                                    <button className="btn btn-blue text-white mt-2 float-end">Send</button>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}
export default UploadLesson