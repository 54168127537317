import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Swal from 'sweetalert2';
import Axios from 'axios';
import * as Constants from '../../assets/helper/constants'
import LoaderComp from "../loader";
import BaethoLogo from "../../assets/images/logo192.png";
import CryptoJS from 'crypto-js'
import Navbar from "../home/navbar/navbar";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const secret = 'BaethoSecretTokenEduTech'
function Login(props) {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const [unsubscribeLogin, setUnsbscribeLogin] = useState(false)
    const [emailOrPhone, setEmailOrPhone] = useState('')
    const [pass, setPass] = useState('')
    const [countryCode, setCountryCode] = useState('')
    const [isErr, setIsErr] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const history = useHistory()
    const location = useLocation()
    const [checked, setChecked] = useState(false);
    const [forgetPassword, setForgetPassword] = useState(false);
    const [phone, setPhone] = useState("");
    const [phoneLogin, setPhoneLogin] = useState(true);
    const [redirect, setRedirect] = useState('')
    const handleChange = () => {
        setChecked(!checked);
    };


    const callPhoneLogin = () => {
        setPhoneLogin(true);
    }
    const clearPhoneLogin = () => {
        setPhoneLogin(false);
    }



    useEffect(() => {
        if (location.search.includes('type=unsubscribe')) {
            // setUnsbscribeLogin(true)
        }
        if (location.search.includes('redirect=pricing')) {
            setRedirect(Constants.Pricing)
        }
        const encryptedId = window.location.href.split('uid=')[1]
        // const queryParam = new URLSearchParams(location.search)
        // let encryptedId = queryParam.get('uid')
        //let id = 'U2FsdGVkX18frGHvGqF0bROw3JCIXSEzSqDvkrr84fBWPUuyoRv5+U4SRzs2Ys/W'
        //U2FsdGVkX18iqg596NdLHWz1pt14zXPG+ouYImPiikgUz5fAHor/QeNCNjt1+0hI
        // var encryptedPwd = CryptoJS.AES.encrypt('praveen@baetho.com,1234', secret).toString();

        if (encryptedId) {
            validateUser(encryptedId)
        }

    }, [])

    const validateUser = (encryptedId) => {

        setIsLoading(true);
        let data = {
            uid: encryptedId
        }
        // var bytes = CryptoJS.AES.decrypt(encryptedId, secret);
        // var uid = bytes.toString(CryptoJS.enc.Utf8);


        Axios.post(Constants.BASE_URL + "api/aggregator/validateUser", data, Constants.header).then((result) => {
            setIsLoading(false);

            if (result.data.status) {
                localStorage['userDetails'] = JSON.stringify(result.data.result)
                history.push(Constants.Composer)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Unable to fetch details.',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })
    }

    const submitForm = (e) => {

        e.preventDefault()
        if (email.trim() === "" || password.trim() === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Email/Phone and Password are Mandatory',
                showConfirmButton: false,
                timer: 2000
            })
            return
        }


        if (checked) {
            let data = {
                email: email,
                password: password
            }
            Axios.post(Constants.BASE_URL + "api/dashboard/login", data, Constants.header).then((result) => {

                if (result.data.result) {

                    //localStorage['userDetails'] = JSON.stringify(result.data.id)
                    //localStorage["rememberme"] = this.state.rememberme
                    const userId = result.data.id;
                    setIsLoading(true);
                    Axios.get(Constants.BASE_URL + `api/dashboard/userDetailsById?userId=${userId}`, Constants.header).then((result) => {
                        setIsLoading(false);
                        if (result.data.result) {

                            if (result.data.data.UserType === "Admin") {
                                history.push(Constants.ProfileUpdate)
                                localStorage['userDetails'] = JSON.stringify(result.data.data)
                            } else if (result.data.data.UserType === "Teacher") {
                                history.push(Constants.Projects)
                                localStorage['userDetails'] = JSON.stringify(result.data.data)
                            } else if (result.data.data.UserType === "Parent") {
                                history.push(Constants.InitialPath)
                                localStorage['userDetails'] = JSON.stringify(result.data.data)
                            } else if (result.data.data.UserType === "MarketingAdmin") {
                                history.push(Constants.Campaign)
                                localStorage['userDetails'] = JSON.stringify(result.data.data)
                            }

                            else {
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'error',
                                    title: 'Invalid User.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            }

                        } else {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'error',
                                title: 'Unable to fetch details.',
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }
                    })

                } else {
                    setIsLoading(false);
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Invalid Credentials',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            });
        } else {
            let valid = true
            let error = ""
            let data;
            var path = ''
            if (email.includes("@")) {
                path = "api/aggregator/emailLogin/";
                data = {
                    email: email,
                    password: password
                }
            } else {
                path = 'api/aggregator/phoneLogin/';

                if (countryCode.includes("+")) {
                    error = "<h4>Enter country code without '+' sign </h4>"
                    valid = false
                }

                if (countryCode.trim() === "" || countryCode === "" || countryCode === undefined) {
                    error = '<h4>Please specify country code.</h4>'
                    valid = false
                }

                data = {
                    phone: email,
                    countryCode: "+" + countryCode,
                    password: password
                }
            }

            if (!valid) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    html: error,
                    showConfirmButton: false,
                    timer: 2000
                })
                return
            } else {
                setIsLoading(true);
                Axios.post(Constants.BASE_URL + path, data).then((result) => {
                    // console.log("result", result);
                    if (result.data.result) {

                        //localStorage['userDetails'] = JSON.stringify(result.data.id)
                        //localStorage["rememberme"] = this.state.rememberme
                        const userId = result.data.id;

                        Axios.get(Constants.BASE_URL + `api/dashboard/getParentsDetails?userId=${userId}`, Constants.header).then((result) => {
                            setIsLoading(false);
                            if (result.data.result) {

                                if (result.data.data.UserType === "Admin") {
                                    history.push(Constants.ProfileUpdate)
                                    localStorage['userDetails'] = JSON.stringify(result.data.data)
                                } else if (result.data.data.UserType === "Teacher") {
                                    history.push(Constants.Projects)
                                    localStorage['userDetails'] = JSON.stringify(result.data.data)
                                } else if (result.data.data.UserType === "Parent") {
                                    if (redirect === '') {
                                        history.push(Constants.InitialPath)
                                    } else {
                                        history.push(redirect)
                                    }
                                    localStorage['userDetails'] = JSON.stringify(result.data.data)
                                } else if (result.data.data.UserType === "MarketingAdmin") {
                                    history.push(Constants.Campaign)
                                    localStorage['userDetails'] = JSON.stringify(result.data.data)
                                }

                                else {
                                    Swal.fire({
                                        position: 'top-end',
                                        icon: 'error',
                                        title: 'Invalid User.',
                                        showConfirmButton: false,
                                        timer: 1500
                                    })
                                }

                            } else {
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'error',
                                    title: 'Unable to fetch details.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            }
                        })

                    } else {
                        setIsLoading(false);
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'Invalid Credentials',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                });
            }
        }


    }


    const handleUnsubscribeLogin = (e) => {
        e.preventDefault()

        if (emailOrPhone.trim() === "" || pass.trim() === "") {
            setIsErr(true)
            setErrMsg("Email or password are mandatory")
            return
        }
        if (!emailOrPhone.includes('@')) {
            if (countryCode.trim() === "") {
                setIsErr(true)
                setErrMsg("Please enter country code.")
                return
            }
        }

        var endpoint = ''
        var loginData = ""
        if (emailOrPhone.includes('@')) {
            endpoint = 'api/aggregator/emailLogin'

            let loginData = {

            }
        } else {
            endpoint = 'api/aggregator/phoneLogin'


            loginData = {
                phone: emailOrPhone,
                countryCode: countryCode,
                password: pass
            }

        }
        setIsLoading(true)

        Axios.post(Constants.BASE_URL + endpoint, loginData, Constants.header).then((result) => {

            if (result.data.result) {


                const userId = result.data.id;
                Axios.get(Constants.BASE_URL + `api/aggregator/userDetailsById?userId=${userId}`, Constants.header).then((result) => {
                    setIsLoading(false);
                    if (result.data.result) {


                        history.push(Constants.UnsubscribeEmails)
                        localStorage['userDetails'] = JSON.stringify(result.data.data)

                        // else {
                        //     Swal.fire({
                        //         position: 'top-end',
                        //         icon: 'error',
                        //         title: 'Invalid User.',
                        //         showConfirmButton: false,
                        //         timer: 1500
                        //     })
                        // }

                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'Unable to fetch details.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                })

                // localStorage['userDetails'] = JSON.stringify(result.data.result)
                // history.push(Constants.Composer)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Invalid Credentials.',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })


    }


    return (
        <>
            <div>
                {!unsubscribeLogin ?
                    (<div className="mainBG">
                        {isLoading ? (<LoaderComp isLoading={isLoading} />) : null}
                        <Navbar />
                        <div className="login-form-container">
                            <div className="login-form">
                                <form onSubmit={submitForm}>
                                    <h2 className="title">Login</h2>
                                    {
                                        (!phoneLogin) ?
                                            <div className="d-flex flex-column">
                                                <label className="login-label">Email</label>
                                                <input
                                                    type="email"
                                                    className='input-field'
                                                    required onChange={(e) => { setEmail(e.target.value) }}
                                                    placeholder="Enter your email"
                                                />
                                                <p className="login-type-info align-self-end" onClick={callPhoneLogin}> Try phone instead  </p>
                                            </div>
                                            :
                                            <div>
                                                {/* <div>
                                <PhoneInput
                                    country={"eg"}
                                    enableSearch={true}
                                    value={phone}
                                    onChange={(phone) => setPhone(phone)}
                                    />
                                </div> */}
                                                <div className="d-flex flex-column">
                                                    <label className="login-label input-phone">Phone number with country code</label>
                                                    <div className="d-flex gap-1">
                                                        <input
                                                            type="number"
                                                            className='input-field w-25'
                                                            required onChange={(e) => { setCountryCode(e.target.value) }}
                                                            placeholder="91"
                                                        />
                                                        <input
                                                            type="number"
                                                            className='input-field'
                                                            required onChange={(e) => { setEmail(e.target.value) }}
                                                            placeholder="Please Enter your phone number"
                                                        />
                                                    </div>
                                                    <p className="login-type-info ms-auto" onClick={clearPhoneLogin}> Try email instead  </p>
                                                </div>
                                            </div>

                                    }
                                    <div>
                                        <label className="login-label">Password</label>
                                        <input
                                            type="password"
                                            className='input-field'
                                            required onChange={(e) => { setPassword(e.target.value) }}
                                            placeholder="Enter your password"
                                        />
                                    </div>
                                    <div className="d-flex justify-content-between text-light">
                                        <div className="d-flex align-items-center gap-2 mb-2">
                                            <input id="teacherCheckbox" className="m-0" type="checkbox" checked={checked} onChange={handleChange} />
                                            <label className="m-0" htmlFor="teacherCheckbox">
                                                Teacher Login
                                            </label>

                                        </div>
                                        <Link to={Constants.ResetPassword} className="login-type-info">Forgot Password?  </Link>
                                    </div>
                                    <button type='submit' className='submit-btn'>Submit</button>
                                </form>
                                {/* <div className="links">
                    <Link to='#'>Forgot Password</Link>
                    <Link to='#'>Forgot Password</Link>
                </div> */}
                            </div>
                        </div>
                    </div>)
                    : (
                        <div className="container-fluid unsubscribe-bg-blue">
                            <div className="row justify-content-center pt-5 ">
                                <div className="d-flex justify-content-center align-items-center gap-1 text-center ">
                                    <img src={BaethoLogo} className="img-fluid" width="40px" height="40px" />
                                    <h4 className="fw-bold">Baetho</h4>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-10 col-10 mt-5 unsubscribe-login-wrap p-5 rounded">
                                    <h3 className="mb-4 text-center ">Login</h3>
                                    <form onSubmit={handleUnsubscribeLogin}>
                                        <div>
                                            <div className="form-group mb-4  ">
                                                <label htmlFor="exampleCountryCode">Country Code</label>
                                                <input
                                                    type="text"
                                                    className="form-control w-25"
                                                    id="exampleCountryCode"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Ex.+91"
                                                    onChange={(e) => setCountryCode(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group mb-4 ">
                                                <label htmlFor="exampleInputEmail1">Email or Phone number</label>
                                                <input
                                                    required
                                                    type="text"
                                                    className="form-control"
                                                    id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Email or phone number"
                                                    onChange={(e) => setEmailOrPhone(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group mb-4">
                                            <label htmlFor="exampleInputPassword1">Password</label>
                                            <input
                                                required
                                                type="password"
                                                className="form-control"
                                                id="exampleInputPassword1"
                                                placeholder="Password"
                                                onChange={(e) => setPass(e.target.value)}
                                            />
                                        </div>

                                        <button type="submit" className="btn btn-primary">Submit</button>

                                    </form>
                                    {isErr && (<small id="emailHelp" className="form-text text-danger">{errMsg}</small>)}
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </>

    )
}

export default Login;