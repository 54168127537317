import Accordion from 'react-bootstrap/Accordion';
import Actions from '../commons/actions';
import Basic from '../commons/basic';

function ToggleButton() {


    let featureProperties = [

        {
            name: 'Up Image',
            desc: 'This property allows you to set an image for a button.'
        },
        {
            name: 'Down Image',
            desc: 'This property allows you to set an image for a button when it is selected.'
        },
        {
            name: 'Is Selected',
            desc: 'Toggle button opens with the down state if this property is checked. It indicates whether the button is selected or not.'
        },
        {
            name: 'Label',
            desc: 'This is the text displayed on the button.'
        },
        {
            name: 'Font',
            desc: 'Using this property we can select the font for the text.'
        },
        {
            name: 'Size',
            desc: 'Using this property we can set the font size of the text.'
        },
        {
            name: 'Font Color',
            desc: 'This property sets the font color.'
        },
        {
            name: 'Background Color',
            desc: 'This property sets the background color of the button.'
        },
        {
            name: 'Background Radius',
            desc: 'This property allows to curve the edges.'
        },
        {
            name: 'Outline Thickness',
            desc: 'Using this property we can set the thickness of the outline.'
        },
        {
            name: 'Outline Color',
            desc: 'This property allows to choose a separate color for the outline.'
        },
        {
            name: 'Bold',
            desc: 'Text will be bold when this property is set to true.'
        },
        {
            name: 'Italic',
            desc: 'Text will be italicized when this property is set to true.'
        },
        {
            name: 'Horizontal Align',
            desc: 'This property aligns the text in the button horizontally. We can align left, right and center.'
        },
        {
            name: 'Vertical Align',
            desc: 'This property aligns the text in the button vertically. We can align it to Top, Bottom, and Center.'
        },
        {
            name: 'Scale When Down',
            desc: 'The button renders at this scale in the down state. 0.1 is the least and 1 is the maximum scaling rate.'
        },

    ]

    let events = [
        {
            name: "Touch",
            params: [
                {
                    paramName: "Tap",
                    paramDesc: "This trigger executes its actions when the user taps on this feature."
                },
                {
                    paramName: "Double Tap",
                    paramDesc: "This trigger executes its actions when the user double taps on this feature."
                },
                {
                    paramName: "Long Press",
                    paramDesc: "This trigger executes its actions when the user taps and holds the feature for sometime."
                },
                // {
                //   paramName: "Pressed",
                //   paramDesc: "This trigger executes its actions when the user has pressed down on this feature."
                // },
                // {
                //   paramName: "Released",
                //   paramDesc: " This trigger executes its actions when the user has pressed down and released this feature."
                // },
            ]
        },
        {
            event: "Swipe",
            name: "Swipe",
            desc: "This trigger executes its actions when the user swipes on the feature in the direction specified.",
            params: [
                {
                    paramName: "Swipe Type",
                    paramDesc: "Using this trigger the user can specify the direction for swipe."
                },
                {
                    paramName: "Key-Values",
                    paramDesc: "This is an optional parameter, we can set the length of the swipe into a key-value."
                },
            ]
        },
        {
            name: "Button Triggers",
            params: [
                // {
                //   paramName: "Up",
                //   paramDesc: "This trigger executes its actions when the user pressed the button enters to up state."
                // },
                // {
                //   paramName: "Down",
                //   paramDesc: "This trigger executes its actions when the user pressed the button enters to down state."
                // },
                {
                    paramName: "Selected",
                    paramDesc: "This trigger executes its actions when the button is selected."
                },
                {
                    paramName: "Deselected",
                    paramDesc: "This trigger executes its actions when the button is deselected."
                },
            ]
        },
    ]

    let actions = [

        {
            actionName: "Button",
            action: [
                {
                    name: "Change Up Image",
                    desc: "This action changes the up image of this feature to the asset specified.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Image Asset",
                            paramDesc: "This is the target asset."
                        },
                    ]
                },
                {
                    name: "Change Down Image",
                    desc: "This action changes the down image of this feature to the asset specified.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Image Asset",
                            paramDesc: "This is the target asset."
                        },
                    ]
                },
                {
                    name: "Change State",
                    desc: "This action changes the state of the toggle button to the specified state.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Button State",
                            paramDesc: "This is the target state."
                        },
                        {
                            paramName: "Perform Action",
                            paramDesc: "If this is true, the specified action will be performed."
                        },
                    ]
                },
                {
                    name: "Change Label",
                    desc: "This action changes the label of this feature to the value specified.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Label",
                            paramDesc: "This is the target value."
                        },
                    ]
                },

            ]
        }

    ]



    return (
        <div className="content-main">
            <h2 className="title">Toggle Button Feature</h2>
            <h3 className="sub-title">Description</h3>
            <p>A toggle button is like an on/off switch. It can be either on/off and remains in that state until the child clicks on it.</p>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><h3 className="sub-title">Properties</h3></Accordion.Header>
                    <Accordion.Body>
                        <Basic />

                        <h3 className="sub-title-2">Toggle Button Properties</h3>
                        <ul className="property-list">
                            {
                                featureProperties.map((element, index) => {
                                    return (
                                        <li>
                                            <span className="property">{element.name} : </span> {element.desc}
                                        </li>
                                    )
                                })
                            }
                        </ul>

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><h3 className="sub-title">Triggers</h3></Accordion.Header>
                    <Accordion.Body>
                        <ul className="property-list">
                            {
                                events.map((element, index) => {
                                    return (
                                        <>
                                            <span className="property">{element.event}</span>
                                            <li className="single-list">
                                                <span className="property">{element.name} : </span>{element.desc}
                                                <ul>
                                                    {
                                                        element.params ?
                                                            element.params.map(param => (
                                                                <li><span className="property">{param.paramName} : </span>{param.paramDesc}</li>
                                                            ))
                                                            : ""
                                                    }
                                                </ul>
                                            </li>
                                        </>
                                    )
                                })
                            }
                        </ul>

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><h3 className="sub-title">Actions</h3></Accordion.Header>
                    <Accordion.Body>
                        <Actions />
                        <ul className="property-list">
                            {
                                actions.map((element, index) => {
                                    return (
                                        <>
                                            <span className="action-name property">{element.actionName} </span>
                                            <ul className="mb-4">
                                                {element.action ?
                                                    element.action.map((action) => (
                                                        <li>
                                                            <span className="property">{action.name} : </span>{action.desc}
                                                            <ul>
                                                                {
                                                                    action.params ?
                                                                        action.params.map(param => (
                                                                            <li><span className="property">{param.paramName} : </span>{param.paramDesc}</li>
                                                                        ))
                                                                        : ""
                                                                }
                                                            </ul>
                                                        </li>
                                                    )) : ""
                                                }
                                            </ul>
                                        </>

                                    )
                                })
                            }
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

        </div>
    );
}
export default ToggleButton;
