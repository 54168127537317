import { useEffect, useState } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import Background from "../background/background";
import LeftPanel from "../leftPanel/leftPanel";
import * as Constants from "../../../assets/helper/constants";
import Image from "../pages/image";
import Label from "../pages/label";
import TextInput from "../pages/textInput";
import ToggleButton from "../pages/toggleButton";
import Video from "../pages/video";
import Gif from "../pages/gif";
import Button from "../pages/Button";
import Lesson from "../pages/lesson";
import Scene from "../pages/scene";
import Checkbox from "../pages/checkbox";
import RadioGroup from "../pages/radioGroup";
import RadioButton from "../pages/radioButton";
import KeyValues from "../pages/key-values";
import '../../../assets/css/documentation.css'
import video from "../../../assets/images/help/FinalWebsite.mp4";
import BaethoLogo from "../../../assets/images/help/Baetho_Logo.png";

import Icon1 from "../../../assets/images/help/Icon-1.png";
import Icon2 from "../../../assets/images/help/Icon-2.png";
import Icon3 from "../../../assets/images/help/Icon-3.png";
import Icon4 from "../../../assets/images/help/Icon-4.png";
import Icon5 from "../../../assets/images/help/Icon-5.png";
import Icon6 from "../../../assets/images/help/Icon-6.png";
import Icon7 from "../../../assets/images/help/Icon-7.png";
import bottomLeft from "../../../assets/images/help/bottom-left.png";
import bottomRight from "../../../assets/images/help/bottom-right.png";
import topLeft from "../../../assets/images/help/top-left.png";
import topRight from "../../../assets/images/help/top-right.png";

function Main() {
    const [active, setActive] = useState("");
    let location = useLocation();
    let history = useHistory();

    useEffect(() => {
        if (localStorage.getItem('userDetails') === null) {
            history.push('/')
        }
        checkPath(location.pathname.split('/')[1])
    }, [])

    const checkPath = (path) => {
        setActive(path);
    };

    return (

        <div className="container-fluid ">
            <div className="docs-logo-wrap">
                <img src={BaethoLogo} alt="Logo" />
            </div>
            <div className="row">
                <div className="col-12 px-0">
                    <div className="video-bg">
                        <video autoPlay muted loop className="myVideo">
                            <source src={video} type="video/mp4" />
                        </video>
                    </div>
                </div>
                <div className="col-12">
                    <div className="wave-1"></div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 px-0">
                    <div className="content-container">

                        <div className="row">
                            <div className="col-12 col-sm-4 col-md-2 col-lg-3 col-xl-2 px-0">
                                <LeftPanel activeLink={active} checkPath={checkPath} />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-10  px-3 icon-wrap">
                                <img className="icon icon-1" src={Icon1} alt="icon" />
                                <img className="icon icon-2" src={Icon2} alt="icon" />
                                <img className="icon icon-3" src={Icon3} alt="icon" />
                                <img className="icon icon-4" src={Icon4} alt="icon" />
                                <img className="icon icon-5" src={Icon5} alt="icon" />
                                <img className="icon icon-6" src={Icon6} alt="icon" />
                                <img className="icon icon-7" src={Icon7} alt="icon" />
                                <img className="icon icon-8 shake" src={topLeft} alt="icon" />
                                <img className="icon icon-9 shake" src={topRight} alt="icon" />
                                <img className="icon icon-10 shake" src={bottomLeft} alt="icon" />
                                <img className="icon icon-11 shake" src={bottomRight} alt="icon" />
                                <Route exact path={Constants.Lesson} component={() => <Lesson />} />
                                <Route exact path={Constants.Scene} component={() => <Scene />} />
                                <Route exact path={Constants.ImageFeature} component={() => <Image />} />
                                <Route exact path={Constants.LabelFeature} component={() => <Label />} />
                                <Route exact path={Constants.TextInputFeature} component={() => <TextInput />} />
                                <Route exact path={Constants.ButtonFeature} component={() => <Button />} />
                                <Route exact path={Constants.ToggleButtonFeature} component={() => <ToggleButton />} />
                                <Route exact path={Constants.VideoFeature} component={() => <Video />} />
                                <Route exact path={Constants.GifFeature} component={() => <Gif />} />
                                <Route exact path={Constants.CheckboxFeature} component={() => <Checkbox />} />
                                <Route exact path={Constants.RadioGroupFeature} component={() => <RadioGroup />} />
                                <Route exact path={Constants.RadioButtonFeature} component={() => <RadioButton />} />
                                <Route exact path={Constants.KeyValues} component={() => <KeyValues />} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="row">
                    <div className="col-md-2 col-sm-4 col-12 left-container">
                        <LeftPanel activeLink={active} checkPath={checkPath} />
                    </div>
                    <div className="col-md-10 col-sm-8 col-12 pr ">
                        <Route exact path={Constants.Lesson} component={() => <Lesson />} />
                        <Route exact path={Constants.Scene} component={() => <Scene />} />
                        <Route exact path={Constants.ImageFeature} component={() => <Image />} />
                        <Route exact path={Constants.LabelFeature} component={() => <Label />} />
                        <Route exact path={Constants.TextInputFeature} component={() => <TextInput />} />
                        <Route exact path={Constants.ButtonFeature} component={() => <Button />} />
                        <Route exact path={Constants.ToggleButtonFeature} component={() => <ToggleButton />} />
                        <Route exact path={Constants.VideoFeature} component={() => <Video />} />
                        <Route exact path={Constants.GifFeature} component={() => <Gif />} />
                        <Route exact path={Constants.CheckboxFeature} component={() => <Checkbox />} />
                        <Route exact path={Constants.RadioGroupFeature} component={() => <RadioGroup />} />
                        <Route exact path={Constants.RadioButtonFeature} component={() => <RadioButton />} />
                        <Route exact path={Constants.KeyValues} component={() => <KeyValues />} />
                    </div>
                </div> */}
        </div>

    );
}
export default Main;
