import Accordion from 'react-bootstrap/Accordion';
import Actions from '../commons/actions';
import Basic from '../commons/basic';
import Interaction from '../commons/interaction';
import Triggers from '../commons/triggers';


function Image() {





    let featureProperties = [
        {
            name: 'Image',
            desc: `This property specifies which image from the media library will be shown.`
        },
        {
            name: 'Fill Behavior',
            desc: `When the aspect ratio of the image differs from that of the feature, fill behaviour describes 
      how the image will be shown. Fit will display the complete image while maintaining the image's aspect ratio. 
      Fill will maintain the picture's aspect ratio while allowing for some of the image to be beyond the feature's 
      dimension and therefore invisible. Additionally, it will take up the entire space of the feature. 
      Deform will alter the image's aspect ratio to conform to the features' proportions.`
        },
    ]





    let actions = [
        {
            actionName: "Interaction",
            action: [
                {
                    name: "Change Movement Type",
                    desc: "This action changes the feature’s movement in the scene. It can be set to Free, Vertical, Horizontal or none.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Movement Type",
                            paramDesc: "This is the target movement type."
                        }
                    ]
                },
            ]
        },
        {
            actionName: "Image",
            action: [
                {
                    name: "Change Image",
                    desc: "This action changes the image that this feature displays.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Image Asset",
                            paramDesc: "This is the target image asset to switch to."
                        },
                        {
                            paramName: "Transition",
                            paramDesc: "These are the effects that can be added to transition from one image to another."
                        },
                        {
                            paramName: "Effect",
                            paramDesc: "This option allows you to change the way a transition effect behaves."
                        },
                        {
                            paramName: "Duration",
                            paramDesc: "This specifies how long the transition should last."
                        },
                    ]
                },
            ]
        }

    ]


    return (
        <div className="content-main">
            <h2 className="title">Image Feature</h2>
            <h3 className="sub-title">Description</h3>
            <p>This is a feature that displays an image on the scene.</p>

            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header> <h3 className="sub-title">Properties</h3></Accordion.Header>
                    <Accordion.Body>
                        <Basic />
                        <Interaction />

                        <h4 className="sub-title-2">Image Properties</h4>
                        <ul className="property-list">

                            {
                                featureProperties.map((element, index) => {
                                    return (
                                        <li>
                                            <span className="property">{element.name} : </span> {element.desc}
                                        </li>
                                    )
                                })
                            }

                        </ul>

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><h3 className="sub-title">Triggers</h3></Accordion.Header>
                    <Accordion.Body>
                        <Triggers />

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><h3 className="sub-title">Actions</h3></Accordion.Header>
                    <Accordion.Body>

                        <Actions />

                        <ul className="property-list">
                            {
                                actions.map((element, index) => {
                                    return (
                                        <>
                                            <span className="action-name property">{element.actionName} </span>
                                            <ul className="mb-4">
                                                {element.action ?
                                                    element.action.map((action) => (
                                                        <li>
                                                            <span className="property">{action.name} : </span>{action.desc}
                                                            <ul>
                                                                {
                                                                    action.params ?
                                                                        action.params.map(param => (
                                                                            <li><span className="property">{param.paramName} : </span>{param.paramDesc}</li>
                                                                        ))
                                                                        : ""
                                                                }
                                                            </ul>
                                                        </li>
                                                    )) : ""
                                                }
                                            </ul>
                                        </>

                                    )
                                })
                            }
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

        </div>
    );
}
export default Image;
