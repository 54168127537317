import { useEffect, useState } from "react";
import Axios from 'axios'
import Swal from 'sweetalert2';
import * as Constants from '../../../assets/helper/constants'

function Retention(props) {

    const [registered, setRegistered] = useState('')
    const [played, setPlayed] = useState('')
    const [playedData, setPlayedData] = useState([])
    const [registeredPerMonth, setRegisteredPerMonth] = useState({})
    const [cohortData, setCohortData] = useState([]);

    let month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    // let mergedArray = []

    useEffect(() => {
        getRetentionData(0)
    }, [])


    const getRetentionData = async (value) => {


        Axios.get(Constants.BASE_URL + `api/dashboard/getRetentionData?os=${value}`).then(async (result) => {
            if (result.data.status) {
                setRegistered(result.data.registered)
                setPlayed(result.data.played)


                // let registeredUsers = {}

                /**Count total number of users registered 
                 * in a specific month
                 * ex: {6: 20, 7:25} =={registeredMonth: totalUsersRegistered}
                 */
                // for (const obj of result.data.registered) {
                //     const month = obj.month;
                //     if (registeredUsers[month]) {
                //         registeredUsers[month]++;
                //     } else {
                //         registeredUsers[month] = 1;
                //     }
                // }
                // setRegisteredPerMonth(registeredUsers)

                /**
                 * Merge registered array and played array
                 */
                // let mergedArray = []
                // result.data.registered.map(reg => {
                //     result.data.played.map(played => {
                //         if (reg.parentId === played.Parents) {
                //             let obj = {
                //                 parentId: reg.parentId,
                //                 kidId: played.User_Id,
                //                 registeredMonth: reg.month,
                //                 playedMonth: played.month
                //             }
                //             mergedArray.push(obj)
                //         }
                //     })
                // })
                // setPlayedData(mergedArray)
                // console.log('data', mergedArray)
                // -------------------------------

                let cohortResult = await performCohortAnalysis(result.data.registered, result.data.played)
                setCohortData(cohortResult);

            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to fetch data.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    /** 
    Returns total number of users registered 
    & played(used the app) in specified month
    @param1 reg: registeredMonth
    @param2 played: playedMonth(month of app usage)*/
    // const calculate = (reg, played) => {
    //     let count = 0
    //     playedData.map(obj => {

    //         if (obj.registeredMonth === reg && obj.playedMonth === played) {
    //             count++
    //         }
    //     })

    //     return count
    // }


    const performCohortAnalysis = async (registeredObjects, playedObjects) => {

        const registeredCohorts = {};
        const playedCohorts = {};

        // Step 1: Group registered objects by registration month and year
        for (const regObj of registeredObjects) {
            const { registeredMonth, registeredYear, parentId } = regObj;
            const cohortKey = `${registeredMonth}/${registeredYear}`;
            if (!registeredCohorts[cohortKey]) {
                registeredCohorts[cohortKey] = [];
            }
            registeredCohorts[cohortKey].push(parentId);
        }

        // Step 2: Group played objects by played month and year
        for (const playObj of playedObjects) {
            const { playedMonth, playedYear, parentId } = playObj;
            const cohortKey = `${playedMonth}/${playedYear}`;
            if (!playedCohorts[cohortKey]) {
                playedCohorts[cohortKey] = [];
            }
            playedCohorts[cohortKey].push(parentId);
        }

        // Step 3: Perform cohort analysis
        const result = [];
        for (const regCohort in registeredCohorts) {
            if (registeredCohorts.hasOwnProperty(regCohort)) {
                const [regMonth, regYear] = regCohort.split('/').map(Number);
                const totalRegistered = registeredCohorts[regCohort].length;
                const cohortRow = {
                    cohort: regCohort,
                    totalRegistered,
                    percentages: [],
                };

                for (let i = 1; i <= 12; i++) { // Assuming analysis for 1 year from registration
                    const playedMonth = (regMonth + i) % 12 || 12;
                    const playedYear = regYear + Math.floor((regMonth + i - 1) / 12);
                    const playedCohortKey = `${playedMonth}/${playedYear}`;
                    const playedUsers = playedCohorts[playedCohortKey] || [];
                    const playedCount = registeredCohorts[regCohort].filter(parentId =>
                        playedUsers.includes(parentId)
                    ).length;
                    const percentagePlayed = (playedCount / totalRegistered) * 100;
                    cohortRow.percentages.push(percentagePlayed.toFixed(2));
                }

                result.push(cohortRow);
            }
        }

        return result
    };


    return (
        <div className="right-panel-container">
            <div className="container-fluid">

                {/* <div className="row mb-4">
                    <div className="col-12">
                        <Header section="Retention Analysis" logout={props.logout} userName="Marketing Admin" />
                    </div>
                </div> */}
                <div className="row mb-4">
                    <div className="col-12">
                        <h2 className="section-name">Retention Analysis</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="bg-white p-4 boxShadow">
                            <div className="row">
                                <div className="col-3">
                                    <select className="form-select" defaultValue={0} onChange={(e) => getRetentionData(e.target.value)} aria-label="Default select example">
                                        <option value="0">Android</option>
                                        <option value="1">IOS</option>
                                    </select>
                                </div>
                            </div>
                            <hr></hr>

                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Cohort</th>
                                        <th>New users</th>
                                        {month.map((val, index) => {
                                            return (
                                                <th>M{val}</th>
                                            )
                                        })}
                                        {/* {cohortData.map(cohortRow => (
                                            <>
                                                {cohortRow.percentages.map((percentage, index) => (
                                                    <th key={index}>{'M' + (index + 1)}</th>
                                                ))}
                                            </>
                                        ))} */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {console.log('data', cohortData)} */}
                                    {cohortData.map(cohortRow => (
                                        <tr key={cohortRow.cohort}>
                                            <td>{cohortRow.cohort}</td>
                                            <td>{cohortRow.totalRegistered}</td>
                                            {cohortRow.percentages.map((percentage, index) => (
                                                <td key={index}>{percentage > 0 && percentage + '%'}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            {/* <table className="table table-striped ">
                                <thead>
                                    <tr>
                                        <th scope="col">Cohort</th>
                                        <th scope="col">New Users</th>
                                        <th scope="col">M1 %</th>
                                        <th scope="col">M2 %</th>
                                        <th scope="col">M3 %</th>
                                        <th scope="col">M4 %</th>
                                        <th scope="col">M5 %</th>
                                        <th scope="col">M6 %</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">June</th>
                                        <td>{registeredPerMonth[6] ? registeredPerMonth[6] : 0}</td>
                                        <td>
                                            {(calculate(6, 7) / (registeredPerMonth[6]) * 100).toFixed(2) > 0
                                                ?
                                                (calculate(6, 7) / (registeredPerMonth[6]) * 100).toFixed(2)
                                                : 0}
                                        </td>
                                        <td>
                                            {(calculate(6, 8) / (registeredPerMonth[6]) * 100).toFixed(2) > 0
                                                ?
                                                (calculate(6, 8) / (registeredPerMonth[6]) * 100).toFixed(2)
                                                : 0}
                                        </td>
                                        <td>
                                            {(calculate(6, 9) / (registeredPerMonth[6]) * 100).toFixed(2) > 0
                                                ?
                                                (calculate(6, 9) / (registeredPerMonth[6]) * 100).toFixed(2) :
                                                0}
                                        </td>
                                        <td>
                                            {(calculate(6, 10) / (registeredPerMonth[6]) * 100).toFixed(2) > 0
                                                ?
                                                (calculate(6, 10) / (registeredPerMonth[6]) * 100).toFixed(2)
                                                : 0}
                                        </td>
                                        <td>
                                            {(calculate(6, 11) / (registeredPerMonth[6]) * 110).toFixed(2) > 0
                                                ?
                                                (calculate(6, 11) / (registeredPerMonth[6]) * 100).toFixed(2) :
                                                0}
                                        </td>
                                        <td>
                                            {(calculate(6, 12) / (registeredPerMonth[6]) * 110).toFixed(2) > 0
                                                ?
                                                (calculate(6, 12) / (registeredPerMonth[6]) * 100).toFixed(2) :
                                                0}
                                        </td>

                                    </tr>

                                </tbody>
                            </table> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Retention;