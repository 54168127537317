import Axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import Header from "../../header/header";
import LoaderComp from "../../loader";
import * as Constants from '../../../assets/helper/constants'
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import DropOffPopup from "./dropOffPopup";



function Analytics(props) {
    const [teachers, setTeachers] = useState('')
    const [showLessons, setShowLessons] = useState(false)
    const [lessons, setLessons] = useState('')
    const [totalViews, setTotalViews] = useState('')
    const [totalCompletionRate, setTotalCompletionRate] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [dropOffs, setDropOffs] = useState('')
    const history = useHistory()

    const getTeachers = () => {
        setIsLoading(true)
        Axios.get(Constants.BASE_URL + `api/aggregator/getListOfTeachers`).then((response) => {
            setIsLoading(false)
            if (response.data.status) {
                setTeachers(response.data.result)
            } else {
                setTeachers([])
            }
        })
    }

    useEffect(() => {
        getTeachers()
    }, [])


    const customStyles = {
        headRow: {
            style: {
                // backgroundColor: '#fff',
            },
        },
        headCells: {
            style: {
                // color: '#777',
                fontSize: '16px',
                // fontWeight: '700',
                // paddingBottom: '20px',
                // paddingTop: '50px'
            },
        },
        rows: {
            style: {
                paddingTop: "10px",
                paddingBottom: "10px",
                // backgroundColor: '#fff',
                fontSize: '14px',
                // color: '#777',

            },
        },
        pagination: {
            style: {
                // border: 'none',
                // backgroundColor: '#fff',
                // fontSize: '14px'
            },
        },
    };
    const columns = [
        {
            name: 'Sl No.',
            selector: (row, index) => {
                return (
                    <div className="">
                        {index + 1}
                    </div>
                )
            }
        },
        {
            name: 'Teacher Name',
            wrap: true,
            left: true,
            selector: (row) => {
                return (
                    <div className="">
                        <span >{row.Name}</span>
                    </div>
                )
            }
        },
        {
            name: "Analytics",
            center: true,
            grow: 2,
            cell: row => {
                return (
                    <div className="actions">
                        <button className="approve-btn mr-1" type="button" onClick={() => viewLessons(row.Id)}>View</button>

                    </div>
                )
            }
        }

    ];

    const lessonsColumn = [
        {
            name: 'Sl No.',
            center: true,
            grow: 1,
            selector: (row, index) => {
                return (
                    <div className="">
                        {index + 1}
                    </div>
                )
            }
        },
        {
            name: 'Lesson Name',
            wrap: true,
            grow: 2,
            left: true,
            selector: (row) => {
                return (
                    <div className="">
                        <span >{row.Name}</span>
                    </div>
                )
            }
        },
        {
            name: 'Views',
            wrap: true,
            right: true,
            grow: 2,
            selector: (row) => {
                return (
                    <div className="">
                        <span >{row.TotalViews}</span>
                    </div>
                )
            }
        },
        {
            name: 'Completion Rate',
            wrap: true,
            right: true,
            selector: (row) => {
                return (
                    <div className="">
                        <span >{row.CompletionRate.toFixed(2)}%</span>
                    </div>
                )
            }
        },
        {
            name: 'Drop Offs',
            wrap: true,
            selector: (row) => {
                return (
                    <div className="">
                        <span onClick={() => togglePopup(row.DropOffs)}><button className="approve-btn">View</button></span>
                    </div>
                )
            }
        },

    ];


    const togglePopup = (dropOff) => {
        setShowPopup(!showPopup)

        const modifiedObject = {};
        for (const key in dropOff) {
            const [prefix, numericKey] = key.split(':');
            const newKey = numericKey ? parseInt(numericKey) : parseInt(key);

            if (prefix === 'CurrentView') {
                if (modifiedObject[newKey]) {
                    modifiedObject[newKey] += dropOff[key];
                } else {
                    modifiedObject[newKey] = dropOff[key];
                }
            } else {
                if (!modifiedObject[newKey]) {
                    modifiedObject[newKey] = dropOff[key];
                }
            }
        }
        setDropOffs(modifiedObject)
    }

    const viewLessons = (teacherId) => {
        // console.log('data', lessons)
        // history.push(Constants.Lessons)
        setIsLoading(true)
        setShowLessons(true)

        Axios.get(Constants.BASE_URL + `api/AggregatorLessons/getAnalyticsForTeacher?userId=${teacherId}`).then((response) => {
            setIsLoading(false)
            if (response.data.status) {
                setTotalViews(response.data.totalViews)
                setTotalCompletionRate(response.data.totalCompletionRate)
                setLessons(response.data.result)

            } else {
                setLessons([])
                console.log(response.data.error)
            }
        })
    }



    return (
        <div className="right-panel-container ">
            <div className="container-fluid">
                <p className='section-name mb-4'>Analytics</p>
                {showPopup && <DropOffPopup show={showPopup} onHide={togglePopup} dropOff={dropOffs} />}
                {isLoading ? <LoaderComp isLoading={isLoading} /> : null}

                {!showLessons ? <div className="row">
                    <div className="col-12">
                        <div className="data-container-wrapper box-shadows d-flex flex-column justify-content-between">
                            <DataTable
                                columns={columns}
                                data={teachers}
                                pagination
                                customStyles={customStyles}
                                persistTableHead
                            />
                        </div>
                    </div>
                </div> :
                    <>
                        <div className="row">
                            <div className="col-md-3 d-flex align-items-center mb-3">
                                <label className="back-btn" onClick={() => setShowLessons(false)}><i className="fa fa-chevron-left" aria-hidden="true"></i><span>Back</span></label>
                            </div>
                        </div>

                        <div className="row">
                            <div className=" col-12 ">
                                <div className="card border-0 box-shadows">
                                    <div className="card-header d-flex gap-3 justify-content-end bg-white">
                                        <div className="right-border px-4">
                                            <h6 className="">Total Views</h6>
                                            <p className="card-text">{totalViews}</p>
                                        </div>

                                        <div>
                                            <h6 className="">Total Completion Rate</h6>
                                            <p className="card-text">{totalCompletionRate.toFixed(2)}%</p>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="data-container-wrapper d-flex flex-column justify-content-between">
                                            <DataTable
                                                columns={lessonsColumn}
                                                data={lessons}
                                                pagination
                                                customStyles={customStyles}
                                                persistTableHead
                                                fixedHeader
                                                fixedHeaderScrollHeight="650px"
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </>
                }
            </div>
        </div >
    )
}

export default Analytics;