import { useEffect, useState } from "react";
import Header from "../../header/header";
import LoaderComp from "../../loader";
import Swal from 'sweetalert2';
import Axios from 'axios'
import * as Constants from '../../../assets/helper/constants'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Navbar from "../../home/navbar/navbar";

const Profile = (props) => {

    const [userInfo, setUserInfo] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory()
    useEffect(() => {
        // let userDetails = JSON.parse(localStorage.getItem("userDetails"))
        setUserInfo(props.userInfo)

    }, [])

    const deleteAccount = () => {

        Swal.fire({
            title: "Delete your account permanently?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(true)
                Axios.delete(Constants.BASE_URL + `api/aggregator/deleteUser?id=${userInfo.Id}`, Constants.header).then((result) => {
                    setIsLoading(false);
                    if (result.data.status) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: "Account deleted successfully.",
                            showConfirmButton: false,
                            timer: 2000
                        })
                        localStorage.removeItem('userDetails')
                        history.push('/login')
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: "Something went wrong. Try again.",
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }
                })
            }
        })


    }

    return (
        <div className="container-fluid profile-bg">
            <Navbar />
            <div className="container">
                {isLoading ? <LoaderComp isLoading={isLoading} /> : null}
                <div className="profile-container">
                    <h4 className="profile-title">Profile</h4>
                    <hr></hr>
                    <div className="row mb-2">
                        <div className="col-12">
                            <p className="mb-2"><span className="fw-600 me-2">Name:</span>{userInfo?.FirstName + ' ' + userInfo?.LastName}</p>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row mb-2">
                        <div className="col-md-12 ">
                            <p className="mb-2"><span className="fw-600 me-2">Email:</span>{userInfo?.Email}</p>

                        </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-md-12 ">
                            <p className="mb-2"><span className="fw-600 me-2">Country:</span>{userInfo?.Country}</p>
                            <p className=" fw-500 me-4"></p><p className=""></p>
                        </div>
                    </div>
                    <hr></hr>
                    <span type="button" className="text-blue" onClick={deleteAccount}>Delete account</span>
                </div>
            </div>

            {/* <div className="container-fluid">
                <p className='section-name mb-4'>Profile</p>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-6">
                                <p>Name</p>
                            </div>
                            <div className="col-6">
                                <p>{userInfo?.FirstName + ' ' + userInfo?.LastName}</p>
                            </div>
                        </div>
                        <hr className="mt-1 mb-3" />
                        <div className="row">
                            <div className="col-6">
                                <p>Email</p>
                            </div>
                            <div className="col-6">
                                <p>{userInfo?.Email}</p>
                            </div>
                        </div>
                        <hr className="mt-1 mb-3" />
                        <div className="row">
                            <div className="col-6">
                                <p>Delete my account</p>
                            </div>
                            <div className="col-6">
                                <button className="btn btn-danger" onClick={deleteAccount}>Delete Account</button>
                            </div>
                        </div>
                        <hr className="mt-2 mb-3" />
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default Profile;