import Accordion from 'react-bootstrap/Accordion';
import Actions from '../commons/actions';
import Basic from '../commons/basic';
function RadioButton() {

    let featureProperties = [

        {
            name: 'Dark Default Icons',
            desc: 'If this property is selected, color of the radio button is set to dark mode.'
        },
        {
            name: 'Unchecked Icon',
            desc: 'This property provides an option to select an unchecked icon used for the radio button.'
        },
        {
            name: 'Checked Icon',
            desc: 'This property provides an option to select a checked icon used for the radio button.'
        },
        {
            name: 'Label',
            desc: 'This is the text displayed for the radio button.'
        },
        {
            name: 'Font',
            desc: 'This property allows to select an available font from the list.'
        },
        {
            name: 'Size',
            desc: 'Using this property we can set the font size of the text.'
        },
        {
            name: 'Font Color',
            desc: 'This property sets the color of the text.'
        },
        {
            name: 'Bold',
            desc: 'Text will be bold when this property is enabled.'
        },
        {
            name: 'Italic',
            desc: 'Text will be italicized when this property is enabled.'
        },
        {
            name: 'Label Offset X',
            desc: 'Use this property to shift the label along the X-axis.'
        },
        {
            name: 'Label Offset Y',
            desc: 'Use this property to shift the label along the Y-axis.'
        },
        {
            name: 'Icon Width',
            desc: 'This property sets width of the icon.'
        },
        {
            name: 'Icon Height',
            desc: 'This property sets height of the icon.'
        },

    ]




    return (
        <div className="content-main">
            <h2 className="title">Radio Button Feature</h2>
            <h3 className="sub-title">Description</h3>
            <p>Radio buttons can only be used within a radio button group. This is used to create multiple choices where the child can choose only one from all options.</p>

            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><h3 className="sub-title">Properties</h3></Accordion.Header>
                    <Accordion.Body>
                        <Basic />

                        <h3 className="sub-title-2">Radio Button Properties</h3>
                        <ul className="property-list">
                            {
                                featureProperties.map((element, index) => {
                                    return (
                                        <li>
                                            <span className="property">{element.name} : </span> {element.desc}
                                        </li>
                                    )
                                })
                            }
                        </ul>

                    </Accordion.Body>
                </Accordion.Item>
                {/* <Accordion.Item eventKey="1">
                    <Accordion.Header><h3 className="sub-title">Triggers</h3></Accordion.Header>
                    <Accordion.Body>
                        
                    </Accordion.Body>
                </Accordion.Item> */}
                <Accordion.Item eventKey="2">
                    <Accordion.Header><h3 className="sub-title">Actions</h3></Accordion.Header>
                    <Accordion.Body>
                        <Actions />

                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

        </div>
    )

}
export default RadioButton;