
function PricingCard(props) {

    return (
        <>
            <div className='col-lg-3 col-md-6 col-sm-12'>
                <div className={props.name === "1 Year" ? 'pricing-card highlight' : 'pricing-card'}>
                    {props.name === "1 Year" && <div className='pricing-badge'><p>Most Popular</p></div>}
                    <h4>{props.name} Plan</h4>
                    <div className='d-flex gap-2 align-items-baseline flex-wrap'>
                        <h2>{props.currencySymbol}{props.amount.toFixed(2)}</h2>
                        {props.name !== "1 Week" ? <span className='strike-through'>{props.currencySymbol}{(props.amount * 2).toFixed(2)}</span>
                            : ''}
                    </div>
                    {props.name !== "1 Week" ? <div className='save-price'>Save {props.currencySymbol}{(props.amount).toFixed(2)}</div> : ''}
                    {
                        props.currency === 'INR' ?
                            <ul>
                                {
                                    props.name === "1 Year" ? <li>₹{(props.amount / 12).toFixed(2)} (<span className='strike-through'>₹676.00</span>) Per Month</li>
                                        : props.name === "6 Months" ? <li>₹{(props.amount / 6).toFixed(2)} (<span className='strike-through'>₹676.00</span>) Per Month</li>
                                            : props.name === "3 Months" ? <li>₹{(props.amount / 3).toFixed(2)} (<span className='strike-through'>₹676.00</span>) Per Month</li>
                                                : <li className="pt-2">₹676.00 Per Month</li>
                                }
                                {props.name !== "1 Week" ? <li>50% Discount</li> : <li>This is a calculation of your monthly cost with this plan.</li>}
                            </ul>
                            :
                            <ul>
                                {
                                    props.name === "1 Year" ? <li>${(props.amount / 12).toFixed(2)} (<span className='strike-through'>$8.00</span>) Per Month</li>
                                        : props.name === "6 Months" ? <li>${(props.amount / 6).toFixed(2)} (<span className='strike-through'>$8.00</span>) Per Month</li>
                                            : props.name === "3 Months" ? <li>${(props.amount / 3).toFixed(2)} (<span className='strike-through'>$8.00</span>) Per Month</li>
                                                : <li className="pt-2">$8.00 Per Month</li>
                                }
                                {props.name !== "1 Week" ? <li>50% Discount</li> : <li>This is a calculation of your monthly cost with this plan.</li>}
                            </ul>
                    }
                    <button className='btn-pricing' onClick={() => { props.createOrder(props.amount, props.currency, props.packageId) }}>
                        Select
                    </button>
                </div>
            </div>
        </>
    )
}

export default PricingCard;