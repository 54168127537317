import Modal from 'react-bootstrap/Modal';
import CreatableSelect from "react-select/creatable";

function CreatableEditField(props) {

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Update
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={props.updateHandle} className='p-2'>
                    <div className="form-group mb-4">
                        <label className='mb-2'>{props.label}</label>
                        {props.label === 'CampaignType' ?
                            <CreatableSelect
                                defaultValue={props.campaignTypeDropdown[props.campaignTypeDropdown.findIndex(obj => obj.value == props.individualCampaign.CampaignType)]}
                                name='CampaignType'
                                options={props.campaignTypeDropdown}
                                className="creatable-select"
                                onChange={(e) => props.handleChange(e, 'CampaignType')}
                                required
                            /> : props.label === 'ContentCategory' ?
                                <CreatableSelect
                                    defaultValue={props.contentCategoryDropdown[props.contentCategoryDropdown.findIndex(obj => obj.value == props.individualCampaign.ContentCategory)]}
                                    name='ContentCategory'
                                    options={props.contentCategoryDropdown}
                                    className="creatable-select"
                                    onChange={(e) => props.handleChange(e, 'ContentCategory')}
                                    required
                                /> : props.label === 'ContentType' ?
                                    <CreatableSelect
                                        defaultValue={props.contentTypeDropdown[props.contentTypeDropdown.findIndex(obj => obj.value == props.individualCampaign.ContentType)]}
                                        name='ContentType'
                                        options={props.contentTypeDropdown}
                                        className="creatable-select"
                                        onChange={(e) => props.handleChange(e, 'ContentType')}
                                        required
                                    /> : props.label === 'ViralityScore' ?
                                        <CreatableSelect
                                            defaultValue={props.viralityScoreDropdown[props.viralityScoreDropdown.findIndex(obj => obj.value == props.individualCampaign.ViralityScore)]}
                                            name='ViralityScore'
                                            options={props.viralityScoreDropdown}
                                            className="creatable-select"
                                            onChange={(e) => props.handleChange(e, 'ViralityScore')}
                                            required
                                        /> : props.label === 'HookScore' ?
                                            <CreatableSelect
                                                defaultValue={props.hookScoreDropdown[props.hookScoreDropdown.findIndex(obj => obj.value == props.individualCampaign.HookScore)]}
                                                name='HookScore'
                                                options={props.hookScoreDropdown}
                                                className="creatable-select"
                                                onChange={(e) => props.handleChange(e, 'HookScore')}
                                                required
                                            /> : props.label === 'Music' ?
                                                <CreatableSelect
                                                    defaultValue={props.musicDropdown[props.musicDropdown.findIndex(obj => obj.value == props.individualCampaign.Music)]}
                                                    name='Music'
                                                    options={props.musicDropdown}
                                                    className="creatable-select"
                                                    onChange={(e) => props.handleChange(e, 'Music')}
                                                    required
                                                /> : ''}
                        {/* <input type="text" name={props.label}
                            className="form-control mb-4"
                            value={props.updateValue}
                            onChange={props.handleChange} /> */}

                    </div>
                    <div className='w-100 text-center'>
                        <button className='btn bg-blue text-white'>Submit</button>
                    </div>
                </form>
            </Modal.Body>
            {/* <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer> */}
        </Modal>
    );
}

export default CreatableEditField