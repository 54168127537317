import DataTable from "react-data-table-component";
import Header from "../../header/header";
import { useEffect, useState } from "react";
import Axios from "axios";
import * as Constants from '../../../assets/helper/constants'
import Swal from "sweetalert2";
import EditField from "./editField";
import CreateCampaignPopup from "./createCampaignPopup";
import CreatableEditField from "./creatableEditField";
// import CreatableSelect from "react-select/creatable";

function Campaign(props) {

    const [showDetails, setShowDetails] = useState(false)
    const [allCampaigns, setAllCampaigns] = useState('')
    const [individualCampaign, setIndividualCampaign] = useState('')
    const [selectedId, setSelectedId] = useState('')
    // const [editMode, setEditMode] = useState({
    //     campaignName: false,
    // })

    const [modalShow, setModalShow] = useState(false);
    const [showCreateCampaign, setShowCreateCampaign] = useState(false);

    const [label, setLabel] = useState('');
    const [updatedValue, setUpdatedValue] = useState('');

    const [enteredData, setEnteredData] = useState({})

    //stores dropdown data list
    const [campaignTypeDropdown, setCampaignTypeDropdown] = useState([])
    const [contentCategoryDropdown, setContentCategoryDropdown] = useState([])
    const [contentTypeDropdown, setContentTypeDropdown] = useState([])
    const [viralityScoreDropdown, setViralityScoreDropdown] = useState([])
    const [hookScoreDropdown, setHookScoreDropdown] = useState([])
    const [musicDropdown, setMusicDropdown] = useState([])


    const [showCreatableEditPopup, setShowCreatableEditPopup] = useState(false)

    const [totalClicks, setTotalClicks] = useState(0)
    const [cpc, setCPC] = useState(0)
    const [registeres, setRegisteres] = useState(0)
    const [cac, setCAC] = useState(0)

    // const [showDetails, setShowDetails] = useState(false)
    // const [campaignData, setCampaignData] = useState(false)

    // const [editMode, setEditMode] = useState({
    //     campaignName: false,
    // });


    useEffect(() => {
        getCampainDetails()
    }, [])



    //  console.log('indicampain', campaignTypeDropdown[campaignTypeDropdown.map(function (obj) { return obj.value; }).indexOf(individualCampaign.CampaignType)], campaignTypeDropdown.indexOf(individualCampaign.CampaignType))
    const getCampainDetails = () => {
        Axios.get(Constants.BASE_URL + 'api/dashboard/getAllCampaignDetails').then((result) => {
            if (result.data.status) {

                setAllCampaigns(result.data.result)

                //setDropdownData(result.data.dropdownList)
                let options = []
                result.data.dropdownList.map((obj) => {
                    if (obj.ColumnType === 'Campaign Type') {

                        let list = obj.Value.split(',')

                        list.map((ele) => {
                            let object = {
                                value: ele.trim(),
                                label: ele.trim()
                            }
                            options.push(object)
                        })
                        setCampaignTypeDropdown(options)

                    }

                    if (obj.ColumnType === 'Content Category') {
                        options = []
                        let list = obj.Value.split(',')
                        list.map((ele) => {
                            let object = {
                                value: ele.trim(),
                                label: ele.trim()
                            }
                            options.push(object)
                        })
                        // console.log('option', options)
                        setContentCategoryDropdown(options)
                    }

                    if (obj.ColumnType === 'Content Type') {
                        options = []
                        let list = obj.Value.split(',')
                        list.map((ele) => {
                            let object = {
                                value: ele.trim(),
                                label: ele.trim()
                            }
                            options.push(object)
                        })
                        setContentTypeDropdown(options)
                    }
                    if (obj.ColumnType === 'Virality Score') {
                        options = []
                        let list = obj.Value.split(',')
                        list.map((ele) => {
                            let object = {
                                value: ele.trim(),
                                label: ele.trim()
                            }
                            options.push(object)
                        })
                        setViralityScoreDropdown(options)
                    }
                    if (obj.ColumnType === 'Hook Score') {
                        options = []
                        let list = obj.Value.split(',')
                        list.map((ele) => {
                            let object = {
                                value: ele.trim(),
                                label: ele.trim()
                            }
                            options.push(object)
                        })
                        setHookScoreDropdown(options)
                    }
                    if (obj.ColumnType === 'Music') {
                        options = []
                        let list = obj.Value.split(',')
                        list.map((ele) => {
                            let object = {
                                value: ele.trim(),
                                label: ele.trim()
                            }
                            options.push(object)
                        })
                        setMusicDropdown(options)
                    }
                })


            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to fetch details.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    const getIndividualCampaign = (id) => {
        setShowDetails(true)
        setSelectedId(id)
        setIndividualCampaign({})
        Axios.get(Constants.BASE_URL + `api/dashboard/getIndividualcampaignDetails/${id}`).then((result) => {

            if (result.data.status) {
                // setCampaignData(result.data.result)
                setIndividualCampaign(result.data.result[0])

                setTotalClicks(result.data.clicks[0].androidClicks + result.data.clicks[0].iOSClicks)
                setCPC((result.data.clicks[0].androidClicks + result.data.clicks[0].iOSClicks) / result.data.result[0].AmountSpent)
                setRegisteres(result.data.registered[0].registered)
                setCAC(result.data.registered[0].registered / result.data.result[0].AmountSpent)

                // let val = campaignTypeDropdown[campaignTypeDropdown.findIndex(obj => obj.value == result.data.result[0].CampaignType)]
                // let val = campaignTypeDropdown.findIndex(obj => obj.value == result.data.result[0].CampaignType)

                // console.log('val', type, result.data.result[0].CampaignType)

            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to fetch details.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }



    const customStyles = {
        headRow: {
            style: {
                // backgroundColor: '#fff',
            },
        },
        headCells: {
            style: {
                // color: '#777',
                fontSize: '16px',
                // fontWeight: '700',
                // paddingBottom: '20px',
                // paddingTop: '50px'
            },
        },
        rows: {
            style: {
                padding: "5px",
                // backgroundColor: '#fff',
                fontSize: '14px',
                // color: '#777',
            },
        },
        pagination: {
            style: {
                // border: 'none',
                // backgroundColor: '#fff',
                // fontSize: '14px'
            },
        },
    };

    const columns = [
        {
            name: 'Sl No.',
            grow: 0,
            center: true,
            selector: (row, index) => {
                return (
                    <div className="d-flex flex-column justify-content-between">
                        {index + 1}
                    </div>
                )
            }
        },
        {
            name: 'Campaign name',
            grow: 2,
            wrap: true,
            center: true,
            sortable: true,
            selector: (row) => row.CampaignName
        },
        {
            name: 'Campaign type ',
            maxWidth: '400px',
            center: true,
            grow: 2,
            wrap: true,
            sortable: true,
            selector: (row) => row.CampaignType
        },
        {
            name: "Start date",
            center: true,
            sortable: true,
            selector: (row) => row.StartDate.slice(0, 10)
        },
        {
            name: "End date",
            center: true,
            grow: 1.5,
            sortable: true,
            selector: row => row.EndDate.slice(0, 10)
        },
        {
            name: "View",
            center: true,
            grow: 1.5,
            selector: row => {
                return (
                    <div className="d-flex flex-column justify-content-center">
                        <button className="btn bg-blue btn-sm text-light" onClick={() => getIndividualCampaign(row.Id)}>View</button>
                    </div>
                )
            }
        },
    ];

    const updateCreatableHandle = (e) => {
        e.preventDefault()

        if (label === '' || updatedValue == '') {
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: "Cannot update empty or unchanged values.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }

        let data = {
            id: selectedId,
            key: label,
            value: updatedValue
        }

        Axios.put(Constants.BASE_URL + 'api/dashboard/updateCampaignDetails', data, Constants.header).then((result) => {
            setModalShow(false)
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "Updated Successfully.",
                    showConfirmButton: false,
                    timer: 2000
                })
                getIndividualCampaign(selectedId)
                setShowCreatableEditPopup(false)
                setUpdatedValue('')
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to Update.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })

    }
    const updateHandle = (e, label) => {
        e.preventDefault()

        if (label === '' || updatedValue == '') {
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: "Cannot update empty or unchanged values.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }

        let data = {
            id: selectedId,
            key: label,
            value: updatedValue
        }

        Axios.put(Constants.BASE_URL + 'api/dashboard/updateCampaignDetails', data, Constants.header).then((result) => {

            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "Updated Successfully.",
                    showConfirmButton: false,
                    timer: 2000
                })
                getIndividualCampaign(selectedId)
                setShowCreatableEditPopup(false)
                setUpdatedValue('')
                setLabel('')
                getCampainDetails()
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to Update.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })

    }
    const handleChange = (e, name) => {

        if (name === "CampaignType" || name === "ContentCategory" || name === "ContentType" ||
            name === "ViralityScore" || name === "HookScore" || name === "Music") {
            let value
            if (e) {
                value = e.value
            } else {
                value = ''
            }
            setLabel(name)
            setUpdatedValue(value)
        } else {
            setUpdatedValue(e.target.value)
        }
        //console.log('change', updatedValue)
    }


    // const showModal = (label, value) => {
    //     setLabel(label)
    //     setUpdatedValue(value)
    //     setModalShow(!modalShow)
    // }

    const toggleCreateCampaignPopup = () => {
        setShowCreateCampaign(!showCreateCampaign)
    }

    const createCampaignChange = (name, value) => {

        if (name === 'CampaignType') {
            if (value) {
                value = value.value
            } else {
                value = ''
            }
        }
        if (name === 'ContentCategory') {
            if (value) {
                value = value.value
            } else {
                value = ''
            }
        }
        if (name === 'ContentType') {
            if (value) {
                value = value.value
            } else {
                value = ''
            }
        }

        setEnteredData((prevState) => ({
            ...prevState, [name]: value
        }))
    }


    const createCampaign = (e) => {
        e.preventDefault()

        for (const key in enteredData) {
            if (enteredData[key].trim() == "") {
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: "All fields are mandatory.",
                    showConfirmButton: false,
                    timer: 2000
                })
                return
            }
        }

        if (enteredData.StartDate > enteredData.EndDate) {
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: "Start Date cannot be greater than End Date.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }

        let data = enteredData
        Axios.post(Constants.BASE_URL + `api/dashboard/createCampaign`, data, Constants.header).then((result) => {

            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "Created Campaign.",
                    showConfirmButton: false,
                    timer: 2000
                })
                getCampainDetails()
                setShowCreateCampaign(false)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to create campaign.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    const showDropdownEditModal = (label, value) => {
        setLabel(label)
        setUpdatedValue(value)
        setShowCreatableEditPopup(!showCreatableEditPopup)

    }

    return (
        <div className="right-panel-container">
            <div className="container-fluid">


                <CreateCampaignPopup
                    show={showCreateCampaign}
                    onHide={toggleCreateCampaignPopup}
                    campaignTypeDropdownList={campaignTypeDropdown}
                    contentCategoryDropdownList={contentCategoryDropdown}
                    contentTypeDropdownList={contentTypeDropdown}
                    createCampaign={createCampaignChange}
                    submitData={createCampaign}
                />
                <p className='section-name mb-4'>Campaign</p>

                {/* <div className="row mb-4">
                <div className="col-12">
                    <Header section="Campaign Analytics" logout={props.logout} userName="Marketing Admin" />
                </div>
            </div> */}
                {!showDetails ? <div className="row mb-4">
                    <div className="col-12 d-flex justify-content-end">
                        <button className="btn bg-blue text-light" onClick={toggleCreateCampaignPopup}>Create Campaign</button>
                    </div>
                </div> : ''}
                <div className="row">
                    <div className="col-12">

                        {
                            !showDetails ?
                                <div className="row">
                                    <div className="col-12">
                                        <div className="data-container-wrapper layout-shadow">
                                            <DataTable
                                                columns={columns}
                                                data={allCampaigns}
                                                pagination
                                                customStyles={customStyles}
                                                persistTableHead
                                                fixedHeader
                                                fixedHeaderScrollHeight="650px"
                                            />
                                        </div>
                                    </div>
                                </div>
                                :
                                <>
                                    {/* <EditField
                                    show={modalShow}
                                    onHide={showModal}
                                    label={label}
                                    updateValue={updatedValue}
                                    handleChange={handleChange}
                                    updateHandle={updateHandle}
                                /> */}
                                    <CreatableEditField
                                        show={showCreatableEditPopup}
                                        onHide={showDropdownEditModal}
                                        label={label}
                                        updateValue={updatedValue}
                                        handleChange={handleChange}
                                        updateHandle={updateCreatableHandle}
                                        campaignTypeDropdown={campaignTypeDropdown}
                                        contentCategoryDropdown={contentCategoryDropdown}
                                        contentTypeDropdown={contentTypeDropdown}
                                        viralityScoreDropdown={viralityScoreDropdown}
                                        hookScoreDropdown={hookScoreDropdown}
                                        musicDropdown={musicDropdown}
                                        individualCampaign={individualCampaign}
                                    />
                                    <div className="row mb-4">
                                        <div className="col-4">
                                            <button className="btn border border-primary" onClick={(e) => setShowDetails(false)}><i className="fa fa-arrow-left me-2"></i>Back</button>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-lg-2 col-md-2 col-sm-6 text-center bg-blue text-light me-3 fw-bold p-4 mb-4 rounded boxShadow">
                                            <label>Clicks:  {totalClicks > 0 ? totalClicks : 0}</label>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-6 text-center bg-blue text-light me-3 fw-bold p-4 mb-4 rounded boxShadow">
                                            <label>CPC:  {cpc > 0 ? cpc : 0}</label>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-6 text-center bg-blue text-light me-3 fw-bold p-4 mb-4 rounded boxShadow">
                                            <label>Downloads:  {0}</label>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-6 text-center bg-blue text-light me-3 fw-bold p-4 mb-4 rounded boxShadow">
                                            <label>Registrations:  {registeres > 0 ? registeres : 0}</label>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-6 text-center bg-blue text-light me-3 fw-bold p-4 mb-4 rounded boxShadow">
                                            <label>CAC:  {cac > 0 ? cac : 0}</label>
                                        </div>
                                    </div>
                                    <div className=" bg-white p-4 boxShadow rounded mb-4">
                                        {/* <form className="campaign-form p-2"> */}
                                        <div className="row mb-4">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="row align-items-end mb-2">
                                                    <div className="col-10">
                                                        <div className="form-group">
                                                            <label htmlFor="campaignName" className="mb-2 campaign-form-label">Campaign Name</label>
                                                            <input
                                                                id="campaignName"
                                                                type="text"
                                                                className="form-control"
                                                                name="CampaignName"
                                                                defaultValue={individualCampaign.CampaignName ? individualCampaign.CampaignName : ''}
                                                                onChange={(e) => { handleChange(e) }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        {/* <button className="btn bg-blue text-light" onClick={(e) => showModal('CampaignName', individualCampaign.CampaignName)}>Edit</button> */}
                                                        <button className="btn bg-blue text-light" onClick={(e) => updateHandle(e, 'CampaignName')}>Update</button>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="row align-items-end mb-2">
                                                    <div className="col-10">
                                                        <div className="form-group">
                                                            <label className="mb-2 campaign-form-label">Campaign Type</label>
                                                            {/* <CreatableSelect
                                                                isClearable
                                                                defaultValue={campaignTypeDropdown[campaignTypeDropdown.map(function (obj) { return obj.value; }).indexOf(individualCampaign.CampaignType)]}
                                                                name='CampaignType'
                                                                options={campaignTypeDropdown}
                                                                className="creatable-select"
                                                                onChange={(e) => handleChange(e, 'CampaignType')}
                                                                required
                                                            /> */}
                                                            <input
                                                                readOnly={true}
                                                                type="text"
                                                                className="form-control"
                                                                name="CampaignType"
                                                                defaultValue={individualCampaign.CampaignType ? individualCampaign.CampaignType : ''}
                                                            // onChange={(e) => { handleChange }} 
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        {/* <button className="btn bg-blue text-light" onClick={(e) => updateCreatableHandle(e)}>Edit</button> */}
                                                        <button className="btn bg-blue text-light" onClick={(e) => showDropdownEditModal("CampaignType", individualCampaign.CampaignType)}>Show</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="row align-items-end mb-2">
                                                    <div className="col-10">
                                                        <div className="form-group">
                                                            <label htmlFor="url" className="mb-2">URL</label>
                                                            <input
                                                                id="url"
                                                                type="text"
                                                                className="form-control"
                                                                name="URL"
                                                                defaultValue={individualCampaign.URL}
                                                                onChange={(e) => { handleChange(e) }}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        {/* <button className="btn bg-blue text-light" onClick={(e) => showModal('URL', individualCampaign.URL)}>Edit</button> */}
                                                        <button className="btn bg-blue text-light" onClick={(e) => updateHandle(e, 'URL')}>Update</button>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="row align-items-end mb-2">
                                                    <div className="col-10">
                                                        <div className="form-group">
                                                            <label className="mb-2">Coupon Code</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="CouponCode"
                                                                defaultValue={individualCampaign.CouponCode}
                                                            // onChange={(e) => { handleChange }} 
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        {/* <button className="btn bg-blue text-light" onClick={(e) => showModal('CouponCode', individualCampaign.CouponCode)}>Edit</button> */}
                                                        <button className="btn bg-blue text-light" onClick={(e) => updateHandle(e, 'CouponCode')}>Update</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="row align-items-end mb-2">
                                                    <div className="col-10">
                                                        <div className="form-group">
                                                            <label className="mb-2">Start Date</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="StartDate"
                                                                defaultValue={individualCampaign.StartDate}
                                                                onChange={(e) => { handleChange(e) }}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        {/* <button className="btn bg-blue text-light" onClick={(e) => showModal('StartDate', individualCampaign.StartDate)}>Edit</button> */}
                                                        <button className="btn bg-blue text-light" onClick={(e) => updateHandle(e, 'StartDate')}>Update</button>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="row align-items-end mb-2">
                                                    <div className="col-10">
                                                        <div className="form-group">
                                                            <label className="mb-2">End Date</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="EndDate"
                                                                defaultValue={individualCampaign.EndDate}
                                                                onChange={(e) => { handleChange(e) }}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        {/* <button className="btn bg-blue text-light" onClick={(e) => showModal('EndDate', individualCampaign.EndDate)}>Edit</button> */}
                                                        <button className="btn bg-blue text-light" onClick={(e) => updateHandle(e, 'EndDate')}>Update</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="row align-items-end mb-2">
                                                    <div className="col-10">
                                                        <div className="form-group">
                                                            <label className="mb-2">Content Category</label>
                                                            <input
                                                                readOnly={true}
                                                                type="text"
                                                                className="form-control"
                                                                name="ContentCategory"
                                                                defaultValue={individualCampaign.ContentCategory}
                                                            // onChange={(e) => { handleChange }} 
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        {/* <button className="btn bg-blue text-light" onClick={(e) => showModal('ContentCategory', individualCampaign.ContentCategory)}>Edit</button> */}
                                                        <button className="btn bg-blue text-light" onClick={(e) => showDropdownEditModal("ContentCategory", individualCampaign.ContentCategory)}>Show</button>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="row align-items-end mb-2">
                                                    <div className="col-10">
                                                        <div className="form-group">
                                                            <label className="mb-2">Content Type</label>
                                                            <input
                                                                readOnly={true}
                                                                type="text"
                                                                className="form-control"
                                                                name="ContentType"
                                                                defaultValue={individualCampaign.ContentType}
                                                            // onChange={(e) => { handleChange }} 
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        {/* <button className="btn bg-blue text-light" onClick={(e) => showModal('ContentType', individualCampaign.ContentType)}>Edit</button> */}
                                                        <button className="btn bg-blue text-light" onClick={(e) => showDropdownEditModal("ContentType", individualCampaign.ContentType)}>Show</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="row align-items-end mb-2">
                                                    <div className="col-10">
                                                        <div className="form-group">
                                                            <label className="mb-2">Virality Score</label>
                                                            <input
                                                                readOnly={true}
                                                                type="text"
                                                                className="form-control"
                                                                name="ViralityScore"
                                                                defaultValue={individualCampaign.ViralityScore}
                                                            // onChange={(e) => { handleChange }} 
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-2 ">
                                                        {/* <button className="btn bg-blue text-light" onClick={(e) => showModal('ViralityScore', individualCampaign.ViralityScore)}>Edit</button> */}
                                                        <button className="btn bg-blue text-light" onClick={(e) => showDropdownEditModal('ViralityScore', individualCampaign.ViralityScore)}>Show</button>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="row align-items-end mb-2">
                                                    <div className="col-10">
                                                        <div className="form-group">
                                                            <label className="mb-2">Hook Score</label>
                                                            <input
                                                                readOnly={true}
                                                                type="text"
                                                                className="form-control"
                                                                name="HookScore"
                                                                defaultValue={individualCampaign.HookScore}
                                                            // onChange={(e) => { handleChange }} 
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        {/* <button className="btn bg-blue text-light" onClick={(e) => showModal('HookScore', individualCampaign.HookScore)}>Edit</button> */}
                                                        <button className="btn bg-blue text-light" onClick={(e) => showDropdownEditModal('HookScore', individualCampaign.HookScore)}>Show</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="row align-items-end mb-2">
                                                    <div className="col-10">
                                                        <div className="form-group">
                                                            <label className="mb-2">Music</label>
                                                            <input
                                                                readOnly={true}
                                                                type="text"
                                                                className="form-control"
                                                                name="Music"
                                                                defaultValue={individualCampaign.Music}
                                                            // onChange={(e) => { handleChange }} 
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        {/* <button className="btn bg-blue text-light" onClick={(e) => showModal('Music', individualCampaign.Music)}>Edit</button> */}
                                                        <button className="btn bg-blue text-light" onClick={(e) => showDropdownEditModal('Music', individualCampaign.Music)}>Show</button>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="row align-items-end mb-2">
                                                    <div className="col-10">
                                                        <div className="form-group">
                                                            <label className="mb-2">Description</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="Description"
                                                                defaultValue={individualCampaign.Description}
                                                                onChange={(e) => { handleChange(e) }}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        {/* <button className="btn bg-blue text-light" onClick={(e) => showModal('Description', individualCampaign.Description)}>Edit</button> */}
                                                        <button className="btn bg-blue text-light" onClick={(e) => updateHandle(e, 'Description')}>Update</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="row align-items-end mb-2">
                                                    <div className="col-10">
                                                        <div className="form-group">
                                                            <label className="mb-2">Amount Spent</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="AmountSpent"
                                                                defaultValue={individualCampaign.AmountSpent}
                                                                onChange={(e) => { handleChange(e) }}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        {/* <button className="btn bg-blue text-light" onClick={(e) => showModal('AmountSpent', individualCampaign.AmountSpent)}>Edit</button> */}
                                                        <button className="btn bg-blue text-light" onClick={(e) => updateHandle(e, 'AmountSpent')}>Update</button>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="row align-items-end mb-2">
                                                    <div className="col-10">
                                                        <div className="form-group">
                                                            <label className="mb-2">Reach</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="Reach"
                                                                defaultValue={individualCampaign.Reach}
                                                                onChange={(e) => { handleChange(e) }}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        {/* <button className="btn bg-blue text-light" onClick={(e) => showModal('Reach', individualCampaign.Reach)}>Edit</button> */}
                                                        <button className="btn bg-blue text-light" onClick={(e) => updateHandle(e, 'Reach')}>Update</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="row align-items-end mb-2">
                                                    <div className="col-10">
                                                        <div className="form-group">
                                                            <label className="mb-2">Plays</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="Plays"
                                                                defaultValue={individualCampaign.Plays}
                                                                onChange={(e) => { handleChange(e) }}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        {/* <button className="btn bg-blue text-light" onClick={(e) => showModal('Plays', individualCampaign.Plays)}>Edit</button> */}
                                                        <button className="btn bg-blue text-light" onClick={(e) => updateHandle(e, 'Plays')}>Update</button>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="row align-items-end mb-2">
                                                    <div className="col-10">
                                                        <div className="form-group">
                                                            <label className="mb-2">Likes</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="Likes"
                                                                defaultValue={individualCampaign.Likes}
                                                                onChange={(e) => { handleChange(e) }}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        {/* <button className="btn bg-blue text-light" onClick={(e) => showModal('Likes', individualCampaign.Likes)}>Edit</button> */}
                                                        <button className="btn bg-blue text-light" onClick={(e) => updateHandle(e, 'Likes')}>Update</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="row align-items-end mb-2">
                                                    <div className="col-10">
                                                        <div className="form-group">
                                                            <label className="mb-2">Shares</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="Shares"
                                                                defaultValue={individualCampaign.Shares}
                                                                onChange={(e) => { handleChange(e) }}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        {/* <button className="btn bg-blue text-light" onClick={(e) => showModal('Shares', individualCampaign.Shares)}>Edit</button> */}
                                                        <button className="btn bg-blue text-light" onClick={(e) => updateHandle(e, 'Shares')}>Update</button>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="row align-items-end mb-2">
                                                    <div className="col-10">
                                                        <div className="form-group">
                                                            <label className="mb-2">Saves</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="Saves"
                                                                defaultValue={individualCampaign.Saves}
                                                                onChange={(e) => { handleChange(e) }}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        {/* <button className="btn bg-blue text-light" onClick={(e) => showModal('Saves', individualCampaign.Saves)}>Edit</button> */}
                                                        <button className="btn bg-blue text-light" onClick={(e) => updateHandle(e, 'Saves')}>Update</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="row align-items-end mb-2">
                                                    <div className="col-10">
                                                        <div className="form-group">
                                                            <label className="mb-2">Comments</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="Comments"
                                                                defaultValue={individualCampaign.Comments}
                                                                onChange={(e) => { handleChange(e) }}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        {/* <button className="btn bg-blue text-light" onClick={(e) => showModal('Comments', individualCampaign.Comments)}>Edit</button> */}
                                                        <button className="btn bg-blue text-light" onClick={(e) => updateHandle(e, 'Comments')}>Update</button>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="row align-items-end mb-2">
                                                    <div className="col-10">
                                                        <div className="form-group">
                                                            <label className="mb-2">Followers Added</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="FollowersAdded"
                                                                defaultValue={individualCampaign.FollowersAdded}
                                                                onChange={(e) => { handleChange(e) }}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        {/* <button className="btn bg-blue text-light" onClick={(e) => showModal('FollowersAdded', individualCampaign.FollowersAdded)}>Edit</button> */}
                                                        <button className="btn bg-blue text-light" onClick={(e) => updateHandle(e, 'FollowersAdded')}>Update</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="row align-items-end mb-2">
                                                    <div className="col-10">
                                                        <div className="form-group">
                                                            <label className="mb-2">Profile Visits</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="ProfileVisits"
                                                                defaultValue={individualCampaign.ProfileVisits}
                                                                onChange={(e) => { handleChange(e) }}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        {/* <button className="btn bg-blue text-light" onClick={(e) => showModal('ProfileVisits', individualCampaign.ProfileVisits)}>Edit</button> */}
                                                        <button className="btn bg-blue text-light" onClick={(e) => updateHandle(e, 'ProfileVisits')}>Update</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        {/* <div className="row mb-4">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="row align-items-end mb-2">
                                                    <div className="col-10">
                                                        <div className="form-group">
                                                            <label htmlFor="formGroupExampleInput " className="mb-2">Campaign Type</label><br></br>
                                                            <input
                                                                type="text"
                                                                value={individualCampaign.CouponCode}
                                                                className="form-control"
                                                            // onChange={(e) => { setIndividualCampaign({ ...individualCampaign, CampaignName: e.target.value }) }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="row align-items-end mb-2">
                                                    <div className="col-10">
                                                        <div className="form-group">
                                                            <label className="mb-2">Downloads</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="Downloads"
                                                                value={individualCampaign.Downloads}
                                                            // onChange={(e) => { handleChange }} 
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        <button className="btn bg-blue text-light" onClick={(e) => showModal('Downloads', individualCampaign.Downloads)}>Edit</button>
                                                    </div>
                                                </div>

                                            </div>

                                        </div> */}

                                        {/* </form> */}
                                    </div>
                                </>
                        }

                    </div>
                </div>
            </div>
        </div>
    )

}

export default Campaign;