import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import * as Constants from '../../../../assets/helper/constants'
import { useEffect, useState } from 'react';
import CreateProjectPopup from './createProjectPopup';
import Swal from 'sweetalert2';
import Axios from 'axios';

function LessonList(props) {

    const [userInfo, setUserInfo] = useState({})
    const [allProjects, setAllProjects] = useState([])
    const [showCreateProjectPopup, setShowCreateProjectPopup] = useState(false)
    const [lessonTitle, setLessonTitle] = useState('')
    const [lessonPlanFile, setLessonPlanFile] = useState(null)
    const [description, setDescription] = useState('')

    useEffect(() => {
        setUserInfo(props.userInfo)
        getAllProjects()
    }, [])


    const customStyles = {
        headRow: {
            style: {
                fontWeight: "600"
            },
        },

        headCells: {
            style: {
                color: '#202124',
                fontSize: '16px',
            },
        },
        rows: {
            highlightOnHoverStyle: {
                backgroundColor: 'rgb(230, 244, 244)',
                borderBottomColor: '#FFFFFF',
                borderRadius: '25px',
                outline: '1px solid #FFFFFF',
            },
        },
        pagination: {
            style: {
                border: 'none',
            },
        },

    }

    const columns = [
        {
            name: 'Sl No.',
            grow: 1,
            selector: (row, index) => {
                return (
                    <div className="">
                        {index + 1}
                    </div>
                )
            }
        },
        {
            name: 'Title',
            grow: 2,
            sortable: true,
            selector: row => row.Title,
            sortable: true,
        },
        {
            name: 'Created Date',
            grow: 2,
            sortable: true,
            selector: row => row.CreatedOn.slice(0, 10),
        },
        {
            name: 'View',
            grow: 2,
            selector: row => (<Link to={`${Constants.StatusTimeline}/${row.Id}`} className="btn btn-sm btn-blue text-white">View <i className='fa fa-arrow-right ms-2'></i></Link>),
        },
    ];


    const getAllProjects = () => {

        Axios.get(Constants.BASE_URL + "api/teacher/getAllProjects").then((result) => {

            if (result.data.status) {
                setAllProjects(result.data.result)
            } else {
                setAllProjects([])
            }
        })
    }
    const createProject = (e) => {
        e.preventDefault()

        if (lessonTitle.trim() === "" || description.trim() === "") {

            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "All fields are mandatory.",
                showConfirmButton: false,
                timer: 1000
            })

            return
        }

        if (lessonPlanFile.name.split('.').pop() !== 'ppt' && lessonPlanFile.name.split('.').pop() !== 'pptx' &&
            lessonPlanFile.name.split('.').pop() !== 'doc' && lessonPlanFile.name.split('.').pop() !== 'docx' &&
            lessonPlanFile.name.split('.').pop() !== 'pdf') {

            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "File type is not supported.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }

        const fromData = new FormData();
        fromData.append("userId", userInfo.Id);
        fromData.append("from", userInfo.FirstName + ' ' + props.userInfo.LastName);
        fromData.append("lessonTitle", lessonTitle);
        fromData.append("lessonPlanFile", lessonPlanFile);
        fromData.append("description", description);

        Axios.post(Constants.BASE_URL_A + "api/teacher/createProject", fromData, Constants.header).then((result) => {

            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: result.data.message,
                    showConfirmButton: false,
                    timer: 1500
                })
                getAllProjects()
                setShowCreateProjectPopup(false)

            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: result.data.message,
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })

    }


    return (
        <div className='right-panel-container'>
            <CreateProjectPopup
                show={showCreateProjectPopup}
                onHide={() => setShowCreateProjectPopup(false)}
                lessonTitle={(e) => setLessonTitle(e.target.value)}
                lessonPlan={(e) => setLessonPlanFile(e.target.files[0])}
                lessonDesc={(e) => setDescription(e.target.value)}
                submitProject={createProject}
            />

            <div className='container-fluid'>

                <div className='row mb-4'>
                    <div className='col-12 d-flex align-items-baseline justify-content-between'>
                        <div className="row">
                            <div className="col-12">
                                <h2 className="section-name">Lessons</h2>
                            </div>
                        </div>
                        <button className='btn btn-blue text-white' onClick={() => setShowCreateProjectPopup(true)}><i className='fa fa-plus me-2'></i>Create Lesson</button>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-12'>
                        <div className='card border-0 data-container-wrapper box-shadows rounded p-0'>
                            <div className='card-body'>
                                <DataTable
                                    columns={columns}
                                    data={allProjects}
                                    customStyles={customStyles}
                                    pagination
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LessonList