

function LessonCard(props) {
    return (
        <div className="card-deck card-primary">
            <div className="card card-secondary">
                <div className="card-image-wrap">
                    <img className="card-img-top" src={props.thumb1} alt="Thumbnail" />
                </div>
                <div className="card-body ">
                    <h5 className="card-title" title={props.name}>{props.name}</h5>
                    <p className="card-text" title={props.desc}>{props.desc}</p>
                </div>
            </div>
        </div>
    )
}
export default LessonCard;