// import { useEffect, useState } from "react";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import TreehouseLogo from '../../../assets/images/baetho-kids-logo.png'
import Navbar from "../navbar/navbar";

function LessonModal(props) {

    const [lessonUrl, setLessonUrl] = useState('')

    //let url = `https://thetreehouse.in/player/?type=Aggregator&path=${props.id}&width=1280&height=720`

    useEffect(() => {
        let height = document.querySelector('.modal-player-container').clientHeight;
        let width = document.querySelector('.modal-player-container').clientWidth;

        let url = `https://thetreehouse.in/playerweb/?type=Aggregator&path=${props.id}&ads=true&width=${width}&height=${height}`
        setLessonUrl(url)
    }, [])



    return (
        <Modal {...props} fullscreen={true} centered dialogClassName="modal-bg" contentClassName='modal-bg'>
            {/* <Modal.Header closeButton className="header-modal" closeVariant='white'>
                <Modal.Title id="contained-modal-title-vcenter">
                    <div className="logo-box">
                        <img src={TreehouseLogo} alt="logo" />
                    </div>
                </Modal.Title>
            </Modal.Header> */}
            <div className="lesson-modal-nav-container">
                <Navbar />
            </div>
            <Modal.Body >
                <div className="container-fluid h-100">
                    <button className="lesson-modal-back-btn" onClick={() => props.setShow(false)}> <i class="fa fa-arrow-left fa-1x text-blue " aria-hidden="true"></i> Back</button>
                    <div className="row h-100 d-flex justify-content-center align-items-center">
                        <div className="col-sm-12 col-md-10 col-lg-8">
                            <div className="modal-player-container">
                                <iframe src={lessonUrl} width="100%" height="100%" title="Baetho" className="modal-player-iframe" />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default LessonModal;