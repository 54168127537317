import DataTable from "react-data-table-component";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import * as Constants from '../../../../assets/helper/constants'
import Swal from 'sweetalert2';
import Axios from 'axios';
import { useEffect, useState } from 'react';

function AdminProject(props) {

    const [userInfo, setUserInfo] = useState({})
    const [allProjects, setAllProjects] = useState([])


    useEffect(() => {
        getAllProjects()
    }, [])

    const getAllProjects = () => {

        Axios.get(Constants.BASE_URL + "api/AggregatorLessons/getAllProjectsForAdmin").then((result) => {

            if (result.data.status) {
                setAllProjects(result.data.result1)
            } else {
                setAllProjects([])
            }
        })
    }

    const customStyles = {
        headRow: {
            style: {
                fontWeight: "600"
            },
        },

        headCells: {
            style: {
                color: '#202124',
                fontSize: '16px',
            },
        },
        rows: {
            highlightOnHoverStyle: {
                backgroundColor: 'rgb(230, 244, 244)',
                borderBottomColor: '#FFFFFF',
                borderRadius: '25px',
                outline: '1px solid #FFFFFF',
            },
        },
        pagination: {
            style: {
                border: 'none',
            },
        },

    }

    const columns = [
        {
            name: 'Sl No.',
            grow: 1,
            selector: (row, index) => {
                return (
                    <div className="">
                        {index + 1}
                    </div>
                )
            }
        },
        {
            name: 'Title',
            grow: 2,
            selector: row => row.Title,
            sortable: true,
        },
        {
            name: 'Created On',
            grow: 2,
            sortable: true,
            selector: row => row.CreatedOn.slice(0, 10),
        },
        {
            name: 'View',
            grow: 2,
            selector: row => (<Link to={`${Constants.AdminTimeline}/${row.Id}`} className="btn btn-sm btn-blue text-white">View <i className='fa fa-arrow-right ms-2'></i></Link>),
        },
    ];

    const data = [
        {
            id: 1,
            title: 'Alphabets',
            lessonPlanStatus: 'Review',
        },
        {
            id: 2,
            title: 'Numbers',
            lessonPlanStatus: 'Review',
        },
    ]

    return (
        <div className='right-panel-container'>
            <div className='container-fluid'>
                <div className="row">
                    <div className="col-12">
                        <h2 className="section-name">Lessons</h2>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-12'>
                        <div className='data-container-wrapper box-shadows rounded p-0'>
                            <DataTable
                                columns={columns}
                                data={allProjects}
                                customStyles={customStyles}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminProject