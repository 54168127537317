import { useParams } from "react-router-dom"
import UploadLessonPlanPopup from "./uploadLessonPlanPopup"
import { useEffect, useState } from "react"
import * as Constants from '../../../../assets/helper/constants'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import Swal from "sweetalert2"
import Axios from 'axios';
import LoaderComp from "../../../loader";
import AnswerRejectPlanPopup from "./AnswerRejectPlanPopup"

function LessonPlan(props) {

    const history = useHistory()
    const [showUploadLessonPlanPopup, setShowUploadLessonPlanPopup] = useState(false)
    const [lessonPlanFile, setLessonPlanFile] = useState(null)
    const [description, setDescription] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [fileDownloadLink, setFileDownloadLink] = useState('')
    const [showRejectPlanPopup, setShowRejectPlanPopup] = useState(false)
    const [messages, setMessages] = useState([])
    const params = useParams()

    const goBack = (e) => {
        history.goBack()
    }

    useEffect(() => {
        getLessonPlanFilePath()
        getMessage()
    }, [])


    const msg = [
        {
            id: 1,
            user: "Teacher",
            message: "Test message",
            date: '17-05-23'
        },
        {
            id: 2,
            user: "Admin",
            message: "Test message",
            date: '17-05-23'
        },

    ]

    const getMessage = () => {
        let projectId = params.projectId;


        Axios.get(Constants.BASE_URL + `api/teacher/getMessages/${projectId}`).then((result) => {

            if (result.data.status) {
                if (result.data.result.length > 0) {
                    let msg = JSON.parse(result.data.result[0].Message)

                    let rejected = msg.filter(message => {

                        return message.type === 'rejectedPlan' && message.answered === false
                    })

                    /** 
                     * merge rejection reasons object into one array
                     * */
                    const resultArray = rejected.reduce((acc, curr) => {
                        acc[0].message = acc[0].message.concat(curr.message);
                        return acc;
                    }, [{ message: [] }]);

                    console.log('arr', resultArray)

                    setMessages(resultArray)
                } else {
                    setMessages([])
                }
            } else {
                setMessages([])
            }
        })
    }

    /**
     * Get lesson plan file download link
     */
    const getLessonPlanFilePath = () => {
        setIsLoading(true)
        let projectId = params.projectId;

        Axios.get(Constants.BASE_URL + `api/teacher/getLessonPlanDownloadLink?id=${projectId}`).then((result) => {
            setIsLoading(false)
            if (result.data.status) {
                setFileDownloadLink(result.data.result[0].LessonPlanPath)
            } else {
                setFileDownloadLink('')
            }

        }).catch((error) => {
            console.log('error', error)
        })
    }

    const updateLessonPlan = (answersList) => {


        // if (description.trim() === "") {
        //     Swal.fire({
        //         position: 'top-end',
        //         icon: 'error',
        //         title: "All fields are mandatory.",
        //         showConfirmButton: false,
        //         timer: 2000
        //     })
        //     return
        // }
        if (lessonPlanFile === null) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please select file.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }

        if (lessonPlanFile.name.split('.').pop() !== 'ppt' && lessonPlanFile.name.split('.').pop() !== 'pptx' &&
            lessonPlanFile.name.split('.').pop() !== 'doc' && lessonPlanFile.name.split('.').pop() !== 'docx' &&
            lessonPlanFile.name.split('.').pop() !== 'pdf') {

            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "File type not supported.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }

        setIsLoading(true)
        let projectId = params.projectId;

        const fromData = new FormData();
        fromData.append("projectId", projectId);
        fromData.append("lessonPlanFile", lessonPlanFile);
        fromData.append("answersList", JSON.stringify(answersList));
        fromData.append("userId", props.userInfo.Id);
        fromData.append("from", props.userInfo.FirstName + ' ' + props.userInfo.LastName);

        //fromData.append("description", description);

        Axios.put(Constants.BASE_URL_A + "api/teacher/updateLessonPlan", fromData, Constants.header).then((result) => {
            setIsLoading(false)
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "File uploaded successfully.",
                    showConfirmButton: false,
                    timer: 2000
                })
                setShowUploadLessonPlanPopup(false)
                getLessonPlanFilePath()
                setShowRejectPlanPopup(false)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Unable to upload file.',
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    const toggleRejectPlanPopup = () => {
        setShowRejectPlanPopup(!showRejectPlanPopup)
    }

    return (
        <div className="right-panel-container">
            {isLoading ? <LoaderComp isLoading={isLoading} /> : null}
            <UploadLessonPlanPopup
                show={showUploadLessonPlanPopup}
                onHide={() => setShowUploadLessonPlanPopup(false)}
                lessonPlan={(e) => setLessonPlanFile(e.target.files[0])}
                lessonDesc={(e) => setDescription(e.target.value)}
                submitProject={updateLessonPlan}
            />
            <AnswerRejectPlanPopup
                show={showRejectPlanPopup}
                onHide={toggleRejectPlanPopup}
                submitLessonPlan={updateLessonPlan}
                messages={messages}
            />

            <div className="container-fluid text-left">
                <div className="row mb-4">
                    <div className="col-12">
                        <h2 className="section-name">Submit Lesson Plan</h2>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12 d-flex justify-content-start align-items-baseline flex-wrap">
                        <button onClick={goBack} className="btn"><i className="fa fa-chevron-left me-2"></i>Back</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="card border-0 box-shadows">
                            <div className="card-body">
                                <h5 className="card-title">Download Lesson plan</h5>
                                <p className="card-text">Download the lesson plan file.</p>
                                <a
                                    href={fileDownloadLink !== null
                                        ? `${Constants.BASE_URL}${fileDownloadLink}`
                                        : ""} target="_blank" download className="btn btn-blue text-light mb-md-2">
                                    <i className="fa fa-download text-light me-2"></i>
                                    {fileDownloadLink.split('\\')[4]}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="card border-0 box-shadows">
                            <div className="card-body ">
                                <h5 className="card-title mb-4">Update Lesson Plan</h5>
                                <div className="row mb-2">
                                    <div className="col-8">
                                        <input type="file" className="form-control" onChange={(e) => setLessonPlanFile(e.target.files[0])} />
                                    </div>
                                    <div className="col-4">
                                        <button className="btn btn-blue text-light" onClick={toggleRejectPlanPopup}>Update</button>
                                    </div>
                                </div>
                                <small className="text-muted">Allowed file type: .pdf, .doc, .docx, .ppt, .pptx</small>
                                {/* <button className="btn btn-blue text-light" onClick={() => setShowUploadLessonPlanPopup(true)}><i className="fa fa-upload text-light me-2"></i>Upload Lesson Plan</button> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-12">
                        <div className="chat-box-wrap d-flex flex-column justify-content-between shadows">
                            <div className="chat-message">
                                <div className="chat-header">
                                    <h3>Messages</h3>
                                </div>
                                <hr></hr>
                                {
                                    msg.map((message) => {
                                        return (
                                            <div className='msg-wrap mb-3 text-left'>
                                                <p className="mb-0 fw-bold">{message.user}<span className="fw-normal ms-2">{message.date}</span></p>

                                                <p> {message.message}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div class="form-floating">
                                <textarea class="form-control chat-text-area input-style " placeholder="Message" id="floatingTextarea"></textarea>
                                <label htmlFor="floatingTextarea">Message</label>
                                <button className="btn btn-blue text-white mt-2 float-end">Send</button>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}
export default LessonPlan