
import { Modal } from "react-bootstrap";

function ThumbnailPopup(props) {

    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h5 className=''>Thumbnail Image</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-4'>

                <div className="row">
                    <div className="col-12">
                        <img className="w-100" src={props.thumbnail} alt="Thumbnail" />
                    </div>
                </div>

            </Modal.Body>
            {/* <Modal.Footer>

            </Modal.Footer> */}
        </Modal>
    );
}
export default ThumbnailPopup;