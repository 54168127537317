
import { useState } from "react";
import { Modal } from "react-bootstrap";
import Swal from 'sweetalert2';

function RejectLessonPopup(props) {

    const [inputList, setInputList] = useState(['']);

    const onPointsChange = (event, index) => {
        let data = [...inputList];
        data[index] = event.target.value;
        setInputList(data);
    }

    const createNewInput = () => {
        let newField = ''
        setInputList([...inputList, newField])
    }

    const removeInput = (index) => {
        if (index > 0) {
            const list = [...inputList];
            list.splice(index, 1);
            setInputList(list)
        }
    }

    const submitHandler = (e) => {
        e.preventDefault()
        // console.log(inputList)
        const trimmedArray = inputList.map(str => str.trim());
        let val = trimmedArray.every(str => str !== '');

        if (val) {
            props.rejectLessonFunc(inputList)
        } else {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "All fields are mandatory.",
                showConfirmButton: false,
                timer: 2000
            })
        }
    }


    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h5 className=''>Reason for rejection.</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-4'>

                <div className="row">
                    <div className="col-12">
                        <form>
                            <p className="form-label">Write list of reasons for rejection. Add or remove input fields as required.</p>
                            {
                                inputList.map((input, index) => {
                                    return (
                                        <>
                                            <div className="form-group mb-4" key={index}>
                                                <input
                                                    defaultValue={inputList[index]}
                                                    className="form-control"
                                                    type="text"
                                                    onChange={(e) => onPointsChange(e, index)}
                                                    autoFocus
                                                    required
                                                />
                                            </div>

                                            <div className="d-flex justify-content-end">
                                                {inputList.length - 1 === index &&
                                                    <button className="btn btn-danger btn-sm mx-2 mb-4" type="button" onClick={() => removeInput(index)}>- Remove</button>
                                                }
                                                {inputList.length - 1 === index &&
                                                    <button className="btn btn-success btn-sm mb-4" type="button" onClick={createNewInput}>+ Add</button>
                                                }
                                            </div>
                                        </>

                                    )
                                })
                            }
                            <div className="text-center">
                                <button type="submit" className="btn bg-blue text-light w-100" onClick={submitHandler}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    );
}
export default RejectLessonPopup;