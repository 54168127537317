
import { useState } from "react";
import { Modal } from "react-bootstrap";
import Swal from 'sweetalert2';

function AnswerForRejectionPopup(props) {

    const [inputList, setInputList] = useState(['']);

    const [list, setList] = useState(['']);



    const onInputChange = (event, index) => {
        let data = [...inputList];
        data[index] = event.target.value;
        setInputList(data);
        // setInputList(prevState => {
        //     [...prevState, event.target.value]
        // })

    }

    const createNewInput = () => {
        let newField = ''
        setInputList([...inputList, newField])
    }

    const removeInput = (index) => {
        if (index > 0) {
            const list = [...inputList];
            list.splice(index, 1);
            setInputList(list)
        }
    }

    const submitHandler = (e) => {
        e.preventDefault()
        const trimmedArray = inputList.map(str => str.trim());
        let val = trimmedArray.every(str => str !== '');

        if (val) {
            props.submitLesson(inputList)
        } else {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please answer to all the points.",
                showConfirmButton: false,
                timer: 2000
            })
        }
    }


    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h5 className=''>Update Lesson Details.</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-4'>

                <div className="row">
                    <div className="col-12">
                        <form onSubmit={submitHandler}>
                            <p>Please write description for the update.</p>

                            {props.messages[0]?.message.length > 0 ?
                                props.messages.map((message, i) => {
                                    return (
                                        <div key={i}>
                                            {
                                                message.message.map((m, index) =>
                                                    <div className="mb-4" key={index}>
                                                        <li className="mb-2">
                                                            {m}
                                                        </li>
                                                        <input
                                                            defaultValue={inputList[index]}
                                                            className="form-control"
                                                            onChange={(e) => onInputChange(e, index)}
                                                            required
                                                        />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                })
                                : <div className="mb-4">
                                    <label>Description</label>
                                    <input
                                        defaultValue={inputList[0]}
                                        className="form-control"
                                        onChange={(e) => onInputChange(e, 0)}
                                        required
                                    />
                                </div>

                            }

                            <div className="text-center">
                                <button type="submit" className="btn bg-blue text-light w-100">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    );
}
export default AnswerForRejectionPopup;