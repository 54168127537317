import { useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import Axios from 'axios'
import Swal from 'sweetalert2';
import * as Constants from '../../../assets/helper/constants'

function LessonTried(props) {

    const [data, setData] = useState('')

    const [selectedDuration, setSelectedDuration] = useState('')
    const [selectedOs, setSelectedOs] = useState('')
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')

    const onChangeHandler = (setter, value) => {

        if (selectedDuration !== 'CUSTOM') {
            setFromDate('')
            setToDate('')
        }
        setter(value)
    }


    const getData = (e) => {

        if (selectedOs === '') {
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: "Please select the OS type.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }

        let link = ''
        if (selectedDuration === 'CUSTOM') {
            if (fromDate === '' || toDate === '') {
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: "Please select From and To date.",
                    showConfirmButton: false,
                    timer: 2000
                })
                return
            } else if (fromDate > toDate) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: "From-date cannot be greater than To-date.",
                    showConfirmButton: false,
                    timer: 2000
                })
                return
            }
            else {
                link = `api/dashboard/triedLessonsAnalytics?time=${selectedDuration}&from=${fromDate}&to=${toDate}&os=${selectedOs}`
            }
        } else {
            if (selectedDuration === '') {
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: "Please select time duration.",
                    showConfirmButton: false,
                    timer: 2000
                })
                return
            } else {
                link = `api/dashboard/triedLessonsAnalytics?time=${selectedDuration}&os=${selectedOs}`
            }
        }


        Axios.get(Constants.BASE_URL + link).then((result) => {
            if (result.data.status) {
                setData(result.data.result)

            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to fetch data.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    return (

        <div className="col-lg-6 col-md-12 mb-4">
            <div className="card">
                <h5 className="card-header">Registered/Tried Lessons</h5>
                <div className="card-body">
                    <div className="row mb-4 align-items-end">
                        <div className="col-3">
                            <label className="mb-2">Duration</label>
                            <select className="form-select" onChange={(e) => onChangeHandler(setSelectedDuration, e.target.value)} aria-label="Default select example">
                                <option value="">Select</option>
                                <option value="WEEK">Last 7 Days</option>
                                <option value="MONTH">Last 30 Days</option>
                                <option value="6_MONTHS">Last 6 Months</option>
                                <option value="YEAR">Last Year</option>
                                <option value="CUSTOM">Custom Date</option>
                            </select>
                        </div>
                        <div className="col-3">
                            <label className="mb-2">Operating System</label>
                            <select className="form-select" onChange={(e) => setSelectedOs(e.target.value)} aria-label="Default select example">
                                <option value="">Select</option>
                                <option value="0">Android</option>
                                <option value="1">IOS</option>
                                <option value="2">Android & IOS</option>
                            </select>
                        </div>
                        <div className="col-3">
                            <button className="btn bg-blue text-light" onClick={(e) => getData(e)}>Submit</button>
                        </div>
                    </div>
                    {
                        selectedDuration == 'CUSTOM' ? <div className="row mb-4">
                            <div className="col-3 mb-4">
                                <label className="mb-2">From Date</label>
                                <input type="date" className="form-control" onChange={(e) => onChangeHandler(setFromDate, e.target.value)} required />
                            </div>
                            <div className="col-3 mb-4">
                                <label className="mb-2">To Date</label>
                                <input type="date" className="form-control" onChange={(e) => onChangeHandler(setToDate, e.target.value)} required />
                            </div>
                        </div> : ""
                    }
                    <hr></hr>
                    <div className="row">
                        <div className="col-6">
                            <BarChart
                                width={700}
                                height={300}
                                data={data}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar name="NotRegd_NotTried" dataKey="notRegisteredNotTried" fill="#a4c639" />
                                <Bar name="NotRegd_Tried" dataKey="notRegisteredTried" fill="#ff9500" />
                                <Bar name="Regd_NotTried" dataKey="registeredNotTried" fill="#EE4B2B" />
                                <Bar name="RegdTried" dataKey="registeredTried" fill="#8884d8" />
                            </BarChart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LessonTried;