
import { Modal } from "react-bootstrap";

function ImagePopup(props) {

    return (
        <>
            <Modal {...props} centered dialogClassName="modal-90w" contentClassName='modal-90w' className="transparent-bg">
                <Modal.Header closeButton >
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex flex-column justify-content-center align-items-center" >
                    {props.children}
                </Modal.Body>
            </Modal>
        </>
    );
}
export default ImagePopup;