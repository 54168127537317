import { useHistory } from "react-router-dom"
import CreatableSelect from 'react-select/creatable';
import { useEffect, useState } from "react";
import Swal from 'sweetalert2';
import Axios from 'axios';
import LoaderComp from "../../../loader";
import * as Constants from '../../../../assets/helper/constants'
import { useParams } from 'react-router-dom';

function MainLesson(props) {

    const [userInfo, setUserInfo] = useState('')
    const [lessonDetails, setLessonDetails] = useState([])
    const [subject, setSubject] = useState('')
    const [lessonName, setLessonName] = useState('')
    const [tags, setTags] = useState('')
    const [age, setAge] = useState('')
    const [region, setRegion] = useState('')
    const [desc, setDesc] = useState('')
    const [lessonFile, setLessonFile] = useState(null)
    const [thumbOne, setThumbOne] = useState(null)
    const [thumbTwo, setThumbTwo] = useState(null)
    const [thumbThree, setThumbThree] = useState(null)

    // const [invalidThumb1, setInvalidThumb1] = useState(false)
    // const [invalidThumb2, setInvalidThumb2] = useState(false)
    // const [invalidThumb3, setInvalidThumb3] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const [uploaded, setUploaded] = useState(null)
    const history = useHistory()
    const params = useParams();

    const lessonOptions = [
        { value: 'English', label: 'English' },
        { value: 'Mathematics', label: 'Mathematics' },
        { value: 'Science', label: 'Science' }
    ]



    const handleSubject = (e) => {

        if (e) {
            setSubject(e.value)
        } else {
            setSubject("")
        }
    }

    const goBack = (e) => {
        history.goBack()
    }

    useEffect(() => {
        // let userDetails = JSON.parse(localStorage.getItem("userDetails"))
        setUserInfo(props.userInfo)
        getLessonDetailsForTeacher()
    }, [])

    const getLessonDetailsForTeacher = () => {
        Axios.get(Constants.BASE_URL + `api/teacher/getLessonDetailsForTeacher?projectId=${params.projectId}`).then((result) => {
            if (result.data.status) {
                setLessonDetails(result.data.result)
            } else {
                setLessonDetails([])
            }
        })
    }

    const uploadLesson = async (e) => {
        e.preventDefault()

        if (subject.trim() === "" || lessonName.trim() === "" || tags.trim() === "" || age.trim() === "" || region.trim() === "" || desc.trim() === "") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "All fields are mandatory.",
                showConfirmButton: false,
                timer: 1000
            })

            return
        }
        if (lessonFile.name.split('.').pop() !== 'exp' && lessonFile.name.split('.').pop() !== 'prj') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Only lesson files of .exp or .prj are allowed.",
                showConfirmButton: false,
                timer: 2000
            })

            return
        }

        if (thumbOne.name.split('.').pop() !== 'jpg' && thumbOne.name.split('.').pop() !== 'JPG'
            && thumbOne.name.split('.').pop() !== 'png' && thumbOne.name.split('.').pop() !== 'PNG'
            && thumbOne.name.split('.').pop() !== 'jpeg' && thumbOne.name.split('.').pop() !== 'JPEG') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Thumbnail image format is not supported.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }
        if (thumbTwo.name.split('.').pop() !== 'jpg' && thumbTwo.name.split('.').pop() !== 'JPG'
            && thumbTwo.name.split('.').pop() !== 'png' && thumbTwo.name.split('.').pop() !== 'PNG'
            && thumbTwo.name.split('.').pop() !== 'jpeg' && thumbTwo.name.split('.').pop() !== 'JPEG') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Thumbnail image format is not supported.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }
        if (thumbThree.name.split('.').pop() !== 'jpg' && thumbThree.name.split('.').pop() !== 'JPG'
            && thumbThree.name.split('.').pop() !== 'png' && thumbThree.name.split('.').pop() !== 'PNG'
            && thumbThree.name.split('.').pop() !== 'jpeg' && thumbThree.name.split('.').pop() !== 'JPEG') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Thumbnail image format is not supported.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }


        let val1 = new Promise((resolve, reject) => {
            let img1 = new Image();
            img1.src = URL.createObjectURL(thumbOne);
            img1.onload = function () {
                if (this.width === 640 && this.height === 360) {
                    URL.revokeObjectURL(img1);
                    resolve()
                } else {
                    URL.revokeObjectURL(img1);
                    reject()
                }
            };
        })

        let val2 = new Promise((resolve, reject) => {
            let img2 = new Image();
            img2.src = URL.createObjectURL(thumbTwo);
            img2.onload = function () {
                if (this.width === 640 && this.height === 360) {
                    URL.revokeObjectURL(img2);
                    resolve()
                } else {
                    URL.revokeObjectURL(img2);
                    reject()
                }
            };
        })

        let val3 = new Promise((resolve, reject) => {
            let img3 = new Image();
            img3.src = URL.createObjectURL(thumbThree);
            img3.onload = function () {

                if (this.width === 640 && this.height === 360) {
                    URL.revokeObjectURL(img3);
                    resolve()
                } else {
                    URL.revokeObjectURL(img3);
                    reject()
                }

            };
        })

        Promise.all([val1, val2, val3]).then(async (result) => {

            const fromData = new FormData();
            fromData.append("lessonFile", lessonFile);
            fromData.append("thumbOne", thumbOne);
            fromData.append("thumbTwo", thumbTwo);
            fromData.append("thumbThree", thumbThree);
            fromData.append("subject", subject);
            fromData.append("lessonName", lessonName);
            fromData.append("lessonTags", tags);
            fromData.append("age", age);
            fromData.append("region", region);
            fromData.append("lessonDesc", desc);
            fromData.append("userId", userInfo.Id);
            fromData.append("projectId", params.projectId);

            const { value: accept } = await Swal.fire({
                title: 'Terms and conditions',
                html:
                    'Please read our, ' +
                    '<a href="https://baetho.app/#/terms-of-use" target="_blank">Terms & Condtions</a> ',
                input: 'checkbox',
                inputValue: 0,
                inputPlaceholder:
                    'I agree with the terms and conditions',
                confirmButtonText:
                    'Continue <i class="fa fa-arrow-right"></i>',
                inputValidator: (result) => {
                    return !result && 'You need to agree with T&C'
                }
            })

            if (accept) {
                setIsLoading(true)
                Axios.post(Constants.BASE_URL_A + "api/AggregatorLessons/uploadLesson/", fromData, Constants.header, {
                    onUploadProgress: (data) => {
                        setUploaded(Math.round((data.loaded / data.total) * 100))
                    }
                }).then((result) => {
                    setIsLoading(false)

                    if (result.data.status) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: "Lesson Uploaded succesfully",
                            showConfirmButton: false,
                            timer: 2000
                        })

                        setSubject("")
                        setLessonName("")
                        setTags("")
                        setDesc("")
                        setAge("")
                        setRegion("")
                        getLessonDetailsForTeacher()

                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: "Unable to Upload Lesson.",
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }
                });
            }

        }).catch((error) => {

            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Please upload thumbnail of 640 x 360 dimension.",
                showConfirmButton: false,
                timer: 2000
            })
        })

    }

    const submitLessonForReview = (e) => {

        let data = {
            lessonId: lessonDetails[0].Id
        }
        Axios.put(Constants.BASE_URL + `api/AggregatorLessons/submitLessonForReview`, data, Constants.header).then((result) => {
            getLessonDetailsForTeacher()
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "Lesson submitted for review.",
                    showConfirmButton: false,
                    timer: 2000
                })
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to submit lesson.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        });
    }


    return (
        <div className='right-panel-container text-left pb-4'>
            <div className='container-fluid'>
                {isLoading ? <LoaderComp isLoading={isLoading} /> : null}

                <div className="row mb-4">
                    <div className="col-12">
                        <h2 className="section-name">Upload Lesson</h2>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12 d-flex justify-content-start align-items-baseline flex-wrap">
                        <button onClick={goBack} className="btn"><i className="fa fa-chevron-left me-2"></i>Back</button>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="card border-0 box-shadows">
                            <h6 className="card-header card-title py-3 fw-500">Please fill all the fields.</h6>
                            <ul className="mt-2 ms-4">
                                <li><small>Please upload thumbnails of 640 x 360 dimensions only.</small></li>
                            </ul>
                            <form className="upload-lesson-form" onSubmit={uploadLesson}>
                                <div className="row mb-4">
                                    <div className="col-lg-6 col-md-12">
                                        <label htmlFor="Lesson file" className="form-label">Lesson file</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            placeholder="Lesson File"
                                            aria-label="Lesson File"
                                            id="Lesson file"
                                            required
                                            onChange={(e) => setLessonFile(e.target.files[0])}
                                        />
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <label htmlFor="thumnail1" className="form-label">Thumbnail 1</label>
                                        <input
                                            type="file"
                                            className='form-control'
                                            placeholder="Thumbnail 1"
                                            aria-label="Thumbnail 1"
                                            id="thumnail1"
                                            required
                                            onChange={(e) => setThumbOne(e.target.files[0])}
                                        />

                                        {/* {invalidThumb1 ? <small className="invalid-feedback">
                                            Please upload thumbnail of 640 x 360 dimension only.
                                        </small> : ""} */}
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-lg-6 col-md-12">
                                        <label htmlFor="thumnail2" className="form-label">Thumbnail 2</label>
                                        <input
                                            type="file"
                                            className='form-control'
                                            placeholder="Thumbnail 2"
                                            id="thumnail2"
                                            aria-label="Thumbnail 2"
                                            required
                                            onChange={(e) => setThumbTwo(e.target.files[0])}
                                        />
                                        {/* {invalidThumb2 ? <small className="invalid-feedback">
                                            Please upload thumbnail of 640 x 360 dimension only.
                                        </small> : ""} */}
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <label htmlFor="thumnail3" className="form-label">Thumbnail 3</label>
                                        <input
                                            type="file"
                                            id="thumnail3"
                                            className='form-control'
                                            placeholder="humbnail 3"
                                            aria-label="humbnail 3"
                                            required
                                            onChange={(e) => setThumbThree(e.target.files[0])}
                                        />
                                        {/* {invalidThumb3 ? <small className="invalid-feedback">
                                            Please upload thumbnail of 640 x 360 dimension only.
                                        </small> : ""} */}
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="form-group row mb-4 mt-4">
                                        <label htmlFor="subject" className="col-lg-3 col-sm-2 col-form-label">Subject:</label>
                                        <div className="col-lg-9 col-sm-10">
                                            <CreatableSelect
                                                isClearable
                                                id="subject"
                                                defaultValue={subject}
                                                options={lessonOptions}
                                                className="creatable"
                                                onChange={handleSubject}
                                                required
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="form-floating mb-2">
                                    <label className="upload-lesson-label">Subject</label>
                                    <CreatableSelect isClearable options={lessonOptions} className="creatable input-style" onChange={handleSubject} />
                    
                                </div> */}
                                    <div className="form-group row mb-4">
                                        <label htmlFor="lessonName" className="col-lg-3 col-sm-4 col-form-label">Lesson Name:</label>
                                        <div className="col-lg-9 col-sm-12">
                                            <input
                                                type="text"
                                                className="form-control input-style"
                                                id="lessonName"
                                                value={lessonName}
                                                placeholder="Enter Lesson Name"
                                                onChange={(e) => setLessonName(e.target.value)}
                                                required />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <label htmlFor="tags" className="col-lg-3 col-sm-2 col-form-label">Tags:</label>
                                        <div className="col-lg-9 col-sm-10">
                                            <input
                                                type="text"
                                                className="form-control input-style"
                                                id="tags"
                                                value={tags}
                                                onChange={(e) => setTags(e.target.value)}
                                                placeholder="Ex: Numbers, Integers" />
                                            <small className="text-muted">Enter a comma after each tag. Do not use hashtags(#)</small>
                                        </div>
                                    </div>
                                    {/* <div className="form-group row  mb-4">
                                    <label htmlFor="inputPassword" className="col-lg-3 col-sm-2 col-form-label">Tags</label>
                                    <div className="col-lg-9 col-sm-10">
                                        <input type="text" className="form-control" id="inputPassword" placeholder="Tags" />
                                    </div>
                                </div> */}
                                    <div className="form-group row  mb-4">
                                        <label htmlFor="age" className="col-lg-3 col-sm-2 col-form-label">Age:</label>
                                        <div className="col-lg-9 col-sm-10">
                                            <select name="age" value={age} id="age" onChange={(e) => setAge(e.target.value)} className="form-control input-style" required>
                                                <option value="">Select age</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group row  mb-4">
                                        <label htmlFor="region" className="col-lg-3 col-sm-2 col-form-label">Region:</label>
                                        <div className="col-lg-9 col-sm-10">
                                            <select name="Region" value={region} id="region" onChange={(e) => setRegion(e.target.value)} className="form-control input-style" required>
                                                <option value="">Select Region</option>
                                                <option value="India">India</option>
                                                <option value="International">International</option>
                                            </select>
                                        </div>
                                    </div>
                                    {/* <div className="form-floating mb-2">
                                    <select name="Region" id="region" className="form-control input-style" required>
                                        <option value="">Select Region</option>
                                        <option value="India">India</option>
                                        <option value="International">International</option>
                                    </select>
                                    <label htmlFor="floatingInput">Region</label>
                                </div> */}
                                    <div className="form-group row mb-4">
                                        <label htmlFor="description" className="col-lg-3 col-sm-2 col-form-label">Description:</label>
                                        <div className="col-lg-9 col-sm-10">
                                            <textarea
                                                className="form-control input-style"
                                                maxLength={1000}
                                                value={desc}
                                                onChange={(e) => setDesc(e.target.value)}
                                                placeholder="Enter Description"
                                                id="description"
                                                required
                                            ></textarea>
                                            <small className="text-muted">Maximum of 1000 characters.</small>
                                        </div>
                                    </div>
                                    {/* <div className="form-floating">
                                    <textarea className="form-control input-style" placeholder="Leave a comment here" id="floatingTextarea2"></textarea>
                                    <label htmlFor="floatingTextarea2">Description</label>
                                    <span className="fs-6 ms-auto text-muted">Maximum of 1000 characters.</span>
                                </div> */}
                                </div>
                                <div className="mt-4 w-100">
                                    <button type="submit" className={lessonDetails.length > 0 ? 'btn text-dark w-100 disabled' : 'btn btn-blue text-light w-100'}>Upload Lesson</button>
                                </div>
                                <div className="mt-4 w-100">
                                    <button
                                        onClick={submitLessonForReview}
                                        type="button"
                                        className={lessonDetails[0]?.Approved == 0 ? 'btn btn-blue text-light w-100' : 'btn text-dark w-100 disabled'}>
                                        Submit for review
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 my-3">
                        {/* <Chats /> */}
                    </div>
                </div>


            </div >
        </div >
    )
}
export default MainLesson