function Interaction() {


    let interaction = [

        {
            name: 'Allowed Move Directions',
            desc: `This specifies the movement of the feature on the scene. It can be set to free, vertical, horizontal or none.`
        },

        {
            name: 'Allow Rotate',
            desc: `If this value is set to true, the feature can freely rotate.`
        },
        {
            name: 'Allow Resize',
            desc: `If this value is set to true, the feature is enabled to resize.`
        },
        {
            name: 'Max Zoom In',
            desc: `This value specifies the maximum amount that an image can be zoomed in.`
        },
        {
            name: 'Min Zoom Out',
            desc: `This value specifies the minimum amount that an image can be zoomed out.`
        },

    ]

    return (
        <>
            <h3 className="sub-title-2">Interaction</h3>
            <ul className="property-list">
                {
                    interaction.map((element, index) => {
                        return (
                            <li>
                                <span className="property">{element.name} : </span> {element.desc}
                            </li>
                        )
                    })
                }
            </ul>
        </>
    )
}
export default Interaction;