import { Link } from "react-router-dom/cjs/react-router-dom.min"
import Header from "../../header/header"
import { Activity, Download, FileDown, FileText, Youtube } from 'lucide-react';
function Composer(props) {
    return (
        <div className="right-panel-container">
            <div className="container-fluid ">
                <p className='section-name mb-4'>Composer</p>
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="card border-0 box-shadows mb-4">
                            <div className="card-body">
                                <div className=" d-flex  content-box-header">
                                    {/* <i className="fa fa-download fa-2x me-4" aria-hidden="true"></i> */}
                                    <Download className="me-4" color="#837DFF" size={50} />
                                    <div>
                                        <h5 className="content-box-title">Download Composer</h5>
                                        <p className="content-box-subtitle">Download recent version of composer</p>
                                        <a href="http://122.248.229.117:8090/api/downloadComposer" className="btn btn-blue text-white">Download Composer</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="card border-0 box-shadows mb-4">
                            <div className="card-body">
                                <div className=" d-flex  content-box-header">
                                    {/* <i className="fa fa-youtube-play fa-5x me-4 color-blue" aria-hidden="true"></i> */}

                                    <Youtube className="me-4" color="#837DFF" size={50} />
                                    <div className="">
                                        <h5 className="content-box-title">Youtube Tutorials</h5>
                                        <p className="content-box-subtitle">Check our tutorials on youtube</p>
                                        <a href='https://www.youtube.com/@baethokids/videos' target="_blank" className="btn btn-blue text-white">Tutorials</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="card border-0 box-shadows mb-4">
                            <div className="card-body">
                                <div className=" d-flex content-box-header">
                                    {/* <i className="fa fa-file fa-2x me-4 color-blue" aria-hidden="true"></i> */}
                                    <FileText className="me-4" color="#837DFF" size={50} />
                                    <div>
                                        <h5 className="content-box-title">Documentation</h5>
                                        <p className="content-box-subtitle">Please check our docs</p>
                                        <Link to='/documentation' target="_blank" className="btn btn-blue text-white">Documentation</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default Composer