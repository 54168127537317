import { Link } from 'react-router-dom';
import BaethoLogo from '../../../assets/images/baetho-kids-logo.png'
import Navbar from '../navbar/navbar';

const PrivacyPolicy = () => {

    return (
        <div className=''>
            <Navbar />

            <div className='container text-left px-4 pb-5'>
                <h5 className='my-4 fw-bold'>Privacy Policy</h5>

                <p className='headline fw-bold'>About Us</p>
                <p className=''>
                    Baetho Technologies Private Limited (the “Company”, “Baetho” or
                    “we” or “us” or “our”) are committed to protecting your Personal Data and supports the right
                    to your privacy. This Privacy Policy explains how Baetho collects, uses, shares and processes
                    your personal data.
                </p>
                <p>
                    We understand how important privacy is to you, When you use our services,
                    you’re trusting us with your information and we are committed to creating a safe
                    and secure environment for our users. Please read this Privacy Policy closely.
                    By using the Services, you agree to the handling of your information in accordance with
                    this Privacy Policy.
                </p>

                <p className='headline fw-bold'>Information We Collect</p>
                <p className=''>
                    In order to use most of our Services, you will be required to create an account. Your account allows you to use all the Platform's Services.
                </p>
                <p>When you are registering to our service you will provide us the below information.</p>
                <p> a&#41; As a Parent : </p>
                <ul>
                    <li>First Name</li>
                    <li>Last Name</li>
                    <li>Email</li>
                    <li>Password</li>
                    <li>Phone Number</li>
                    <li>Date of Birth</li>
                    <li>Country</li>
                </ul>
                <p>Parents are allowed to add kids with the below mentioned information.</p>
                <span> Kid :</span>
                <ul>
                    <li>First Name</li>
                    <li>Last Name</li>
                    <li>Date of Birth</li>
                    <li>Gender</li>
                    <li>Interested subjects(Optional)</li>
                </ul>
                <p>b&#41; As a Teacher :</p>
                <ul>
                    <li>First Name</li>
                    <li>Last Name</li>
                    <li>Email</li>
                    <li>Password</li>
                    <li>Phone Number</li>
                    <li>Date of Birth</li>
                    <li>Country</li>
                    <li>Subjects</li>
                    <li>Experience</li>
                    <li>Bio</li>
                </ul>
                <p>
                    All content created by the teacher is reviewed before it gets uploaded on the application.
                    We may require the device id for analytical purposes.
                </p>

                <p className='headline fw-bold'>How we use Information</p>
                <p>We use it for the following purposes:</p>
                <ul>
                    <li>
                        To help provide personalized activity suggestion, and to understand and improve our Service and to keep our Service safe and secure.
                    </li>
                    <li>We use the Email and password to authenticate the user at the time of login.</li>
                    <li> During the onboarding process we will ask the parent to provide their child’s date of birth, this is to suggest activities that are relevant to that age group.</li>
                    <li>To respond to customer support questions and issues and resolve disputes.</li>
                    <li>
                        To create, review, analyze and share technical information.
                    </li>
                    <li>
                        To advertise our Services on third-party websites or mobile applications.
                    </li>
                    <li>
                        To market, process or fulfill promotions administered or sponsored by us.
                    </li>
                    <li>
                        To send emails, WhatsApp messages, and other communications according to your preferences or that display
                        content that we think may interest you including reports, new features and promotional offers, etc.
                    </li>
                    <li>
                        To monitor, analyze and describe usage patterns and performance of the service,
                        including aggregate metrics such as total number of visitors, traffic, and demographic patterns.
                    </li>
                    <li>
                        To improve Our services, including testing, research, internal analytics and product development.
                    </li>
                </ul>

                <p className='headline fw-bold'>Data retention</p>
                <ul>
                    <li> User’s data will be retained as long as the user wishes to stay with us.</li>
                    <li> Users have an option to delete their account at any point of time.</li>
                </ul>

                <p className='headline fw-bold'>Information security</p>
                <p>Baetho has implemented administrative and technical safeguards it believes are appropriate
                    to protect the confidentiality, integrity and availability of your personal data and access credentials.</p>

                <p className='headline fw-bold'>Updates to the Privacy Notice</p>
                <p>
                    We reserve the right to amend, modify, vary or update this Privacy Notice,
                    at Our sole discretion from time to time, as and when the need arises. The most recently
                    published Privacy Notice shall prevail over any of its previous versions. You are encouraged
                    to check this Privacy Notice from time to time to stay informed of any changes.
                </p>


                {/* <p className='headline fw-bold'>Advertisement</p>
                <p>The external parties in this section enable us to showcase relevant ads to users.</p>
                <p>Google Admob - Google Admob provided by Google LLC (“Google”) is what we use for in app ads.
                    Please go through their Privacy Policy to learn more about their Personal Data processing practices.
                    We do our utmost to ensure all settings are in place for appropriate content only. </p> */}

                <p className='headline fw-bold'>Contact Us</p>
                <p> Email: support@baetho.app</p>
                <p>
                    Address: BAETHO TECHNOLOGIES PVT LTD<br></br>
                    no.198, Vinayaka Complex,<br></br>
                    2nd main, Old Airport Road, Domlur,<br></br>
                    Bangalore North, Bangalore 560071<br></br>
                </p>
            </div>
        </div>
    )
}

export default PrivacyPolicy;