function Basic() {
    let basic = [
        {
            name: 'Feature Name',
            desc: "This is the feature's name that can be modified from its default value."
        },
        {
            name: 'X',
            desc: 'This is the horizontal center point of the feature on the scene.'
        },
        {
            name: 'Y',
            desc: 'This is the vertical center point of the feature on the scene.'
        },
        {
            name: 'Width',
            desc: 'This is the width of the feature.'
        },
        {
            name: 'Height',
            desc: 'This is the height of the feature.'
        },
        {
            name: 'Angle',
            desc: 'This is the angle of the feature specified in degrees.'
        },
        {
            name: 'Opacity',
            desc: 'This is a value that specifies the transparency of the feature. 0 is fully transparent and 1 is fully opaque.'
        },
    ]
    return (
        <>
            <h3 className="sub-title-2">Basic</h3>
            <ul className="property-list">
                {
                    basic.map((element, index) => {
                        return (
                            <li>
                                <span className="property">{element.name} : </span> {element.desc}
                            </li>
                        )
                    })
                }
            </ul>
        </>
    )
}

export default Basic;