import { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Constants from '../../../assets/helper/constants'
import Logo from '../../../assets/images/Baetho-Logo-white.png'

function AdminLeftPanel(props) {

    const [clicked, setClicked] = useState(false)

    let tabs = [
        {
            id: 1,
            name: "Profile Update",
            activeLink: "profile-update",
            link: Constants.ProfileUpdate,
            icon: "fa fa-user fa-lg"
        },
        {
            id: 2,
            name: "Validate Lesson",
            activeLink: "admin-lessons",
            link: Constants.AdminProject,
            icon: "fa fa-book fa-lg"
        },
        {
            id: 3,
            name: "Analytics",
            activeLink: "analytics",
            link: Constants.Analytics,
            icon: "fa fa-bar-chart fa-lg"
        },
        {
            id: 4,
            name: "Lesson Performance",
            activeLink: "performance",
            link: Constants.Performance,
            icon: "fa fa-line-chart fa-lg"
        },
    ];

    const setActive = (id) => {
        props.checkPath(id)
    }
    const toggleMenu = () => {
        setClicked(!clicked);
    }


    return (
        <>
            <div className="offcanvas offcanvas-start sidebar-nav sidebar-nav bg-blue text-white shadow" data-bs-scroll="true" data-bs-backdrop tabIndex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
                <div className="offcanvas-header ms-auto">
                    <button type="button " className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-0 ">
                    <div className='leftpanel-logo'>
                        <img src={Logo} alt="Baetho Logo" />
                    </div>
                    <ul className="nav flex-column gap-2 left-panel-menubar mb-4">
                        {tabs.map((element) => {
                            return (
                                <li key={element.id} className={props.activeLink === element.activeLink ? "d-flex justify-content-stretch align-items-baseline nav-item active-menu  ps-3" : "d-flex justify-content-start align-items-baseline nav-item inactive ps-3"} onClick={(e) => { setActive(element.activeLink) }}>
                                    <i className={props.activeLink === element.activeLink ? `leftpanel-icons-black ${element.icon}` : `leftpanel-icons-white ${element.icon}`} aria-hidden="true"></i>
                                    <Link to={element.link} className={props.activeLink === element.activeLink ? "disable-link nav-link w-100" : "nav-link w-100"} onClick={toggleMenu}>

                                        <span className={props.activeLink === element.activeLink ? 'text-black ' : 'text-white'}>{element.name}</span>
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>








            {/* <div className="mobile-menu-icon" onClick={toggleMenu}>
                <i className={clicked ? "fa fa-times fa-2x" : "fa fa-bars fa-2x"}></i>
            </div>
            <div className={clicked ? "admin-panel-backdrop" : "backdrop-inactive"} onClick={toggleMenu}></div>
            <div className={clicked ? "left-panel-container mobile-left-panel mobile-container" : "left-panel-container mobile-container"}>
                <div className='leftpanel-logo'>
                    <img src={Logo} alt="Treehouse Logo" />
                </div>
                <ul className="nav flex-column gap-2 left-panel-menubar mb-4">
                    {tabs.map((element) => {
                        return (
                            <li key={element.id} className={props.activeLink === element.activeLink ? "d-flex justify-content-stretch align-items-baseline nav-item active" : "d-flex justify-content-start align-items-baseline nav-item inactive"} onClick={(e) => { setActive(element.activeLink) }}>
                                <i className={props.activeLink === element.activeLink ? `leftpanel-icons-black ${element.icon}` : `leftpanel-icons-white ${element.icon}`} aria-hidden="true"></i>
                                <Link to={element.link} className={props.activeLink === element.activeLink ? "disable-link nav-link" : "nav-link"} onClick={toggleMenu}>

                                    <span className={props.activeLink === element.activeLink ? 'text-black ' : 'text-white'}>{element.name}</span>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </div> */}
        </>
    )
}

export default AdminLeftPanel;