import Axios from 'axios'
import * as Constants from '../../assets/helper/constants'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

function AppDownloadCapture() {

    let location = useLocation()


    useEffect(() => {
        let name = location.pathname.split('/store/')[1]

        let os = ''
        let userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf('android') > -1) {
            os = "Android"
        } else if (userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipod') > -1 || userAgent.indexOf('ipad') > -1 || userAgent.indexOf('iOS') > -1) {
            os = "IOS"
        } else {
            os = "Android"
        }

        Axios.get(Constants.BASE_URL + `api/aggregator/appDownloadRequest?source=${name}&platform=${os}`).then((result) => {
            if (result.data.status) {

                if (userAgent.indexOf('android') > -1) {
                    window.location.href = `https://play.google.com/store/apps/details?id=com.baetho.aggregator&referrer=${name}`;
                } else if (userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipod') > -1 || userAgent.indexOf('ipad') > -1 || userAgent.indexOf('iOS') > -1) {
                    window.location.href = `https://apps.apple.com/in/app/baetho-the-activity-store/id6447284412?referrer=${name}`;
                } else {
                    window.location.href = `https://play.google.com/store/apps/details?id=com.baetho.aggregator&referrer=${name}`;
                }
            } else {
                console.log('error')

                if (userAgent.indexOf('android') > -1) {
                    window.location.href = `https://play.google.com/store/apps/details?id=com.baetho.aggregator&referrer=${name}`;
                } else if (userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipod') > -1 || userAgent.indexOf('ipad') > -1 || userAgent.indexOf('iOS') > -1) {
                    window.location.href = `https://apps.apple.com/in/app/baetho-the-activity-store/id6447284412?referrer=${name}`;
                } else {
                    window.location.href = `https://play.google.com/store/apps/details?id=com.baetho.aggregator&referrer=${name}`;
                }
            }
        })
    }, [])


    return (
        <div>
            <p style={{ marginTop: '20px', textAlign: 'center' }}>
                Redirecting Please wait..
            </p>
        </div>
    )

}

export default AppDownloadCapture