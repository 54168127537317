import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function CreateProjectPopup(props) {

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <p className='popup-title'>Create Lesson</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-4'>

                {/* {props.children} */}


                <form onSubmit={props.submitProject}>
                    <div className="form-group mb-4">
                        <label htmlFor="formGroupExampleInput">Lesson Title</label>
                        <input type="text" className="form-control input-style" id="formGroupExampleInput" placeholder="Title" required onChange={props.lessonTitle} />
                    </div>
                    <div className="form-group mb-4">
                        <label htmlFor="formGroupExampleInput2">Upload Lesson Plan<span className='input-info'>(Allowed file type: .pdf, .doc, .docx, .ppt, .pptx)</span></label>
                        <input type="file" className="form-control input-style" id="formGroupExampleInput2" required onChange={props.lessonPlan} />
                    </div>
                    <div className="form-group mb-4">
                        <label htmlFor="formGroupExampleInput3">Description</label>
                        <textarea type="" className="form-control input-style" id="formGroupExampleInput3" placeholder="Description" required onChange={props.lessonDesc} />
                    </div>
                    <div>
                        <button className='btn btn-blue text-light w-100'>Submit</button>
                    </div>

                </form>

            </Modal.Body>
            {/* <Modal.Footer>

            </Modal.Footer> */}
        </Modal>
    );
}

export default CreateProjectPopup;