import { useEffect, useState } from "react";
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import Axios from 'axios'
import Swal from 'sweetalert2';
import * as Constants from '../../../assets/helper/constants'

function Metrics(props) {

    const [metrics, setMetrics] = useState('')
    // const [osFilter, setOsFilter] = useState('')
    // const [time, setTime] = useState('')

    // const [fromDate, setFromDate] = useState('')
    // const [toDate, setToDate] = useState('')
    // const [customDateOs, setCustomDateOs] = useState('')

    //Line chart states
    const [lineChartMetrics, setLineChartMetrics] = useState('')
    // const [fromDateLineChart, setFromDateLineChart] = useState('')
    // const [toDateLineChart, setToDateLineChart] = useState('')
    // const [osLineChart, setOsLineChart] = useState('')


    const [durationOne, setDurationOne] = useState('')
    const [fromDateOne, setFromDateOne] = useState('')
    const [toDateOne, setToDateOne] = useState('')
    const [osFilterOne, setOsFilterOne] = useState('')


    const onChangeHandler = (setter, value) => {

        if (durationOne !== 'CUSTOM') {
            setFromDateOne('')
            setToDateOne('')
        }
        setter(value)
    }


    useEffect(() => {
        // getMetrics("WEEK")
    }, [])




    const getMetrics = (e) => {

        let link = ''
        if (durationOne === 'CUSTOM') {
            if (fromDateOne === '' || toDateOne === '') {
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: "Please select from and to date.",
                    showConfirmButton: false,
                    timer: 2000
                })
                return
            } else if (fromDateOne > toDateOne) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: "From-date cannot be greater than To-date.",
                    showConfirmButton: false,
                    timer: 2000
                })
                return
            } else {
                link = `api/dashboard/usageMetrics?time=${durationOne}&os=${osFilterOne}&from=${fromDateOne}&to=${toDateOne}`
            }
        } else {
            if (durationOne === '') {
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: "Please select time duration.",
                    showConfirmButton: false,
                    timer: 2000
                })
                return
            } else {
                link = `api/dashboard/usageMetrics?time=${durationOne}&os=${osFilterOne}`
            }
        }


        if (osFilterOne === '') {
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: "Please select OS type.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }


        Axios.get(Constants.BASE_URL + link).then((result) => {
            if (result.data.status) {
                setMetrics(result.data.result)

                //count total number or registered user on a particular day
                // const newArray = result.data.registers.reduce((result, obj) => {
                //     const existingItem = result.find(item => item.date === obj.date);
                //     if (existingItem) {
                //         existingItem.registered += obj.registered;
                //     } else {
                //         result.push({ date: obj.date, registered: obj.registered });
                //     }
                //     return result;
                // }, []);
                // console.log('regi', newArray)

                // Create an empty object to store merged data
                //const mergedData = {};

                // Merge clicksData into mergedData
                // for (const item of result.data.clicks) {
                //     const { date, clicks } = item;
                //     mergedData[date] = { ...mergedData[date], date, clicks, registered: 0 };
                // }

                // Merge registeredData into mergedData
                // for (const item of newArray) {
                //     const { date, registered } = item;
                //     mergedData[date] = { ...mergedData[date], date, clicks: 0, registered };
                // }

                // Convert mergedData object into an array
                // const mergedArray = Object.values(mergedData);

                //mergedArray.sort((a, b) => new Date(a.date) - new Date(b.date));

                //  setLineChartMetrics(mergedArray)
                // console.log('date', mergedArray)

                // const merged = result.data.clicks.map(obj1 => {
                //     const matchingObj2 = newArray.find(obj2 => obj2.date === obj1.date);
                //     const registered = matchingObj2 ? matchingObj2.registered : 0;
                //     return { ...obj1, registered };
                // });
                // setLineChartMetrics(merged)
                // console.log('merge', newArray)

                const consolidatedData = [];
                const dateMap = {};

                for (const obj of result.data.clicks) {
                    const { date, total_clicks, total_registrations } = obj;

                    if (dateMap[date]) {
                        dateMap[date].total_clicks += total_clicks;
                        dateMap[date].total_registrations += total_registrations;
                    } else {
                        dateMap[date] = { ...obj };
                    }
                }

                for (const date in dateMap) {
                    consolidatedData.push(dateMap[date]);
                }
                setLineChartMetrics(consolidatedData)
                console.log('data', consolidatedData)

            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to fetch details.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }


    return (
        <div className="card mb-4">
            <h5 className="card-header">Clicks & Registers</h5>
            <div className="card-body">
                <div className="row mb-2 align-items-end">
                    <div className="col-2">
                        <label className="mb-2">Duration</label>
                        <select className="form-select" onChange={(e) => onChangeHandler(setDurationOne, e.target.value)} aria-label="Default select example">
                            <option value="">Select</option>
                            <option value="WEEK">Last 7 Days</option>
                            <option value="MONTH">Last 30 Days</option>
                            <option value="6_MONTHS">Last 6 Months</option>
                            <option value="YEAR">Last Year</option>
                            <option value="CUSTOM">Custom Date</option>
                        </select>
                    </div>

                    <div className="col-2">
                        <label className="mb-2">Operating System</label>
                        <select className="form-select" onChange={(e) => onChangeHandler(setOsFilterOne, e.target.value)} aria-label="Default select example">
                            <option value="">Select</option>
                            <option value="0">Android</option>
                            <option value="1">iOS</option>
                            <option value="2">Android & iOS</option>
                        </select>
                    </div>
                    <div className="col-2">
                        <button className="btn bg-blue text-light" onClick={(e) => getMetrics(e)}>Submit</button>
                    </div>
                </div>
                <div className="row mb-4">
                    {
                        durationOne === 'CUSTOM' &&
                        (
                            <>
                                <div className="col-2">
                                    <label>From</label>
                                    <input type="date" className="form-control" onChange={(e) => onChangeHandler(setFromDateOne, e.target.value)} required />
                                </div>
                                <div className="col-2">
                                    <label>From</label>
                                    <input type="date" className="form-control" onChange={(e) => onChangeHandler(setToDateOne, e.target.value)} required />
                                </div>
                            </>
                        )
                    }
                </div>
                <div className="row mb-4">
                    <div className="col-lg-5 col-md-12 border">
                        <div className="row mb-4">
                            {/* <div className="col-3">
                                <select className="form-select" onChange={(e) => setTime(e.target.value)} aria-label="Default select example">
                                    <option value="">Select</option>
                                    <option value="WEEK">Last 7 Days</option>
                                    <option value="MONTH">Last 30 Days</option>
                                    <option value="6_MONTHS">Last 6 Months</option>
                                    <option value="YEAR">Last Year</option>
                                </select>
                            </div>
                            <div className="col-3">
                                <select className="form-select" onChange={(e) => setOsFilter(e.target.value)} aria-label="Default select example">
                                    <option value="">Select</option>
                                    <option value="0">Android</option>
                                    <option value="1">IOS</option>
                                </select>
                            </div>
                            <div className="col-3">
                                <button className="btn bg-blue text-light" onClick={(e) => getMetrics(e)}>Submit</button>
                            </div> */}
                            {/* <div className="col-2">
                            <button className="p-3 btn btn-info " onClick={(e) => getMetrics('WEEK', e)}> Last 7 Days</button>
                        </div>
                        <div className="col-2">
                            <button className="p-3 btn btn-info" onClick={(e) => getMetrics('MONTH', e)}> Last 30 Days</button>
                        </div>
                        <div className="col-2">
                            <button className="p-3 btn btn-info" onClick={(e) => getMetrics('6_MONTHS', e)}> Last 6 Months</button>
                        </div>
                        <div className="col-2">
                            <button className="p-3 btn btn-info" onClick={(e) => getMetrics('YEAR', e)}> Last Year</button>
                        </div> */}
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <BarChart
                                    width={600}
                                    height={300}
                                    data={metrics}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="clicks" fill="#8884d8" />
                                    <Bar dataKey="registered" fill="#82ca9d" />

                                    {/* <Bar dataKey="androidClicks" stackId="a" fill="#8884d8" />
                                    <Bar dataKey="iosClicks" stackId="a" fill="#82ca9d" /> */}
                                </BarChart>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 border">
                        {/* <div className="mb-5">
                        <h5>Clicks & Registers</h5>
                    </div> */}
                        <div className="row">
                            <div className="col-6 mt-5">
                                <LineChart
                                    width={900}
                                    height={300}
                                    data={lineChartMetrics}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="date" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" name="clicks" dataKey="total_clicks" stroke="#8884d8" activeDot={{ r: 8 }} />
                                    <Line type="monotone" name="registered" dataKey="total_registrations" stroke="#82ca9d" />
                                </LineChart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Metrics