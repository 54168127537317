import { Link } from 'react-router-dom';

import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import * as Constants from '../../../assets/helper/constants'
import Navbar from '../navbar/navbar';
import PricingModal from './pricingModal';
import { useHistory } from "react-router-dom";
import { sendFeedback } from '../../../apis/api';
import BaethoLogo from '../../../assets/images/logo192.png'
import axios from 'axios';
import PricingCard from './PricingCard';


function Pricing(props) {

    const [user, setUser] = useState(null)
    const [premiumMember, setPremiumMember] = useState(false)
    const [feedbackType, setFeedbackType] = useState("")
    const [feedbackText, setFeedbackText] = useState("")
    const [callbackCheckbox, setCallbackCheckbox] = useState(false)
    const [loading, setLoading] = useState(false)
    const [packagesINR, setPackagesINR] = useState([])
    const [packagesUSD, setPackagesUSD] = useState([])
    const [showPricingModal, setShowPricingModal] = useState(false)

    const history = useHistory()

    // const weekly = "169.00"
    // const threeMonth = "1200.00"
    // const sixMonth = "1600.00"
    // const yearly = "2000.00"

    useEffect(() => {

        getPackages()

        let userDetails = JSON.parse(localStorage.getItem("userDetails"))

        if (userDetails !== null & userDetails !== undefined) {
            setUser(userDetails)
            let isPremium = userDetails.PremiumMember
            let expires = new Date(userDetails.Expires)
            let today = new Date()

            if (isPremium === 1 && expires >= today) {
                setPremiumMember(true)
            } else {
                setPremiumMember(false)
            }
        } else {
            setPremiumMember(false)
        }
    }, [premiumMember])


    const getPackages = () => {

        axios.get(Constants.BASE_URL + `api/aggregator/getExternalPackages`).then((response) => {
            if (response.data.result) {
                let inrPackage = []
                let usdPackage = []
                response.data.data.forEach(element => {
                    if (element.Currency === "INR") {
                        inrPackage.push(element)
                    } else {
                        usdPackage.push(element)
                    }
                });
                setPackagesINR(inrPackage)
                setPackagesUSD(usdPackage)
            } else {
                setPackagesINR([])
                setPackagesUSD([])
            }
        })
    }

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement('script')
            script.src = src
            script.onload = () => {
                resolve(true)
            }
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script)
        })
    }
    async function displayRazorpay(plan) {
        // setLoading(true)
        // let userDetails = JSON.parse(localStorage.getItem("userDetails"))
        //if user not logged in, redirect to login page.
        if (user === null || user === undefined) {

            history.push(Constants.Login + "?redirect=pricing")

        } else {
            try {
                const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
                // console.log("res", res);
                if (!res) {
                    alert('Razorpay SDK failed to load. Are you online?')
                    return
                }
            } catch (e) {
                console.log("e", e);
            }
            // const data = await fetch('http://127.0.0.1:8090/razorpay', { method: 'POST' }).then((t) =>
            // 	t.json()
            // )

            // console.log(data)

            // const options = {
            // 	key: 'rzp_test_YFDWEMLgjhIlOX',
            // 	currency: data.currency,
            // 	amount: data.amount.toString(),
            // 	order_id: data.id,
            // 	name: 'Donation',
            // 	description: 'Thank you for nothing. Please give us some money',
            // 	// image: 'http://localhost:3000/logo.svg',
            // 	handler: function (response) {
            // 		alert(response.razorpay_payment_id)
            // 		alert(response.razorpay_order_id)
            // 		alert(response.razorpay_signature)
            // 	},
            // 	// prefill: {
            // 	// 	// name,
            // 	// 	email: 'pradeep@baetho.com',
            // 	// 	phone_number: '9741670405'
            // 	// }
            // }

            let planId = ""
            let description = ""
            if (plan === 'weekly') {
                planId = "plan_NlLbazAav5mGFp"
                description = "Weekly Test Plan"
            } else if (plan === '3month') {
                planId = "plan_NlLahl3LhZQzJX"
                description = "3 Month Test Plan"
            } else if (plan === '6month') {
                planId = "plan_NlLa5lV4ixnECh"
                description = "6 Month Test Plan"
            } else if (plan === 'yearly') {
                planId = "plan_NfoxmGiDx0ipcr"
                description = "Yearly Test Plan"
            }

            const val = {
                plan_id: planId,
                offer_id: "",
                userId: user.Id
            };
            const customHeaders = {
                "Content-Type": "application/json",
            }
            const data = await fetch('http://127.0.0.1:8090/api/aggregator/createSubscriptions/', {
                method: 'POST',
                body: JSON.stringify(val),
                headers: customHeaders
            }).then((t) =>
                t.json()
            )
            // console.log(data)
            var options = {
                "key": "rzp_test_YFDWEMLgjhIlOX",
                "subscription_id": data.id,
                "name": "Baetho",
                "description": description,
                // "image": "/your_logo.png", 
                "handler": function (response) {
                    // alert(response.razorpay_payment_id)
                    // alert(response.razorpay_subscription_id)
                    // alert(response.razorpay_signature)
                    const data = fetch('http://127.0.0.1:8090/api/aggregator/paymentVerification/', { method: 'POST', body: JSON.stringify(response), headers: customHeaders }).then((t) =>
                        console.log("paymentVerification", t)
                    )

                },
                "prefill": {
                    "name": "Pradeep",
                    "email": "pradeep@baetho.app",
                    "contact": "+919741670405"
                },
                "theme": {
                    "color": "#837DFF"
                }
            }
            const paymentObject = new window.Razorpay(options)
            paymentObject.open()
        }

    }
    /*
    function handleSubscription(){
    var options = { 
      "key": "key_id",
      "subscription_id": "sub_00000000000001", 
      "name": "Acme Corp.", 
      "description": "Monthly Test Plan", 
      "image": "/your_logo.png", 
      "handler": function(response) { 
         alert(response.razorpay_payment_id)        alert(response.razorpay_subscription_id)      alert(response.razorpay_signature)
      }, 
      "prefill": { 
         "name": "Gaurav Kumar", 
         "email": "gaurav.kumar@example.com", 
         "contact": "+919876543210" 
      }, 
      "notes": { 
         "note_key_1": "Tea. Earl Grey. Hot", 
         "note_key_2": "Make it so." 
      }, 
      "theme": { 
      "color": "#F37254" 
      } 
    }; 
    var paymentObject = new Razorpay(options);
    paymentObject.open()
    }
    
    */


    async function craeteOrder(amount, currency, packageId) {

        if (user === null || user === undefined) {

            history.push(Constants.Login + "?redirect=pricing")

        } else {
            try {
                const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
                // console.log("res", res);
                if (!res) {
                    alert('Razorpay SDK failed to load. Are you online?')
                    return
                }
            } catch (e) {
                console.log("e", e);
            }

            const val = {
                amount: amount,
                currency: currency,
            };
            const customHeaders = {
                'dashboard': true,
                "Content-Type": "application/json",
            }

            var options = {
                method: 'POST',
                url: Constants.BASE_URL + 'api/aggregator/createOrder/',
                headers: customHeaders,
                data: val
            };

            //'rzp_test_YFDWEMLgjhIlOX'
            try {
                axios.request(options).then((result) => {

                    const options = {
                        key: 'rzp_live_sSgCgOBJ2Gacap',
                        currency: result.data.currency,
                        amount: result.data.amount,
                        order_id: result.data.id,
                        name: 'Baetho',
                        description: 'Baetho website payment.',
                        handler: function (response) {

                            if (response.razorpay_order_id != undefined) {
                                // alert("Payment Successful");
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: "Payment Successful.",
                                    showConfirmButton: false,
                                    timer: 2000
                                })

                                let data = {
                                    userID: user.Id,
                                    packageID: packageId,
                                    paymentID: response.razorpay_payment_id,
                                    orderID: response.razorpay_order_id
                                }
                                axios.post(Constants.BASE_URL + `api/aggregator/updateExternalPayment`, data, Constants.header).then(() => {
                                    console.log('payment done');
                                })
                            }

                        },
                        "prefill": {
                            "name": user.FirstName + " " + user.LastName,
                            "email": user.Email,
                            "contact": user.CountryCode + user.Phone
                        },
                        notes: {
                            "userID": user.Id,
                            "packageID": packageId
                        },
                        "theme": {
                            "color": "#837DFF"
                        },
                    }

                    const paymentObject = new window.Razorpay(options)
                    paymentObject.open();

                }).catch((err) => {
                    console.log('Error:', err);
                })
            } catch (error) {
                console.log('Error:', error);

            }


        }

    }


    const toggleModal = (e) => {
        setShowPricingModal(!showPricingModal)
    }
    const feedbackHandler = (e) => {

        e.preventDefault()

        if (feedbackType.trim() === "" || feedbackType === "") {

            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: "Please select 'Feedback Type'.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }
        if (feedbackText.trim() === "" || feedbackText === "") {

            Swal.fire({
                position: 'top-end',
                icon: 'error',
                html: '<h4>Please type your feedback.</h4>',
                showConfirmButton: false,
                timer: 2000
            })
            return
        }

        let data = {
            userId: user.Id,
            feedbackType: feedbackType,
            feedbackDetails: feedbackText,
            callbackRequested: callbackCheckbox ? 1 : 0
        }

        sendFeedback(data)

    }
    return (
        <>
            <div className='pricing-main'>
                {showPricingModal && <PricingModal show={showPricingModal} setShow={setShowPricingModal} onHide={toggleModal} />}

                <Navbar />
                {
                    premiumMember ?
                        <div className='feedback-section'>
                            <div className='container'>
                                <p className='premium-member-text'>Congratulations, you are subscribed to Baetho Premium Membership.</p>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='pricing-feedback-container'>
                                            <form onSubmit={feedbackHandler}>
                                                <div className='feedback-title'>
                                                    <p className='feedback-text'>Submit Your Feedback</p>
                                                    <p className='feedback-caption'>Let us know how we're doing. We appreciate your feedback!</p>
                                                </div>
                                                <select
                                                    required className="form-select mb-4"
                                                    aria-label="Default select example"
                                                    onChange={(e) => setFeedbackType(e.target.value)}
                                                >
                                                    <option value="">Feedback Type</option>
                                                    <option value="General Feedback">General Feedback</option>
                                                    <option value="Suggestion">Suggestion</option>
                                                    <option value="Complaint">Complaint</option>
                                                </select>

                                                <textarea
                                                    required
                                                    className='form-control mb-4'
                                                    type='text'
                                                    placeholder='Type your feedback here..'
                                                    onChange={(e) => setFeedbackText(e.target.value)}
                                                />
                                                <div className='d-flex align-items-center gap-2 mb-4'>
                                                    <input
                                                        id='getCallBack'
                                                        type='checkbox'
                                                        checked={callbackCheckbox}
                                                        onChange={(e) => setCallbackCheckbox(!callbackCheckbox)} />
                                                    <label htmlFor='getCallBack' className='m-0 mt-2'>Get a call back</label>
                                                </div>
                                                <button
                                                    className='btn bg-blue text-light'
                                                    type='submit'>
                                                    Submit Feedback
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            <div className='pricing-section'>
                                <div className='container'>
                                    <div className='pricing-title'>
                                        <h1>PREMIUM BENEFITS</h1>
                                    </div>
                                    <div className='benefits-container'>
                                        <p className='benefits-heading'>UNLOCK MORE LESSONS</p>
                                        <ul>
                                            <li>Access to all new lessons being uploaded weekly</li>
                                            <li>Give your child the best content</li>
                                        </ul>
                                        <p className='benefits-heading'>UNLOCK THE ADVENTURE ACADEMY</p>
                                        <ul>
                                            <li>Courses in Math, English and Science</li>
                                            <li>Designed to improve your child's skill in each subject</li>
                                            <li>Improve your child's reading</li>
                                            <li>Give them a thorough understanding of Math concepts</li>
                                            <li>Improve your child's general knowledge</li>
                                            <li>Your child can learn all this while travelling through adventures</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>

                            <div className='pricing-section'>
                                <div className='pricing-title'>
                                    <h1>PICK YOUR PLAN</h1>
                                </div>
                                <div className='container'>
                                    <div className='row justify-content-center'>
                                        {
                                            user?.Country === "India" || user === null || user === undefined ?
                                                packagesINR.toReversed().map((pkg) => {
                                                    if (pkg.Active !== 1) {
                                                        return null
                                                    }
                                                    return (
                                                        <PricingCard
                                                            key={pkg.Id}
                                                            packageId={pkg.Id}
                                                            type={pkg.Type}
                                                            amount={pkg.Amount}
                                                            name={pkg.Name}
                                                            currency={pkg.Currency}
                                                            currencySymbol={"₹"}
                                                            createOrder={craeteOrder}
                                                        />
                                                    )
                                                })
                                                :
                                                packagesUSD.toReversed().map((pkg) => {
                                                    if (pkg.Active !== 1) {
                                                        return null
                                                    }
                                                    return (
                                                        <PricingCard
                                                            key={pkg.Id}
                                                            packageId={pkg.Id}
                                                            type={pkg.Type}
                                                            amount={pkg.Amount}
                                                            name={pkg.Name}
                                                            currency={pkg.Currency}
                                                            currencySymbol={"$"}
                                                            createOrder={craeteOrder}
                                                        />
                                                    )
                                                })
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                }

                <div className="footer-copyright pb-2">
                    <p>© COPYRIGHT @ {JSON.stringify(new Date().getFullYear())} BAETHO TECHNOLOGIES PRIVATE LIMITED. ALL RIGHTS RESERVED.</p>
                    <Link to="/privacy-policy" className="nav-link text-blue d-inline-block">Privacy Policy</Link> | <Link to="/terms-of-use" className="nav-link text-blue d-inline-block">Terms of use</Link>
                </div>
            </div>
        </>
    )

}
export default Pricing;