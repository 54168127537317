import DataTable from "react-data-table-component";
import Swal from 'sweetalert2';
import Axios from 'axios'
import * as Constants from '../../../assets/helper/constants'
import { useEffect, useState } from "react";
import Header from "../../header/header";
import ImagePopup from "./imagePopup";
import LoaderComp from "../../loader";

function ProfileUpdate(props) {

    const [users, setUsers] = useState('');
    const [dataFromAPI, setDataFromAPI] = useState('');
    const [triggerImagePopup, setTriggerImagePopup] = useState(false)
    const [imageLink, setImageLink] = useState('')
    const [triggerVideoPopup, setTriggerVideoPopup] = useState(false)
    const [videoLink, setVideoLink] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [info, setInfo] = useState('')


    //Get users
    const getUsers = () => {
        setIsLoading(true)
        Axios.get(Constants.BASE_URL + `api/aggregator/getRequestsForApproval`).then((result) => {
            setIsLoading(false)
            if (result.data.status) {
                setUsers(result.data.result);
                setDataFromAPI(result.data.result);
            } else {
                setUsers([])
                setDataFromAPI([]);
            }
        })
    }

    useEffect(() => {
        getUsers()
    }, [])

    const approveHandler = (key, value, email) => {

        setIsLoading(true)
        let data = {
            email: email,
            [key]: value
        }

        Axios.post(Constants.BASE_URL + "api/aggregator/approveTeacherDetails", data, Constants.header).then((result) => {
            setIsLoading(false)
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "Approved succesfully",
                    showConfirmButton: false,
                    timer: 2000
                })

                getUsers()
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to approve.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        });
    }

    const customStyles = {
        headRow: {
            style: {
                // backgroundColor: '#fff',
            },
        },
        headCells: {
            style: {
                // color: '#777',
                fontSize: '16px',
                // fontWeight: '700',
                // paddingBottom: '20px',
                // paddingTop: '50px'
            },
        },
        rows: {
            style: {
                padding: "5px",
                // backgroundColor: '#fff',
                fontSize: '14px',
                // color: '#777',
            },
        },
        pagination: {
            style: {
                // border: 'none',
                // backgroundColor: '#fff',
                // fontSize: '14px'
            },
        },
    };


    const columns = [
        {
            name: 'Sl No.',
            grow: 0,
            center: true,
            selector: (row, index) => {
                return (
                    <div className="d-flex flex-column justify-content-between">
                        {index + 1}
                    </div>
                )
            }
        },
        {
            name: 'Name',
            grow: 2,
            wrap: true,
            left: true,
            selector: (row) => {
                return (
                    <div className="d-flex flex-column justify-content-between">
                        <span >{row.FirstName + ' ' + row.LastName}</span>
                    </div>
                )
            }
        },
        {
            name: 'Bio',
            maxWidth: '400px',
            center: true,
            grow: 2,
            wrap: true,
            selector: (row) => {
                return (
                    row.TempBio && row.TempBio !== "" ?
                        <div className="d-flex flex-column justify-content-between">
                            <span >{row.TempBio}</span>
                            <button className="approve-btn align-self-center" onClick={() => approveHandler('bio', row.TempBio, row.Email)}>Approve</button>
                        </div> : ''
                )
            }
        },
        {
            name: "Subjects",
            wrap: true,
            center: true,
            grow: 2,
            selector: (row) => {
                return (
                    row.TempSubjects && row.TempSubjects !== "" ?
                        <div className="d-flex flex-column justify-content-between">
                            <p>{row.TempSubjects}</p>
                            <button className="approve-btn align-self-center" onClick={() => approveHandler('subjects', row.TempSubjects, row.Email)}>Approve</button>
                        </div> : ''
                )

            }
        },
        {
            name: 'Photo',
            center: true,
            // maxWidth: '100px',
            grow: 1,
            selector: (row) => {
                return (
                    row.TempPhoto && row.TempPhoto !== "" ?
                        <div className="image-wrap">
                            <img className="view-image" alt="Profile" src={Constants.BASE_URL + '' + row.TempPhoto} onClick={() => toggleImagePopup(Constants.BASE_URL + '' + row.TempPhoto, 'photo', row.TempPhoto, row.Email)} />

                            {/* <button className="approve-btn align-self-center" onClick={() => approveHandler('photo', row.TempPhoto, row.Email)}>Approve</button> */}
                        </div> : ''

                )
            },
        },
        {
            name: "Banner Image",
            center: true,
            grow: 1,
            selector: (row) => {
                return (
                    row.TempBannerImage && row.TempBannerImage !== "" ?
                        <div className="image-wrap">
                            {row.TempBannerImage ? <img alt="Banner" className="view-image" src={Constants.BASE_URL + '' + row.TempBannerImage} onClick={() => toggleImagePopup(Constants.BASE_URL + '' + row.TempBannerImage, 'bannerImage', row.TempBannerImage, row.Email)} /> : ""}
                            {/* <button className="approve-btn align-self-center" onClick={() => approveHandler('bannerImage', row.TempBannerImage, row.Email)}>Approve</button> */}
                        </div> : ''
                )
            }
        },
        {
            name: "Video",
            center: true,
            grow: 3,
            selector: row => {
                return (
                    row.TempVideo && row.TempVideo !== "" ?
                        <div className="d-flex flex-column justify-content-center">
                            {row.TempVideo ? <p className="play-btn" onClick={() => toggleVideoPopup(Constants.BASE_URL + '' + row.TempVideo, 'video', row.TempVideo, row.Email)} > Play Video</p> : ""}
                            {/* <button className="approve-btn align-self-center" onClick={() => approveHandler('video', row.TempVideo, row.Email)}>Approve</button> */}
                        </div> : ''
                )
            }
        },
        {
            name: "Remove",
            center: true,
            grow: 2,
            selector: row => {
                return (
                    <div className="d-flex flex-column justify-content-end">
                        <button className="reject-btn align-self-center" onClick={() => removeHandler(row.Id)}>Remove</button>
                    </div>
                )
            }
        },
    ];

    const removeHandler = (userId) => {


        Swal.fire({
            title: "Remove?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(true)
                Axios.get(Constants.BASE_URL + `api/aggregator/removeUser?userId=${userId}`).then((result) => {
                    setIsLoading(false)
                    if (result.data.status) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: "Removed succesfully",
                            showConfirmButton: false,
                            timer: 2000
                        })
                        getUsers()

                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: "Unable to remove. Please try again.",
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }
                });
            }
        })
    }

    const toggleImagePopup = (imgURL, key, value, email) => {
        setTriggerImagePopup(!triggerImagePopup)
        setInfo({ key: key, value: value, email: email })
        setImageLink(imgURL)
    }
    const toggleVideoPopup = (videoURL, key, value, email) => {
        setTriggerVideoPopup(!triggerVideoPopup)
        setInfo({ key: key, value: value, email: email })
        setVideoLink(videoURL)
    }

    const searchTeacher = (e) => {
        if (e.target.value === '') {
            setUsers(dataFromAPI)
        } else {
            var result = dataFromAPI.filter((object) => {
                //console.log('obj', object.FirstName)
                return object.FirstName.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    object.LastName.toLowerCase().includes(e.target.value.toLowerCase())
            })
            setUsers(result)
            console.log('data', result)
        }
    }


    return (

        <div className="right-panel-container">
            <div className="container-fluid">
                <p className='section-name mb-4'>Profile Update</p>
                {isLoading ? <LoaderComp isLoading={isLoading} /> : null}
                <ImagePopup show={triggerImagePopup} onHide={toggleImagePopup} >
                    <img alt="popup image" className="popup-img" src={imageLink} />
                    <button className="btn bg-blue text-light mt-2" onClick={() => approveHandler(info.key, info.value, info.email)}>Approve</button>
                </ImagePopup>
                <ImagePopup show={triggerVideoPopup} onHide={toggleVideoPopup} >
                    <video width="854" height="480" controls>
                        <source src={videoLink} type="video/mp4" />
                    </video>
                    <button className="btn bg-blue text-light mt-2" onClick={() => approveHandler(info.key, info.value, info.email)}>Approve</button>
                </ImagePopup>
                <div className="row mb-4">
                    <div className="col-12">
                        <div className="card border-0 box-shadows">
                            <div className="card-header py-4">
                                <div className="row justify-content-end">
                                    <div className="col-lg-3 col-md-4 col-sm-12">
                                        <input onChange={searchTeacher} className="form-control input-style" placeholder="Search teacher" />
                                    </div>
                                </div>
                            </div>
                            <div className="data-container-wrapper d-flex flex-column justify-content-between">
                                <DataTable
                                    columns={columns}
                                    data={users}
                                    pagination
                                    customStyles={customStyles}
                                    persistTableHead
                                    fixedHeader
                                    fixedHeaderScrollHeight="650px"
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileUpdate;