import Accordion from 'react-bootstrap/Accordion';
// import Actions from '../commons/actions';


function Scene() {

    let featureProperties = [
        {
            name: 'Background Image',
            desc: "This property is used to set the background image of the scene."
        },
        {
            name: 'Background Color',
            desc: 'This property is used to set the background color of the scene if there is no background image.'
        },
        {
            name: 'Name',
            desc: 'This property is used to set a name for the scene.'
        },

    ]

    let events = [
        {
            name: "Touch",
            params: [
                {
                    paramName: "Tap",
                    paramDesc: "This trigger executes its actions when the user taps on this feature."
                },
                {
                    paramName: "Double Tap",
                    paramDesc: "This trigger executes its actions when the user double taps on this feature."
                },
                {
                    paramName: "Long Press",
                    paramDesc: "This trigger executes its actions when the user taps and holds the feature for sometime."
                },
                // {
                //   paramName: "Pressed",
                //   paramDesc: "This trigger executes its actions when the user has pressed down on this feature."
                // },
                // {
                //   paramName: "Released",
                //   paramDesc: " This trigger executes its actions when the user has pressed down and released this feature."
                // },
            ]
        },
        {
            event: "Swipe",
            name: "Swipe",
            desc: "This trigger executes its actions when the user swipes on the scene in the direction specified.",
            params: [
                {
                    paramName: "Swipe Type",
                    paramDesc: "Using this trigger the user can specify the direction for swipe."
                },
                {
                    paramName: "Key-Values",
                    paramDesc: "This is an optional parameter, we can set the length of the swipe into a key-value."
                },
                {
                    paramName: "Works On Features",
                    paramDesc: "When it is true, the actions will be applied on all the features of the Scene. When it is false, then the actions will only be applied on the scene background and not on any feature."
                },
            ]
        },
        {
            event: "Key Press",
            name: "Key Pressed",
            desc: "This trigger is called every time a key is pressed. It follows the key that is specified in the key type.",

            params: [
                {
                    paramName: "Key Type",
                    paramDesc: "Select the type of the key from the dropdown. You can also define custom key."
                },
                {
                    paramName: "Key",
                    paramDesc: "Character that it’s listening for."
                },
                {
                    paramName: "Case Sensitive",
                    paramDesc: "If enabled, it only listens for the specified character. Letter 'a' and letter 'A' are considered as different characters."
                }
            ]
        },
        {
            event: "Timer",
            name: "Timer",
            desc: "This starts a timer and executes the actions when the time is up. You can have it run multiple times by specifying the number of runs, 0 is to keep it running indefinitely.",
            params: [{
                paramName: "Duration",
                paramDesc: "Duration of time before executing actions."
            },
            {
                paramName: "Number Of Runs",
                paramDesc: "Specifies the number of runs, 0 is to keep it running indefinitely."
            }]
        },


        {
            event: "Scene",
            name: "Scene Opens",
            desc: "This trigger will execute as soon as the scene starts."
        }
    ]

    let actions = [
        {
            actionName: "Create Feature",
            action: [
                {
                    name: "Create Feature From Template",
                    desc: "This action creates a feature from Feature-Variables like image, video and GIF.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Feature Variable",
                            paramDesc: "This is the target feature variable."
                        },
                    ]
                },
            ]
        },
        {
            actionName: "Rewards",
            action: [
                {
                    name: "Confetti",
                    desc: "This action blasts colorful confetti all over the scene, to celebrate achievements.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Magnitude",
                            paramDesc: "This determines the quantity of confetti that can be High, Medium, or Low, depending on the requirement."
                        },
                        {
                            paramName: "Direction",
                            paramDesc: "This specifies the direction in which the confetti emits."
                        },
                    ]
                },
                {
                    name: "Stars",
                    desc: "Similar to confetti, this action blasts colorful star particles all over the scene, to celebrate achievements.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Magnitude",
                            paramDesc: "This determines the quantity of star particles that can be High, Medium, or Low, depending on the requirement."
                        },
                        {
                            paramName: "Direction",
                            paramDesc: "This specifies the direction in which the star particles emit."
                        },
                    ]
                },
            ]
        },

        {
            actionName: "Sound",
            action: [
                {
                    name: "Play Sound",
                    desc: "This action will play a specified audio.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Sound Asset",
                            paramDesc: "This is the sound file that will be played by this action."
                        },
                        // {
                        //   paramName: "Start Time",
                        //   paramDesc: "The audio will start from the specified time."
                        // },
                        {
                            paramName: "Loops",
                            paramDesc: "This specifies that the audio will start over again, every time it ends."
                        },
                        {
                            paramName: "Stop All Other Sounds",
                            paramDesc: "If enabled, it stops all the other sounds."
                        }
                    ]
                },
                {
                    name: "Text To Speech",
                    desc: "This action will convert given text to speech.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Text",
                            paramDesc: "This is the text that will be converted to speech."
                        },
                        {
                            paramName: "Accent",
                            paramDesc: "This specifies the accent of the voice. It can be Region based, American, British, or Indian."
                        },
                    ]
                },
                {
                    name: "Stop Sound",
                    desc: "This action will stop a specified audio.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Sound Asset",
                            paramDesc: "This is the sound file that will be stopped by this action."
                        },
                    ]
                },
                {
                    name: "Stop All Sounds",
                    desc: "This action will stop all the sounds.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                    ]
                }
            ]
        },




        // {
        //   actionName: "Scene",
        //   action: [
        //     {
        //       name: "Change Scene Background",
        //       desc: "This action will change scene background.",
        //       params: [
        //         {
        //           paramName: "Image Asset",
        //           paramDesc: "Image asset to be set as background."
        //         },
        //         {
        //           paramName: "Transition",
        //           paramDesc: "These are different effects to choose from to add style to your Lesson."
        //         },
        //         {
        //           paramName: "Effect",
        //           paramDesc: "The effect on the transition controls the manner in which the transition plays."
        //         },
        //         {
        //           paramName: "Duration",
        //           paramDesc: "This specifies the amount of time for the transition to take."
        //         },

        //       ]
        //     },
        //   ]
        // },

    ]


    return (
        <div className="content-main" >
            <h3 className="title">Scene</h3>
            <h3 className="sub-title">Description</h3>
            <p>A scene consists of a background and features like Images, Labels, Videos, GIFs, and others. A lesson consists of several scenes. Navigation between scenes can be done using Scene Actions. Triggers and Actions set for a particular scene work only for that scene.</p>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><h3 className="sub-title">Properties</h3></Accordion.Header>
                    <Accordion.Body>

                        <h3 className="sub-title-2">Feature Properties</h3>
                        <ul className="property-list">
                            {
                                featureProperties.map((element, index) => {
                                    return (
                                        <li>
                                            <span className="property">{element.name} : </span> {element.desc}
                                        </li>
                                    )
                                })
                            }
                        </ul>

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><h3 className="sub-title">Triggers</h3></Accordion.Header>
                    <Accordion.Body>
                        <ul className="property-list">
                            {
                                events.map((element, index) => {
                                    return (
                                        <>
                                            <span className="property">{element.event}</span>
                                            <li className="single-list">
                                                <span className="property">{element.name}: </span>{element.desc}
                                                <ul>
                                                    {
                                                        element.params ?
                                                            element.params.map(param => (
                                                                <li><span className="property">{param.paramName}: </span>{param.paramDesc}</li>
                                                            ))
                                                            : ""
                                                    }
                                                </ul>
                                            </li>
                                        </>
                                    )
                                })
                            }
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><h3 className="sub-title">Actions</h3></Accordion.Header>
                    <Accordion.Body>
                        <ul className="property-list">
                            {
                                actions.map((element, index) => {
                                    return (
                                        <>
                                            <span className="property">{element.actionName} </span>
                                            <ul>
                                                {element.action ?
                                                    element.action.map((action) => (
                                                        <li>
                                                            <span className="property">{action.name}: </span>{action.desc}
                                                            <ul>
                                                                {
                                                                    action.params ?
                                                                        action.params.map(param => (
                                                                            <li><span className="property">{param.paramName}: </span>{param.paramDesc}</li>
                                                                        ))
                                                                        : ""

                                                                }
                                                            </ul>
                                                        </li>
                                                    )) : ""
                                                }
                                            </ul>
                                        </>

                                    )
                                })
                            }
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div >
    );
}
export default Scene;
