import { Link } from "react-router-dom";
import BaethoLogo from '../../../assets/images/baetho-kids-logo.png'
import Navbar from "../navbar/navbar";


function TermsOfUse(params) {

    return (
        <div>
            <Navbar />

            <div className="container pb-5">
                <h5 className='my-4 fw-bold'>Terms of Use</h5>
                <p className='headline fw-bold'>About Baetho</p>
                <p>Baetho has a library of interactive activities for children aged 2.5-9 that you can pull out of your pocket anytime you want to occupy your child with some great, fun, and educational activities.</p>
                <p> With our new premium membership, you can get access to the best activities with new activities released every month and that’s not all.
                    Your child will be able to do the Adventure Academy courses, where he/she will go on adventures with our comedic character Herman Toothroth,
                    who hates going to bed, but when he falls asleep, he goes on an adventure. Each adventure has its own story, like waking up on a space shuttle or
                    on a pirate ship, and your child will love doing activities to help Herman get through the adventure. </p>

                <p className='headline fw-bold'>PAID SERVICE SUBSCRIPTION AND ACCESS TO USE</p>
                <p className='headline fw-bold'>Plans and Terms</p>
                <ul>
                    <li>
                        We may offer a varied number of plans with different term options.
                    </li>
                    <li>
                        We allow users to use the free section of the app without any plans and for the premium plans, the term we consider a week as 7 days and a year as 365 days.
                    </li>
                    <li>
                        The user may, based on his/her preference choose any one of them and pay the relevant amount for it.
                    </li>
                    <li>
                        User can view the details of selected subscription plan at "Profile Settings &gt; Manage Subscription" page.
                    </li>
                </ul>

                <p className='headline fw-bold'>Promotional Offers and Special Plans/Packs</p>
                <ul>
                    <li>
                        We may from time to time offer special promotional offers, plans, packs or memberships ("Offers") on our platform or via association with partners.
                    </li>
                    <li>
                        An offer or special plan/pack cannot be clubbed with another or exchanged for any other product or subscription.
                    </li>

                    <li>
                        We have the sole discretion to determine the offer or plan eligibility, and we reserve the right to revoke
                        an offer/special plan/pack and put the user account on hold in the event that we determine the user is not eligible.
                    </li>
                </ul>


                <p className='headline fw-bold'>Coupon</p>
                <ul>
                    <li>
                        As part of certain promotional offers or via sales from different platforms we might give out coupons.
                        We have the sole discretion to decide on how these coupons/vouchers are distributed and what kind of benefits they provide to the user.
                    </li>
                    <li>
                        User can redeem these coupons at the time of selecting a pack.
                    </li>
                    <li>
                        We may give more discounted packages to the users with valid coupons.
                        These coupons would have certain terms and conditions attached to it which would have been communicated to the user at the time of distribution or in other
                        communications made available to the user.
                    </li>
                </ul>

                <p className='headline fw-bold'>Auto-Renewal</p>
                <ul>
                    <li>
                        Users with an auto-renew subscription will have their subscription renewed automatically unless the user cancels it 24 hours before it renews, in order
                        to avoid billing of subscription fees for the renewal term.
                    </li>
                </ul>

                <p className='headline fw-bold'>Updating user's Payment Methods</p>
                <ul>
                    <li>In case of any payment issue faced during the auto-renewal of the subscription, we may ask you to update your payment details.</li>
                    <li>Following any update, you authorize us to continue to charge the applicable payment method(s).</li>
                </ul>

                <p className='headline fw-bold'>Cancellation and Refund</p>
                <ul>
                    <li>
                        Once a payment is made by you, it cannot be cancelled. To the extent permitted by the applicable law, payments are non-refundable and we do not provide refunds
                        or credits for any partial membership periods or unwatched content.
                    </li>
                    <li>
                        You can cancel  the selected subscription plan at "Profile Settings &gt; Manage Subscription" page so that auto-renewal won't happen.
                    </li>
                    <li>
                        In a scenario where you attempted to purchase a plan and your payment was deducted from your account/card but not received by Baetho, you should seek refund from the financial service provider.
                    </li>
                </ul>
                <p className='headline fw-bold'>Contact Information</p>
                <p> If you have any questions regarding products, services and applications please contact us at:</p>
                <p>
                    Name: Gautham Prakash<br></br>
                    Email: gautham@baetho.app
                </p>
                <p className='headline fw-bold'>Complaints</p>
                <p> For any complaints or concerns with regards to content and or comment, please contact us at:</p>
                <p> Email: support@baetho.app</p>
                <p>
                    Address: BAETHO TECHNOLOGIES PVT LTD<br></br>
                    no.198, Vinayaka Complex,<br></br>
                    2nd main, Old Airport Road, Domlur,<br></br>
                    Bangalore North, Bangalore 560071<br></br>
                </p>

            </div>
        </div>
    )
}
export default TermsOfUse