function Actions() {
    let actions = [
        {
            actionName: "Move & Resize",
            action: [
                {
                    name: "Move and Resize",
                    desc: "This action changes the position, width, height, and angle of the feature based on the specified values and animation parameters. We can also set target destination by clicking on 'Set Position' button.",
                    params: [
                        {
                            paramName: "X",
                            paramDesc: "This action changes the x property of the feature to the specified value."
                        },
                        {
                            paramName: "Y",
                            paramDesc: "This action changes the y property of the feature to the specified value."
                        },
                        {
                            paramName: "Width",
                            paramDesc: "This action changes the width property of the feature to the specified value."
                        },
                        {
                            paramName: "Height",
                            paramDesc: "This action changes the height property of the feature to the specified value."
                        },
                        {
                            paramName: "Angle",
                            paramDesc: "This action changes the angle property of the feature to the specified value."
                        },
                        {
                            paramName: "Set Position",
                            paramDesc: "This allows you to move the feature to the desired destination on the scene."
                        },
                    ]
                },
                {
                    name: "Move To Original Position",
                    desc: "This action moves the feature to its original position.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                    ]
                }

            ]
        },
        {
            actionName: "Move & Resize By Offset",
            action: [
                {
                    name: "X Offset",
                    desc: "This action changes the x property of the feature by adding the specified value.",
                    params: [
                        {
                            paramName: "X Offset",
                            paramDesc: "This is the value to be added to the current value."
                        }
                    ]
                },
                {
                    name: "Y Offset",
                    desc: "This action changes the y property of the feature by adding the specified value.",
                    params: [
                        {
                            paramName: "Y Offset",
                            paramDesc: "This is the value to be added to the current value."
                        }
                    ]
                },
                {
                    name: "Width Offset",
                    desc: "This action changes the width property of the feature by adding the specified value.",
                    params: [
                        {
                            paramName: "Width Offset",
                            paramDesc: "This is the value to be added to the current value."
                        }
                    ]
                },
                {
                    name: "Height Offset",
                    desc: "This action changes the height property of the feature by adding the specified value.",
                    params: [
                        {
                            paramName: "Height Offset",
                            paramDesc: "This is the value to be added to the current value."
                        }
                    ]
                },
                {
                    name: "Rotate By",
                    desc: "This action changes the angle property of the feature by adding the specified value.",
                    params: [
                        {
                            paramName: "Rotation Offset",
                            paramDesc: "This is the value to be added to the current value."
                        }
                    ]
                },
            ]
        },
        {
            actionName: "Order",
            action: [
                {
                    name: "Move To Front",
                    desc: "This action moves the feature to the front of all the features that are in the scene.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                    ]
                },
                {
                    name: "Move To Back",
                    desc: "This action moves the feature to the back of all the features that are in the scene.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                    ]
                },

            ]
        },
        {
            actionName: "Effects",
            action: [
                {
                    name: "Change Opacity",
                    desc: "This action changes the transparency of the feature to the specified value.",
                    params: [
                        {
                            paramName: "Opacity",
                            paramDesc: "The value to change transparency must be between 0 and 1."
                        },
                    ]
                },
                {
                    name: "Blinking",
                    desc: "This action causes the feature to blink based on the specified animation properties.",
                },
                {
                    name: "Bouncing",
                    desc: "This action causes the feature to bounce based on the specified animation properties.",
                    params: [
                        {
                            paramName: "Resize Factor",
                            paramDesc: "This is the target value by which the feature will be resized. The value must be between 0 and 1."
                        },
                    ]
                },
                {
                    name: "Color",
                    desc: "This action changes the color of the feature based on the specified animation properties.",
                    params: [
                        {
                            paramName: "Color",
                            paramDesc: "This is the target color."
                        },
                    ]
                },
                {
                    name: "Blur",
                    desc: " This action adds a blur effect to the feature based on the specified animation properties.",
                    params: [
                        {
                            paramName: "Magnitude",
                            paramDesc: "This is the magnitude value of the target. The higher the number, the blurrier the features."
                        },
                    ]
                },

            ]
        },
        {
            actionName: "Rewards",
            action: [
                {
                    name: "Confetti",
                    desc: "This action blasts colorful confetti for the feature, to celebrate achievements.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Magnitude",
                            paramDesc: "This determines the quantity of confetti that can be High, Medium, or Low, depending on the requirement."
                        },
                        {
                            paramName: "Direction",
                            paramDesc: "This specifies the direction in which the confetti emits."
                        },
                    ]
                },
                {
                    name: "Stars",
                    desc: "Similar to confetti, this action blasts colorful star particles for the feature, to celebrate achievements.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                        {
                            paramName: "Magnitude",
                            paramDesc: "This determines the quantity of star particles that can be High, Medium, or Low, depending on the requirement."
                        },
                        {
                            paramName: "Direction",
                            paramDesc: "This specifies the direction in which the star particles emit."
                        },
                    ]
                },
            ]
        },
        {
            actionName: "Delete Feature",
            action: [
                {
                    name: "Delete Feature",
                    desc: "This action deletes the feature from the screen.",
                    params: [
                        {
                            paramName: "Delay",
                            paramDesc: "The length of time to wait in seconds before performing the action."
                        },
                    ]
                },

            ]
        },

    ]
    return (
        <>
            <ul className="property-list">
                {
                    actions.map((element, index) => {
                        return (
                            <>
                                <span className="property">{element.actionName} </span>
                                <ul className="mb-3">
                                    {element.action ?
                                        element.action.map((action) => (
                                            <li>
                                                <span className="property">{action.name} : </span>{action.desc}
                                                <ul>
                                                    {
                                                        action.params ?
                                                            action.params.map(param => (
                                                                <li><span className="property">{param.paramName} : </span>{param.paramDesc}</li>
                                                            ))
                                                            : ""

                                                    }
                                                </ul>
                                            </li>
                                        )) : ""
                                    }
                                </ul>
                            </>

                        )
                    })
                }
            </ul>
        </>
    )
}

export default Actions;