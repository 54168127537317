import { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Constants from '../../../assets/helper/constants'
import Logo from '../../../assets/images/Baetho-Logo-white.png'

function TeacherLeftPanel(props) {

    const [clicked, setClicked] = useState(false)

    let tabs = [
        // {
        //     id: 1,
        //     name: "Upload Lesson",
        //     activeLink: "upload-lesson",
        //     link: Constants.UploadLesson,
        // },
        // {
        //     id: 2,
        //     name: "Lesson Status",
        //     activeLink: "lesson-status",
        //     link: Constants.LessonStatus,
        // },
        // {
        //     id: 3,
        //     name: "Documentation",
        //     activeLink: "documentation",
        //     link: Constants.Documentation,
        // },

        {
            id: 1,
            name: "Lessons",
            activeLink: "lessons",
            link: Constants.Projects,
        },
        {
            id: 2,
            name: "Lesson Performance",
            activeLink: "lesson-performance",
            link: Constants.LessonPerformance,
        },
        {
            id: 3,
            name: "Composer",
            activeLink: "composer",
            link: Constants.Composer,
        },
        {
            id: 4,
            name: "Profile",
            activeLink: "profile",
            link: Constants.TeacherProfile,
        },
        // {
        //     id: 5,
        //     name: "Documentation",
        //     activeLink: "documentation",
        //     link: Constants.Documentation,
        // },
    ];

    const setActive = (id) => {
        props.checkPath(id)
    }

    const toggleMenu = () => {
        setClicked(!clicked);
    }


    return (
        <>

            <div className="offcanvas offcanvas-start sidebar-nav sidebar-nav bg-blue text-white shadows border-0" data-bs-scroll="true" data-bs-backdrop tabIndex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
                <div className="offcanvas-header ms-auto">
                    <button type="button " className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-0 ">
                    <div className='leftpanel-logo'>
                        <img src={Logo} alt="Treehouse Logo" />
                    </div>
                    <ul className="nav flex-column gap-2 left-panel-menubar mb-4">
                        {tabs.map((element) => {
                            return (
                                <li key={element.id} className={props.activeLink === element.activeLink ? "d-flex justify-content-stretch align-items-baseline nav-item active-menu ps-3" : "d-flex justify-content-start align-items-baseline nav-item inactive ps-3"} onClick={(e) => { setActive(element.activeLink) }}>
                                    <i className={props.activeLink === element.activeLink ? `leftpanel-icons-black ${element.icon}` : `leftpanel-icons-white ${element.icon}`} aria-hidden="true"></i>
                                    <Link to={element.link} target={element.name === 'Documentation' ? '_blank' : ""} className={props.activeLink === element.activeLink ? "disable-link nav-link d-block w-100" : "nav-link d-block w-100"} onClick={toggleMenu}>

                                        <span className={props.activeLink === element.activeLink ? 'text-black ' : 'text-white'}>{element.name}</span>
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>














            {/* <div className="mobile-menu-icon" onClick={toggleMenu}>
                <i className={clicked ? "fa fa-times fa-2x" : "fa fa-bars fa-2x"}></i>
            </div>
            <div className={clicked ? "admin-panel-backdrop" : "backdrop-inactive"} onClick={toggleMenu}></div>
            <div className={clicked ? "left-panel-container mobile-left-panel mobile-container" : "left-panel-container mobile-container"}>
                <div className='leftpanel-logo'>
                    <img src={Logo} alt="Treehouse Logo" />
                </div>
                <ul className="nav flex-column gap-2 left-panel-menubar mb-4">
                    {tabs.map((element) => {
                        return (

                            <li key={element.id} className={props.activeLink === element.activeLink ? "nav-item active" : "nav-item inactive"} onClick={(e) => { setActive(element.activeLink) }}>
                                <Link to={element.link} target={element.name === 'Documentation' ? '_blank' : ""} className={props.activeLink === element.activeLink ? "disable-link nav-link" : "nav-link"} onClick={toggleMenu}>

                                    <span className={props.activeLink === element.activeLink ? 'text-black ' : 'text-white'}>{element.name}</span>
                                </Link>
                            </li>

                        )
                    })}
                </ul>
            </div> */}
        </>
    )
}

export default TeacherLeftPanel;