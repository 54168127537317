import React from 'react';
import { Link } from 'react-router-dom';

function Header(props) {
    return (
        <>

            <nav className="navbar navbar-expand-lg navbar-light bg-white py-3 mb-4 p-0 box-shadows">
                <div className="container-fluid">
                    <div className='d-flex'>
                        {/* Off canvas trigger */}
                        <button className="navbar-toggler toggler-left-panel navbar-light bg-light me-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">
                            <i className="fa fa-bars" aria-hidden="true"></i>
                        </button>
                        <div className="navbar-brand">Baetho</div>
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className=" fa fa-bars"></span>
                    </button>
                    <div className="collapse navbar-collapse pe-5" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <div className="nav-link" >
                                    {props.userName && props.userName !== undefined ? <h3 className='profile-name'>{props.userName}</h3> : ""}
                                </div>
                            </li>

                            <ul className="navbar-nav mb-2 mb-lg-0 " >
                                <li className="nav-item dropdown">
                                    <div className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fa fa-user-circle" aria-hidden="true"></i>
                                    </div>
                                    <ul className="dropdown-menu dropdown-menu-end border-0 shadow">
                                        {/* <li><Link className="dropdown-item">Profile</Link></li> */}
                                        <li><button type='button' onClick={props.logout} className="dropdown-item">Logout</button></li>
                                    </ul>
                                </li>
                            </ul>
                        </ul>
                    </div>
                </div>
            </nav>
















            {/* <div className="HeaderSection mt-3 pr-3 d-flex justify-content-between align-items-center">
                OffCanvas Trigger
                <div className='d-flex justify-contents-start align-items-center'>
                    <button className="navbar-toggler toggler-left-panel navbar-light bg-light me-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">
                        <i className="fa fa-bars fa-2x" aria-hidden="true"></i>
                    </button>

                    <p className='section-name'>{props.section}</p>
                </div>

                <div className="col-6 col-sm-6 col-md-6 col-lg-6 d-flex align-items-center justify-content-end" >
                    {props.userName && props.userName != undefined ? <h3 className='profile-name'>{props.userName}</h3> : ""}
                    <button className='admin-logout' onClick={props.logout}>Logout</button>
                </div>

                OffCanvas Trigger


                <div className='row h-100'>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6  d-flex align-items-center justify-content-start" >
                        <p className='section-name'>{props.section}</p>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 d-flex align-items-center justify-content-end" >
                        {props.userName && props.userName != undefined ? <h3 className='profile-name'>{props.userName}</h3> : ""}
                        <button className='admin-logout' onClick={props.logout}>Logout</button>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default Header