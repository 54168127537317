
import Header from "../../header/header"
import Metrics from "./metrics";
import AdsWatched from "./adsWatched";
import LessonTried from "./lessonsTried";

function MarketingAnalytics(props) {



    return (
        <div className="right-panel-container">

            <div className="container-fluid">

                {/* <div className="row mb-4">
                    <div className="col-12">
                        <Header section="Marketing Analytics" logout={props.logout} userName="Marketing Admin" />
                    </div>
                </div> */}
                <div className="row mb-4">
                    <div className="col-12">
                        <h2 className="section-name">Marketing Analytics</h2>
                    </div>
                </div>

                <Metrics />

                <div className="row">
                    <AdsWatched />
                    <LessonTried />
                </div>
            </div>
        </div>
    )
}
export default MarketingAnalytics