import { useState, useEffect } from "react";
import Header from "../../header/header";
import DataTable from "react-data-table-component";
// import Swal from 'sweetalert2';
import Axios from 'axios'
import * as Constants from '../../../assets/helper/constants'
import Loader from "react-loader-spinner";
import LoaderComp from "../../loader";


function LessonStatus(props) {

    const [userInfo, setUserInfo] = useState('')
    const [lessons, setLessons] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    //Get Lessons
    const getLessons = () => {

        setIsLoading(true)
        Axios.get(Constants.BASE_URL + `api/AggregatorLessons/getLessonStatusForTeacher?teacherId=${props.userInfo.Id}`).then((result) => {
            setIsLoading(false)
            if (result.data.status) {
                setLessons(result.data.result);
                // setDataFromApi(result.data.info);
            } else {
                setLessons([])
                // setDataFromApi([]);
            }
        })
    }

    useEffect(() => {
        setUserInfo(props.userInfo)
        getLessons()
    }, [])

    const customStyles = {
        headRow: {
            style: {
                // backgroundColor: '#fff',
            },
        },
        headCells: {
            style: {
                // color: '#777',
                fontSize: '16px',
                // fontWeight: '700',
                // paddingBottom: '20px',
                // paddingTop: '50px'
            },
        },
        rows: {
            style: {
                padding: "5px",
                // backgroundColor: '#fff',
                fontSize: '14px',
                // color: '#777',
            },
        },
        pagination: {
            style: {
                // border: 'none',
                // backgroundColor: '#fff',
                // fontSize: '14px'
            },
        },
    };

    const columns = [
        {
            name: 'Sl No.',
            grow: 0,
            selector: (row, index) => {
                return (
                    <div className="d-flex justify-content-left align-items-center">
                        {index + 1}
                    </div>
                )
            }
        },
        {
            name: 'Name',
            wrap: true,
            grow: 3,
            selector: (row) => {
                return (
                    <div className="d-flex justify-content-left align-items-center">
                        <span className="left-align">{row.Name}</span>
                    </div>
                )
            }
        },
        {
            name: 'Subject',
            center: false,
            wrap: true,
            selector: (row) => {
                return (
                    <div className="d-flex justify-content-left align-items-center">
                        <span className="left-align">{row.Subject}</span>
                    </div>
                )
            }
        },
        {
            name: 'Tags',
            wrap: true,
            grow: 3,
            selector: (row) => {
                let arr = row.Tags.split(',')
                return (
                    <div className="d-flex justify-content-start align-items-center flex-wrap">
                        {arr.map((tag) => {
                            return (<span className="tags badge-inverse-primary">{tag}</span>)
                        })}
                    </div>
                )
            }
        },
        {
            name: 'Description',
            grow: 3,
            wrap: true,
            selector: (row) => {
                return (
                    <div className="d-flex justify-content-left align-items-center">
                        <span className="left-align">{row.Description}</span>
                    </div>
                )
            }
        },
        {
            name: 'Status',
            wrap: true,
            selector: (row) => {
                return (
                    <div className="d-flex justify-content-left align-items-center">
                        {row.Approved === 0 ? <span className="pending-status">Pending</span>
                            : row.Approved === 1 ? <span className="approved-status">Approved</span>
                                : <span className="rejected-status">Rejected</span>}
                    </div>
                )
            }
        },
        {
            name: 'Rejection Reason',
            grow: 2,
            wrap: true,
            center: false,
            selector: (row) => {
                return (
                    <div className="d-flex justify-content-left align-items-center">
                        <span className="left-align">{row.Rejection_Info}</span>
                    </div>
                )
            }
        },
    ];

    return (
        <div className="right-panel-container">
            {/* <div className="row header-wrap">
                <div className="col-12 p-0">
                    <Header section="Lesson Status" logout={props.logout} userName={props.userInfo.FirstName + ' ' + props.userInfo.LastName} />
                </div>
            </div> */}
            <div className="container-fluid">
                {isLoading ? (<LoaderComp isLoading={isLoading} />) : null}
                {/* <ImagePopup show={triggerImagePopup} onHide={toggleImagePopup} >
                <img alt="popup image" className="popup-img" src={imageLink} />
            </ImagePopup>
            <ImagePopup show={triggerVideoPopup} onHide={toggleVideoPopup} >
                <video width="854" height="480" controls>
                    <source src={videoLink} type="video/mp4" />
                </video>
            </ImagePopup> */}
                <p className='section-name mb-4'>Lesson Status</p>
                <div className="row ">
                    <div className="col-12">
                        <div className="data-container-wrapper d-flex flex-column justify-content-between">

                            <DataTable
                                columns={columns}
                                data={lessons}
                                pagination
                                customStyles={customStyles}
                                persistTableHead
                                fixedHeader
                                fixedHeaderScrollHeight="650px"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LessonStatus;