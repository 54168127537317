
import './App.css';
import { Route, Switch, HashRouter, Redirect } from 'react-router-dom';
import ForgotPassword from './components/forgotPassword';
import 'bootstrap/dist/css/bootstrap.min.css'
import VerifyEmail from './components/verifyEmail';
import Login from './components/Login/login'
import * as Constants from '../src/assets/helper/constants';
import Contents from './components/contents/contents';
import Home from './components/home/homepage/home';
import Main from './components/documentation/main/main';
import PrivacyPolicy from './components/home/privacyPolicy/privacyPolicy';
import AboutUs from './components/home/aboutUs/aboutUs';
import AppDownloadCapture from './components/appDownlods/AppDownloadCapture';
import Unsubscribe from './components/home/unsubscribe/unsubscribeUser';
import TermsOfUse from './components/home/TermsOfUse/TermsOfUse';
import Pricing from './components/home/pricing/pricing';
import ResetPassword from './components/reset-password/resetPassword';
function App() {
  return (

    <HashRouter>
      <div className="App">

        <Switch>
          <Route path={Constants.ResetPassword} component={() => <ResetPassword pageType="ForgotPassword" />} />
          <Route path="/emailVerification/*" component={() => <VerifyEmail pageType="VerifyEmail" />} />
          <Route exact path={Constants.Login} component={() => <Login pageType="Login" />} />
          {/* <Route path={Constants.LessonsDashboard} component={() => <Contents pageType="Normal" />} />
          <Route path={Constants.InitialPath} component={() => <Contents pageType="Normal" />} /> */}
          <Route path={Constants.Contents} component={() => <Contents pageType="Normal" />} />
          <Route exact path={Constants.PrivacyPolicy} component={() => <PrivacyPolicy />} />
          <Route exact path={Constants.TermsOfUse} component={() => <TermsOfUse />} />
          <Route exact path={Constants.InitialPath} component={() => <Home pageType="Normal" />} />
          <Route exact path={Constants.AboutUs} component={() => <AboutUs />} />
          <Route exact path={Constants.Pricing} component={() => <Pricing />} />
          <Route exact path={`${Constants.AppDownloads}/*`} component={() => <AppDownloadCapture />} />
          <Route exact path={`${Constants.UnsubscribeEmails}`} component={() => <Unsubscribe />} />

          {/* Documentation */}
          <Route exact path={Constants.Documentation}>
            <Redirect to={Constants.Documentation + '/Lesson'} />
          </Route>
          <Route exact path={Constants.Documentation + '/*'} component={() => <Main />} />
        </Switch>
      </div>
    </HashRouter>

  );
}

export default App;
