import React, { useEffect, useState } from 'react';
import * as Constants from '../../../../assets/helper/constants'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import CheckBlue from '../../../../assets/images/check-blue.svg';
import { Link, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import Axios from 'axios';
// import Chats from './chats/chats';


function AdminTimeline() {
    const [status, setStatus] = useState('')
    const history = useHistory()
    const [key, setKey] = useState('home');
    const params = useParams();

    const goBack = (e) => {
        history.goBack()
    }

    useEffect(() => {
        getStatus()
    }, [])

    const getStatus = () => {

        let projectId = params.projectId;

        Axios.get(Constants.BASE_URL + `api/teacher/getStatusForTimeline?id=${projectId}`).then((result) => {

            if (result.data.status) {
                setStatus(result.data.result[0])
            } else {
                setStatus('')
            }
        })
    }

    return (
        <>
            <div className='right-panel-container text-left'>
                <div className='container-fluid'>

                    <div className="row mb-4">
                        <div className="col-12">
                            <h2 className="section-name">Manage Lessons</h2>
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col-12'>
                            <button onClick={goBack} className="btn me-auto "><i className="fa fa-chevron-left me-2"></i>Back</button>
                        </div>
                    </div>


                    <div className='row'>
                        <div className='col-lg-6 col-md-12 mb-4 '>
                            <VerticalTimeline lineColor='#777'
                                layout='1-column-left'
                                className='py-1'
                            >
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work mb-0 "
                                    iconStyle={true ? { background: 'rgb(255, 255, 255)' } : { background: 'rgb(119, 119, 119)' }}
                                    contentStyle={{ width: '60%', marginBottom: '0px' }}
                                    icon={status.lessonPlanStatus === 0 || status.lessonPlanStatus === 1 || status.lessonPlanStatus === 2 ?
                                        <i className="fa fa-check-circle fa-3x fa-blue" aria-hidden="true"></i>
                                        : <i className="fa fa-check-circle fa-3x fa-gray" aria-hidden="true"></i>
                                    }
                                ><div className='card'>

                                        <div className='card-body'>
                                            <h5 className="card-title">Lesson Plan Submission</h5>
                                            <hr></hr>
                                            {/* <h5 className="vertical-timeline-element-subtitle text-left fw-500">
                                            Lesson Plan Submission
                                        </h5> */}
                                            {/* <hr></hr> */}

                                            <div className='d-flex align-items-baseline '>
                                                {status.lessonPlanStatus == 0 ? <span className='pending-status px-2'>In Review</span>
                                                    : status.lessonPlanStatus == 1 ? <span className='approved-status px-2'>Approved</span>
                                                        : status.lessonPlanStatus == 2 ? <span className='rejected-status px-2'>Rejected</span>
                                                            : ""
                                                }

                                                <Link to={`${Constants.AdminLessonPlan}/${params.projectId}`}> <span className='ms-3 btn timeline-details-btn'>See details <i className='fa fa-arrow-right'></i></span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work "
                                    iconStyle={true ? { background: 'rgb(255, 255, 255)' } : { background: 'rgb(119, 119, 119)' }}
                                    contentStyle={{ width: '60%', marginTop: '0px' }}
                                    icon={status.lessonPlanStatus === 1 ?
                                        <i className="fa fa-check-circle fa-3x fa-blue" aria-hidden="true"></i>
                                        : <i className="fa fa-check-circle fa-3x fa-gray" aria-hidden="true"></i>
                                    }
                                >
                                    <div className='card'>
                                        <div className='card-body'>
                                            <h6 className="fw-500 mb-0"> Lesson Plan Approved</h6>
                                        </div>
                                    </div>
                                </VerticalTimelineElement>

                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work mb-0 "
                                    iconStyle={true ? { background: 'rgb(255, 255, 255)' } : { background: 'rgb(119, 119, 119)' }}
                                    contentStyle={{ width: '60%', marginBottom: '0px' }}
                                    icon={status.lessonStatus == 0 || status.lessonStatus == 1 || status.lessonStatus == 2 || status.lessonStatus == 4 ?
                                        <i className="fa fa-check-circle fa-3x fa-blue" aria-hidden="true"></i>
                                        : <i className="fa fa-check-circle fa-3x fa-gray" aria-hidden="true"></i>
                                    }
                                >
                                    <div className='card'>
                                        <div className='card-body'>

                                            <h5 className="card-title">
                                                Lesson Submission
                                            </h5>
                                            <hr></hr>
                                            <div className='d-flex align-items-baseline'>
                                                {status.lessonStatus == 4 ? <span className='pending-status px-2'>In Review</span>
                                                    : status.lessonStatus == 1 ? <span className='approved-status px-2'>Approved</span>
                                                        : status.lessonStatus == 2 ? <span className='rejected-status px-2'>Rejected</span>
                                                            : ""
                                                }
                                                {status.lessonStatus != null ? < Link to={`${Constants.ValidateLesson}/${params.projectId}`}> <p className='ms-3 text-dark'>See details <i className='fa fa-arrow-right'></i></p></Link>
                                                    : <p className='ms-3 text-muted'>See details <i className='fa fa-arrow-right'></i></p>}
                                            </div>
                                        </div>
                                    </div>


                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work "
                                    iconStyle={true ? { background: 'rgb(255, 255, 255)' } : { background: 'rgb(119, 119, 119)' }}
                                    contentStyle={{ width: '60%', marginTop: '0px' }}
                                    icon={status.lessonStatus == 1 ?
                                        <i className="fa fa-check-circle fa-3x fa-blue" aria-hidden="true"></i>
                                        : <i className="fa fa-check-circle fa-3x fa-gray" aria-hidden="true"></i>
                                    }
                                >
                                    <div className='card'>
                                        <div className='card-body'>
                                            <h6 className="fw-500 mb-0"> Lesson Approved</h6>
                                        </div>
                                    </div>
                                </VerticalTimelineElement>

                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work mb-0 "
                                    iconStyle={true ? { background: 'rgb(255, 255, 255)' } : { background: 'rgb(119, 119, 119)' }}
                                    contentStyle={{ width: '60%', marginBottom: '0px' }}
                                    icon={status.updateStatus == 1 || status.updateStatus == 2 ?
                                        <i className="fa fa-check-circle fa-3x fa-blue" aria-hidden="true"></i>
                                        : <i className="fa fa-check-circle fa-3x fa-gray" aria-hidden="true"></i>
                                    }
                                >
                                    <div className='card'>
                                        <div className='card-body'>
                                            <h5 className="card-title">
                                                Lesson Update
                                            </h5>
                                            <hr></hr>
                                            <div className='d-flex align-items-baseline'>
                                                {status.updateStatus == 1 ? <span className='pending-status px-2'>In Review</span>
                                                    : status.updateStatus == 2 ? <span className='rejected-status px-2'>Rejected</span>
                                                        : ""
                                                }
                                                {status.lessonStatus != null
                                                    ? <Link to={`${Constants.AdminLessonUpdate}/${params.projectId}`}> <p className='ms-3 text-dark'>See details <i className='fa fa-arrow-right'></i></p></Link>
                                                    : <p className='ms-3 text-muted'>See details <i className='fa fa-arrow-right'></i></p>}
                                            </div>
                                        </div>
                                    </div>

                                </VerticalTimelineElement>


                            </VerticalTimeline>
                        </div>
                        <div className='col-lg-6 col-md-12'>
                            {/* <Chats /> */}
                        </div>
                    </div>
                </div>
            </div >

        </>
    );
}

export default AdminTimeline;