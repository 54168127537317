import { useEffect, useState } from "react";
import Header from "../../header/header";
import Axios from 'axios'
import * as Constants from '../../../assets/helper/constants'
import Swal from 'sweetalert2';
import LoaderComp from "../../loader";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";


function LessonsPerformance(props) {

    const [lessons, setLessons] = useState('')
    const [dataFromAPI, setDataFromAPI] = useState('')
    const [individualLesson, setIndividualLesson] = useState('')
    const [completed, setCompleted] = useState('')
    const [dropOff, setDropOff] = useState('')
    const [showLesson, setShowLesson] = useState(false)
    const [lessonName, setLessonName] = useState(false)
    const [teacherName, setTeacherName] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')


    useEffect(() => {
        getLessons()
    }, [])

    const searchData = (e) => {
        if (e.target.value === '') {
            setLessons(dataFromAPI)
        } else {
            let filteredData = dataFromAPI.filter((item) => {
                return item.TeacherName?.toLowerCase().includes(e.target.value) ||
                    item.Name?.toLowerCase().includes(e.target.value)
            })
            setLessons(filteredData)
        }
    }

    const getLessons = () => {
        setIsLoading(true)

        Axios.get(Constants.BASE_URL + "api/dashboard/getAllLessonsPerformanceForAdmin").then((result) => {
            setIsLoading(false)
            if (result.data.status) {
                setLessons(result.data.result)
                setDataFromAPI(result.data.result)
            } else {
                setLessons([])
                setDataFromAPI([])
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to fetch lessons.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        });
    }

    const getFilteredData = (e) => {
        e.preventDefault()

        if (fromDate.trim() === '') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "From-Date cannot be empty.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }
        if (toDate.trim() === '') {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "To-Date cannot be empty.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }
        if (fromDate > toDate) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "From-Date cannot be greater than To-Date.",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }
        setIsLoading(true)

        Axios.get(Constants.BASE_URL + `api/dashboard/getAllLessonsPerformanceForAdmin?fromDate=${fromDate}&toDate=${toDate}`).then((result) => {
            setIsLoading(false)
            if (result.data.status) {
                setLessons(result.data.result)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to fetch lessons.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        });
    }



    const customStyles = {
        headRow: {
            style: {
                // backgroundColor: '#fff',
            },
        },
        headCells: {
            style: {
                // color: '#777',
                fontSize: '16px',
                // fontWeight: '700',
                // paddingBottom: '20px',
                // paddingTop: '50px'
            },
        },
        rows: {
            style: {
                padding: "5px",
                // backgroundColor: '#fff',
                fontSize: '14px',
                // color: '#777',
            },
        },
        pagination: {
            style: {
                // border: 'none',
                // backgroundColor: '#fff',
                // fontSize: '14px'
            },
        },
    };

    const lessonColumns = [
        {
            name: 'Sl No.',
            grow: 0,
            // center: true,
            selector: (row, index) => {
                return (
                    <div className="">
                        {index + 1}
                    </div>
                )
            }
        },
        {
            name: 'Lesson Name',
            grow: 2,
            wrap: true,
            center: false,
            sortable: true,
            selector: (row) => row.Name
        },
        {
            name: 'Teacher Name',
            maxWidth: '400px',
            // center: true,
            grow: 2,
            wrap: true,
            sortable: true,
            selector: (row) => row.TeacherName
        },
        {
            name: "Views",
            wrap: true,
            // center: true,
            sortable: true,
            grow: 1,
            selector: (row) => row.TotalViews
        },
        {
            name: 'Completed',
            // center: true,
            // maxWidth: '100px',
            sortable: true,
            grow: 1.5,
            selector: (row) => row.TotalCompletedViews
        },
        {
            name: "Drop offs",
            // center: true,
            sortable: true,
            grow: 1,
            selector: (row) => row.Dropoff
        },
        {
            name: "Completed%",
            // center: true,
            grow: 1.5,
            sortable: true,
            selector: row => (isNaN(((row.TotalCompletedViews / row.TotalViews) * 100).toFixed(2)) ? 0 : ((row.TotalCompletedViews / row.TotalViews) * 100).toFixed(2))
        },
        {
            name: "Drop off%",
            // center: true,
            grow: 1,
            sortable: true,
            selector: row => {
                return (isNaN((((row.Dropoff) / row.TotalViews) * 100).toFixed(2)) ? 0 : (((row.Dropoff) / row.TotalViews) * 100).toFixed(2))
            }
        },
        {
            name: "View Lesson",
            // center: true,
            grow: 1,
            selector: row => {
                return (
                    <div className="d-flex">
                        {/* <button className="btn bg-blue btn-sm text-light"
                            onClick={() => getIndividualLesson(row.Id, row.Name, row.TeacherName)}>View <i className="fa fa-chevron-right"></i>
                        </button> */}
                        <Link to={Constants.IndividualLessonPerformance + '/' + row.LessonId}
                            className="btn btn-sm bg-blue text-light"
                        >
                            View
                            <i className="fa fa-chevron-right ms-1"></i>
                        </Link>
                    </div>
                )
            }
        },
    ];



    return (
        <div className="right-panel-container">
            <div className="container-fluid">
                {isLoading ? <LoaderComp isLoading={isLoading} /> : null}

                <div className="row mb-4">
                    <div className="col-12">
                        <h2 className="section-name ">Lesson Performance</h2>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12">
                        <div className="card border-0 box-shadows">
                            <div className="card-header py-4">
                                <div className="row justify-content-between">
                                    <div className="col-lg-6 col-md-12">
                                        <form onSubmit={getFilteredData}>
                                            <div className="row">
                                                <div className="col">
                                                    <label>From</label>
                                                    <input onChange={(e) => setFromDate(e.target.value)} type="date" className="form-control input-style" required />
                                                </div>
                                                <div className="col">
                                                    <label>To</label>
                                                    <input onChange={(e) => setToDate(e.target.value)} type="date" className="form-control input-style" required />
                                                </div>
                                                <div className="col-2 d-flex align-items-end">
                                                    <button className="btn bg-blue text-light">Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-lg-3 col-md-4 align-self-end">
                                        <input onChange={searchData} className="form-control input-style" placeholder="Search" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body data-container-wrapper ">
                                <DataTable
                                    columns={lessonColumns}
                                    data={lessons}
                                    pagination
                                    customStyles={customStyles}
                                    persistTableHead
                                    fixedHeader
                                    fixedHeaderScrollHeight="650px"
                                    highlightOnHover
                                    responsive
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LessonsPerformance;