import { Link } from 'react-router-dom';
import BaethoLogo from '../../../assets/images/baetho-kids-logo.png'
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";
import * as Constants from '../../../assets/helper/constants'

function Navbar() {

    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [user, setUser] = useState(null)
    const history = useHistory()
    useEffect(() => {
        let userDetails = JSON.parse(localStorage.getItem("userDetails"))

        if (userDetails !== null && userDetails !== undefined) {
            setIsLoggedIn(true)
            setUser(userDetails)
        } else {
            setIsLoggedIn(false)
        }

    }, [])

    const logoutHandler = () => {
        Swal.fire({
            title: "Are you sure you want to logout?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('userDetails')
                //localStorage.removeItem('rememberme')
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Logged Out Successfully',
                    showConfirmButton: false,
                    timer: 3000
                })
                history.push('/Login')
            }
        })
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-blue shadow mb-4 ">
            <div className="container">
                <Link to='/' className="navbar-brand"> <img src={BaethoLogo} width={170} alt="logo" /></Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse mr-" id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item active ">
                            <Link to="/" className="nav-link text-light">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/about-us" className="nav-link text-light">About Us</Link>
                        </li>
                        <li className="nav-item me-2">
                            <Link to="/pricing" className="nav-link text-light">Pricing</Link>
                        </li>
                        <li className="nav-item">
                            {isLoggedIn ?

                                <div className="dropdown">
                                    <button className="btn text-light dropdown-toggle pt-2 ps-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {user.FirstName}
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <Link to={Constants.UserProfile} className="dropdown-item text-dark" >Profile</Link>
                                        <span className="dropdown-item" type="button" onClick={logoutHandler}>Logout</span>
                                    </div>
                                </div>
                                :
                                <Link to="/login" className="nav-link text-light">Login</Link>}
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
export default Navbar