import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import * as Constants from '../../../../assets/helper/constants'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import Swal from "sweetalert2"
import Axios from 'axios';
import LoaderComp from "../../../loader";
import RejectLessonPlanPopup from "./RejectLessonPlanPopup"

function AdminLessonPlan(props) {

    const [fileDownloadLink, setFileDownloadLink] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [showRejectPlanPopup, setShowRejectPlanPopup] = useState(false)
    const [messages, setMessages] = useState([])
    const params = useParams()

    const history = useHistory()

    const goBack = (e) => {
        history.goBack()
    }

    useEffect(() => {
        getLessonPlanFilePath()
        getMessage()
    }, [])

    /**
        * Get lesson plan file download link
        */
    const getLessonPlanFilePath = () => {
        setIsLoading(true)
        let projectId = params.projectId;

        Axios.get(Constants.BASE_URL + `api/teacher/getLessonPlanDownloadLink?id=${projectId}`).then((result) => {
            setIsLoading(false)
            if (result.data.status) {
                setFileDownloadLink(result.data.result[0].LessonPlanPath)
            } else {
                setFileDownloadLink('')
            }

        }).catch((error) => {
            console.log('error', error)
        })
    }

    const getMessage = () => {
        let projectId = params.projectId;


        Axios.get(Constants.BASE_URL + `api/teacher/getMessages/${projectId}`).then((result) => {

            if (result.data.status) {
                if (result.data.result.length > 0) {
                    let msg = JSON.parse(result.data.result[0].Message)

                    let rejected = msg.filter(message => {

                        return message.type === 'rejectedPlan' && message.answered === false
                    })
                    console.log('ans', rejected)
                    setMessages(rejected)
                } else {
                    setMessages([])
                }
            } else {
                setMessages([])
            }
        })
    }

    const rejectLessonPlan = (inputList) => {

        let data = {
            projectId: params.projectId,
            message: inputList,
            from: props.userInfo.FirstName + ' ' + props.userInfo.LastName,
            senderId: props.userInfo.Id
        }
        setIsLoading(true)
        Axios.put(Constants.BASE_URL + `api/AggregatorLessons/rejectLessonPlan`, data, Constants.header).then((result) => {
            setIsLoading(false)
            if (result.data.status) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "Lesson plan rejected.",
                    showConfirmButton: false,
                    timer: 2000
                })
                setShowRejectPlanPopup(false)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Unable to reject.",
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    const approveLessonPlan = (e) => {

        let data = {
            projectId: params.projectId
        }

        Swal.fire({
            title: "Approve Lesson Plan?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(true)
                Axios.put(Constants.BASE_URL + `api/AggregatorLessons/approveLessonPlan`, data, Constants.header).then((result) => {
                    setIsLoading(false)
                    if (result.data.status) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: "Lesson plan approved.",
                            showConfirmButton: false,
                            timer: 2000
                        })
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: "Unable to approve.",
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }
                })

            }
        })
    }

    const togglePopup = (e) => {
        setShowRejectPlanPopup(!showRejectPlanPopup)
    }

    return (
        <div className='right-panel-container'>
            <div className='container-fluid'>
                {isLoading ? <LoaderComp isLoading={isLoading} /> : null}
                <RejectLessonPlanPopup
                    show={showRejectPlanPopup}
                    onHide={togglePopup}
                    rejectPlanFunc={rejectLessonPlan}
                />
                <div className="row mb-4">
                    <div className="col-12">
                        <h2 className="section-name">Lesson Plan</h2>
                    </div>
                </div>

                <div className='row mb-4'>
                    <div className='col-12 text-left'>
                        <button onClick={goBack} className="btn me-auto "><i className="fa fa-chevron-left me-2"></i>Back</button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 col-sm-12 text-left">
                        <div className="card ">
                            <h5 className="card-header">Download Lesson Plan</h5>
                            <div className="card-body">
                                {/* <h5 className="card-title">Download the lesson plan</h5> */}
                                <p className="card-text">Download the lesson plan file.</p>
                                <a href={fileDownloadLink !== null
                                    ? `${Constants.BASE_URL}${fileDownloadLink}`
                                    : ""}
                                    target="_blank"
                                    download
                                    className="btn btn-blue text-light mb-md-2">
                                    <i className="fa fa-download text-light me-2"></i>
                                    {fileDownloadLink.split('\\')[4]}
                                </a>
                            </div>

                            <div className="card-footer text-end">
                                <button className="btn btn-sm btn-danger ms-auto me-4" onClick={(e) => togglePopup(e)}>Reject</button>
                                <button className="btn btn-sm btn-success" onClick={approveLessonPlan}>Approve</button>
                            </div>
                        </div>
                        {/* <div className="content-box shadows ">
                            <div className="row mb-4">
                                <div className="col d-flex align-items-center">
                                    <h4>Review Lesson plan</h4>
                                    <span className='approved-status px-2 ms-3'>Approved</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <button className="btn btn-blue text-light"><i className="fa fa-download text-light me-2"></i>Download Lesson Plan</button>

                                </div>
                            </div>

                            <div className="row">
                                <div className="col d-flex justify-content-end">
                                    <button className="btn btn-success me-3">Approve</button>
                                    <button className="btn btn-danger">Reject</button>
                                </div>
                            </div>

                        </div> */}

                    </div>
                </div>


            </div>
        </div>
    )
}

export default AdminLessonPlan